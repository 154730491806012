let stringLanguage_enGB = {
    //Login    
    relancerAuthent:"Restart the authentication",
    //Sidebar
    carte:"Map",
    btnHarvestOnSidebar:"Corn harvests",
    btnModulationOnSidebar:"Prescription maps",
    btnBiomassOnSidebar:"Rapeseed weighings",
    btnFertilizerOnSidebar:"Nitrogen recommendation",
    parcellesListBtnOnSidebar:"Fields",
    parcellesImportBtnOnSidebar:"Import boundaries",
    observationBtnOnSidebar:"Scouting",
    aide:"Help",
    allHelps:"Useful Tips",
    termsAndConditionsSpotifarm:"Show Spotifarm's terms and conditions?",
    dependenciesApi:"Display all dependencies and APIs used in our product below",
    indicevege:"Understand our vegetation index",
    language:"Language",
    //importer mes parcelles
    importparcelles:"Enter your farm name:",
    provfichier:"Import field boundaries",
    aideimport:"Need help to import your field boundaries ?",
    aideimportParcelles:"Need help to retrieve your fields?",
    autrefichier:"Other",
    selectionnerfichier:"Select the File",
    enregistrerparcelles:"Save the Fields",
    annulerimport:"Cancel",
    aideimportredirect:"If you do not have a file with your fields boundaries, you can draw them directly on the map",
    importFichiersProvenance:"Source of files",
    importFichiersType:"File Type",
    importFichiersTypeZip:"Zip file",
    importFichiersTypeIndividuels:"Single Files",
    importParcellesGif:"File Importation GIF Help",
    // Header
    seconnecter:"Login",
    creationcompte:"New Account",
    changePassword:"Change my password",
    disconnect:"Logout",
    confirmDisconnection:"Are you sure you want to disconnect?",
    confirmDeleteAccount:"Are you sure you want to delete your account?",
    confirmChangePassword:"Are you sure you want to change your password?",
    confirmChangePwd:"Change",
    parametres:"Settings",
    notifications:"Notifications",
    historique:"History",
    parametrage:"Settings",
    compte:"Account",
    plus:"More",
    monprofil:"My Profile",
    calendrier:"Calendar",
    tooltipDisableFilter:"Enable/Disable the Filter",
    farmsLabel:"Select the Farm:",
    cropsLabel:"Crop:",
    nameLabel:"Field name:",
    allFarmFilter:"All",
    allCropFilter:"All",
    noFarmNameFilter:"<without name>",
    noCropNameFilter:"<without name>",
    selectedFarmsCounter:" filtered farms",
    selectedCropsCounter:" filtered crops",
    filterAreaHelper:"Enter an area to apply the filter",
    selectionnerhistorique:"Click a field to display the history",
    news:"What's New",
    tutoVideos:"Video tutorials",
    contactSupport:"Contact the CCS",
    shareApp:"Share the app",
    joinWhatsappGroup:"Join the whatsapp group",
    rateUs:"Rate the app",
    aboutUs:"About",
    removeClientFromNativeApp:"Delete my account and data",
    titleConfirmRemoveAccount:"Confirmation before delete",
    askConfirmRemoveAccount:"Are you sure you want us to delete your account and all your data?",
    cancelRemoveAccount:"Cancel",
    validRemoveAccount:"Confirm",
    //Notifications:
    conseil:"Recommendations:",
    incitationWellcomeTitle:"Welcome to your Spotifarm Account",
    incitationWellcomeTextWithDiscountPV:"Use the promo code: '#LECODEPROMO#' to get two months free.",
    incitationWellcomeTextWithDiscount:"Use the promo code: '#LECODEPROMO#' to get a discount on the subscription.",
    incitationWellcomeText:"A PRO version exists...",
    incitationTitle:"Wanna more views 🛰?",
    invitationPremium:"Unauthorised feature with your current plan. Please change your subscription.",
    invitationPremiumLayersGlobal:"The global layers are reserved for the PRO version.",
    invitationPremiumLayersGlobalForMobile:"This functions are reserved for the Spotifarm full version subscribers.",
    invitationPremiumNoLimitParcels:"You are limited to 3 Fields...",
    invitationPremiumNoLimitHitoricParcels:"You cannot generate the field history...",
    invitationPremiumSolution:"Discover Spotifarm PRO and activate all the other features!",
    invitationPremiumAction:"I want to try SpotiFarm PRO >>",
    incitationMoreInfos:"Consult our website for more information: ",
    invitationPremiumPlusSolution:"Discover the Spotifarm PRO version and activate all the functionalities including the Prescription Map!",
    invitationPremiumPlusSolutionBiomass:"Discover the Spotifarm PRO version and activate all the functionalities including the rapeseed weighing!",
    invitationPremiumPlusSolutionFertilizer:"Discover the Spotifarm PRO version and activate all the functionalities including the Nutrient Plans!",
    //settings
    monparametrage:"Settings",
    nommax20:"The name must not exceed 20 characters",
    valeurtolere:"Value allowed between 10 and 80",
    modifier:"Edit",
    annuler:"Cancel",
    enregistrer:"Save",
    suppParcel:"Import a new farm layout",
    monParcellaire:"My Farm Layout",
    confirmSuppParcels:"This new import will delete your current farm layout (Prescription maps and related weighings. Continue?",
    looseActualImages:"Deleting your farm layout will delete the associated satellite images. You must subscribe to Spotifarm to upload new images.",
    importparcel:"Import boundaries",
    nomexploit:"Farm name",
    tauxnuage:"Cloud Rate",
    legendAndGraph:"Legend",
    myaccount:"My Account",
    myexploitation:"Vegetation Index",
    mymodulation:"Prescription Maps",
    mybiomasses:"Rapeseed weighings",
    qualityImagModul:"Prescription Maps Settings",
    IsoXmlFileVersion:"Version of IsoXml files",
    ShpDoseLabel:"Dose identifiers in SHP prescriptions",
    ShpDoseLabelNotBeVoid:"The identifier cannot be blank", 
    ShpDoseLabelWithInvalidChar:"Your identifier contains at least one unsupported character", 
    maxModulationQualityLabel:"Very high",
    mediumModulationQualityLabel:"High",
    defaultModulationQualityLabel:"Average",
    minModulationQualityLabel:"Low",
    maxModulationQualityDetail:"Maximum resolution, which sometimes limits readability by some consoles",
    mediumModulationQualityDetail:"High Resolution",
    defaultModulationQualityDetail:"Medium Resolution",
    minModulationQualityDetail:"Decrease the map resolution to the maximum to facilitate acceptability by some consoles",
    noRows:"No data to display.",
    displayLandsat8:"Show landsat8 images",
    //parcelles
    importparcelles2:"To start with a new Farm layout",
    ListparcelsModulation:"New prescription",
    ListparcelsModulationMobile:"New map",
    listparcels:"Fields List",
    nomcolumn:"Name",
    idcolumn:"ID",
    searchPlaceholder:"Find",
    search:"Find",
    filterall:"All",
    filtertitle:"Filters",
    filterreset:"Reset",
    viewcolumntitle:"Columns to Select",
    viewcolumntitlearia:"Show/Hide Table Columns",
    desonoinfo:"No information found",
    sort:"Sort",
    selectedParcelRowText:"selected field(s)",
    errorGenerateParcelDrawed:"The drawn field cannot not be saved.",
    askForDeletingParcel:"Are you sure you want to delete this field?",
    askForDeletingParcels:"Are you sure you want to delete the selected fields ?",
    deleteParcel:"Delete",
    deleteParcels:"Delete Selection",
    alertDeleteModulation:"The prescription maps assigned to these fields will also be deleted",
    // DicoDetails.json
    libelecolumncampagne:"Crop year",
    libelecolumnculture:"Crop",
    libelecolumnvariete:"Variety",
    libelecolumnsemis:"Sowing Date",
    libelecolumnrecolte:"Harvest Date",
    libelecolumnfloraison:"Flowering Date",
    libelecolumnrecolteestime:"Estimated harvest date",
    libelecolumndegrecertitude:"Level of certainty",
    libelecolumncultureprece:"Previous crop",
    libelecolumnsurface:"Area (Ha)",
    libelecolumnexploitation:"Farm",
    libelecolumnmodel:"Cultivation model",
    // choix listes déroulante pour culturesn exploitations, variétés
    chooseCulture:"- Select a crop -",
    chooseExploitation:"- Select a farm -",
    chooseVariety:"- Select a variety -",
    // DicoCulture
    libelleBetterave:"Beet",
    libelleBleDur:"Durum wheat",
    libelleBleTendre:"Soft wheat",
    libelleColza:"Rapeseed",
    libelleLin:"Linen",
    libelleLuzerne:"Alfalfa",
    libelleMaisEnsilage:"Maize silage",
    libelleMaisGrain:"Grain corn",
    libelleOrgeDePrintemps:"Spring barley",
    libelleOrgeDHiver:"Winter barley",
    libellePoisFourrager:"Forage peas",
    libellePoisProteagineux:"Protein peas",
    libellePrairiePermanente:"Permanent grassland",
    libellePrairieTemporaire:"Temporary grassland",
    libellePommeDeTerre:"Potato",
    libelleSoja:"Soy bean",
    libelleTournesol:"Sunflower",
    libelleAutre:"Other",
    // error
    erroroups:"Oops",
    errorsuite:", an error occurred in the application",
    error:"The error was sent and will be corrected shortly",
    // StripeInfoCard(anciennement 'StripeInformationDetails')
    infofact:" My Billing Information",
    infofactForMobile:" My Personal Information",
    mesabos:"My Subscriptions",
    abonner:"Subscriptions",
    label:"Address: ",
    postalcode:"Post Code: ",
    city:"city: ",
    country:"Country: ",
    firstname:"First name: ",
    lastname:"Name: ",
    company:"Company: ",
    usertext:"Denomination: ",
    phone:"Telephone: ",
    id:"Identifier: ",
    subscriptiontext:"Subscription: ",
    subscriptionpaidstate:"Paid Account",
    subscriptionfreestate:"Free Account",
    description:"Description: ",
    card:"Credit card",
    expiry:"Expiration",
    askForSubscription:"Subscription change request",
    contactForSubscription:"Our team will get back to you as soon as possible",
    // StripePlanCard
    jour:" Amount (Excl. VAT)/ Day",
    semaine:" Amount (Excl. VAT)/Week",
    mois:" Amount (Excl. VAT)/Month",
    annee:" Amount (Excl. VAT)/Year",
    jourS:" Amount (Excl. VAT), every X days",
    semaineS:" Amount (Excl. VAT), every X weeks",
    moisS:" Amount (Excl. VAT), every X months",
    anneeS:" Amount (Excl. VAT), every X years",
    selectbutton:"Start",
    unsuscribebutton:"Unsubscribe",
    suscribetext:"Subscriber",
    buyByStore:"via your Store",
    comingsoontext:"Soon",
    promocodeedit:"Promo code ? Click here",
    promocodeverify:"Validate",
    promocodecancel:"Cancel",
    promocodenotvalid:"Invalid Code",
    // StripeCreditCardView
    cbName:"CARDHOLDER NAME",
    numberelement:"Card Number",
    expiryelement:"Expiration Date",
    cvcelement:"Security Number",
    errorSaveCBOfCustomer:"Saving the banking details has generated an error.",
    errorSaveCustomer:"Failed to create the data.",
    confirmerpaiement:"Confirm Payment",
    revenir:"Back",
    endcard:"Credit card ending in: ",
    expiration:"Expiration: ",
    cardexpiry:"The credit card is expired; change it",
    confirmersub:"Confirm New Subscription",
    cartenonvalide:"The credit card is incorrect",
    changeOfCard:"Change card",
    titlecard:"Current credit card",
    cardNewInput:"Fill in your credit card information",
    subheader:"Banking Data",
    cgvtext:"By checking this box, you confirm that you have read and accept the ",
    cgvlink:"Terms and Conditions",
    pgdlink:"Privacy Policy.",
    // StripeInvoice
    facture:"Number",
    descriptionfac:"Description",
    prix:"Price (incl. VAT)",
    statut:"Status",
    pdf:"PDF",
    factures:"Invoices",
    aucunefac:"No pending invoice",
    facturePayee:"Paid",
    factureEnImpayee:"Pending",
    viewYourBillsTitle:"View your invoices",
    viewYourBillsText:"To view your invoices, click here",
    // StripePlanView
    desabo:"To unsubscribe, contact the Customer Care Service by email",
    selectPlanMessageText:"Voluntary selection of a subscription is required to proceed to the next step.",
    autounsubcribePlanMessageText:"If you already have a subscription, selecting a new subscription will automatically stop the current subscription. You will be charge for one periodic debit only.",
    // StripeSuscribeProcess
    selectionabo:"Select your plan",
    infofac:"Complete the billing information",
    infobanc:"Complete the banking details",
    errorpay:"An error occurred during payment (customer). Contact the Customer Care Service",
    nonvalide:"The input data are invalid",
    errorsous:"An error occurred during payment (subscription). Contact the Customer Care Service",
    felicitation:"Congratulations!",
    aboValide:"We have just validated your Spotifarm subscription 🙂. \nWe have one last step left, which is the validation of the payment with your bank. It will not take more than one day and by then you can fully enjoy Spotifarm like nothing has happened! \nYou trusted us so we do the same 🙂. \nIf there was a problem with the payment we will get back to you, otherwise consider that everything is in order !",
    aboValideMobile:"We have just validated your Spotifarm subscription 🙂 You can take full advantage of Spotifarm right now 🙂! You can find your purchase directly on your mobile store space",
    continuer:"Continue",
    reloadingClientDatas:"We are reloading your data... Please wait.",
    oups:"Oops...",
    abofail:"The payment was refused. Contact the Customer Care Service by Email",
    abofailDetails:"Details:",
    errorCreateCBToken:"Saving the banking details has generated an error.",
    errorCreateCustomerTokenVoid:"Saving the banking details has failed.",
    errorCreateCustomerTokenIdVoid:"Failed to record the bank details.",
    errorGetClientBilling:"Failed to read your data.",
    errorUpdateClientBilling:"Failed to save your information",
    errorStripeCreateCustomer:"Failed to create the data required to set up the subscription.",
    errorCreateSubcribePlanIdVoid:"The subscription seems to have failed.",
    errorStripeSubscribePlan:"Your subscription failed.",
    UserDataInvoiceSaveError:"An error occurred when saving the data",
    UserDataInvoiceNotComplet:"Some data are missing...",
    submitting:"Saving...",
    // StripeUserFormView
    allemagne:"Germany",
    belgique:"Belgium",
    france:"France",
    luxembourg:"Luxembourg",
    suisse:"Switzerland",
    tvanonrecuperable:"Warning, the VAT will not be recoverable",
    renseignerprenom:"Enter a first name",
    renseignernom:"Enter a last name",
    renseignertelephone:"Enter a telephone number",
    renseigneradresse:"Enter an address",
    renseignercode:"Enter a valid post code",
    renseignerville:"Enter a city",
    renseignerpays:"Enter a country",
    errorsave:"An error occurred when saving the data\nTry again later",
    labelPrenom:"First name",
    labelNom:"Name",
    labelTelephone:"Telephone",
    labelSociete:"Company",
    labelAdresse:"Address",
    labelCp:"Post Code",
    labelVille:"City",
    acprenom:"Your First name",
    acnom:"Your Last name",
    actelephone:"Your Phone Number",
    acsociete:"Your company",
    acadresse:"Address",
    acville:"city",
    valider:"Validate",
    other:"Other",
    mandatoryField:"This field is required",
    AnyCityFromCurrentPostalcode:"The current post code is not associated with any city",
    SomeDataRequired:"A required field is missing or incomplete", 
    // calendrier 
    revenirimage:"Back to last image",
    newerImage:"You are at the last picture",
    legendForDelayedImage:"Image is not yet available",
    legendForCloudyImage:"Cloud Image",
    buttonForMoreImage:"Get more images",
    buttonGoBackLastImage:"Back to last image",
    imageNonObtenue:"The image cannot be downloaded.",
    noImageBefore:"No images available before this date!",
    tauxennuagement:"{0} % of cloudiness",
    // errorBoundary
    relancerapp:"Restart the application",
    //imageSwiper
    chargeimage:"Loading the images, please wait...",
    formatDate:"dd/MM/yyyy",
    //layerSelection
    fondcarte:"Map Background",
    plan:"Map",
    satellite:"Satellite",
    vueglobale:"Global View",
    vueglobaleOnlyForPremium:"Option disabled for free clients",
    visible:"Visible",
    indvege:"Vegetation index",
    vueparcelle:"Field View",
    carterout:"Road Map",
    visustand:"Standard Display",
    photovis:"Visible satellite images",
    anavege:"Vegetation Analysis",
    displayObservations:"Show the observations",
    //loginTest
    redirectauth:"Redirect to the authentication page.",
    redirectmdp:"Redirect to Password change page.",
    deco:"Disconnecting, please wait...",
    reco:"Connecting, please wait...",
    reco2:"Launching the satellite, please wait...",
    //mapinfo
    recupimg:"Retrieving the images...",
    newimg:"New images are available",
    noimg:"No new image",
    redirectimport:"Don't forget to import the Farm Layout",
    maxccupdate:"Updated Images",
    selectionnerparcelle:"Click a field to view the calendar",
    clickToUnderstandImage:"Click here to display Spotifarm images explanation",
    //parcelNameEdit
    enregistrer2:"Save",
    annuler2:"Cancel",
    modifier2:"Edit",
    //parcelEdit
    enregistrer3:"Save",
    annuler3:"Cancel",
    modifier3:"Edit",
    ajouter3:"New",
    updateCrop:"Modify the field",
    titreajouterinformation:"New Information",
    libelleajouterinformation:"Add the missing information to the predefined information list",
    libellecolumninformation:"Information",
    //searchBar
    recherche:"Field Name",
    filtre:"Filters",
    surface:"Area",
    typecult:"Type of Crop",
    reinitialiser:"Reset",
    filtrer:"Filter",
    tous:"All",
    labelAreaCompareAll:"All",
    labelAreaCompareLower:"<",
    labelAreaCompareUpper:">",
    SampleParcelAreaValue:"Ex: 1",
    // stateCalendar
    obtentionimg:"Retrieving the images",
    obtentionNotAsk:"...",
    pourmois:"For this month",
    noimgexpl:"No usable images",
    errorimg:"Error when retrieving the images",
    reloadhisto:"Reload",
    //importParcels
    mezip:"Cannot import without the .ZIP file",
    mefichier:"The import file generated an error",
    me4:"Error: Select either the four files with the .SHP .SHX .DBF .PRJ extensions or a .ZIP file containing all four",
    me3:"Error: Select either the three files with the .SHP .SHX .DBF extensions or a .ZIP file containing all three",
    me2Min:"Error: You must provide: Either .SHP and .DBF files at least (The extension file .PRJ is optional but recommended); or a .ZIP file",
    meZip2:"Error: You must select a .zip file",
    exploitation:"My Farm",
    selectionfichier:"Select the File",
    enregistrementparc:"Save the Farm Layout",
    geofolia:"Geofolia",
    telepac:"RPA",
    mesparcelles:"Fields",
    autres:"Other",
    vosparcelles:"Your Fields",
    selectionnerparcelles:"Select the Fields",
    parcelDefaultNameFormat:"field ",
    telepacClusterAttribute: "Sector",
    telepacParcelAttribute: "Parcel",
    // renderMaps
    noauth:"<strong>Sorry!</strong> You are not authorised to draw this type of field",
    bravo:"Name your field and save to continue.",
    bravotitle:"Congratulations!",
    nomparcelle:"Field name",
    parcelledess:"drawn field",
    monexploitation:"My Farm",
    drawn:"drawn",
    autre:"Other",
    chargevisi:"Visible while loading",
    chargeind:"Loading the vegetation index",
    tooltipstartgeolocation:"Activate Geolocation",
    tooltipstopgeolocation:"Disable Geolocation",
    tooltipcouche:"Layers to be applied",
    tooltipInfo:"Field Information",
    tooltipcenter:"Center on My Farm",
    geoSearchAddress:"Enter an address",
    zoomer:"Zoom In",
    dezoomer:"Zoom Out",
    commune:"Find a city",
    visiblecharge:"Visible while loading",
    indvegecharge:"Loading the vegetation index",
    geoloc:"Geolocation was not authorised.",
    desopos:"Sorry, your position was not found",
    noOpenGlobalLayerWithoutParcelSelection:"Please select a field to display a global layer.",
    //menuParcels
    shapefile:"This feature enables you to import the fields' data as a Shapefile",
    importnew:"Import New Fields",
    functionnew:"This feature enables you to import new fields. Warning! The old data will be deleted when importing.",
    viewlist:"Display the Fields List",
    functionlist:"This feature allows you to consult the field information",
    viewparcelles:"Display the Fields on a Map",
    functionview:"This feature enables you to display the fields on a map",
    //abstractProfil
    infos:"My Information",
    mesfactures:"My Invoices",
    //stripeCustomPlan
    abogratuit:"Use Spotifarm on three fields with:\nWeb and Smartphone Access\nOne-year Fields History\n1 Farm",
    abopremium:"Use Spotifarm Full Access\n(Free until 28/02/2020)\n+\nAll Fields\nUnlimited History",
    abopremiummodul:"All the advantages of the Pro plan + \nPrescription Map Access.",
    freemium:"Free",
    premium:"Pro (Introductory Offer)",
    premiummodul:"Premium + Prescription",
    //calendrier
    //Attention, la propriété ci-après ('calendar') doit être au format "culture complète !"
    calendar:"en-GB",
    //leafletdraw
    dessinerparcelle:"Draw a Field",
    terminer:"Finish",
    supppoint:"Delete last point",
    reduire:"Minimize",
    start:"Click to start drawing.",
    cont:"Click to resume drawing.",
    end:"Click the last point to complete the drawing.",
    placemarker:"Click to pin a note.",
    placeMarkerOnMap:"Click the map to pin a note.",
    placeFirstPointForPolygonOnMap:"Click the map to start drawing.",
    //cardShowed
    valid:"expires at the end of",
    //stripeinfoadress
    adresseLabelCard:"Billing Address",
    adresse:"Address",
    codepostal:"Post Code",
    ville:"City",
    pays:"Country",
    onerror:"An error occurred when saving the data",
    errorlabel:"Enter an address",
    errorcodepostal:"Enter a post code",
    errorville:"Enter a city",
    errorpays:"Enter a country",
    //stripeInfoCard
    cartebanc:"Credit Card",
    expiryError:"The credit card is expired or expires very soon. Enter a new credit card to continue to enjoy all the features of Spotifarm.",
    changecard:"Change Credit Card",
    savecard:"Add your map",
    cbNamemaj:"CARDHOLDER NAME",
    numcard:"Card Number",
    dateexpiry:"Expiration Date",
    errorcard:"Unable to save the credit card",
    cryptogramme:"Security Number",
    //StripeInfoNaming
    clientLabelCard:"Client",
    email:"Email",
    prenom:"First name",
    nom:"Name",
    societe:"Company",
    telephone:"Telephone",
    errorprenom:"Enter a first name",
    errornom:"Enter a last name",
    errorphone:"Enter a telephone number",
    //StripeInformationsdetails
    avertissement:"Warning",
    modifdonnees:"Some data are being modified and will be lost if you leave this page. Don't forget to save.",
    button:"I understand",
    //StripePayment
    puis:"Then",
    total:"Total",
    couponReduc:"Discount coupon",
    resumeCommande:"Order Summary",
    infoUsers:"User Information",
    //authentBase
    alertrs:"If you are connected through your social network, the change of password will only be processed from the associated website.",
    alertdeco:"You seem to be disconnected. Relaunching the authentication...",
    //actions, reducers, containers
    noLoadClientDatasOrId:"The data of the connected client were not found or the identifier is incorrect.",
    noLoadDatasWithoutStoreAcces:"Unable to query the Spotifarm database without access to the application data.",
    noLoadClientDatasWithoutEmail:"The user email was not detected. Impossible to query the Spotifarm database.",
    noLoadDatasWithoutToken:"The access token was not detected. Unable to query the Spotifarm database.",
    noCreateClientDatas:"Failed to initialise the user data.",
    badSearchClientDatas:"Failed to search of the user data.",
    noLoadParcelDatasWithoutClientId:"Cannot access the Farm Layout without identifying the client.",
    noLoadModulationDatasWithoutClientId:"Cannot access the prescription maps without identifying the client.",
    noLoadFertilizerDataWithoutClientId:"Cannot access without identifying the client.",
    noLoadHarvestDataWithoutClientId:"Cannot start an harvest without identifying the client.",
    noSaveOrUpdateFertilizerDataWithoutClientId:"Cannot record or modify Nutrient Plans data without identifying the customer.",
    noLoadFertilizerCommonInfosByCultureWithoutClientId:"Cannot ask for common information about Nutrient Plans according to the crop without having identified the customer.",
    noGeneratePdfBiomassWithoutClientId:"Cannot generate the weighing PDF without identifying the client.",
    badLoadParcelDatas:"Loading the Farm Layout has failed.",
    badLoadModulationDatas:"Loading the prescription maps has failed.",
    badLoadInvoiceDatas:"Failed to access invoice.",
    badLoadFertilizerDatas:"An error occurred when loading the Nutrient Management data",
    badLoadLastNitrogenInputDatas:"An error occurred while loading the data of the last nitrogen contributions.",
    badLoadHarvestDatas:"An error occurred when loading data to start a harvest.",
    badLoadModelisationDatas:"An error occurred while loading the model data.",
    badinitModelisationDatas:"An error occurred when initialising the model data.",
    badLoadFertilizerCrops:"An error occurred when loading crops to start a Nutrient Management plan",
    badLoadFertilizerCommonInfosByCultureDatas:"Failed to access to crop common data",
    noLoadInvoiceDatasWithoutClientId:"Cannot access the invoice without identifying the client.",
    badShowInvoice:"Failed to open your invoice.",
    badSaveModulationDatas:"Failed to record the prescription map.",
    badSaveCommonInfosManagementDatas:"An error occurred when recording the grouped records for nitrogen monitoring.",
    badSaveSpecificInfosManagementDatas:"An error occurred when recording the monitoring information to start the Nutrient Management plan.",
    badPartialBuildModulation:"Failed to generate a new prescription.",
    badPartialBuildBiomass:"Failed to generate a new rapeseed weighing.",
    badPartialBuildFertilizer:"An error occurred when generating a new Nutrient Management plan.",
    badCompletBuildModulation:"Failed to generate the whole prescription maps.",
    badCompletBuildLniModulation:"Failed to generate the whole prescription maps.",
    badBuildPpfFile:"Failed to get NMP file.",
    badGeneratePdfModulation:"Failed to generate the Prescription Map PDF.",
    badGeneratePdfFertilizer:"Failed to generate the NMP PDF.",
    badShowPdfModulation:"Failed to display the Prescription Map PDF.",
    badShowPdfFertilizer:"Failed to display the NMP PDF.",
    badGeneratePdfBiomass:"Failed to generate the Weighing PDF.",
    badGeneratePdfBiomassResume:"Failed to generate the weighing summary PDF.",
    badShowPdfBiomass:"Failed to display the weighing PDF.",
    badShowPdfBiomassResume:"Failed to display the weighing summary PDF.",
    badGeneratePdfLastNitrogenInputPdfResume:"The display of the Pdf file summary of the last contribution.",
    badShowPdfLastNitrogenInput:"The display of the PDF file of the last contribution failed.",
    badShowPdfLastNitrogenInputResume: "The display of the Pdf file summary of the last contribution.",
    noGenerateHistoDatasWithoutClientId:"Cannot generate the images for a field without identifying the client or field.",
    noGenerateHistoDatasWithoutMonthYear:"Cannot generate an image list without a defined month and year.",
    badGenerateHistoDatas:"Failed to generate the image history.",
    noUpdateDatasWithoutStoreAcces:"Cannot update the Spotifarm database without accessing the application data.",
    noUpdateDatasWithoutClientId:"Cannot update the Spotifarm database without identifying the client.",
    noUpdateDatasWithoutToken:"The access token was not detected. Impossible to update the Spotifarm database.",
    noUpdateDatasWithoutDatas:"Cannot update the Spotifarm database without the data.",
    badUpdateDatas:"The update request has failed.",
    badUpdateFertilizerDatas:"An error occurred while updating the Nutrient Management data.",
    noCheckDatasWithoutStoreAcces:"Cannot run a verification without access to the application data.",
    noCheckDatasWithoutClientId:"Cannot run a verification without identifying the client.",
    noCheckDatasWithoutToken:"The access token was not detected. Impossible start the verification.",
    noCheckDatasWithoutDatas:"Cannot run a verification without the data.",
    badCheckPRJDatas:"Failed to verify the projection.",
    badCorrectZipOfShpDatas:"Failed to correct the SHP import.",
    noDeleteDatasWithoutStoreAcces:"Cannot delete the data from the Spotifarm database without accessing the application data.",
    noDeleteDatasWithoutClientId:"Impossible to delete the data from the Spotifarm database without identifying the client.",
    noDeleteDatasWithoutToken:"The access token was not detected. Impossible to delete data from the Spotifarm database.",
    noDeleteDatasWithoutDatas:"Cannot delete data from the Spotifarm database without identifying the data to be deleted.",
    badDeleteParcelDatas:"Failed to delete the Farm Layout.",
    badDeleteModulationDatas:"Failed to delete the prescription map.",
    badDeleteFertilizerDatas:"An error occurred while deleting Nutrient Plans data.",
    badDeleteHarvestVarietyDatas:"An error occurred while deleting the variety data.",
    errorUpdateParcelName:"An error occurred when saving the field name",
    noGetAnalysDatasWithoutClientId:"Cannot retrieve Spotifarm database image analysis without identifying the client.",
    noGetAnalysDatasWithoutToken:"The access token was not detected. Impossible to retrieve the image analysis from the Spotifarm database.",
    badGetAnalysDatas:"Failed to obtain the analysis.",
    importPlotsByFarmNameEmptyResult:"The field data returned an empty object following a GEOFOLIA Import.",
    noRemoveClientWithoutStoreAccess:"Cannot delete the account and data without access to the application data.",
    noRemoveClientWithoutClientId:"Cannot delete the account and data without identifying the client.",
    noRemoveClientWithoutToken:"Access token is not detected. Unable to delete account and data.",
    noLoadLastNitrogenInputDataWithoutClientId:"Cannot start a last contribution without identifying the customer.",
    badLoadLastNitrogenInputsDatas:"An error has occurred while loading the last contribution data.",
    noBuildLastNitrogenInputWithoutClientId:"Cannot calculate a 'last contribution' recommendation without identifying the customer.",
    noBuildLastNitrogenInputWithoutDatas:"Cannot provide 'last contribution' recommendation without the required data.",
    badBuildLastNitrogenInput:"An error occurred when generating the recommendation for the  last  nitrogen contribution.",
    noResultLastNitrogenInputBuild:"The last nitrogen recommendation did not return any result.",
    noResultLastNitrogenInputBuildSampleAndDoses:"An error has occurred when generating the modulation for a final contribution.",
    noResultLastNitrogenInputSavedSampleAndDoses:"An error has occurred when saving the modulation of a final input.",
    doseApporterError: "The dose to be applied should be greater than 0. Modifies the dose already applied accordingly",
    //ImportGeofoliaFAH
    geofoliaStep1:"Geofolia",
    textStep1:"Import my Geofolia fields",
    textStep1_onlyFarmName:"Import my Geofolia fields linked to the farm:",
    textStep2:"Select the farm to import:",
    authorizeStep1:"By continuing, I agree to share my Geofolia fields with Spotifarm!",
    refusedPvFAHOnStep:"Stop proposing the data import",
    cancelStep1:"Cancel",
    continueStep1:"Continue",
    cancelStpe2:"Cancel",
    importStep2:"Import",
    errorStep1:"An error occurred when loading the farms",
    errorStep2:"An error occurred when importing the fields from Geofolia",
    //LanguageMenu
    itemMenuLangueFR:"Français (France)",
    itemMenuLangueBE:"Français (Belgique)",
    itemMenuLangueES:"Español (España)",
    itemMenuLangueUS:"English (United States)",
    itemMenuLangueGB:"English (United Kingdom)",
    //NdviScale
    scalelow:"No Vegetation",
    scalehigh:"Dense Vegetation",
    scalekey:"Vegetation index",
    //NdviChart
    imageAnalysis:"Analyse the image",
    cloudswarning:"Cloudiness",
    verifClouds:"Check for clouds",
    noVerifCloudForModelisation:"No cloud detection for modelled images",
    noNdviOnAllMapForModelisation:"No vegetation index on the entire map for modelled images",
    goBackToVegetationIndex:"Back to Vegetation Index",
    //RenderObservations
    columnparcelname:"Field",
    columnculture:"Crop",
    columnobservationdate:"Date",
    columnobservationtype:"Type",
    columnsurperficie:"Area (Ha)",
    columnremarques:"Comments",
    noparcelname:"Other",
    renderobservationstitle:"Note List",
    toolbarsearch:"Find",
    toolbarsearchculture:"Find a crop",
    toolbarSearchNameCornTypeAndVariety:"Find plot, harvest type, variety",
    toolbardownloadCsv:"CSV Download",
    toolbarprint:"Print",
    toolbarviewColumns:"Columns",
    toolbarfilterTable:"Filter",
    selectedRowText:"selected note(s)",
    selectedRowTitle:"Delete",
    noobservation:"No notes.",
    newobservation:"New note",
    point:"pin",
    zone:"zone",
    labelToday:"Today",
    labelYesterday:"Yesterday",
    labelTheLast7days:"The last 7 days",
    labelThisMonth:"This month",
    labelLastMonth:"Last month",
    labelAllDates:"All dates",
    //PinDropControl
    createpindropmarker:"Pin a new note",
    createpindroppolygon:"New scouting area",
    createpindropimage:"Take a picture",
    //PinDropFormPopup
    pinDropFormTitlePoint:"Note",
    pinDropFormTitleZone:"Observation area",
    pindropmarkersuccess:"You pined a note. Save it to continue, or click Cancel.",
    pindroppolygonsuccess:"You created a scouting area of {0} Ha. Save it to continue, or click Cancel.",
    pinDropFormImage:"Picture",
    pinDropFormAddImage:"Add a picture",
    pinDropFormErrorShowImage:"Sorry, the image could not be loaded",
    supprimer:"Delete",
    pindDropFormErrorSaveObservation:"Failed to save the note. This is probably due to a connection problem. Please try again clicking the button \"Enregistrer\"",
    pindDropFormErrorSaveImage:"Failed to save the image. This is probably due to a connection problem. Please try again clicking the button \"Enregistrer\"",
    deleteObservation:"Delete the note",
    deleteObservationConfirmation:"Are you sure you want to delete the note?",
    cancelObservation:"Undo changes",
    cancelObservationConfirmation:"Changes have been made to the observation. Are you sure you want to cancel them?",
    pinDropFormIdentificationComment:"Plant recognition:",
    //Observations
    deleteObservations:"Delete the selected observations",
    deleteObservationsConfirmation:"Are you sure you want to delete the selected observations?",
    periode:"Period",
    // Observations - Type d'observations - ⚠️ l'unité (_1,_2,...) correspond à l'observationTypeId en BDD
    observation_type_0:"All",
    observation_type_1:"Other",
    observation_type_2:"Adventives",
    observation_type_3:"Deficiencies",
    observation_type_4:"Game damage",
    observation_type_5:"Disease",
    observation_type_6:"Emergence problem",
    observation_type_7:"Pests",
    typesObservations:"Types of observation",
    // Didacticiel / Tutorial : FirstParcel
    howtoimportfirstslot:"I want to add more fields",
    close:"Close",
    welcomespotifarmertitle:"Welcome!",
    welcomespotifarmercontent1:"To start with 'Monitor your fields' and benefit from a ",
    welcomespotifarmercontent2:"30 days trial,",
    welcomespotifarmercontent3:"draw your first field",
    starttutorial:"Start",
    /* Step choix */
    choixtitle:"Start with your field boundaries",
    choixsubtitle:"Select how you want to add fields in Spotifarm",
    choixdrawtitle:"Draw",
    choixdrawcontent:"Draw your fields directly on the map",
    choiximporttitle:"Import",
    choiximportcontent:"Import your field boundaries (.shp or .xml) from your rural payment agency or your farm management software",
    choixgeofoliatitle:"Synchronise",
    choixgeofoliacontent:"Synchronise your Crop Rotation if you use Geofolia",
    /* Step Import */
    importmenusaisie:"Records",
    importmenuaide:"Need help ?",
    importhelp1title:"Need help to Import the files",
    importredirecttostepdraw:"My import does not work, I prefer to draw my fields",
    importhelptitlepasdefichier:"I don't have a Farm Layout file",
    importhelppasdefichiercontent:"Select your Management tool",
    importhelpsubtitlepasdefichier1:"Import the file with RPA",
    importhelpsubtitlepasdefichier2:"Import the file with my fields",
    importhelpsubtitlepasdefichier3:"Import the file with Geofolia",
    importhelptitleimport:"I have an issue importing the file",
    importhelptitleautreprobleme:"I have another issue",
    importhelpsubtitleautreprobleme1:"The Spotifarm pictures",
    importhelpsubtitleautreprobleme2:"Contact Us",
    importhelpenvoyeremail:"Send",
    /* Step indice */
    stepIndiceTitle:"Vegetation Index",
    stepIndiceSubtitle:"What is the vegetation index?",
    stepIndiceButton:"I understand",
    /* Step succès */
    successtitle:"Congratulations!",
    successsubtitle:"Now, you benefit from a 30 days trial offer on Spotifarm.",
    successcontent:"You will receive a satellite view every 3 to 5 days for free during 30 days to:",
    successsubcontent1:"Control emergence on the fields",
    successsubcontent2:"Follow & monitor your crops",
    successsubcontent3:"Locating the affected areas (disease, irrigation, pest attack, game)",
    successsubcontent4:"Measuring the effectiveness of fertiliser application ",
    successsubcontent5:"Determine the optimum harvest date",
    successbtnvalidation:"Let's Go !",
    /* Step dessin */
        /* Sub step géolocalisation */
    dessinwaitgeolocalisation:"Geolocation in progress, please wait",
        /* CardExplain Zoom map */
    dessinzoom:"I Zoom In the field thanks to the wheel",
        /* CardExplain Move map */
    dessinmovemap:"Move the map with the mouse",
        /* Button start draw */
    dessindrawparcel:"Start drawing",
    dessinzoominsidemap:"I cannot find my city, I prefer to zoom in the map to locate my fields",
    gobacktostepchoix:"Change method",
        /* Place search */
    searchcity:"Find your city",
    // spécifiques pour le cas de chargement depuis l'appli native
    noDatasAuthentFromNativeApp:"The authentication data are missing. The application failed to connect.",
    disconnectNativeApp:"Change Account",
    closeNativeApp:"Close the application",
    commNativeWithoutWebView:"The initialisation of the authentication system should not use 'native' mode.",
    /* Step succès */
    actualize:"I created my Geofolia link. I refresh",
    actualizeForSmallScreen:"I created my link",
    /* Incitation fin période d'essai avant fin */
    incitationendoftrialtitle:"It seems that you enjoy the app",
    incitationendoftrialcontent1:"Your free trial month ends soon.",
    incitationendoftrialcontent2:"Become a Spotifarmer and continue to monitor your fields with the satellite views unlimited access.",
    incitationendoftrialcontent2ForMobile:"To become a Spotifarmer, logon to your computer and continue to monitor your fields with the satellite views unlimited access.",
    incitationendoftrialcontent3:"No commitment required",
    incitationendoftrialok:"Ok let's go!",
    incitationendoftrialnotnow:"Later",
    /* Incitation fin période d'essai post fin */
    incitationaftertrialtitle:"Try Spotifarm PRO",
    incitationaftertrialcontent1:"You enjoy Spotifarm and you want to follow and monitor your crops more than ever?",
    incitationaftertrialcontent2:"You can subscribe to our plan. No commitment required.",
    incitationaftertrialcontent2ForMobile:"This functions are reserved for the Spotifarm full version subscribers.",
    incitationaftertrialcontenthowtodo1ForMobile:"How To",
    inctitaionaftertrialcontenthowtodo2ForMobile:"The subscription is not yet available through the application. It must be completed from your computer.",
    incitationaftertrialcontentokForMobile:"I agree.",
    incitationaftertrialsupport:"Any question ?",
    incitationaftertrialcontactsupport:"Contact the Customer Care Service",
    incitationaftertrialcontent2BeforeDiscount:"You can subscribe to our plan using this coupon. No commitment required.",
    incitationaftertrialcontent2BeforeDiscountForMobile:"You can subscribe to our plan using this coupon. No commitment required.",
    /* Incitation freemium ayant déjà un shp */
    incitationfreemiumwithshptitle:"The world is changing. So are we.",
    incitationfreemiumwithshpcontent1:"As of now, the application will be free of fields limitation. The download of the new images will be reserved for subscribers.",
    incitationfreemiumwithshpcontent2:"To show you the best of Spotifarm, we offer you a 1 month trial full access.",
    incitationfreemiumwithshpsignature:"The Spotifarm Team",
    /* incitation footer */
    incitationtrialfooter:"Any question or Need help ? Contact the Customer Care Service",
    /* Incitation depuis la fumure */
    incitationDownloadFertiPrescriptions:"You have the rights to generate a Nutrient Management Plan, but to download the prescription maps you need to subscribe to Spotifarm - Prescription Map.",
    /* formulaire d'inscription via réseaux sociaux */
    socialformtitle:"One more step to monitor your fields",
    champobligatoire:"Required field",
    /* Modulation : Aides */
    helpForModulation:"Create a prescription map",
    helpForDownloadModulation:"Need help downloading?",
    helpForFilesModulation:"Which file format for my console?",
    helpForDownloadModulationOnDesktop:"Computer",
    helpForResolutionModulation:"Need help on prescription maps resolution?",
    helpAsk:"Need help ?",
    helpAskModulation:"Which file format for my console?",
    helpContactUs:"I have another question",
    /*  Pesée de colza aide */
    aidePeseeColza:"Rapeseed weighing",
    /* Ecrans de modulation */
    totalDoseToExpand:"Total: ",
    totalDoseToExpandAlert:"Caution, you selected some prescription maps with solid AND liquid applications.",
    parcelModulated:"Is modulated",
    downloadNotAvailable:"Available on PC",
    noRecordsToDisplay:"No prescription to display",
    loadingModulsRecords:"Loading the prescription map(s) ... ",
    deleteModulations:"Delete the prescription maps",
    deleteSelectedModulations:"Delete the selected prescription map(s)",
    confirmDeleteModulations:"Are you sure you want to delete the selected prescription map(s)?",
    startModulation:"Start",
    showOnlyParcelWithoutPrescription:"Show fields without prescription maps only",
    ModulationsExistsTitle:"Prescription Maps",
    ModulationsExistsTitleMobile:"My maps",
    ColumnPclName:"Name",
    ColumnMdlDate:"Created",
    ColumnMdlMediumDose:"Average Dose",
    ColumnContentRate:"Content %",
    ColumnMdlToDownLoad:"Download Maps",
    ColumnMdlToDownLoading:"Downloading maps...",
    downloadLastInputNs:"Download Maps",
    lastInputNsDownLoading:"Downloading maps...",
    badDownloadModulationFiles:"Failed to download the files associated with field prescription. Please contact us.",
    badContentDownloadModulationFiles:"The downloaded files Content, associated to field prescription, has failed. Contact us.",
    ObserveVisibleLayer:"Select an image with as few clouds as possible. In this view, the clouds can be identified by their white color and their shadows darkening the image. We apply the overall average dose on the areas detected as cloudy.",
    ObserveCloudLayer:"We might have missed some clouds applying our filter and it might disrupt the vegetation index on some images. You can observe the cloud cover when the photo was taken to detect them.",
    ComeBackToIndicevege:"Back to Vegetation Index",
    DetectClouds:"Detect clouds",
    ConfirmSelectedImage:"Validate the selected image",
    titleDeleteModulations:"Delete the prescription map(s)",
    confirmDeleteModulationsSelected:"Are you sure you want to delete the selected prescription maps ?",
    confirmDeleteAction:"Confirm",
    cancelDeleteAction:"Cancel",
    dialogSubtitleTypeShape1:"Selects the prescription maps export format:",
    dialogSubtitleTypeShape11:"Selects the prescription maps export format:",
    dialogSubtitleTypeShape12:"Select the PDF format:",
    dialogSubtitleTypeShape2:"If in doubt about the formats accepted by your console, ask your dealer.",
    startModulationImageChoice:"Select the image",
    twoSupplyTypesSelected:"Be careful, your selection includes maps with different types of supplies (liquid and solid). If this is your choice, continue, otherwise we advise you to separate the files by type of supplies to simplify the use on your console.",
    someoneWithoutModulSelected:"Please note that your selection includes last-contribution recommendations that do not have prescription maps configured. If you continue, incomplete recommendation will be excluded from the prescription generated.",
    goBackToModulationTable:"Back to my selection",
    biomassRangeMobileHelper:"Dates",
    newModulationWidget:"Create a modulation map",
    /* Ecrans de modulation : doses de modulation */
    rememberModulationSettings:"Retrieve the average dose, content and setting for future prescription maps",
    subTitleDefinitionZones:"Defining areas", 
    subTitleDoses:"Dose Distribution",
    subTitleSettings:"Settings",
    mediumDose:"Average Dose",
    content:"Content",
    nbZones:"Number of areas",
    ratio:"Proportion",
    doseInputs:"Input Dose",
    area:"Area",
    distributionType:"Type of distribution",
    doseChange:"Dose Calculation",
    compensation:"Compensation",
    optimization:"Optimisation",
    manuelDoses:"Manual",
    automaticDoses:"Automatic",
    helpContent:"Concentration of your useful product in the applied product. For example 39% for nitrogen 39 solution",
    helpCloudZones:"We apply the average dose For areas where clouds are detected (red areas)",
    helpOptimization:"In compensation, the algorithm favours the less developed areas, with less potential, applying more product. In optimisation, the algorithm favours the most developed areas, with high potential, applying more product.",
    helpDoseChange:"If you enter the doses by area manually, the overall average dose may change. Be careful to respect your Nutrient Management Plan. Keeping the automatic calculation, we will modify the doses of the other areas each time you modify a dose for an area, without modifying the global average dose.",
    altImgModulation:"Preview not available",
    quantityToExpand:"Total amount to be applied",
    quantityToFertiExpand:"Rate to be applied for the contribution",
    quantityToFertiExpandShort:"Average Dose",
    badQuantityToFertiExpand:"Your total dose to be applied has been reached!",
    badQuantityToFertiExpandDetails:"If possible, reduce your reserve dose to get a valid prescription map",
    goBackStepModulation:"Back",
    saveModulation:"Save and Finish",
    saveModulationAndGoNext:"Validate and go to the next",
    cancelModulationAndGoNext:"Cancel and go to next",
    downloadModulation:"Download",
    downloadModulPdf:"Download the PDF",
    downloadModulShp:"Download the SHP file",
    downloadModulIso:"Download the ISOXml file",
    downloadModulRds:"Download the RDS file",
    errorDownloadModulation:"An error occurred while downloading. Contact us!",
    msgUseComputerForDownloadModulation:"The download can only be done from a computer.",
    downloadModulationAndGoNext:"Download and go to the next field",
    unvalidZonesFromBuild:"This map cannot generate more than {0} areas.",
    unvalidZonesFromBuildFerti:"This map cannot generate the {0} areas.",
    errorNoLoadDatesRange:"Failed to load the Nutrients plan service range ",
    detectException:"Unexpected error updating the prescription data.",
    badNumberOfAreas:"Cannot increase the number of zones, the heterogeneity of the field is insufficient.",
    dateImag:"Snapshot date",
    supplyType:"Supply Type",
    supplyTypeSolid:"Solid",
    supplyTypeLiquid:"Liquid",
    doseNitrogen:"Nitrogen dose",
    downloadChoiceShp:"SHP", 
    downloadChoiceIsoXml:"ISO XML", 
    downloadChoiceRds:"RDS",
    repartitionLabel: "Heterogeneity in rate distribution",
    repartitionTip: "By adjusting the dose distribution, you decide the difference between your highest and lowest doses in your prescription maps. The greater the heterogeneity of your field, the more heterogeneous your distribution should be.",
    lowerRepartitionLabel: "Low",
    higherRepartitionLabel: "High",
    errorDoseNominalZero: "Entered at least one dose greater than 0",
    /* Modulations : écran Félicitation */
    messageEndModulation:"You completed the prescription for the selected fields.",
    downloadModulationInProgress:"Saving...",
    /* Calculette dose Azote */
    CalculDoseNitrogenTitle:"Calculate my optimal nitrogen dose",
    CalculDoseNitrogenTitleMobile:"Dose optimiser",
    calculDoseNitrogenTypeBle:"wheat",
    calculDoseNitrogenTypeColza:"Rapeseed",
    calculDoseNitrogenDesc:"Calculate the Nitrogen dose corresponding to the technical-economic optimum according to the selling price of your {0} and the Nitrogen purchase price.", 
    calculDoseNitrogenSellPrice:"I think i will sell my {0}",
    calculDoseNitrogenPurchaseCost:"I think i will buy my nitrogen fertiliser",
    calculDoseNitrogenConcentration:"My nitrogen fertiliser contains",
    calculDoseNitrogenConcentrationEnd:"of Nitrogen",
    calculDoseNitrogenRecommanded:"The Nitrogen dose to be applied from your Nutrient Management Plan, is",
    calculDoseNitrogenOptimized:"Your optimal nitrogen dose is ",
    calculDoseNitrogenUnite:"Units",
    calculDoseNitrogenInfo:"This calculation results from a study published by Arvalis on the nitrogen dose corresponding to the economic optimum according to selling price of the crop and the Nitrogen purchase price. The calculation does not require the crop yield, as this yield is already considered in the forecasted dose calculation provided by the balance summary.",
    calculDoseNitrogenGoToArticle:"For more details you can read",
    calculDoseNitrogenGoToArticleBetween:"for wheat, and ",
    calculDoseNitrogenGoToArticleEnd:"for rapeseed.",
    calculDoseNitrogenArticle:"this item",
    calculDoseNitrogenErrorData:"The recorded data must be positive integers",
    calculDoseNitrogenErrorPurchaseCost:"The price of your nitrogen fertiliser as well as its nitrogen concentration gives a cost per unit of nitrogen {lowerOrHigherThan}. Our algorithm does not allow a relevant calculation for these prices.",
    lowerThan:"lower than {0}",
    higherThan:"higher to {0}",
    calculDoseNitrogenErrorSellPrice:"The selling price of your {crop} must be between {priceMin} and {priceMax}",
    calculDoseNitrogenErrorCulture:"Our algorithm cannot provide a result for your crop",
    /* Biomasse */
    /* Biomasse : choix des parcelles */
    parcelHasBeginningWinter:"Before winter",
    parcelHasEndingWinter:"After winter",
    startBiomass:"Start",
    startBiomassBeginningWinter:"Start the weighing before winter",
    startBiomassEndingWinter:"Start the weighing after winter",
    selectBiomassBeginningWinter:"Select the image : before winter",
    selectBiomassEndingWinter:"Select the image: after winter",
    updateBiomassEndingWinter:"Modify the weighing after winter",
    listparcelsBiomass:"New rapeseed weighing",
    next:"Next",
    biomassesCertifTerresInovia:"Our model has been validated and approved by Terres-inovia so that you can use it in your Nutrient Management Plan",
    biomassesHowToSelectDates:"To ensure that the data is consistent, make sure that you select before and after winter dates using the same criteria as you would for weighing in the field.",
    /* Biomasse : tableau des biomasses */
    ColumnBioDateWinter:"Date of weighing",
    ColumnBioBeginningWinter:"Start",
    ColumnBioEndingWinter:"End",
    ColumnBioWeighting:"Weighing (Kg/m²)",
    deleteWeightingBiomasses:"Delete the weighings",
    generateResumePdfWeightingBiomasses:"{0} Weighing(s) Summary PDF",
    generateResumePdfOneWeightingBiomass:"PDF summary of this weighing",
    generateResumePdfWeightingAllBiomasses:"All weighings Summary PDF",
    weightingBiomassesExistsTitle:"My rapeseed weighings",
    titleDeleteBiomasses:"Delete of rapeseed weighing(s)",
    confirmDeleteBiomassesSelected:"Are you sure you want to delete the selected rapeseed weightings?",
    noBiomassRecordsToDisplay:"No rapeseed weighing to be displayed",
    loadingBiomassRecords:"My rapeseed weighings being loaded... ",
    downloadBiomasses:"Download the weighings",
    linkResultsWeightings:"To calculate the amount of nitrogen to apply to your rapeseed, you can Use the ",
    clicHere:"Nitrogen ruler from Terres inovia",
    newBiomassWidget:"New rapeseed weighing",
    /* Biomasse : map */
    goBackStepBiomass:"Back",
    biomassRangeDates:"From {0} to {1}",
    biomassRangeText:"dates recommended by Terres Inovia",
    biomassExtraRangeDates:"From {0} to {1} or from {2} to {3}",
    biomassExtraRangeText:"acceptable dates",
    biomassOutOfRangeText:"Out of period",
    /* Biomasse : résultat */
    subTitleMapZonesBiomass:"Your weighing map",
    subTitleYourWeightingBiomass:"Your quantities",
    subTitleResultWeightingBiomass:"Your weighing result",
    quantity:"Quantity",
    download:"Download",
    downloadWeightingPdf:"Download the PDF",
    goToNextFieldSkipTitle:"Go to the next field",
    goToNextFieldSkipContent:"Cancel and go to the next field?",
    averageQuantityResult_1:"You have an average of ",
    averageQuantityResult_2:" kg of fresh rapeseed material per m² on this field.",
    totalArea:"Total area:",
    weightingSuccess:"Your weighing has been saved.",
    parcelleSuivanteDesktop:"Skip and Next",
    parcelleSuivanteMobile:"Skip",
    ignorerEtTerminer:"Skip and Finish",
    biomassesCheckBy:"Model validated by",
    /* Widget Analyse image */
    lifting:"Emergence",
    growth:"Growth",
    maturity:"Maturity",
    cloud:"Clouds",
    /* Identification */
    identificationTitle:"Identification",
    /* Identification: choix d'image */
    identificationChooseImageSubtitle:"Select the images",
    identificationChooseImageTips:"For best results, select a particular plant organ and add a photo of that organ:",
    identificationChooseImageOrganLeaf:"Leaf",
    identificationChooseImageOrganFlower:"Flower",
    identificationChooseImageOrganFruit:"Fruit",
    identificationChooseImageOrganBark:"Stem",
    identificationChooseImageSearch:"Find",
    identificationChooseImageMsgError:"Failed to identify. This is probably due to a connection problem. Please try again clicking the button \"Rechercher\"",
    identificationSelectImage:"Select an image to identify the plant",
    /* Identification: résultat */
    identificationResultSubtitle:"Reports",
    identificationResultEmpty:"There are no results for your search. For a more precise search, you can take pictures of the different organs of the plant.",
    identificationResultNotFound:"I can't find my plant",
    identificationResultPoweredByPlantnet:"Our photo plant identification service uses the Pl@ntnet API, regularly updated and accessible on the website ",
    /* Identification: résultat item */
    identificationResultComponentConfidence:"Trust index: {0}%",
    /* Achat In-app Mobile */
    fromStore:"(via the mobile store)",
    pleaseWaitBuyByStore:"Please wait. The purchase process will start on the device",
    buyByStoreRequireTime:"Buying from the Store seems to take longer than expected. Please wait OR go back (We will notify you as soon as it is completed).",
    errorPurchasePlanNotDetected:"A purchase from the Store was validated. However, the Store has not sent us the subscribed offer. Please contact us to activate your access.", 
    errorUpgradeClientRulesAfterPurchase:"Failed to activate the access following the purchase you made on the Store. Please contact us.", 
    errorNoPurchaseWithoutSelection:"You need to select the offer you want to subscribe via the Store", 
    errorNoPurchaseWithoutIdStorePlan:"The purchase from the Store is cancelled because the Store cannot find your selection.", 
    errorPurchaseMessageFromNativeApp:"The purchase from the Store returned an error ({0}). Please contact us.",
    errorUpdateClientAfterPurchasedFromNativeAppMessage:"Failed to activate your access rights. Please contact us so that we can fully activate your access.", 
    purchaseResultWithoutInfosFromNativeApp:"The purchase from the Store did not confirm that purchase was considered. Please contact us.",
    appelStoreStep:"Connection to the Store...",
    explainBuyByStore:"We will launch the purchase for you from your Mobile Store. Following the validation of the payment, your access will be unlocked.",
    purchaseStore:"Buy",
    goBackPurchaseStoreWaiting:"<< Back",
    dlgTitlePurchaseStoreResult:"Result of the purchase on the store",
    dlgContentPurchaseStoreResult:"Following the purchase:",
    messagePurchaseStoreGoodResult:"The purchase has been validated. Enjoy your Spotifarm access now!",
    btnClosePurchaseStoreResult:"Close",
    /* Modélisation */
    modelisationTitle:"Modelling",
    modelisationCalendarDescription:"Modelled image",
    modelisationTutoStartTitle:"Enjoy images every week without interruption",
    modelisationTutoStartExplanation:"Thanks to our artificial intelligence, we can now model plant growth and provide you with images of your crops at regular intervals, whatever the weather conditions.",
    modelisationTutoStartIncitation:"I take advantage of it",
    modelisationTutoStartHowTo:"To benefit from these new images you must validate your crops to follow 👉🏻",
    modelisationTutoStartButton:"Let me start",
    modelisationTutoChooseModelTitle:"Select the crops",
    modelisationTutoChooseModelExplanation:"Each culture has its own specificities and growth characteristics. We have prepared 3 growth models for you for wheat, barley and rapeseed.",
    modelisationTutoChooseModelSelect:"Select the crops you are interested in to start entering data:",
    modelisationTutoChooseModelButton:"Validate",
    modelisationCropChoiceTitle:"Choice of crop to be modelled:",
    modelisationCropChoiceButton:"Validate the crop",
    modelisationCropChoiceHelper:"Once you have chosen the crop to be modelled, select and validate the plots concerned",
    modelisationAddParcelsSelectTitle:"Select the Fields",
    modelisationAddParcelsSelectAction:"Start by selecting all your fields from {0}",
    modelisationAddParcelsFillInTitle:"Data information",
    modelisationAddParcelsFillInAction:"Check and complete the information for each field",
    modelisationDataGridButtonAdd:"Add fields to this model",
    modelisationDataGridButtonStop:"Stop modelling these fields",
    modelisationDataGridButtonValidInformations:"Validate information",
    modelisationDataGridButtonAddSelected:"Add these fields to model",
    modelisationDataGridPopupSaving:"Your fields are ready. We're starting to process the data to provide you with modelled images from the next period without satellite images",
    modelisationDataGridPopupGoToNext:"We will automatically move on to the next crop at the end of loading",
    modelisationMsgErrorReset:"A problem occurred when stopping the modelling one of your fields. Try again later.",
    modelisationMsgErrorUpdate:"A problem occurred when you started modelling one of your fields. Try again later.",
    loadingModelisation:"Modelling in progress",
    modelisationIsLoading:"Modelling in progress.",
    modelisationResultPageInfo1:"We prepare all the fields, meteorological and satellite data needed to calculate the modelled images.",
    modelisationResultPageInfo2:"They will appear as soon as a cloudy period intervenes and satellite images are therefore unavailable.",
    modelisationBackToExploit:"Back to farm",
    newModelisation:"New modelling",
    cropName:"Crop :",
    modelisationSpecificInfo1:"Check and validate your sowing dates by field here. ",
    modelisationSpecificInfo2:"When everything is correct, start image generation using the button at the bottom right.",
    modelisationSpecificInfo3:"Image generation will take place as soon as images are missing due to clouds.",
    modelisationCrop:"Modelling culture :",
    modelisationGenerateImages:"Generate the images",
    modelisationCommonInfosUpdateQuestion1:"We already have sowing dates for these fields, ",
    modelisationCommonInfosUpdateQuestion2:"do you want to modify them?",
    modelisationCommonInfosStart:"Enter the sowing date of your fields to start the modelling,",
    modelisationMessageInstructionCrop:"Predict by Spotifarm will enable you to monitor your crops throughout the year, even when it's cloudy, by providing you with constant images.",
    modelisationStep0Culture:"Crop:",
    modelisationStep1Parcelle:"Select the fields",
    modelisationStep2SaisieTableauRecapitulatif:"Settings",
    modelisationStep3Résultat:"Reports",
    modelisationApplySowingDateToAllSlots:"Apply to all fields",
    modelisationApplySowingDateToSlotsWithoutDate:"Apply to fields without a sowing date",
    modelisationSowingDateError:"Specifies a date for the crop year {0}",
    modelisationRemoveParcelsSelected:"Remove selected fields",
    modelisationDeleteSelected:"Delete selected models",
    modelisationStatusTitle:"Modelling",
    modelisationStatusInitialisation:"Initialisation",
    modelisationStatusHarvested:"Harvested",
    modelisationNbImageModelised:"Modelled images",
    modelisationStartNewModelisation:"Generate new models",
    deleteModelisationTitle:"Warning",
    deleteModelisationText:"If you delete this field, you'll go back to the first stage of modelling",
    backToModelisation:"Back to modelling",
    keepModelisation:"Keep this field",
    //ProcessStatus
    processStatusWaiting:"Pending",
    processStatusInProgress:"In progress",
    processStatusSuccess:"Done",
    processStatusError:"Failed",
    processStatusReady:"Active",
    processStatusDiferred:"Deferred",
    //commonStructures
    selectCrop:"Select a crop to start",
    validSlots:"Validate the fields",
    errorCommonStructures:"An error has occurred",
    errorCommonStructuresInfos:"If the problem persists, ",
    //Fertilizer
    fertilizer:"Nutrient Plans",
    fertilizerData:"Manure data",
    nitrogenManagement:"Nitrogen  Monitoring",
    nitrogenManagementShort:"Monitoring",
    goBack:"Back",
    validate:"Validate",
    doseLastInstake:"Dose of last contribution",
    dosesProvided:"Supplied rates",
    intermediatecropFrequency:"Frequency of intermediate crop",
    plowing:"Ploughing:",
    cultivationHabitsPlot:"Cultivation habits of the field",
    yes:"Yes",
    no:"No",
    organicContribution:"Organic Nutrients Applied",
    fertilization:"Fertiliser application",
    practicesFromLast10years:"Last 10 years",
    productionTarget:"Production Target",
    agronomicInformation:"Agronomic Information",
    commonInfosManagementInfos:"In this screen, you can enter as much information as possible that will apply to all the fields in this crop. If some fields are managed differently, I recommend that you still enter the information that applies to the majority, and you can then enter the details on the next screen.",
    writeLater:"I will record it later",
    selectCultureBeforeStarting:"Enter a crop before you begin.",
    quit:"Quit",
    associatedFrostCulture:"If you have a frost-free crop that developed well before the frost, it is recommended that you reduce the total nitrogen dose of the NMP by 30 Units.",
    quantityToKeep:"Quantity of nitrogen to be reserved",
    quantityAlreadySupplied:"Quantity of nitrogen already supplied",
    nitrogenContent:"Nitrogen content of fertiliser",
    nitrogenContentShort:"Nitrogen content",
    noAvailableChoice:"No choice available",
    typeToSpread:"Type of fertiliser to be applied",
    removeHorizon:"Remove horizon",
    addHorizon:"New horizon",
    fertilizerQuantity:"Amount of nitrogen",
    remainderDate:"Date of  Residual Nutrients *",
    relicTypeAnalysis:"Analysis",
    relicTypeRegionalValue:"Regional Value",
    relicTypeEstimatedValue:"Estimated Value",
    nitrateContent:"Nitrate content",
    quantityWater:"Quantity of water",
    irrigation:"Irrigation",
    dateContribution:"Date of contribution",
    quantitySupplied:"Quantity supplied",
    dateBurialResiduesN1:"Y-1 residues Incorporation Date",
    becomingResiduesN1:"Fate of Y-1 residues",
    harvestDateN1:"Y-1 Harvest Date*",
    sowingDateN1:"Y-1 Sowing Date*",
    nitrogenFertilizerN1:"Y-1 Nitrogen fertilisation",
    previousYield:"Y-1 Yield",
    previousCrop:"Crop Y-1",
    loading:"Loading...",
    plowingDepth:"Ploughing depth",
    organicFertilizerFrequency:"Frequency of Organic nutrients",
    organicFertilizerType:"Organic Nutrients type",
    performanceObjective:"Target Yield",
    subTextPerformanceObjective:"(Average over the last 5 years, excluding minimum and maximum values)",
    soilType:"Soil Type",
    pebbleRate:"Stones Prevalence",
    residueManagement:"Residues Management",
    intermediateCultureType:"Type of intermediate crop",
    meadowTurning:"Grassland Ploughing",
    meadowTurningDate:"Grassland Ploughing Date",
    meadowDuration:"Pasture Age",
    errorDialogText1:"Check the data you entered.",
    errorDialogText2:"If the problem persists, ",
    beginningWinterDate:"Winter Start Date:",
    endingWinterDate:"Winter End Date:",
    noFrequency:"No frequency",
    noMeadow:"No grassland",
    buried:"Incorporated",
    reliquat30:"0 to 30",
    reliquat60:"30 to 60",
    reliquat90:"60 to 90",
    horizon:"horizon",
    relicTypeEstimatedValueMsg:"Please note that we will estimate this value using a calculation model. As soon as a local value is available, you should prefer it to the estimated method. However, if your crop situation is not covered by the data for your location, you can keep the estimated value.",
    winterOutingShot:"Before winter shot: ",
    winterEntranceShot:"After winter shot: ",
    totalDose:"Total dose",
    recommandedTotalDose:"Recommended total dose",
    doseAlreadyProvided:"Dose already applied",
    reserveDose:"Reserve dose",
    registerAndContinue:"Save and continue",
    downloadPPF:"Download the NMP",
    calculateAgain:"Recalculate",
    complete:"Complete",
    parcelHasLastInputN:"Already adjusted",
    sowingDate:"Sowing Date",
    perfGoal:"NMP Potential yield",
    newPerfGoal:"Adjusted yield",
    totalNdose:"NMP N Dose",
    modulatedNdose:"Modulated N dose",
    downloadMaps:"Download Maps",
    modulation:"Prescription Map",
    quantityToSpread:"Quantity to be applied",
    historized:"History",
    actual:"Current",
    popupIncitationFertilizer:" for more information.",
    cropYear:"Crop Year",
    currentCropYear:"Current Crop Year",
    archivedCropYears:"Archived Crop Years",
    deleteFertilizers:"Delete the recommendation(s)",
    confirmFertilizersSelected:"Confirm the selected Nutrient Plans ?",
    askDeleteFertilizers:"Delete the Nutrient Manure Plans ?",
    entryGrouped:"Grouped Records",
    updateLocalisationErrorMessage:"An error occurred while changing the location of the field",
    newsNitrogenBoard:"New NMP",
    tipsNitrogen:"My NMP",
    titleInfoAlertFertilizers:"Info",
    textUnavailableAlertFertilizers:"Nitrogen recommendation generation is temporarily unavailable.",
    textOpenDateAlertFertilizers:"The reopening will be on ",
    textOpenDateAlertFertilizersWithoutDate:"It will reopen at the beginning of January.",
    textGetPastAlertFertilizers:"You can still access past nutrient plans.",
    popupInfoCrop:"The nitrogen recommendation crop is different from the field crop. It will be changed later.",
    buildFertiNeedThisData:"This information is required to generate your Nutrients plan",
    buildFertiNeedThisWhenAddOrganicInput:"Data required if Organic Nutrients Applied",
    ppf:"NMP",
    fertilizerHavingLastNitrogenInputPopupTitle:"You have selected fields where you have already adjusted the last contribution:",
    fertilizerHavingLastNitrogenInputPopupContent:"If you continue, your adjustments to the last contributions will be deleted for the fields concerned.",
    deleteFertilizersWithLastInputN:"If you continue, your adjustments to the last contributions will also be deleted for the fields concerned.",
    parcelHasFertilizer:"Has a NMP",
    startPPF: "Start a NMP",
    // Harvest
    ListparcelsHarvestMobile:"New Harvest",
    ListparcelsHarvest:"New corn harvest",
    harvestsExistsTitleMobile:"My Harvests",
    harvestsExistsTitle:"My corn harvests",
    askDeleteHarvestTitle:"Delete harvest monitoring on selected parcels",
    askDeleteHarvestVarietyTitle:"Delete selected corn varieties",
    askDeleteHarvests:"Are you sure you want to delete harvest monitoring on your parcels?",
    askDeleteHarvestVarieties:"Are you sure you want to remove custom corn varieties?",
    deleteHarvests:"Delete the harvests",
    harvestCampaign:"Crop year",
    harvestCulture:"Crop",
    harvestVariety:"Variety",
    harvestDegreeOfCertainty:"Level of certainty",
    harvestSowingdate:"Sowing Date",
    harvestEstimatedDate:"Estimated date",
    harvestFloweringDate:"Flowering Date",
    harvestGenerationDate:"Created",
    harvestAlreadyGenerated:"Already generated?",
    accessToEstimationDates:"Access date estimates",
    loadingCropVarieties:"Loading crop varieties...",
    errorHarvestGenerate1:"To obtain a result, you need to enter the harvest objective, variety, harvest type and sowing date for each plot.",
    errorHarvestGenerate2:"If you fill in the flowering date, it must be greater than the sowing date.",
    cornType:"Harvest type",
    selectItem:"Select",
    grain:"Grain",
    ensilage:"Silage",
    percentage:"Harvest target",
    percentageForPopup:"Stage",
    dryMaterial:"dry material",
    humidity:"humidity",
    sortBtn:"Refresh the estimates",
    hightDegreeOfCertitude:"Our estimate is too uncertain to recommend a date at this time. Come back in a few days.",
    helpHarvestTitle:"Harvest corn",
    loadingHarvestData:"Loading data ...",
    dontSaveDatas:"Do not save",
    saveDatasBeforeGoingBackToFirstStep:"Do you want to save the data before returning to the choice of plots? Please note, harvest data previously submitted will be replaced.",
    datesGenerated:"The estimated dates of plots with good data are available in \"My corn harvests\".",
    myVarieties:"My varieties",
    deleteVarieties:"Delete my varieties",
    errorSaveHarvests:"An error occurred when saving the corn harvest data.",
    objective:"Objetive n°",
    degreeDaysToFlowering:"°C degree days to flowering",
    manufacturer:"Manufacturer",
    degreeDays:"°C days",
    needsInDegreeDays:"Needs in °C.j",
    dryMaterialInitials:"DM",
    addVariety:"Add variety",
    updateVariety:"Update variety",
    varietyLabelRequired:"Variety label is required",
    harvestTypeRequired:"Harvest type is required",
    harvestObjectiveRequired:"Harvest target is required",
    harvestObjectiveGreaterThanZero:"Harvest target must be greater than 0",
    degreeDaysToFloweringGreaterThanZero:"°C days to flowering must be greater than 0",
    degreeDaysGreaterThanZero:"°C days must be greater than 0",
    degreeDaysRequired:"°C days are required",
    fillAtLeastOneHarvestTarget:"Complete at least one harvest objective",
    sameHarvestTargetData:"You have completed objectives with the same type and the same stage",
    harvest:"Harvest",
    errorHarvestUsedVariety: "Suppression of the variety has failed. This is probably because it is currently being used in a harvest. Please check associated harvests before trying again.",
    showAllVarieties: "Show all varieties",
    newDateHarvestTitle: "Estimated corn harvest date",
    loadingHarvestRecords: "Loading my corn crops",
    deleteHarvestWithVariety:"Please note, you will also delete the harvest data associated with the selected varieties.",
    //LastNitrogenInput
    lastNitrogenInput:"Last contribution",
    newTotalNDose:"Adjusted N dose",
    lastNitrogenInputModulationButton:"Prescription Map",
    adjustment:"NMP / Adjustment Difference",
    lastInputDate:"Adjustment Date",
    startModulationOfLastInputN:"Start",
    lastNitrogenInputModulated:"Edit",
    selectForLastInputN:"Wheat Last contribution Adjustment",
    selectForLastInputNMobile:"Last contribution",
    tipsLastInputN:"My Adjustments",
    buildLastInputNs:"Calculate the prescription adjustment",
    rebuildLastInputNs:"Recalculate the prescription adjustment",
    lastInputNDeleteBtn:"Delete the adjustment(s)",
    lastInputNDeleteDialogTitle:"Delete last contributions?",
    lastInputNDeleteDialogContent:"Are you sure you want to delete these latest contributions?",
    lastInputDeleteDialogContentWarningArchived:"Some of the most recent contributions are recorded in history and will not be deleted.",
    lastInputNDeleteError:"An error occurred when deleting the last contribution.",
    changeImageForLastInputNModulation:"Change image",
    saveLastInputNModulationAndGoNext:"Save and continue",
    ppfDifference:"Deviation from the NMP :",
    doseToSpreadForLastInputN:"Rate to be applied for the last application",
    finalTotalPlotDose:"Final total dose to the field:",
    pdfDownloadNotAvaible:"We are still finalising your summary documents. We will let you know when they are available.",
    modulationValuesAreDifferent:"The total dose has been changed. Remember to update the prescription map.",
    adjusted:"Adjusted",
    modulatingDose:"Dose to be modulated",
    lastNInputImageChoiceHelper:"Choose the most recent image to best reflect the heterogeneity of the field when applying fertiliser. The choice of image will not affect the total dose.",
    receipt:"Receipt",
    lastInputNDose:"Last dose",
    lastInputNGenerationInfo:"Our last application advice is calculated from satellite, weather and agronomic data for your plots. However, be sure to check the regulations in force on your plots to ensure that you are entitled to use tools to control the last nitrogen application.",
    startCalculation:"Start calculation",
    restartCalculation:"Restart calculation",
    infosToStartLastInputN: "To start managing your last inputs, start by completing your NMPs on the plots concerned.",
    generateResumePdfWeightingLastNitrogenInput:"PDF summary of {0} latest contribution(s)",
    generateResumePdfOneWeightingLastNitrogenInput:"PDF summary of this last contribution",
    generateResumePdfWeightingAllLastNitrogenInput:"PDF summary of all the latest contributions",
    lastInputCannotGenerateModulationForArchived: "Modulation not possible for a historical campaign",
    incitationDownloadLastNInputPrescriptions:"You have the rights to generate a last-input adjustment, but to download the modulation maps you need to subscribe to Spotifarm - modulation.",
    incitationDownloadLastNInputPrescriptionsPdf:" for more information. To download the proof of your last contribution, click on the 'PDF' icon.",
    // message correspond aux codes d'erreur renvoyé par l'API
    msgErrorCodeFertiNPrescriptionIsNull:"According to the data you have entered, the quantity of supplies exceeds the amount of needs. The nitrogen dose to be applied is therefore negative.",
    msgErrorCodeEndWinterNAbsorbedOver:"The photos you've selected show a seemingly excessive amount of nitrogen absorbed during the winter. You can change the date of the photo, remembering to check for cloud cover and try again.",
    //autres
    formatDatePlaceHolder:"mm/dd/yyyy",
    beta:"beta",
    newFeaturesAlert:"new",
    contactUs:"contact us",
    //modal videos
    titleModalVideos:"Video tutorials",
}

export default stringLanguage_enGB;