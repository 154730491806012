import { ActionTypeBiomass, BiomassStep, BiomassTab } from '../actions/biomass';
import { BiomassesHelper } from '../../utils/biomassesHelper';
import ConstantsLayers from '../../utils/constantsOfLayers.js';
import { GetBaseLayerIndex, GetLastLayerIndex } from '../../utils/constantsOfLayers.js';


/* state initial */
const initialState = {
    biomassTab: BiomassTab.NewBiomass, // Par défaut, on affiche l'onglet pour créer de nouvelle biomass

    loading: false, //permettra de mettre une waiting cursor dans le tableau et le sidebar...
    deleting: false, //permettra de mettre un waiting cursor dans le tableau des biomasses pendant la suppression des biomasses
    lastAction: undefined, //dernière action faite sur la biomasse
    errorMessage: undefined,
    readableMode: false, //permet de visualiser le paramétrage d'une pesée de biomasse colza sans rien pouvoir en changer (mode consultation).
    building: false, //permettra de mettre une waiting cursor,
    parcelIdsToDesign: undefined, //liste des parcelles visées par une nouvelle demande de génération (cette liste reste complète tous le temps des étapes de paramétrage).
    parcelIdsSelected: undefined, //liste RESTANTES des parcelles visées par une nouvelle demande de génération.
    
    generatingFile: false, //permettra de mettre un waiting cursor lors de la création/téléchargement des fichiers de pesée.

    biomassStep: BiomassStep.CHOIX_PARCELS,

    hasAskedForEndingBiomass: false,
    biomassSelected: undefined,
    biomassIdSelected: undefined,
    biomassesParcelIdDeleted: [], //liste des parcels id dont les modulations ont été supprimées du tableau des modulations sauvegardées.
    newBiomassCounter: 0, //Nombre de nouvelle(s) pesée(s) de biomasse colza complète(s) produite(s) !

    biomassDico: {}, //dico dont la clef est l'ID de parcelle associée - valeur l'entité 'Biomassdata'. => Uniquement les pesée de biomasse colza complète en BdD.
    biomassDicoCounter: 0,

    settings: { //settings for map biomass
        lastLayerForBiomass: 4, //correspond à 'ConstantsLayers.NdviParcelLayerName'
        baseLayerForBiomass: 'satellite',
        showNdviCharts: true,
        isPanelOpened: true, //RQ : Pour le moment, on n'autorise pas son 'masquage' !
    },

    //Choix des couches: 
    baseLayerSelectedForBiomass: ConstantsLayers.VisibleBaseLayerName, // VisibleBaseLayerName ou RoadBaseLayerName !
    forewardLayerSelectedForBiomass: ConstantsLayers.NdviParcelLayerName, // NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
};

function bioMassManagerAction(state = initialState, action) {
    switch (action.type) {
        case ActionTypeBiomass.GO_TO_TAB_OF_BIOMASS: {
            return Object.assign({}, state, {
                biomassTab: action.biomassTab
            });
        }

        case ActionTypeBiomass.CLEAR_NEW_BIOMASS: {
            return Object.assign({}, state, {
                //remise à zéro 
                errorMessage: '',
                newBiomassCounter: 0,
                building: false,
                hasAskedForEndingBiomass: false,
            });
        }

        case ActionTypeBiomass.ERROR_LOAD_BIOMASSES: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: action.errorMessage,
                readableMode: false,
            });
        }

        case ActionTypeBiomass.CLEAN_ERROR: {
            return Object.assign({}, state, {
                errorMessage: '',
            });
        }

        case ActionTypeBiomass.ERROR_BUILD_BIOMASS: {
            return Object.assign({}, state, {
                building: false,
                errorMessage: action.errorMessage,
                readableMode: false,
            });
        }

        case ActionTypeBiomass.ASK_FOR_ENDING_BIOMASS: {
            return Object.assign({}, state, {
                hasAskedForEndingBiomass: action.hasAskedForEndingBiomass
            });
        }

        case ActionTypeBiomass.SELECT_BIOMASS: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: '',
                readableMode: (action.readableMode !== undefined) ? action.readableMode : true,
                biomassSelected: action.biomassSelected,
                building: false,
            });
        }

        case ActionTypeBiomass.SELECT_PARCELS_FOR_BIOMASS: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: '',
                parcelIdsToDesign: action.parcelIdsSelected, //initialisation de la liste des parcelles que l'on vient de sélectionner pour y paramétrer leur pesée de biomasse colza.
                parcelIdsSelected: action.parcelIdsSelected, //@@vérif si ids de parcelles ou de pesées de biomasse colza ?!
            });
        }

        case ActionTypeBiomass.GO_TO_STEP_OF_BIOMASS: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: '',
                biomassStep: action.biomassStep,
                building: (action.biomassStep === BiomassStep.CHOIX_PARCELS) ? false : state.building,
                readableMode: action.readableMode,
            });
        }

        case ActionTypeBiomass.LOADING_BIOMASSES: {
            return Object.assign({}, state, {
                loading: true,
                errorMessage: '',
                /*biomassDico: action.biomassDico,
                biomassDicoCounter: BiomassesHelper.count(action.biomassDico),*/
            });
        }

        case ActionTypeBiomass.LOAD_BIOMASSES: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: '',
                biomassDico: action.biomassDico,
                biomassDicoCounter: BiomassesHelper.count(action.biomassDico),
            });
        }

        case ActionTypeBiomass.SELECT_LAYER_FOR_BIOMASS: {
            //baseLayerSelected peut valoir: ConstantsLayers.VisibleBaseLayerName, // VisibleBaseLayerName ou RoadBaseLayerName !
            //forewardLayerSelected peut valoir: NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            let newBaseLayersSelected = action.baseLayertypeForBiomass;
            if ((!newBaseLayersSelected) || (
                (newBaseLayersSelected !== ConstantsLayers.VisibleBaseLayerName) && (newBaseLayersSelected !== ConstantsLayers.RoadBaseLayerName))) {
                newBaseLayersSelected = ConstantsLayers.VisibleBaseLayerName;
            };
            let newForewardLayersSelected = action.forewardLayertypeForBiomass;
            if ((!newForewardLayersSelected) || (
                (newForewardLayersSelected === ConstantsLayers.VisibleBaseLayerName) || (newForewardLayersSelected === ConstantsLayers.RoadBaseLayerName))) {
                newForewardLayersSelected = ConstantsLayers.NdviParcelLayerName;
            };

            return Object.assign({}, state, {
                errorMessage: '',
                settings: {
                    ...state.settings,
                    baseLayerForBiomass: GetBaseLayerIndex(newBaseLayersSelected),
                    lastLayerForBiomass: GetLastLayerIndex(newForewardLayersSelected),
                },
                baseLayerSelectedForBiomass: newBaseLayersSelected,
                forewardLayerSelectedForBiomass: newForewardLayersSelected,
            });
        }

        case ActionTypeBiomass.CLEAR_BIOMASS_VALUE: {
            return Object.assign({}, state, {
                biomassSelected: undefined,
                errorMessage: '',
                hasAskedForEndingBiomass: false
            });
        }

        case ActionTypeBiomass.START_BUILD_BIOMASS: {
            return Object.assign({}, state, {
                building: true,
                errorMessage: '',
            });
        }

        case ActionTypeBiomass.END_BUILD_BIOMASS: {
            return Object.assign({}, state, {
                building: false,
                errorMessage: '',
            });
        }

        case ActionTypeBiomass.DELETING_BIOMASS: {
            return Object.assign({}, state, {
                deleting: true,
                errorMessage: '',
                readableMode: false,
            });
        }

        case ActionTypeBiomass.DELETING_BIOMASSES: {
            return Object.assign({}, state, {
                deleting: true,
                errorMessage: '',
                readableMode: false,
            });
        }

        case ActionTypeBiomass.DELETE_BIOMASSES: {
            let newBiomassDico = {};

            const actualBiomasses = state.biomassDico;

            if (actualBiomasses && (state.biomassDicoCounter > 0) &&
                (action.biomassIdsDeleted !== undefined) && (action.biomassIdsDeleted.length > 0)) {
                    const counterDeleteToGo = action.biomassIdsDeleted.length;
                    for (const key in actualBiomasses) {
                        const biomassItem = actualBiomasses[key];
                        if (biomassItem) {
                            let isFound = false;

                            for (let indexI = 0; indexI < counterDeleteToGo; indexI++) {
                                let id = action.biomassIdsDeleted[indexI];
                                if (biomassItem.id === id) {
                                    isFound = true;
                                    break; //sort de la boucle puisqu'on l'a trouvé !                        
                                } //else //passe à la comparaison suivante !
                            }
                            if (isFound === false) { //pas trouvé dans la liste de celle à supprimer => on la conserve !
                                newBiomassDico[biomassItem.id] = biomassItem;
                            }
                        }
                    }
                } else {
                    newBiomassDico = actualBiomasses; //Ne change rien !
                }

            return Object.assign({}, state, {
                deleting: false,
                errorMessage: '',
                biomassDico: newBiomassDico,
                biomassDicoCounter: BiomassesHelper.count(newBiomassDico),
            });
        }

        case ActionTypeBiomass.UP_NEW_BIOMASS: {
            
            state.biomassDico[action.newBiomass.id] = action.newBiomass;
            return Object.assign({}, state, {
                //incrémente 
                biomassDicoCounter: BiomassesHelper.count(state.biomassDico),
                biomassDico: state.biomassDico,
                building: false,
            });
        }

        case ActionTypeBiomass.GENERATING_BIOMASS_FILE: {
            return Object.assign({}, state, {
                generatingFile: true,
            });
        }

        case ActionTypeBiomass.BIOMASS_FILE_GENERATED: {
            return Object.assign({}, state, {
                generatingFile: false,
            });
        }
        
        default:
            return state;
    }
}

export default bioMassManagerAction;