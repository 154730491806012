import React from "react";
import lodashGet from 'lodash/get';
import lodashIsNil from 'lodash/isNil';
import { connect } from 'react-redux';

/* Composants MUI */
import {
    Grid, Typography, TextField, CircularProgress, Button, Dialog,
    DialogTitle, DialogContent, DialogActions, FormControl,
    Stack, Select, MenuItem, InputLabel, FormHelperText
} from '@mui/material';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* Redux */
import { CornType, Crop, ActionUpdateVariety, ActionOpenPopupVarietyForm, ActionAddVariety } from "../../redux/actions/harvest.js";

/* css */
import '../../assets/css/pdp-pinDropPopup.css';
import { DefaultHarvestTargets, DefaultHarvestTarget, HarvestHelper } from "../../utils/harvestHelper.js";

/* theme Berry
import getTheme from "../../themes/index.js";

let theme = getTheme(); */


class VarietyForm extends React.Component {
    constructor(props) {
        super(props);

        const variety = props.variety;
        const action = props.action;

        this.state = {
            /* Données de la variété */
            varietyId: lodashGet(variety, 'id', 0),
            label: lodashGet(variety, 'label', ''),
            manufacturer: lodashGet(variety, 'manufacturer', ''),
            flowering: lodashGet(variety, 'flowering', ''),
            varietyInfos: this.initializeVarietyInfos(lodashGet(variety, 'varietyInfos', [])),
            addVariety: (action === "add") ? true : false,
            validationErrors: {},
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { updatingVariety, addingVariety } = this.props;

        if ((prevProps.updatingVariety === true && updatingVariety === false) ||
            ((prevProps.addingVariety === true && addingVariety === false))) {
            this.setState({
                //On remet à zéro les infos qui se trouvent dans la popup
                varietyId: 0,
                label: null,
                manufacturer: null,
                harvestType: CornType.None,
                flowering: '',
                percentageHarvestTarget: '',
                sumDegreeDayHarvestTarget: '',
                varietyInfos: [],
            });
        }
    }

    //Initialise les lignes d'objectifs de récoltes vide
    initializeVarietyInfos(varietyInfos) {
        const emptyInfos = Array(4).fill().map(() => ({
            harvestType: CornType.None,
            percentageHarvestTarget: '',
            sumDegreeDayHarvestTarget: '',
        }));
        return varietyInfos.concat(emptyInfos.slice(varietyInfos.length));
    }

    /*Fonction de vérificiation des données présentes dans les lignes d'objectifs de récoltes */
    validateForm() {
        const { label, flowering, varietyInfos } = this.state;
        const errors = {};
        let hasValidObjective = false;

        if (!label.trim() || label === "" || lodashIsNil(label)) errors.label = `${StringTranslate.varietyLabelRequired}`;
        if (flowering && (parseInt(flowering) <= 0)) errors.flowering = `${StringTranslate.degreeDaysToFloweringGreaterThanZero}`;

        //Contrôles pour chaque champ de la ligne
        varietyInfos.forEach((info, index) => {
            if (info.harvestType !== CornType.None || info.percentageHarvestTarget || info.sumDegreeDayHarvestTarget) {
                if ((!info.harvestType) || (info.harvestType === CornType.None)) errors[`harvestType_${index}`] = `${StringTranslate.harvestTypeRequired}`;

                if (!info.percentageHarvestTarget) errors[`percentageHarvestTarget_${index}`] = `${StringTranslate.harvestObjectiveRequired}`;
                if (isNaN(info.percentageHarvestTarget) || parseInt(info.percentageHarvestTarget) <= 0) errors[`percentageHarvestTarget_${index}`] = `${StringTranslate.harvestObjectiveGreaterThanZero}`;

                if (!info.sumDegreeDayHarvestTarget) errors[`sumDegreeDayHarvestTarget_${index}`] = `${StringTranslate.degreeDaysRequired}`;
                if (isNaN(info.sumDegreeDayHarvestTarget) || parseInt(info.sumDegreeDayHarvestTarget) <= 0) errors[`sumDegreeDayHarvestTarget_${index}`] = `${StringTranslate.degreeDaysGreaterThanZero}`;

                if (info.harvestType && info.percentageHarvestTarget && info.sumDegreeDayHarvestTarget) {
                    hasValidObjective = true;
                }
            }
        });

        if (!hasValidObjective) {
            errors.noHarvestObjective = `${StringTranslate.fillAtLeastOneHarvestTarget}`;
        }

        if (!HarvestHelper.areVarietiesUniq(varietyInfos)) {
            errors.areNoVarietiesInfosIUniq = `${StringTranslate.sameHarvestTargetData}`;
        }

        this.setState({ 
            validationErrors: errors,
        });

        return (Object.keys(errors).length === 0);
    }

    /*Fonction pour ajouter les données de la variété en base de données */
    handleAddVariety() {
        if (!this.validateForm()) return;

        const { varietyId, label, manufacturer, flowering, varietyInfos } = this.state;
        const { addVariety, clientId } = this.props;

        let validVarietyInfos = varietyInfos.filter(info =>
            info.harvestType && info.percentageHarvestTarget && info.sumDegreeDayHarvestTarget &&
            parseInt(info.percentageHarvestTarget) > 0 && parseInt(info.sumDegreeDayHarvestTarget) > 0
        );

        const newVariety = {
            varietyId: varietyId,
            clientId: clientId,
            label: label.trim(),
            manufacturer: manufacturer,
            flowering: flowering,
            harvestCrop: {   //TODO: Adapter quand plusieurs cultures pour récoltes               
                id: 1,
                enumCrop: Crop.Corn,
            },
            varietyInfos: validVarietyInfos,
            degreeThresholdMin: 6,
            degreeThresholdMax: 30,
        };

        addVariety(newVariety);
    }

    /* fonction pour enregistrer les données modifiées de la variété */
    handleUpdateVariety() {
        if (!this.validateForm()) return;

        const { varietyId, label, manufacturer, flowering, varietyInfos } = this.state;
        const { updateVariety, clientId } = this.props;

        let validVarietyInfos = varietyInfos.filter(info =>
            info.harvestType && info.percentageHarvestTarget && info.sumDegreeDayHarvestTarget &&
            parseInt(info.percentageHarvestTarget) > 0 && parseInt(info.sumDegreeDayHarvestTarget) > 0
        );

        const newVariety = {
            id: varietyId,
            clientId: clientId,
            harvestCrop: {
                enumCrop: Crop.Corn,
            },
            label: label.trim(),
            manufacturer: manufacturer,
            flowering: flowering,
            varietyInfos: validVarietyInfos,
            degreeThresholdMin: 6,
            degreeThresholdMax: 30,
        };

        //Mise à jour de la variété en base de données :
        updateVariety(newVariety);
    }

    /*Fonction pour mettre à jour les données de chaque ligne d'objectif de récolte */
    handleChangeInfoField(field, value, index) {
        const varietyInfos = [...this.state.varietyInfos];

        //Cas particuliers :
        switch (field) {
            case 'harvestType':
                if (varietyInfos[index]['harvestType'] !== value) {
                    varietyInfos[index]['percentageHarvestTarget'] = 0;
                }
        
                if (value === CornType.None) {
                    varietyInfos[index]['sumDegreeDayHarvestTarget'] = 0;
                }
                break;
            default:
                break;
        }

        

        varietyInfos[index][field] = value;
        
        this.setState({ varietyInfos });
    }

    /* fonction pour fermer la popup */
    handleCloseUpdateVariety() {
        const { openPopupVarietyForm } = this.props;

        openPopupVarietyForm(false);
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {
        const { label, manufacturer, varietyInfos, flowering, addVariety, validationErrors } = this.state; // etat du dialog popup

        const { updatingVariety, addingVariety, openPopupVariety } = this.props;

        return (
            <>
                {/* ↓↓ Partie visuel - affichage Popup pour la modification d'une variété ↓↓ */}
                {openPopupVariety ?
                    <Dialog
                        maxWidth="xs"
                        open={openPopupVariety}
                        onClose={() => this.handleCloseUpdateVariety()}>
                        <DialogTitle>
                            <Typography>{(addVariety) ? StringTranslate.addVariety : `${StringTranslate.updateVariety} ${label}`} </Typography>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack
                                        direction={{ xs: 'column', sm: 'row' }}
                                        spacing={{ xs: 2, sm: 1 }}>
                                        {/* Label de la variété */}
                                        <TextField
                                            size="small"
                                            disabled={(updatingVariety === true) || (addingVariety === true)}
                                            required
                                            error={!!validationErrors.label}
                                            helperText={validationErrors.label}
                                            label={StringTranslate.harvestVariety}
                                            id="variety-label"
                                            name="label"
                                            value={label}
                                            onChange={(event) => this.setState({ label: event.target.value, })}
                                        />

                                        {/* Fabriquant de la variété */}
                                        <TextField
                                            size="small"
                                            disabled={(updatingVariety === true) || (addingVariety === true)}
                                            label={StringTranslate.manufacturer}
                                            id="variety-manufacturer"
                                            name="manufacturer"
                                            value={manufacturer}
                                            onChange={(event) => this.setState({ manufacturer: event.target.value, })}
                                        />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12}>
                                    {/* Nombre de degré jours à floraison de la variété */}
                                    <TextField
                                        size="small"
                                        disabled={(updatingVariety === true) || (addingVariety === true)}
                                        error={!!validationErrors.flowering}
                                        helperText={validationErrors.flowering}
                                        type="number"
                                        label={StringTranslate.degreeDaysToFlowering}
                                        id="variety-flowering"
                                        name="flowering"
                                        value={flowering}
                                        onChange={(event) => this.setState({ flowering: event.target.value })}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    {/* Infos de la variété */}
                                    {(varietyInfos !== undefined) && varietyInfos.map((info, index) => ( 
                                        <Grid container spacing={1} key={index}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" display="block" gutterBottom sx={{ mt: 2 }}>{StringTranslate.objective}{index + 1} :</Typography>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl fullWidth error={!!validationErrors[`harvestType_${index}`]}>
                                                    <InputLabel required id={`select-label-harvest-type-${index}`}>{StringTranslate.cornType}</InputLabel>
                                                    <Select 
                                                        disabled={(updatingVariety === true) || (addingVariety === true)}
                                                        size="small"
                                                        required
                                                        labelId={`select-label-harvest-type-${info.id}`}
                                                        id={`select-harvest-type-${info.id}`}
                                                        value={info.harvestType}
                                                        label={StringTranslate.cornType}
                                                        onChange={(event) => this.handleChangeInfoField('harvestType', event.target.value, index)}>
                                                        <MenuItem value={CornType.None}>{StringTranslate.selectItem}</MenuItem>
                                                        <MenuItem value={CornType.Grain}>{StringTranslate.grain}</MenuItem>
                                                        <MenuItem value={CornType.Ensilage}>{StringTranslate.ensilage}</MenuItem>
                                                    </Select>
                                                    {validationErrors[`harvestType_${index}`] && <FormHelperText>{validationErrors[`harvestType_${index}`]}</FormHelperText>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Stack
                                                    direction={{ xs: 'column', sm: 'row' }}
                                                    spacing={{ xs: 2, sm: 1 }}>
                                                    <TextField
                                                        disabled={(updatingVariety === true) || (addingVariety === true) || (info.harvestType === CornType.None)}
                                                        size="small"
                                                        required
                                                        type="number"
                                                        error={!!validationErrors[`sumDegreeDayHarvestTarget_${index}`]}
                                                        helperText={validationErrors[`sumDegreeDayHarvestTarget_${index}`]}
                                                        label={StringTranslate.needsInDegreeDays}
                                                        id={`variety-sumDegreeDayHarvestTarget-${index}`}
                                                        name="sumDegreeDays"
                                                        value={(info.sumDegreeDayHarvestTarget <= 0) ? "" : info.sumDegreeDayHarvestTarget}
                                                        onChange={(event) => this.handleChangeInfoField('sumDegreeDayHarvestTarget', event.target.value, index)}
                                                    />

                                                    <FormControl fullWidth error={!!validationErrors[`percentageHarvestTarget_${index}`]}>
                                                        <InputLabel required id={`select-label-percentage-harvest-target-${index}`}>{StringTranslate.percentageForPopup}</InputLabel>
                                                        <Select
                                                            disabled={(updatingVariety === true) || (addingVariety === true) || (info.harvestType === CornType.None)}
                                                            size="small"
                                                            required
                                                            labelId={`select-label-percentage-harvest-target-${info.id}`}
                                                            id={`select-percentage-harvest-target-${info.id}`}
                                                            value={(info.percentageHarvestTarget <= 0) ? 0 : info.percentageHarvestTarget}
                                                            label={StringTranslate.percentageForPopup}
                                                            onChange={(event) => this.handleChangeInfoField('percentageHarvestTarget', event.target.value, index)}>
                                                            <MenuItem key={0} value={0} disabled>{StringTranslate.selectItem}</MenuItem> {/* select default */}
                                                            {(info.harvestType === CornType.Grain) && DefaultHarvestTargets.Grain.map((vi) => {
                                                                return (vi === DefaultHarvestTarget) ? <MenuItem key={vi} value={vi}><strong>{vi}% {StringTranslate.humidity}</strong></MenuItem> :
                                                                <MenuItem key={vi} value={vi}>{vi}% {StringTranslate.humidity}</MenuItem> })}
                                                            {(info.harvestType === CornType.Ensilage) && DefaultHarvestTargets.Silage.map((vi) => {
                                                                return (vi === DefaultHarvestTarget) ? <MenuItem key={vi} value={vi}><strong>{vi}% {StringTranslate.dryMaterial}</strong></MenuItem> :
                                                                <MenuItem key={vi} value={vi}>{vi}% {StringTranslate.dryMaterial}</MenuItem> })}
                                                        </Select>
                                                        {validationErrors[`percentageHarvestTarget_${index}`] && <FormHelperText>{validationErrors[`percentageHarvestTarget_${index}`]}</FormHelperText>}
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    {validationErrors.noHarvestObjective && (
                                        <Typography color="error" sx={{ mt: 3 }}>
                                            {validationErrors.noHarvestObjective}
                                        </Typography>
                                    )}
                                    {validationErrors.areNoVarietiesInfosIUniq && (
                                        <Typography color="error" sx={{ mt: 3 }}>
                                            {validationErrors.areNoVarietiesInfosIUniq}
                                        </Typography>
                                    )}
                                </Grid>

                            </Grid>
                        </DialogContent>

                        {/* - Bouton d'annulation qui ferme la popup 
                        - Bouton d'enregistrement des données de la variété */}
                        <DialogActions>
                            <Button variant="text" color="error"
                                onClick={() => this.handleCloseUpdateVariety()}
                                disabled={(updatingVariety === true) || (addingVariety === true)}>{StringTranslate.cancelDeleteAction}</Button>
                            <Button
                                color="primary"
                                disabled={(updatingVariety === true) || (addingVariety === true)}
                                autoFocus
                                startIcon={((updatingVariety === true) || (addingVariety === true)) && <CircularProgress color="inherit" size={25} />}
                                variant="contained"
                                onClick={(addVariety) ? () => this.handleAddVariety() : () => this.handleUpdateVariety()}>
                                {(addVariety) ? StringTranslate.ajouter3 : StringTranslate.enregistrer}
                            </Button>
                        </DialogActions>
                    </Dialog> : null}
            </>
        );
    }

}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'harvest':
    errorHarvest: lodashGet(state, 'harvestData.errorMessage', undefined),
    updatingVariety: lodashGet(state, 'harvestData.updatingVariety', false),
    openPopupVariety: lodashGet(state, 'harvestData.openPopupVarietyForm', false),
    addingVariety: lodashGet(state, 'harvestData.addingVariety', false),
    //Infos provenant du reducer 'clientUser':
    clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    updateVariety: (variety) => dispatch(ActionUpdateVariety(variety)),
    openPopupVarietyForm: (open) => dispatch(ActionOpenPopupVarietyForm(open)),
    addVariety: (newVariety) => dispatch(ActionAddVariety(newVariety)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VarietyForm);