import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import lodashGet from 'lodash/get';

/* Translate */
import StringTranslate from '../../assets/i18n/stringLanguage';

/* Utils */
import { IsNativeHoster } from '../../utils/platformHelper';

/* Components react */
import MainCard from "../../components/subLayouts/mainCard";
import { TabPanel, buildPropsForTab } from '../../components/tabPanel';
import HarvestStepsManagement from '../../components/harvest/harvestStepsManagement';
import HarvestList from '../../components/harvest/harvestsList';
import CornHarvestAuthorization from '../../components/harvest/cornHarvestAuthorization.jsx';
import HarvestVarietiesList from '../../components/harvest/harvestVarietiesList.jsx';

/* Components MUI */
import { Tab, Tabs } from '@mui/material';

/* Actions Redux */
import { ActionSetCurrentPageName, PageName } from '../../redux/actions/contextApp';
import { HarvestStep, ActionGetAllHarvests, ActionGetAllVarietiesCharacteristics, ActionLoadTargetDryingInfos, 
    Crop, ActionGoToStepOfHarvest, ActionGoToTab, HarvestTab } from '../../redux/actions/harvest';

/* Icones */
import { Add } from '@mui/icons-material';
import CornIcon from '../../assets/images/harvest/cornIcon.tsx';

/* theme */
//import getTheme from "../../themes/index.js";


//let theme = getTheme();

///////////////////////////////////////////////////////////////////////////
// Composant général concernant la modulation de parcelles (gestion et affichage).
///////////////////////////////////////////////////////////////////////////
class AbstractHarvest extends React.Component {
    constructor(props) {
        super(props);

        this.state = { };
    }

    handleChangeTab = (event, newValue) => {
        //Signaler à Redux la position de la tabulation :
        this.props.setTab((newValue === 0) ? HarvestTab.Management : (newValue === 1) ? HarvestTab.Summary : HarvestTab.VarietiesSummary);

        //Quand on retourne à l'onglet 1 "Nouvelle récolte de maïs", on doit se retrouver à l'étape 1 sion a finit l'étape 2
        //Sinon on reste à l'étape 2 (si on décide de retourner sur l'onglet 1)
        if (newValue === 0) {
            const { goToStep, specificInfosParcelIdsDone, parcelIdsSelected } = this.props;
            if ((specificInfosParcelIdsDone.length > 0) && 
                (parcelIdsSelected.length > 0) && 
                (specificInfosParcelIdsDone.length === parcelIdsSelected.length)) {
                goToStep(HarvestStep.CHOIX_PARCELS);
            }
        }
    }

    componentDidMount() {
        const { setCurrentPageName, getAllHarvests, clientId, getAllVarietiesCharacteristics, currentCrop, loadTargetDryingInfos } = this.props;

        //Signaler à Redux qu'on est dans la fonctionnalité Récolte
        setCurrentPageName(PageName.Harvest, false);

        //Chargement des données de récolte
        getAllHarvests(clientId, currentCrop, HarvestStep.MANAGEMENT);

        //Chargement des variétés du client mais aussi celles communes
        getAllVarietiesCharacteristics(clientId, currentCrop);

        //Chargement des spécificités des cultures, pour certains cas uniquement... (Ex: Maïs grain)
        loadTargetDryingInfos(clientId, currentCrop);
    }

    render() {
        const { harvestTab, authorizeCornHarvest } = this.props;

        const nativeAppHoster = IsNativeHoster();

        return (
            <MainCard>
                    <Tabs
                        value={harvestTab}
                        variant="scrollable"
                        onChange={this.handleChangeTab}
                        variant_pz="row"
                    >
                        {/* menu des onglets: ('buildPropsForTab' génère les ids entre les items de menu et leur contenu respectif) */}
                        <Tab
                            component={Link}
                            to="#"
                            icon={<Add />}
                            label={(nativeAppHoster === true) ? `${StringTranslate.ListparcelsHarvestMobile}` : `${StringTranslate.ListparcelsHarvest}`}
                            {...buildPropsForTab('menuHarvest', HarvestTab.Management)} />
                        <Tab
                            component={Link}
                            to="#"
                            icon={<CornIcon />}
                            label={(nativeAppHoster === true) ? `${StringTranslate.harvestsExistsTitleMobile}` : `${StringTranslate.harvestsExistsTitle}`}
                            {...buildPropsForTab('menuHarvest', HarvestTab.Summary)} />

                        <Tab
                            component={Link}
                            to="#"
                            icon={<CornIcon />}
                            label={`${StringTranslate.myVarieties}`}
                            {...buildPropsForTab('menuHarvest', HarvestTab.VarietiesSummary)} />

                    </Tabs>

                    {/* contenu des onglets: */}
                    <TabPanel value={harvestTab} index={HarvestTab.Management} prefixId='menuHarvest'>
                        { (authorizeCornHarvest === true) ? <HarvestStepsManagement /> : <CornHarvestAuthorization /> }
                    </TabPanel>
                    <TabPanel value={harvestTab} index={HarvestTab.Summary} prefixId='menuHarvest'>
                        <HarvestList />
                    </TabPanel>
                    <TabPanel value={harvestTab} index={HarvestTab.VarietiesSummary} prefixId='menuHarvest'>
                        <HarvestVarietiesList />
                    </TabPanel>

                </MainCard>);

    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'clientUser':
    clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
    isOpenMenuBurgerDesktop: lodashGet(state, 'contextAppData.isOpenMenuBurgerDesktop', false),
    authorizeCornHarvest: lodashGet(state, 'clientUserData.clientDatas.authorizeCornHarvest', true),

    //Infos provenant du reducer 'harvest':
    currentCrop: lodashGet(state, 'harvestData.currentCrop', Crop.Corn),
    specificInfosParcelIdsDone: lodashGet(state, 'harvestData.specificInfosParcelIdsDone', []),
    parcelIdsSelected: lodashGet(state, 'harvestData.parcelIdsSelected', {}),
    harvestTab: lodashGet(state, 'harvestData.harvestTab', HarvestTab.Management),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    setCurrentPageName: (pageName) => dispatch( ActionSetCurrentPageName(pageName, false) ),
    getAllHarvests: (clientId, cropEnum) => dispatch(ActionGetAllHarvests(clientId, cropEnum)),
    getAllVarietiesCharacteristics: (clientId, cropEnum) => dispatch( ActionGetAllVarietiesCharacteristics(clientId, cropEnum) ),
    loadTargetDryingInfos: () => dispatch(ActionLoadTargetDryingInfos()),
    goToStep: (step) => dispatch(ActionGoToStepOfHarvest(step)),
    setTab: (tab) => dispatch(ActionGoToTab(tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AbstractHarvest);