let stringLanguage_frBE = {
    //Login    
    relancerAuthent:"Relancer l'authentification",
    //Sidebar
    carte:"Carte",
    btnHarvestOnSidebar:"Récoltes maïs",
    btnModulationOnSidebar:"Modulations",
    btnBiomassOnSidebar:"Pesées de colza",
    btnFertilizerOnSidebar:"Conseil Azoté",
    parcellesListBtnOnSidebar:"Parcelles",
    parcellesImportBtnOnSidebar:"Importer mes parcelles",
    observationBtnOnSidebar:"Observations",
    aide:"Aide",
    allHelps:"Aides utiles",
    termsAndConditionsSpotifarm:"Voir les conditions générales de Spotifarm ?",
    dependenciesApi:"Voir ci-dessous toutes les dépendances et API utilisées dans notre produit",
    indicevege:"Comprendre l'indice de végétation",
    language:"Langage",
    //importer mes parcelles
    importparcelles:"Saisis le nom de ton exploitation",
    provfichier:"Sélectionne la provenance de tes fichiers",
    aideimport:"Besoin d'aide pour importer mes parcelles",
    aideimportParcelles:"Comment récupérer mes parcelles ?",
    autrefichier:"Autres",
    selectionnerfichier:"Sélectionne ton fichier",
    enregistrerparcelles:"Enregistrer mes parcelles",
    annulerimport:"Annuler",
    aideimportredirect:"Si tu n'as pas tes parcelles dans un fichier, tu peux les dessiner directement sur la carte",
    importFichiersProvenance:"Provenance des fichiers",
    importFichiersType:"Type de fichiers",
    importFichiersTypeZip:"Fichier zip",
    importFichiersTypeIndividuels:"Fichiers individuels",
    importParcellesGif:"Gif d'aide d'import de fichier",
    // Header
    seconnecter:"Se connecter",
    creationcompte:"Créer un compte",
    changePassword:"Changer mon mot de passe",
    disconnect:"Se déconnecter",
    confirmDisconnection:"Es-tu sûr de vouloir te déconnecter ?",
    confirmDeleteAccount:"Es-tu sûr de vouloir supprimer ton compte ?",
    confirmChangePassword:"Es-tu sûr de vouloir modifier ton mot de passe ?",
    confirmChangePwd:"Changer",
    parametres:"Paramètres",
    notifications:"Notifications",
    historique:"Historique",
    parametrage:"Paramétrages",
    compte:"Compte",
    plus:"Plus",
    monprofil:"Mon profil",
    calendrier:"Calendrier",
    tooltipDisableFilter:"active (ou pas) le filtre",
    farmsLabel:"Choix de l'exploitation",
    cropsLabel:"Choix de la culture",
    nameLabel:"Sélection par nom",
    allFarmFilter:"Toutes",
    allCropFilter:"Toutes",
    noFarmNameFilter:"<sans nom>",
    noCropNameFilter:"<sans nom>",
    selectedFarmsCounter:" exploitations filtrées",
    selectedCropsCounter:" cultures filtrées",
    filterAreaHelper:"Saisis une surface pour appliquer le filtre",
    selectionnerhistorique:"Clique sur une parcelle afin d'afficher l'historique",
    news:"Nouveautés",
    tutoVideos:"Tutos vidéos",
    contactSupport:"Contacter le service client",
    shareApp:"Partager l'appli",
    joinWhatsappGroup:"Rejoindre le groupe whatsapp",
    rateUs:"Noter l'appli",
    aboutUs:"À propos​",
    removeClientFromNativeApp:"Supprimer mon compte et mes données",
    titleConfirmRemoveAccount:"Confirmation avant suppression totale",
    askConfirmRemoveAccount:"Souhaites-tu réellement que nous supprimions ton compte ainsi que toutes tes données ?",
    cancelRemoveAccount:"Annuler",
    validRemoveAccount:"Confirmer",
    //Notifications:
    conseil:"Conseil:",
    incitationWellcomeTitle:"Bienvenue sur ton compte Spotifarm",
    incitationWellcomeTextWithDiscountPV:"Utiliser le code promo : '#LECODEPROMO#' pour bénéficier de 2 mois gratuits.",
    incitationWellcomeTextWithDiscount:"Utiliser le code promo : '#LECODEPROMO#' pour bénéficier d'une remise sur l'abonnement.",
    incitationWellcomeText:"Il existe une version PRO...",
    incitationTitle:"Envie de nouvelles images 🛰 ?",
    invitationPremium:"Fonctionnalité non-disponible avec ton abonnement actuel...",
    invitationPremiumLayersGlobal:"Les couches globales sont réservées à la version PRO.",
    invitationPremiumLayersGlobalForMobile:"Ces fonctionnalités sont réservées aux utilisateurs ayant souscrit à la version complète de Spotifarm.",
    invitationPremiumNoLimitParcels:"Tu es limité à 3 parcelles...",
    invitationPremiumNoLimitHitoricParcels:"Tu ne peux pas générer l'historique des parcelles...",
    invitationPremiumSolution:"Découvre la version PRO de spotifarm et active également toutes les autres fonctionnalités !",
    invitationPremiumAction:"J'essaye la version PRO >>",
    incitationMoreInfos:"Pour plus d'informations, visite notre site: ",
    invitationPremiumPlusSolution:"Découvre la version PRO de spotifarm et active également toutes les fonctionnalités dont la modulation !",
    invitationPremiumPlusSolutionBiomass:"Découvre la version PRO de spotifarm et active également toutes les fonctionnalités dont les pesées de colza !",
    invitationPremiumPlusSolutionFertilizer:"Découvre la version PRO de spotifarm et active également toutes les fonctionnalités dont les fumures !",
    //settings
    monparametrage:"Mon paramétrage",
    nommax20:"Le nom ne doit pas excéder plus de 20 caractères",
    valeurtolere:"valeur tolérée entre 0 et 100",
    modifier:"Modifier",
    annuler:"Annuler",
    enregistrer:"Enregistrer",
    suppParcel:"Importer un nouveau parcellaire",
    monParcellaire:"Mon Parcellaire",
    confirmSuppParcels:"Ce nouvel import entraînera la suppression de ton parcellaire actuel (ainsi que les modulations et pesées associées), veux-tu continuer ?",
    looseActualImages:"La suppression de ton parcellaire entrainera la suppression des images satellites associées. Pour charger de nouvelles images il faut t'abonner à Spotifarm.",
    importparcel:"Importer mes parcelles",
    nomexploit:"Nom de l'exploitation",
    tauxnuage:"Taux d'ennuagement",
    legendAndGraph:"Légende et histogramme",
    myaccount:"Mon compte",
    myexploitation:"Ma cartographie",
    mymodulation:"Modulation",
    mybiomasses:"Mes pesées de colza",
    qualityImagModul:"Réglage de la résolution des cartes de modulation",
    IsoXmlFileVersion:"Version des fichiers IsoXml",
    ShpDoseLabel:"Identifiant des doses dans les prescriptions SHP",
    ShpDoseLabelNotBeVoid:"L'identifiant ne peut pas être vide", 
    ShpDoseLabelWithInvalidChar:"Ton identifiant contient au moins un caractère non supporté", 
    maxModulationQualityLabel:"très-haute",
    mediumModulationQualityLabel:"haute",
    defaultModulationQualityLabel:"moyenne",
    minModulationQualityLabel:"basse",
    maxModulationQualityDetail:"Résolution maximale, limitant parfois la lisibilité par certaines consoles",
    mediumModulationQualityDetail:"Résolution haute",
    defaultModulationQualityDetail:"Résolution moyenne",
    minModulationQualityDetail:"Diminution de la résolution de la carte au maximum pour faciliter l'acceptabilité par certaines consoles",
    noRows:"Aucune donnée à afficher.",
    displayLandsat8:"Afficher les images Landsat8",
    //parcelles
    importparcelles2:"Pour repartir avec un nouvel assolement",
    ListparcelsModulation:"Nouvelle carte de modulation",
    ListparcelsModulationMobile:"Nouvelle carte",
    listparcels:"Liste de mes parcelles",
    nomcolumn:"Nom",
    idcolumn:"Id",
    searchPlaceholder:"Recherche",
    search:"Recherche",
    filterall:"Tout",
    filtertitle:"Filtres",
    filterreset:"Réinitialiser",
    viewcolumntitle:"Colonnes à sélectionner",
    viewcolumntitlearia:"Montrer/cacher Colonnes de la table",
    desonoinfo:"Désolé. Aucune information trouvée",
    sort:"Trier",
    selectedParcelRowText:"parcelle(s) sélectionnée(s)",
    errorGenerateParcelDrawed:"La parcelle dessinée n'a pas pu être sauvegardée.",
    askForDeletingParcel:"Es-tu sûr de vouloir supprimer cette parcelle ?",
    askForDeletingParcels:"Es-tu sûr de vouloir supprimer les parcelles sélectionnées ?",
    deleteParcel:"Supprimer",
    deleteParcels:"Supprimer les parcelles sélectionnées",
    alertDeleteModulation:"Les modulations liées à ces parcelles seront également supprimées",
    // DicoDetails.json
    libelecolumncampagne:"Campagne",
    libelecolumnculture:"Culture",
    libelecolumnvariete:"Variété",
    libelecolumnsemis:"Date de semis",
    libelecolumnrecolte:"Date de récolte",
    libelecolumnfloraison:"Date de floraison",
    libelecolumnrecolteestime:"Date de récolte estimée",
    libelecolumndegrecertitude:"Degré de certitude",
    libelecolumncultureprece:"Culture précédente",
    libelecolumnsurface:"Surface (Ha)",
    libelecolumnexploitation:"Exploitation",
    libelecolumnmodel:"Modèle de culture",
    // choix listes déroulante pour culturesn exploitations, variétés
    chooseCulture:"- Choisir une culture -",
    chooseExploitation:"- Choisir une exploitation -",
    chooseVariety:"- Choisir une variété -",
    // DicoCulture
    libelleBetterave:"Betterave",
    libelleBleDur:"Blé dur",
    libelleBleTendre:"Blé tendre",
    libelleColza:"Colza",
    libelleLin:"Lin",
    libelleLuzerne:"Luzerne",
    libelleMaisEnsilage:"Mais ensilage",
    libelleMaisGrain:"Mais grain",
    libelleOrgeDePrintemps:"Orge de printemps",
    libelleOrgeDHiver:"Orge d'hiver",
    libellePoisFourrager:"Pois fourrager",
    libellePoisProteagineux:"Pois protéagineux",
    libellePrairiePermanente:"Prairie permanente",
    libellePrairieTemporaire:"Prairie temporaire",
    libellePommeDeTerre:"Pomme de terre",
    libelleSoja:"Soja",
    libelleTournesol:"Tournesol",
    libelleAutre:"Autre",
    // error
    erroroups:"Oups",
    errorsuite:", une erreur est survenue dans l'application",
    error:"L'erreur nous a été envoyée et sera corrigée prochainement",
    // StripeInfoCard(anciennement 'StripeInformationDetails')
    infofact:" Mes informations de facturation",
    infofactForMobile:" Mes informations personnelles",
    mesabos:"Mes abonnements",
    abonner:"Abonnement",
    label:"adresse:",
    postalcode:"code postal: ",
    city:"ville: ",
    country:"pays: ",
    firstname:"prénom: ",
    lastname:"nom: ",
    company:"société: ",
    usertext:"dénomination: ",
    phone:"téléphone: ",
    id:"identifiant: ",
    subscriptiontext:"abonnement: ",
    subscriptionpaidstate:"compte payant",
    subscriptionfreestate:"compte gratuit",
    description:"description: ",
    card:"ta carte",
    expiry:"expiration",
    askForSubscription:"Demande de changement d'abonnement",
    contactForSubscription:"Notre équipe te recontactera au plus vite",
    // StripePlanCard
    jour:" HT / Jour",
    semaine:" HT / Semaine",
    mois:" HT / Mois",
    annee:" HT / An",
    jourS:" HT, tous les X jours",
    semaineS:" HT, toutes les X semaines",
    moisS:" HT, tous les X mois",
    anneeS:" HT, toutes les X années",
    selectbutton:"Démarrer",
    unsuscribebutton:"se désabonner",
    suscribetext:"Abonné",
    buyByStore:"via ton Store",
    comingsoontext:"prochainement",
    promocodeedit:"Code Promo",
    promocodeverify:"valider",
    promocodecancel:"annuler",
    promocodenotvalid:"code non valide",
    // StripeCreditCardView
    cbName:"NOM DU TITULAIRE",
    numberelement:"Numéro de carte",
    expiryelement:"Date d'expiration",
    cvcelement:"Cryptogramme",
    errorSaveCBOfCustomer:"L'enregistrement de tes coordonnées bancaires a provoqué une erreur.",
    errorSaveCustomer:"La création de tes données n'a pas abouti.",
    confirmerpaiement:"Confirmer le paiement",
    revenir:"Retour",
    endcard:"ta carte termine par: ",
    expiration:"expiration: ",
    cardexpiry:"ta carte bancaire est expirée ; change là",
    confirmersub:"Confirmer la nouvelle souscription",
    cartenonvalide:"ta carte bancaire n'est pas la bonne",
    changeOfCard:"Changer de carte",
    titlecard:"Ma carte bancaire",
    cardNewInput:"Renseigne ta carte bancaire",
    subheader:"Données fournies par le service bancaire",
    cgvtext:"En cochant cette case, tu confirmes que tu as lu et accepté les ",
    cgvlink:"conditions générales de vente.",
    pgdlink:"Politique de confidentialité.",
    // StripeInvoice
    facture:"Numéro",
    descriptionfac:"Description",
    prix:"Prix (TTC)",
    statut:"Statut",
    pdf:"PDF",
    factures:"factures",
    aucunefac:"Aucune facture en cours",
    facturePayee:"Payée",
    factureEnImpayee:"En attente",
    viewYourBillsTitle:"Consulter tes factures",
    viewYourBillsText:"Pour consulter tes factures, clique ici",
    // StripePlanView
    desabo:"Si tu souhaites te désabonner, contacte notre service clientèle par email",
    selectPlanMessageText:"La sélection volontaire d'un abonnement est nécessaire pour passer à l'étape suivante",
    autounsubcribePlanMessageText:"Disposant déjà d'un abonnement, le choix d'une nouvelle souscription arrêtera automatiquement l'abonnement actuel. Tu n'auras qu'un seul prélèvement périodique.",
    // StripeSuscribeProcess
    selectionabo:"Sélectionne ton abonnement",
    infofac:"Renseigne tes informations de facturation",
    infobanc:"Renseigne tes informations bancaires",
    errorpay:"une erreur est survenue lors du paiement (création des données de compte). Contacte le service client",
    nonvalide:"les données d'entrée ne sont pas valides",
    errorsous:"une erreur est survenue lors du paiement (souscription). Contacte le service client",
    felicitation:"Félicitations !",
    aboValide:"Nous venons de valider ton abonnement Spotifarm 🙂.\nIl nous reste une dernière étape qui est la validation du paiement auprès de ta banque. Cela ne prendra pas plus d'une journée et d'ici là tu peux profiter pleinement de Spotifarm comme si de rien était !\nTu nous as fait confiance donc nous faisons de même 🙂.\nSi il y a avait un problème avec le paiement nous reviendrons vers toi, sinon considères que tout est en ordre !",
    aboValideMobile:"Nous venons de valider ton abonnement Spotifarm 🙂.\nTu peux profiter pleinement de Spotifarm dès maintenant 🙂!\nTu peux retrouver ton achat directement sur ton espace du Store de ton mobile",
    continuer:"Continuer",
    reloadingClientDatas:"Nous rechargeons tes données... Merci de patienter.",
    oups:"Oups...",
    abofail:"Le paiement n'a pas été accepté. Nous t'invitons à appeler notre service clientèle par email",
    abofailDetails:"Détails:",
    errorCreateCBToken:"L'enregistrement de tes coordonnées bancaires a soulevé une erreur.",
    errorCreateCustomerTokenVoid:"L'enregistrement de tes coordonnées bancaires semble avoir échoué.",
    errorCreateCustomerTokenIdVoid:"Le résultat de l'enregistrement de tes coordonnées bancaires semble avoir échoué.",
    errorGetClientBilling:"La lecture de vos informations a échoué.",
    errorUpdateClientBilling:"L'enregistrement de vos informations a échoué",
    errorStripeCreateCustomer:"La création de tes données (pour la mise en place de ton abonnement) n'a pas abouti.",
    errorCreateSubcribePlanIdVoid:"Ton adhésion à l'abonnement semble avoir échoué.",
    errorStripeSubscribePlan:"Ton adhésion à l'abonnement n'a pas abouti.",
    UserDataInvoiceSaveError:"une erreur est survenu lors la sauvegarde de tes données",
    UserDataInvoiceNotComplet:"Les données semblent incomplètes...",
    submitting:"Enregistrement en cours",
    // StripeUserFormView
    allemagne:"Allemagne",
    belgique:"Belgique",
    france:"France",
    luxembourg:"Luxembourg",
    suisse:"Suisse",
    tvanonrecuperable:"attention, la TVA ne sera pas récupérable",
    renseignerprenom:"tu dois renseigner un prénom",
    renseignernom:"tu dois renseigner un nom",
    renseignertelephone:"tu dois renseigner un numéro de téléphone",
    renseigneradresse:"tu dois renseigner une adresse",
    renseignercode:"tu dois renseigner un code postal valide",
    renseignerville:"tu dois renseigner une ville",
    renseignerpays:"tu dois renseigner un pays",
    errorsave:"Une erreur est survenue lors de la sauvegarde de tes données\nRetentes ultérieurement",
    labelPrenom:"Prénom",
    labelNom:"Nom",
    labelTelephone:"Téléphone",
    labelSociete:"Société",
    labelAdresse:"Adresse",
    labelCp:"Code postal",
    labelVille:"Ville",
    acprenom:"ton prénom",
    acnom:"ton nom",
    actelephone:"ton numéro de téléphone",
    acsociete:"ta société",
    acadresse:"ton adresse",
    acville:"ville",
    valider:"Valider",
    other:"Autre",
    mandatoryField:"Ce champ est obligatoire",
    AnyCityFromCurrentPostalcode:"Le code postal actuel n'est associé à aucune ville",
    SomeDataRequired:"Un champ obligatoire est manquant ou incomplet", 
    // calendrier 
    revenirimage:"Revenir à la dernière image",
    newerImage:"Tu es à la dernière image",
    legendForDelayedImage:"Image pas encore disponible",
    legendForCloudyImage:"Image ennuagée",
    buttonForMoreImage:"Obtenir plus d'images",
    buttonGoBackLastImage:"Revenir à la dernière image",
    imageNonObtenue:"L'image n'est pas téléchargeable",
    noImageBefore:"Il n'existe aucune image disponible avant cette date!",
    tauxennuagement:"{0} % d'ennuagement",
    // errorBoundary
    relancerapp:"Relancer l'application",
    //imageSwiper
    chargeimage:"images en cours de chargement, merci de patienter...",
    formatDate:"dd/MM/yyyy",
    //layerSelection
    fondcarte:"Fond de carte",
    plan:"Plan",
    satellite:"Satellite",
    vueglobale:"Sur toute la carte",
    vueglobaleOnlyForPremium:"Option désactivée pour les clients gratuits",
    visible:"Visible",
    indvege:"Indice de végétation",
    vueparcelle:"Sur mes parcelles",
    carterout:"carte routière",
    visustand:"visualisation standard",
    photovis:"photos visibles depuis le satellite",
    anavege:"analyse de la végétation",
    displayObservations:"Afficher les observations",
    //loginTest
    redirectauth:"Redirection vers la page d'authentification.",
    redirectmdp:"redirection vers la page de changement de mot de passe.",
    deco:"Déconnexion en cours, merci de patienter...",
    reco:"Connexion en cours, merci de patienter...",
    reco2:"Lancement du satellite, merci de patienter...",
    //mapinfo
    recupimg:"Récupération des images, merci de patienter… ",
    newimg:"Nouvelles images disponibles",
    noimg:"Aucune nouvelle image",
    redirectimport:"Pense à importer tout ton parcellaire",
    maxccupdate:"Images mises à jour",
    selectionnerparcelle:"Clique sur une parcelle afin d'afficher le calendrier",
    clickToUnderstandImage:"Clique ici pour comprendre les images de Spotifarm",
    //parcelNameEdit
    enregistrer2:"enregistrer",
    annuler2:"annuler",
    modifier2:"modifier",
    //parcelEdit
    enregistrer3:"enregistrer",
    annuler3:"annuler",
    modifier3:"modifier",
    ajouter3:"Ajouter",
    updateCrop:"Modifier la parcelle",
    titreajouterinformation:"Ajouter une information",
    libelleajouterinformation:"Rentre une information inexistante à la liste des informations prédéfinies.",
    libellecolumninformation:"Information",
    //searchBar
    recherche:"Nom de parcelle",
    filtre:"Filtres",
    surface:"Surface",
    typecult:"Type de culture",
    reinitialiser:"Réinitialiser",
    filtrer:"Filtrer",
    tous:"Tous",
    labelAreaCompareAll:"Toute",
    labelAreaCompareLower:"<",
    labelAreaCompareUpper:">",
    SampleParcelAreaValue:"ex: 1",
    // stateCalendar
    obtentionimg:"Obtention des images en cours",
    obtentionNotAsk:"...",
    pourmois:"pour ce mois-ci",
    noimgexpl:"Pas d'images exploitables",
    errorimg:"Erreur lors de l'obtention des images",
    reloadhisto:"Recharger",
    //importParcels
    mezip:"Sans le fichier .zip, l'import ne peut avoir lieu",
    mefichier:"Le fichier d'importation a provoqué une erreur",
    me4:"erreur: tu dois sélectionner : Soit les 4 fichiers avec les extensions .shp .shx .dbf .prj ; Soit un fichier .zip",
    me3:"erreur: tu dois sélectionner : Soit les 3 fichiers avec les extensions .shp .shx .dbf ; Soit un fichier .zip",
    me2Min:"erreur: tu dois fournir : Soit au moins les fichiers .shp et .dbf (Le fichier d'extension .prj est optionnel mais conseillé) ; Soit un fichier .zip",
    meZip2:"erreur: tu dois sélectionner un fichier .zip",
    exploitation:"Mon exploitation",
    selectionfichier:"Sélection du fichier",
    enregistrementparc:"Enregistrement du parcellaire",
    geofolia:"GeoFolia",
    telepac:"PAC on Web",
    mesparcelles:"Mes parcelles",
    autres:"Autres",
    vosparcelles:"Tes parcelles",
    selectionnerparcelles:"Sélectionne tes parcelles",
    parcelDefaultNameFormat:"parcelle ",
    telepacClusterAttribute: "Ilot",
    telepacParcelAttribute: "Parcelle",
    // renderMaps
    noauth:"<strong>Désolé !</strong> Tu n'est pas autorisé à dessiner ce type de parcelle",
    bravo:"Tu viens de dessiner une parcelle. Nomme-la et enregistre pour continuer. Sinon cliques sur annuler.",
    bravotitle:"Bravo !",
    nomparcelle:"Nom de la parcelle",
    parcelledess:"parcelle dessinée",
    monexploitation:"mon exploitation",
    drawn:"dessiné",
    autre:"autre",
    chargevisi:"visible en cours de chargement",
    chargeind:"indice de végétation en cours de chargement",
    tooltipstartgeolocation:"Activer la géolocalisation",
    tooltipstopgeolocation:"Désactiver la géolocalisation",
    tooltipcouche:"Couches à appliquer",
    tooltipInfo:"Information parcelle",
    tooltipcenter:"Recentrer sur mon exploitation",
    geoSearchAddress:"Entrer une adresse",
    zoomer:"Zoomer",
    dezoomer:"Dézoomer",
    commune:"Rechercher une commune",
    visiblecharge:"visible en cours de chargement",
    indvegecharge:"indice de végétation en cours de chargement",
    geoloc:"la géolocalisation n'a pas été autorisée.",
    desopos:"Désolé. Ta position n'a pas été trouvée",
    noOpenGlobalLayerWithoutParcelSelection:"Sélectionne une parcelle pour afficher une couche globale.",
    //menuParcels
    shapefile:"Cette fonctionnalité te permet d'importer tes données de parcelles sous forme de fichier shapefile",
    importnew:"Importer de nouvelles parcelles",
    functionnew:"cette fonctionnalité te permet d'importer de nouvelles parcelles. Attention, tes anciennes données seront effacées lors de l'import",
    viewlist:"Voir la liste de mes parcelles",
    functionlist:"cette fonctionnalité te permet de consulter les informations de tes parcelles",
    viewparcelles:"Voir mes parcelles sur une carte",
    functionview:"Cette fonctionnalité te permet de visualiser tes parcelles sur une carte",
    //abstractProfil
    infos:"Mes informations",
    mesfactures:"Mes factures",
    //stripeCustomPlan
    abogratuit:"Profite de Spotifarm sur 3 parcelles avec :\nAccès Web et Smartphone\n1 an d'historique de tes parcelles\n1 exploitation",
    abopremium:"Profite de tout le fonctionnel Spotifarm\n(jusqu'au 28/02/2020 gratuitement)\n+\nToutes tes parcelles\nRemontée dans l'historique illimitée",
    abopremiummodul:"Tous les avantages du mode pro + \nl'accès à la génération de carte de modulation.",
    freemium:"Gratuit",
    premium:"Pro (offre lancement)",
    premiummodul:"Premium + Modulation",
    //calendrier
    //Attention, la propriété ci-après ('calendar') doit être au format "culture complète !"
    calendar:"fr-BE",
    //leafletdraw
    dessinerparcelle:"Dessiner une parcelle",
    terminer:"Terminer",
    supppoint:"Supprimer le dernier point",
    reduire:"réduire",
    start:"Cliquer pour commencer à dessiner.",
    cont:"Cliquer pour continuer à dessiner.",
    end:"Cliquer sur le dernier point pour terminer le dessin.",
    placemarker:"Cliquer pour placer un point d'observation.",
    placeMarkerOnMap:"Cliquer sur la carte pour placer un point d'observation.",
    placeFirstPointForPolygonOnMap:"Cliquer sur la carte pour commencer à dessiner.",
    //cardShowed
    valid:"expire à fin",
    //stripeinfoadress
    adresseLabelCard:"Adresse de facturation",
    adresse:"Adresse",
    codepostal:"Code postal",
    ville:"Ville",
    pays:"Pays",
    onerror:"une erreur s'est produite durant la sauvegarde de tes données",
    errorlabel:"tu dois renseigner une adresse",
    errorcodepostal:"tu dois renseigner un code postal",
    errorville:"tu dois renseigner une ville",
    errorpays:"tu dois renseigner un pays",
    //stripeInfoCard
    cartebanc:"Carte bancaire",
    expiryError:"Ta carte est périmée ou expire très prochainement. Renseigne une nouvelle carte bancaire pour continuer à profiter pleinement des fonctionnalités de Spotifarm",
    changecard:"Change ta carte",
    savecard:"Ajoute ta carte",
    cbNamemaj:"NOM TITULAIRE",
    numcard:"numéro de carte",
    dateexpiry:"date d'expiration",
    errorcard:"Impossible d'enregistrer ta carte",
    cryptogramme:"cryptogramme",
    //StripeInfoNaming
    clientLabelCard:"Client",
    email:"Email",
    prenom:"Prénom",
    nom:"Nom",
    societe:"Société",
    telephone:"Téléphone",
    errorprenom:"tu dois renseigner un prénom",
    errornom:"tu dois renseigner un nom",
    errorphone:"tu dois renseigner un numéro de téléphone",
    //StripeInformationsdetails
    avertissement:"Avertissement",
    modifdonnees:"certaines données sont en cours de modification et seront perdues si tu quitte cette page. N'oublie pas de les enregistrer.",
    button:"j'ai compris",
    //StripePayment
    puis:"Puis",
    total:"Total",
    couponReduc:"Coupon de réduction",
    resumeCommande:"Résumé de la commande",
    infoUsers:"Information Utilisateur",
    //authentBase
    alertrs:"Si tu es connecté via ton réseau social, le changement de mot de passe se fera uniquement depuis le site associé.",
    alertdeco:"Il semblerait que tu sois déconnecté. Relançons l'authentification...",
    //actions, reducers, containers
    noLoadClientDatasOrId:"Les données du client connecté n'ont pas été retrouvé ou l'identifiant est incorrecte.",
    noLoadDatasWithoutStoreAcces:"Impossible d'interroger la base Spotifarm sans accès aux données applicatives.",
    noLoadClientDatasWithoutEmail:"L'email utilisateur n'a pas été détecté. Impossible d'interroger la base Spotifarm.",
    noLoadDatasWithoutToken:"Le jeton d'accès n'a pas été détecté. Impossible d'interroger la base Spotifarm.",
    noCreateClientDatas:"L'initialisation des données utilisateur a échoué.",
    badSearchClientDatas:"La recherche des données utilisateur a échoué.",
    noLoadParcelDatasWithoutClientId:"Impossible de demander le parcellaire sans avoir identifié le client.",
    noLoadModulationDatasWithoutClientId:"Impossible de demander les modulations sans avoir identifié le client.",
    noLoadFertilizerDataWithoutClientId:"Impossible de démarrer une fumure sans avoir identifié le client.",
    noLoadHarvestDataWithoutClientId:"Impossible de démarrer une récolte sans avoir identifié le client.",
    noSaveOrUpdateFertilizerDataWithoutClientId:"Impossible d'enregistrer ou de modifier des données de fumure sans avoir identifié le client.",
    noLoadFertilizerCommonInfosByCultureWithoutClientId:"Impossible de demander les infos communes de la fumure en fonction de la culture sans avoir identifié le client.",
    noGeneratePdfBiomassWithoutClientId:"Impossible de générer le PDf de pesée sans avoir identifié le client.",
    badLoadParcelDatas:"La demande du parcellaire a échoué.",
    badLoadModulationDatas:"La demande des modulations a échouée.",
    badLoadInvoiceDatas:"L'accès à la facture a échoué.",
    badLoadFertilizerDatas:"Une erreur est survenue lors du chargement des données pour démarrer une fumure.",
    badLoadLastNitrogenInputDatas:"Une erreur est survenue lors du chargement des données des derniers apports azotés.",
    badLoadHarvestDatas:"Une erreur est survenue lors du chargement des données pour démarrer une récolte.",
    badLoadModelisationDatas:"Une erreur est survenue lors du chargement des données de modélisation.",
    badinitModelisationDatas:"Une erreur est survenue lors de l'init des données de modélisation.",
    badLoadFertilizerCrops:"Une erreur est survenue lors du chargement des cultures pour démarrer une fumure.",
    badLoadFertilizerCommonInfosByCultureDatas:"L'accès aux données communes par rapport à la culture a échoué",
    noLoadInvoiceDatasWithoutClientId:"Impossible de demander l'accès à une facture sans avoir identifié le client.",
    badShowInvoice:"L'ouverture de ta facture a échoué.",
    badSaveModulationDatas:"L'enregistrement de la modulation a échoué.",
    badSaveCommonInfosManagementDatas:"Une erreur est survenue lors de l'enregistrement de la saisie groupée pour le pilotage d'azote.",
    badSaveSpecificInfosManagementDatas:"Une erreur est survenue lors de l'enregistrement des infos de pilotage pour démarrer la fumure.",
    badPartialBuildModulation:"La génération d'une nouvelle modulation a échoué.",
    badPartialBuildBiomass:"La génération d'une nouvelle pesée de colza a échoué.",
    badPartialBuildFertilizer:"La génération d'une nouvelle fumure a échoué.",
    badCompletBuildModulation:"La génération complète des prescriptions a échoué.",
    badCompletBuildLniModulation:"La génération complète des prescriptions a échoué.",
    badBuildPpfFile:"L'obtention du fichier PPF a échoué.",
    badGeneratePdfModulation:"La génération du fichier Pdf de la modulation a échoué.",
    badGeneratePdfFertilizer:"La génération du fichier Pdf de la fumure a échoué.",
    badShowPdfModulation:"L'affichage du fichier Pdf de la modulation a échoué.",
    badShowPdfFertilizer:"L'affichage du fichier Pdf de la fumure a échoué.",
    badShowPdfLastNitrogenInput:"L'affichage du fichier Pdf de dernier apport à échoué.",
    badShowPdfLastNitrogenInputResume: "L'affichage du fichier Pdf récapitulatif de dernier apport.",
    badGeneratePdfBiomass:"La génération du fichier Pdf de la pesée a échoué.",
    badGeneratePdfBiomassResume:"La génération du fichier Pdf récapitulatif des pesées a échoué.",
    badGeneratePdfLastNitrogenInputPdfResume:"La générationdu fichier Pdf récapitulatif des pesées a échoué.",
    badShowPdfBiomass:"L'affichage du fichier Pdf de la pesée a échoué.",
    badShowPdfBiomassResume:"L'affichage du fichier Pdf récapitulatif des pesées a échoué.",
    noGenerateHistoDatasWithoutClientId:"Impossible de demander la génération des images pour une parcelle sans avoir identifié le client ou la parcelle visée.",
    noGenerateHistoDatasWithoutMonthYear:"Impossible de demander la génération d'une liste d'images sans un mois et une année définie",
    badGenerateHistoDatas:"La demande de génération d'un historique d'images a échoué.",
    noUpdateDatasWithoutStoreAcces:"Impossible de mettre à jour la base Spotifarm sans accès aux données applicatives.",
    noUpdateDatasWithoutClientId:"Impossible de mettre à jour la base Spotifarm sans avoir identifié le client.",
    noUpdateDatasWithoutToken:"Le jeton d'accès n'a pas été détecté. Impossible de mettre à jour la base Spotifarm.",
    noUpdateDatasWithoutDatas:"Impossible de mettre à jour la base Spotifarm sans avoir les données.",
    badUpdateDatas:"La demande de mise à jour a échoué.",
    badUpdateFertilizerDatas:"Une erreur est survenue lors de la mise à jour des données de fumure.",
    noCheckDatasWithoutStoreAcces:"Impossible de lancer une vérification, sans accès aux données applicatives.",
    noCheckDatasWithoutClientId:"Impossible de lancer une vérification sans avoir identifié le client.",
    noCheckDatasWithoutToken:"Le jeton d'accès n'a pas été détecté. Impossible de lancer une vérification.",
    noCheckDatasWithoutDatas:"Impossible de lancer une vérification, sans avoir les données.",
    badCheckPRJDatas:"La demande de vérification de la projection a échoué.",
    badCorrectZipOfShpDatas:"La demande de correction de l'import SHP a échoué.",
    noDeleteDatasWithoutStoreAcces:"Impossible de supprimer des données de la base Spotifarm sans accès aux données applicatives.",
    noDeleteDatasWithoutClientId:"Impossible de supprimer des données de la base Spotifarm sans avoir identifié le client.",
    noDeleteDatasWithoutToken:"Le jeton d'accès n'a pas été détecté. Impossible de supprimer des données de la base Spotifarm.",
    noDeleteDatasWithoutDatas:"Impossible de supprimer des données de la base Spotifarm sans avoir quelles données sont concernées.",
    badDeleteParcelDatas:"La demande de suppression du parcellaire a échoué.",
    badDeleteModulationDatas:"La demande de suppression de la modulation a échoué.",
    badDeleteFertilizerDatas:"Une erreur est survenue lors de la suppression des données de fumure.",
    badDeleteHarvestVarietyDatas:"Une erreur est survenue lors de la suppression des données de variété.",
    errorUpdateParcelName:"Une erreur est survenue lors de l'enregistrement du nom de la parcelle",
    noGetAnalysDatasWithoutClientId:"Impossible d'obtenir l'analyse d'image de la base Spotifarm sans avoir identifié le client.",
    noGetAnalysDatasWithoutToken:"Le jeton d'accès n'a pas été détecté. Impossible d'obtenir l'analyse d'image de la base Spotifarm.",
    badGetAnalysDatas:"La demande d'obtention de l'analyse a échoué.",
    importPlotsByFarmNameEmptyResult:"Le retour des données de parcelles suite à un import GEOFOLIA a renvoyé un objet vide",
    noRemoveClientWithoutStoreAccess:"Impossible de supprimer le compte et les données sans accès aux données de l'application.",
    noRemoveClientWithoutClientId:"Impossible de supprimer le compte et les données sans identifier le client.",
    noRemoveClientWithoutToken:"Le jeton d'accès n'est pas détecté. Impossible de supprimer le compte et les données.",
    noLoadLastNitrogenInputDataWithoutClientId:"Impossible de démarrer un dernier apport sans avoir identifié le client.",
    badLoadLastNitrogenInputsDatas:"Une erreur est survenue lors du chargement des données de dernier apport.",
    noBuildLastNitrogenInputWithoutClientId:"Impossible de calculer un conseil 'dernier apport' sans identifier le client.",
    noBuildLastNitrogenInputWithoutDatas:"Impossible de fournir un conseil 'dernier apport' sans les données nécessaires.",
    badBuildLastNitrogenInput:"Une erreur est survenue lors de la production du conseil d'un dernier apport azote.",
    noResultLastNitrogenInputBuild:"La production du conseil d'un dernier apport azote n'a pas retourné de résultat.",
    noResultLastNitrogenInputBuildSampleAndDoses:"Une erreur est survenue lors de la génération de la modulation d'un dernier apport.",
    noResultLastNitrogenInputSavedSampleAndDoses:"Une erreur est survenue lors de la sauvegarde de la modulation d'un dernier apport.",
    doseApporterError: "La dose à épandre doit être supérieur à 0. Modifie la dose déjà apportée en conséquence",
    //ImportGeofoliaFAH
    geofoliaStep1:"Geofolia",
    textStep1:"Importer mes parcelles Geofolia",
    textStep1_onlyFarmName:"Importer mes parcelles Geofolia, associées à l'exploitation: ",
    textStep2:"Choisir l'exploitation à importer:",
    authorizeStep1:"En poursuivant, j'accepte de partager mes parcelles Geofolia avec Spotifarm !",
    refusedPvFAHOnStep:"Ne plus proposer l'importation",
    cancelStep1:"Annuler",
    continueStep1:"Continuer",
    cancelStpe2:"Annuler",
    importStep2:"Importer",
    errorStep1:"Une erreur s'est produite lors du chargement de la liste des exploitations",
    errorStep2:"Une erreur s'est produite lors de l'importation de tes parcelles depuis Geofolia",
    //LanguageMenu
    itemMenuLangueFR:"Français (France)",
    itemMenuLangueBE:"Français (Belgique)",
    itemMenuLangueES:"Español (España)",
    itemMenuLangueUS:"English (US)",
    itemMenuLangueGB:"English (UK)",
    //NdviScale
    scalelow:"pas de végétation",
    scalehigh:"forte végétation",
    scalekey:"indice de végétation",
    //NdviChart
    imageAnalysis:"Analyse de l'image",
    cloudswarning:"Présence de nuages",
    verifClouds:"Vérifier la présence de nuages",
    noVerifCloudForModelisation:"Pas de détection de nuage pour les images modélisées",
    noNdviOnAllMapForModelisation:"Pas d'indice de végétation sur toute la carte pour les images modélisées",
    goBackToVegetationIndex:"Revenir à l'indice de végétation",
    //RenderObservations
    columnparcelname:"Parcelle",
    columnculture:"Culture",
    columnobservationdate:"Date d'observation",
    columnobservationtype:"Type d'observation",
    columnsurperficie:"Superficie (Ha)",
    columnremarques:"Remarques",
    noparcelname:"Autre",
    renderobservationstitle:"Liste des observations",
    toolbarsearch:"Recherche",
    toolbarsearchculture:"Rechercher une culture",
    toolbarSearchNameCornTypeAndVariety:"Rechercher parcelle, type de récolte, variété",
    toolbardownloadCsv:"Téléchargement CSV",
    toolbarprint:"Imprimer",
    toolbarviewColumns:"Colonnes",
    toolbarfilterTable:"Filtre",
    selectedRowText:"observation(s) sélectionnée(s)",
    selectedRowTitle:"Supprimer",
    noobservation:"Aucune observation.",
    newobservation:"Créer une nouvelle observation",
    point:"point",
    zone:"zone",
    labelToday:"Aujourd'hui",
    labelYesterday:"Hier",
    labelTheLast7days:"Les 7 derniers jours",
    labelThisMonth:"Ce mois-ci",
    labelLastMonth:"Le mois dernier",
    labelAllDates:"Toutes les dates",
    //PinDropControl
    createpindropmarker:"Créer un point d'observation",
    createpindroppolygon:"Créer une zone d'observation",
    createpindropimage:"Prendre une photo",
    //PinDropFormPopup
    pinDropFormTitlePoint:"Observation",
    pinDropFormTitleZone:"Zone d'observation",
    pindropmarkersuccess:"Tu viens de créer un point d'observation. Enregistre-le pour continuer, sinon clique sur annuler.",
    pindroppolygonsuccess:"Tu viens de créer une zone d'observation de {0} Ha. Enregistre-la pour continuer, sinon clique sur annuler.",
    pinDropFormImage:"Image",
    pinDropFormAddImage:"Ajouter une photo",
    pinDropFormErrorShowImage:"Désolé, l'image n'a pas pu être chargée",
    supprimer:"Supprimer",
    pindDropFormErrorSaveObservation:"Nous n'avons pas pu sauvegarder ton observation. Cela est sûrement dû à un problème de connexion. Réessaye en appuyant de nouveau sur le bouton \"Enregistrer\"",
    pindDropFormErrorSaveImage:"Nous n'avons pas pu sauvegarder une image. Cela est sûrement dû à un problème de connexion. Réessaye en appuyant de nouveau sur le bouton \"Enregistrer\"",
    deleteObservation:"Supprimer l'observation",
    deleteObservationConfirmation:"Es-tu sûr de vouloir supprimer l'observation ?",
    cancelObservation:"Annuler les modifications",
    cancelObservationConfirmation:"Des modifications ont été apportées à l'observation. Es-tu sûr de vouloir les annuler?",
    pinDropFormIdentificationComment:"Reconnaissance de plante:",
    //Observations
    deleteObservations:"Supprimer les observations sélectionnées",
    deleteObservationsConfirmation:"Es-tu sûr de vouloir supprimer les observations sélectionnées ?",
    periode:"Période",
    // Observations - Type d'observations - ⚠️ l'unité (_1,_2,...) correspond à l'observationTypeId en BDD
    observation_type_0:"Toutes",
    observation_type_1:"Autre",
    observation_type_2:"Adventices",
    observation_type_3:"Carences",
    observation_type_4:"Dégâts de gibier",
    observation_type_5:"Maladie",
    observation_type_6:"Problème de levée",
    observation_type_7:"Ravageurs",
    typesObservations:"Types d'observation",
    // Didacticiel / Tutorial : FirstParcel
    howtoimportfirstslot:"Je veux rajouter des parcelles",
    close:"Fermer",
    welcomespotifarmertitle:"Bienvenue !",
    welcomespotifarmercontent1:"Pour débuter ton tour de plaine et débloquer tes",
    welcomespotifarmercontent2:"30 jours d'essai gratuit,",
    welcomespotifarmercontent3:"commence par dessiner ta première parcelle",
    starttutorial:"Commencer",
    /* Step choix */
    choixtitle:"Ma première parcelle sur Spotifarm",
    choixsubtitle:"Je sélectionne la méthode qui me convient le mieux :",
    choixdrawtitle:"Dessiner",
    choixdrawcontent:"Dessine tes parcelles depuis notre outil carto",
    choiximporttitle:"Importer",
    choiximportcontent:"Importe tes parcelles depuis PAC on Web ou un autre outil (.shp ou .zip)",
    choixgeofoliatitle:"Synchroniser",
    choixgeofoliacontent:"Synchronise ton assolement si tu utilises le logiciel Geofolia",
    /* Step Import */
    importmenusaisie:"Saisie",
    importmenuaide:"Besoin d'aide ?",
    importhelp1title:"Besoin d'aide pour l'import de mes fichiers",
    importredirecttostepdraw:"Mon import ne fonctionne pas, je préfère dessiner mes parcelles",
    importhelptitlepasdefichier:"Je n'ai pas de fichier de mon parcellaire",
    importhelppasdefichiercontent:"Choisis ton outil de gestion",
    importhelpsubtitlepasdefichier1:"Récupérer le fichier avec PAC on Web",
    importhelpsubtitlepasdefichier2:"Récupérer le fichier avec mes parcelles",
    importhelpsubtitlepasdefichier3:"Récupérer le fichier avec Geofolia",
    importhelptitleimport:"J'ai un problème lors de mon import de fichier",
    importhelptitleautreprobleme:"J'ai un autre problème",
    importhelpsubtitleautreprobleme1:"Les images spotifarm",
    importhelpsubtitleautreprobleme2:"Nous contacter",
    importhelpenvoyeremail:"Envoyer",
    /* Step indice */
    stepIndiceTitle:"Indice de Végétation",
    stepIndiceSubtitle:"À quoi correspond l'indice de végétation?",
    stepIndiceButton:"J'ai compris",
    /* Step succès */
    successtitle:"Félicitation!",
    successsubtitle:"Tu disposes désormais de 30 jours pour essayer gratuitement Spotifarm",
    successcontent:"Tu vas recevoir une image satellite tous les 3 à 5 jours gratuitement pendant 30 jours pour :",
    successsubcontent1:"Contrôler la levée sur tes parcelles",
    successsubcontent2:"Suivre la croissance des cultures",
    successsubcontent3:"Localiser les problèmes (maladie, irrigation, ravageur, gibier)",
    successsubcontent4:"Vérifier l'efficacité de tes apports d'engrais",
    successsubcontent5:"Déterminer la date optimale de récolte",
    successbtnvalidation:"C'est parti",
    /* Step dessin */
        /* Sub step géolocalisation */
    dessinwaitgeolocalisation:"Géolocalisation en cours, merci de patienter",
        /* CardExplain Zoom map */
    dessinzoom:"Je zoome sur ma parcelle avec la molette",
        /* CardExplain Move map */
    dessinmovemap:"Je me déplace sur la carte avec la souris",
        /* Button start draw */
    dessindrawparcel:"Commencer à dessiner",
    dessinzoominsidemap:"je ne trouve pas ma commune, je préfère zoomer sur la carte pour localiser mes parcelles",
    gobacktostepchoix:"Changer de méthode",
        /* Place search */
    searchcity:"Recherche de ta ville",
    // spécifiques pour le cas de chargement depuis l'appli native
    noDatasAuthentFromNativeApp:"Les données nécessaire à l'authentification n'ont pas été transmise. L'application ne peut pas te permettre de te connecter.",
    disconnectNativeApp:"Changer de compte",
    closeNativeApp:"Fermer l'application",
    commNativeWithoutWebView:"L'initialisation du système d'authentification n'aurai pas dû exploiter le mode 'natif'.",
    /* Step succès */
    actualize:"J'ai créé ma liaison Géofolia, j'actualise",
    actualizeForSmallScreen:"J'ai créé ma liaison",
    /* Incitation fin période d'essai avant fin */
    incitationendoftrialtitle:"On dirait que t'aimes bien l'appli",
    incitationendoftrialcontent1:"La fin de ton mois d'essai gratuit se termine bientôt.",
    incitationendoftrialcontent2:"Deviens un vrai Spotifarmer et continue de surveiller tes parcelles grâce à un accès illimité aux images satellite.",
    incitationendoftrialcontent2ForMobile:"Pour devenir un vrai spotifarmer, connecte-toi sur ton ordinateur et continue de surveiller tes parcelles grâce à un accès illimité aux images satellite.",
    incitationendoftrialcontent3:"C'est sans engagement",
    incitationendoftrialok:"Ok, c'est parti",
    incitationendoftrialnotnow:"Pas maintenant",
    /* Incitation fin période d'essai post fin */
    incitationaftertrialtitle:"Teste la version PRO de Spotifarm",
    incitationaftertrialcontent1:"Tu aimes Spotifarm et tu veux continuer de suivre la croissance de tes cultures ?",
    incitationaftertrialcontent2:"Tu peux souscrire à notre offre, c'est sans engagement.",
    incitationaftertrialcontent2ForMobile:"Ces fonctionnalités sont réservées aux utilisateurs ayant souscrit à la version complète de Spotifarm.",
    incitationaftertrialcontenthowtodo1ForMobile:"Comment faire ?",
    inctitaionaftertrialcontenthowtodo2ForMobile:"La souscription n'est actuellement pas disponible dans l'application. Elle s'effectue depuis ton ordinateur.",
    incitationaftertrialcontentokForMobile:"D'accord, j'ai compris.",
    incitationaftertrialsupport:"As-tu une question ?",
    incitationaftertrialcontactsupport:"Contacter notre équipe",
    incitationaftertrialcontent2BeforeDiscount:"Tu peux souscrire à notre offre en utilisant cette réduction, c'est sans engagement.",
    incitationaftertrialcontent2BeforeDiscountForMobile:"Tu peux souscrire à notre offre en utilisant cette réduction, c'est sans engagement.",
    /* Incitation freemium ayant déjà un shp */
    incitationfreemiumwithshptitle:"Le monde change. Nous aussi.",
    incitationfreemiumwithshpcontent1:"Désormais, l'application sera accessible sans limite de parcelles. Mais le téléchargement de nouvelles images sera réservé aux abonnés.",
    incitationfreemiumwithshpcontent2:"Pour te rappeler tout l'intérêt de Spotifarm, on te débloque tout pendant un mois, et c'est gratuit.",
    incitationfreemiumwithshpsignature:"La team Spotifarm",
    /* incitation footer */
    incitationtrialfooter:"Si tu as une question ou besoin d'aide, n'hésite pas à contacter notre support",
    /* Incitation depuis la fumure */
    incitationDownloadFertiPrescriptions:"Tu disposes des droits pour générer un plan de fumure, mais le téléchargement des cartes de modulation nécessite de s'abonner à Spotifarm - modulation.",
    /* formulaire d'inscription via réseaux sociaux */
    socialformtitle:"Une dernière étape avant de démarrer ton tour de plaine",
    champobligatoire:"Champ obligatoire",
    /* Modulation : Aides */
    helpForModulation:"Besoin d'aide pour la modulation",
    helpForDownloadModulation:"Besoin d'aide pour le téléchargement ?",
    helpForFilesModulation:"Quel format de fichier pour ma console ?",
    helpForDownloadModulationOnDesktop:"Ordinateur",
    helpForResolutionModulation:"Besoin d'aide pour la résolution des cartes de modulation ?",
    helpAsk:"Besoin d'aide ?",
    helpAskModulation:"Quel format de fichier pour ma console ?",
    helpContactUs:"J'ai une autre question",
    /*  Pesée de colza aide */
    aidePeseeColza:"La pesée de colza",
    /* Ecrans de modulation */
    totalDoseToExpand:"Total : ",
    totalDoseToExpandAlert:"Attention, tu as sélectionné des modulations en apports solide ET liquide.",
    parcelModulated:"Est modulée",
    downloadNotAvailable:"Disponible sur ordinateur",
    noRecordsToDisplay:"Aucune modulation enregistrée à afficher",
    loadingModulsRecords:"Modulation en cours de chargement ... ",
    deleteModulations:"Supprimer les modulations",
    deleteSelectedModulations:"Supprimer la(es) modulation(s) sélectionnée(s)",
    confirmDeleteModulations:"Confirme-tu la suppression de(s) modulation(s) sélectionnée(s) ?",
    startModulation:"Commencer",
    showOnlyParcelWithoutPrescription:"Afficher les parcelles non modulées uniquement",
    ModulationsExistsTitle:"Mes cartes de modulations",
    ModulationsExistsTitleMobile:"Mes cartes",
    ColumnPclName:"Nom",
    ColumnMdlDate:"Date de création",
    ColumnMdlMediumDose:"Dose moyenne",
    ColumnContentRate:"Teneur %",
    ColumnMdlToDownLoad:"Télécharger les cartes",
    ColumnMdlToDownLoading:"Téléchargement en cours des cartes",
    downloadLastInputNs:"Télécharger les cartes",
    lastInputNsDownLoading:"Téléchargement en cours des cartes",
    badDownloadModulationFiles:"Le téléchargement des fichiers associées à une modulation de parcelle a échoué. Contactes nous.",
    badContentDownloadModulationFiles:"Le contenu des fichiers téléchargés, associées à une modulation de parcelle, a échoué. Contacte nous.",
    ObserveVisibleLayer:"Sélectionne une image avec le moins de nuages possible. Sur cette vue les nuages se repèrent par leur couleur blanche et leurs ombres par l'assombrissement de l'image. Nous mettons la dose moyenne globale sur les zones détectées comme nuageuses.",
    ObserveCloudLayer:"Des nuages peuvent avoir passé notre filtre et venir perturber l'indice de végétation sur certaines images. Pour les détecter tu peux observer la couverture nuageuse au moment de la prise de la photo.",
    ComeBackToIndicevege:"Revenir à l'indice de végétation",
    DetectClouds:"Détecter les nuages",
    ConfirmSelectedImage:"Valider l'image sélectionnée",
    titleDeleteModulations:"Suppression de(s) modulation(s)",
    confirmDeleteModulationsSelected:"Confirme-tu la suppression des modulations sélectionnées ?",
    confirmDeleteAction:"Confirmer",
    cancelDeleteAction:"Annuler",
    dialogSubtitleTypeShape1:"Sélectionne le format d'export des cartes de modulation :",
    dialogSubtitleTypeShape11:"Sélectionne le format d'export des cartes de modulation :",
    dialogSubtitleTypeShape12:"Sélectionne le format PDF :",
    dialogSubtitleTypeShape2:"En cas de doute sur les formats acceptés par ta console renseigne-toi auprès de ton revendeur.",
    startModulationImageChoice:"Choix de l'image",
    twoSupplyTypesSelected:"Attention, ta sélection comporte des cartes avec des types d'apport différents (liquides et solides). Si c'est un choix de ta part tu peux continuer, sinon nous te conseillons de séparer les fichiers par type d'apport pour simplifier l'utilisation dans ta console.",
    someoneWithoutModulSelected:"Attention, ta sélection comporte des conseils de derniers apports qui n'ont pas de modulation configurée. Si tu poursuis, les conseils incomplets seront excluts de la prescription produite.",
    goBackToModulationTable:"Revenir à ma sélection",
    biomassRangeMobileHelper:"Dates",
    newModulationWidget:"Créer une carte de modulation",
    /* Ecrans de modulation : doses de modulation */
    rememberModulationSettings:"Se souvenir de la dose moyenne, de la teneur et du paramétrage pour les prochaines modulations",
    subTitleDefinitionZones:"Définition des zones", 
    subTitleDoses:"Répartition des doses",
    subTitleSettings:"Paramétrage",
    mediumDose:"Dose moyenne",
    content:"Teneur",
    nbZones:"Nombre de zones",
    ratio:"proportion",
    doseInputs:"Dose d'intrants",
    area:"Surface",
    distributionType:"Type de répartition",
    doseChange:"Calcul de dose",
    compensation:"Compensation",
    optimization:"Optimisation",
    manuelDoses:"Manuel",
    automaticDoses:"Automatique",
    helpContent:"Concentration de ton produit utile dans le produit épandu. Par exemple 39% pour de la solution d'azote 39",
    helpCloudZones:"Pour les zones où des nuages (les zones rouges) sont détectés nous appliquons la dose moyenne",
    helpOptimization:"En compensation l'algorithme privilégie les zones moins développées, à moindre potentiel, en appliquant plus de produit. En optimisation l'algorithme privilégie les zones plus développées, à fort potentiel, en appliquant plus de produit",
    helpDoseChange:"Si tu renseignes les doses manuellement par zone, la dose moyenne globale est susceptible de changer. Attention à bien respecter ton plan de fumure. Si tu laisses le calcul automatique nous modifierons les doses des autres zones pour à chaque fois que tu modifieras une dose pour une zone, sans modifier la dose moyenne globale",
    altImgModulation:"aperçu non-disponible",
    quantityToExpand:"Quantité totale à épandre",
    quantityToFertiExpand:"Dose à épandre pour cet apport",
    quantityToFertiExpandShort:"Dose moyenne",
    badQuantityToFertiExpand:"Ta dose totale à épandre est atteinte !",
    badQuantityToFertiExpandDetails:"si possible diminues ta dose de réserve pour avoir une carte de modulation valide",
    goBackStepModulation:"Retour",
    saveModulation:"Enregistrer et terminer",
    saveModulationAndGoNext:"Valider et passer à la suivante",
    cancelModulationAndGoNext:"Annuler et passer à la suivante",
    downloadModulation:"Télécharger",
    downloadModulPdf:"Télécharger le PDF",
    downloadModulShp:"Télécharger le fichier SHP",
    downloadModulIso:"Télécharger le fichier ISOXml",
    downloadModulRds:"Télécharger le fichier RDS",
    errorDownloadModulation:"Une erreur est survenue pendant le téléchargement. contacte nous !",
    msgUseComputerForDownloadModulation:"Le téléchargement ne peut se faire qu'à partir d'un ordinateur.",
    downloadModulationAndGoNext:"Télécharger et passer à la parcelle suivante",
    unvalidZonesFromBuild:"Cette carte ne permet pas de générer plus de {0} zones.",
    unvalidZonesFromBuildFerti:"Cette carte ne permet pas de générer les {0} zones.",
    errorNoLoadDatesRange:"Chargement de la plage d'ouverture du service de fumure impossible.",
    detectException:"L'actualisation des données de modulation a rencontré un problème inattendu.",
    badNumberOfAreas:"Impossible d'augmenter le nombre de zone, l'hétérogénéité de la parcelle est insuffisante.",
    dateImag:"Date du cliché",
    supplyType:"Type d'apport",
    supplyTypeSolid:"Solide",
    supplyTypeLiquid:"Liquide",
    doseNitrogen:"Dose d'azote",
    downloadChoiceShp:"SHP", 
    downloadChoiceIsoXml:"ISO XML", 
    downloadChoiceRds:"RDS",
    repartitionLabel:"Hétérogénéité de la repartition des doses",
    repartitionTip:"Via le réglage de la répartition de dose, vous décidez de l'écart entre vos plus fortes doses et vos plus faibles doses dans vos cartes de modulation. Plus l'hétérogénéité de votre parcelle est importante, plus cela justifie une forte hétérogénéité de votre répartition.",
    lowerRepartitionLabel:"Faible",
    higherRepartitionLabel:"Forte",
    errorDoseNominalZero: "Saisie au moins une dose supérieur à 0",
    /* Modulations : écran Félicitation */
    messageEndModulation:"Tu as terminé la modulation pour les parcelles sélectionnées.",
    downloadModulationInProgress:"Enregistrement en cours",
    /* Calculette dose Azote */
    CalculDoseNitrogenTitle:"Calculer ma dose d'azote optimale",
    CalculDoseNitrogenTitleMobile:"Optimisateur de dose",
    calculDoseNitrogenTypeBle:"blé",
    calculDoseNitrogenTypeColza:"colza",
    calculDoseNitrogenDesc:"Calcule ici la dose d'azote correspondant à l'optimum technico-économique en fonction du prix de vente de ton {0} et du prix d'achat de ton azote.", 
    calculDoseNitrogenSellPrice:"Je pense vendre mon {0}",
    calculDoseNitrogenPurchaseCost:"Je pense acheter mon engrais azoté",
    calculDoseNitrogenConcentration:"Mon engrais azoté contient",
    calculDoseNitrogenConcentrationEnd:"d'azote",
    calculDoseNitrogenRecommanded:"Ta dose d'azote à épandre, issu de ton plan de fumure, est",
    calculDoseNitrogenOptimized:"Ta dose d'azote optimale est ",
    calculDoseNitrogenUnite:"unités",
    calculDoseNitrogenInfo:"Ce calcul est issu d'une étude publiée par Arvalis sur la dose d'azote correspondant à l'optimum économique en fonction du prix de vente de la culture et du prix d'achat de l'azote. Le calcul ne nécessite pas le rendement de la culture, car ce rendement est déjà pris en compte dans le calcul de la dose prévisionnel fourni par la méthode du bilan.",
    calculDoseNitrogenGoToArticle:"Pour plus de détails tu peux lire ",
    calculDoseNitrogenGoToArticleBetween:"pour le blé, et ",
    calculDoseNitrogenGoToArticleEnd:"pour le colza.",
    calculDoseNitrogenArticle:"cet article",
    calculDoseNitrogenErrorData:"Les données saisies doivent êtres des nombres entiers positifs",
    calculDoseNitrogenErrorPurchaseCost:"Le prix de ton engrais d'azote ainsi que sa concentration en azote donne un coût par unité d'azote {lowerOrHigherThan}. Notre algorithme ne permet pas de calcul pertinent pour ces prix là.",
    lowerThan:"inférieur à {0}",
    higherThan:"supérieur à {0}",
    calculDoseNitrogenErrorSellPrice:"Le prix de vente de ton {culture} doit être compris entre {priceMin} et {priceMax}",
    calculDoseNitrogenErrorCulture:"Notre algorithme ne peut te donner de résultat pour ta culture",
    /* Biomasse */
    /* Biomasse : choix des parcelles */
    parcelHasBeginningWinter:"Entrée d'hiver",
    parcelHasEndingWinter:"Sortie d'hiver",
    startBiomass:"Commencer",
    startBiomassBeginningWinter:"Démarrer la pesée d'entrée d'hiver",
    startBiomassEndingWinter:"Démarrer la pesée de sortie d'hiver",
    selectBiomassBeginningWinter:"Choix de l'image: entrée d'hiver",
    selectBiomassEndingWinter:"Choix de l'image: sortie d'hiver",
    updateBiomassEndingWinter:"Modifier la pesée de sortie d'hiver",
    listparcelsBiomass:"Nouvelle pesée de colza",
    next:"Suivant",
    biomassesCertifTerresInovia:"Notre modèle est validé et approuvé par Terres-inovia afin que tu puisses l'utiliser dans ton plan de fumure.",
    biomassesHowToSelectDates:"Pour garantir la cohérence des données, veille à sélectionner des dates d'entrée et de sortie d'hiver avec les mêmes critères que tu utiliserais pour faire une pesée en plein champ.",
    /* Biomasse : tableau des biomasses */
    ColumnBioDateWinter:"Date de pesée",
    ColumnBioBeginningWinter:"Entrée",
    ColumnBioEndingWinter:"Sortie",
    ColumnBioWeighting:"Pesée (Kg/m²)",
    deleteWeightingBiomasses:"Supprimer les pesées",
    generateResumePdfWeightingBiomasses:"PDF récapitulatif de(s) {0} pesée(s)",
    generateResumePdfOneWeightingBiomass:"PDF récapitulatif de cette pesée",
    generateResumePdfWeightingAllBiomasses:"PDF récapitulatif de toutes les pesées",
    weightingBiomassesExistsTitle:"Mes pesées de colza",
    titleDeleteBiomasses:"Suppression de(s) pesée(s) de colza",
    confirmDeleteBiomassesSelected:"Confirme-tu la suppression des pesées de colza sélectionnées ?",
    noBiomassRecordsToDisplay:"Aucune pesée de colza à afficher",
    loadingBiomassRecords:"Mes pesées de colza en cours de chargement ... ",
    downloadBiomasses:"Télécharger les pesées",
    linkResultsWeightings:"Pour calculer la dose d'azote à apporter sur tes colzas, tu peux utiliser la ",
    clicHere:"réglette Azote de Terres inovia",
    newBiomassWidget:"Nouvelle pesée de colza",
    /* Biomasse : map */
    goBackStepBiomass:"Retour",
    biomassRangeDates:"Du {0} au {1}",
    biomassRangeText:"dates recommandées par Terres Inovia",
    biomassExtraRangeDates:"Du {0} au {1} ou du {2} au {3}",
    biomassExtraRangeText:"dates acceptables",
    biomassOutOfRangeText:"Hors période",
    /* Biomasse : résultat */
    subTitleMapZonesBiomass:"Ta carte de pesée",
    subTitleYourWeightingBiomass:"Tes quantités",
    subTitleResultWeightingBiomass:"Ton résultat de pesée",
    quantity:"Quantité",
    download:"Télécharger",
    downloadWeightingPdf:"Télécharger le PDF",
    goToNextFieldSkipTitle:"Passer à la parcelle suivante",
    goToNextFieldSkipContent:"Souhaites-tu annuler et passer à la parcelle suivante ?",
    averageQuantityResult_1:"Tu as en moyenne ",
    averageQuantityResult_2:" Kg de matière fraiche de colza par m² sur cette parcelle.",
    totalArea:"Surface totale :",
    weightingSuccess:"Ta pesée a bien été sauvegardée.",
    parcelleSuivanteDesktop:"Ignorer et suivant",
    parcelleSuivanteMobile:"Ignorer",
    ignorerEtTerminer:"Ignorer et Terminer",
    biomassesCheckBy:"Modèle validé par",
    /* Widget Analyse image */
    lifting:"Levée",
    growth:"Croissance",
    maturity:"Maturité",
    cloud:"Nuages",
    /* Identification */
    identificationTitle:"Identification",
    /* Identification: choix d'image */
    identificationChooseImageSubtitle:"Choix des Images",
    identificationChooseImageTips:"Pour un meilleur résultat, sélectionne un organe de la plante en particulier et ajouter une photo de cet organe :",
    identificationChooseImageOrganLeaf:"Feuille",
    identificationChooseImageOrganFlower:"Fleur",
    identificationChooseImageOrganFruit:"Fruit",
    identificationChooseImageOrganBark:"Tige",
    identificationChooseImageSearch:"Rechercher",
    identificationChooseImageMsgError:"Nous n'avons pas pu réaliser ton identification. Cela est sûrement dû à un problème de connexion. Merci de réessayer en appuyant de nouveau sur le bouton \"Rechercher\"",
    identificationSelectImage:"Sélectionne une image pour identifier la plante",
    /* Identification: résultat */
    identificationResultSubtitle:"Résultat",
    identificationResultEmpty:"Il n'y a aucun résultat pour ta recherche. Pour une recherche plus précise, tu peux prendre en photo les différents organes de la plante.",
    identificationResultNotFound:"Je ne trouve pas ma plante",
    identificationResultPoweredByPlantnet:"Notre service d'identification de plantes par photographie utilise l'API de Pl@ntnet, régulièrement mis à jour et accessible sur le site ",
    /* Identification: résultat item */
    identificationResultComponentConfidence:"Indice de confiance: {0}%",
    /* Achat In-app Mobile */
    fromStore:"(via le store du mobile)",
    pleaseWaitBuyByStore:"Merci de patienter. le processus d'achat va commencer sur l'appareil",
    buyByStoreRequireTime:"L'achat depuis le Store semble nécessiter plus de temps que prévu. Merci de patienter OU de revenir en arrière (Nous t'avertirons dés sa finalisation).",
    errorPurchasePlanNotDetected:"Un achat depuis le Store a bien été validé. toutefois, le Store ne nous a pas transmis l'offre souscrit. Merci de nous contacter afin de t'activer tes accès.", 
    errorUpgradeClientRulesAfterPurchase:"L'activation de tes accès, suite à ton achat depuis le Store a rencontré une difficulté. Contactes nous.", 
    errorNoPurchaseWithoutSelection:"Une souscription via un achat depuis le Store nécessite de sélectionner l'offre souhaitée. Merci de recommencer.", 
    errorNoPurchaseWithoutIdStorePlan:"L'achat depuis le Store est annulé car le Store ne retrouve pas ta sélection.", 
    errorPurchaseMessageFromNativeApp:"L'achat depuis le Store a retourné une erreur ({0}). Merci de nous contacter.",
    errorUpdateClientAfterPurchasedFromNativeAppMessage:"L'activation de tes droits d'accès a échoué. Merci de nous contacter pour que nous activions pleinement tes accès.", 
    purchaseResultWithoutInfosFromNativeApp:"L'achat depuis le Store a fourni de résultat. Merci de nous contacter.",
    appelStoreStep:"Connexion au Store...",
    explainBuyByStore:"Nous allons lancer pour toi l'achat depuis ton Store Mobile. Suite à la validation du paiement, tes accès seront déverrouillés.",
    purchaseStore:"Acheter",
    goBackPurchaseStoreWaiting:"<< Retour",
    dlgTitlePurchaseStoreResult:"Résultat de l'achat depuis le store",
    dlgContentPurchaseStoreResult:"Suite au déclenchement de l'achat : ",
    messagePurchaseStoreGoodResult:"L'achat a été validé. Profites dès maintenant de tes accès à Spotifarm !",
    btnClosePurchaseStoreResult:"Fermer",
    /* Modélisation */
    modelisationTitle:"Modélisation",
    modelisationCalendarDescription:"Image modélisée",
    modelisationTutoStartTitle:"Profite d'images toutes les semaines sans interruption",
    modelisationTutoStartExplanation:"Grâce à notre intelligence artificielle nous pouvons désormais modéliser la croissance des plantes et ainsi te fournir des images de tes cultures à intervalle régulier quelles que soient les conditions météo.",
    modelisationTutoStartIncitation:"J'en profite",
    modelisationTutoStartHowTo:"Pour profiter de ces nouvelles images il faut valider tes cultures à suivre 👉🏻",
    modelisationTutoStartButton:"Je commence",
    modelisationTutoChooseModelTitle:"Sélection des cultures",
    modelisationTutoChooseModelExplanation:"Chaque culture a ses spécificités et ses caractéristiques de croissance propres. Nous t'avons préparé 3 modèles de croissance pour le blé, l'orge et le colza.",
    modelisationTutoChooseModelSelect:"Sélectionne les cultures qui te concerne pour commencer à saisir des données :",
    modelisationTutoChooseModelButton:"Valider",
    modelisationCropChoiceTitle:"Choix de la culture à modéliser :",
    modelisationCropChoiceButton:"Valider la culture",
    modelisationCropChoiceHelper:"Après avoir choisi la culture à modéliser, tu sélectionneras et valideras les parcelles concernées",
    modelisationAddParcelsSelectTitle:"Sélection des parcelles",
    modelisationAddParcelsSelectAction:"Commence par sélectionner toutes tes parcelles de {0}",
    modelisationAddParcelsFillInTitle:"Renseignement des données",
    modelisationAddParcelsFillInAction:"Vérifie et complète les informations pour chaque parcelle",
    modelisationDataGridButtonAdd:"Ajouter des parcelles à ce modèle",
    modelisationDataGridButtonStop:"Arrêter la modélisation pour ces parcelles",
    modelisationDataGridButtonValidInformations:"Valider les informations",
    modelisationDataGridButtonAddSelected:"Ajouter ces parcelles à la modélisation",
    modelisationDataGridPopupSaving:"Tes parcelles sont prêtes. Nous commençons le traitement des données pour te fournir des images modélisées dès la prochaine période sans image satellite",
    modelisationDataGridPopupGoToNext:"Nous passerons automatiquement à la culture suivante à la fin du chargement",
    modelisationMsgErrorReset:"Un problème est survenue lors de l'arrêt de la modélisation d'une de tes parcelles. Réessaie plus tard.",
    modelisationMsgErrorUpdate:"Un problème est survenue lors du lancement de la modélisation d'une de tes parcelles. Réessaie plus tard.",
    loadingModelisation:"Modélisation en cours",
    modelisationIsLoading:"La modélisation est en cours.",
    modelisationResultPageInfo1:"Nous préparons avec toutes les données parcellaires, météorologiques et satellitaires pour le calcul des images modélisées.",
    modelisationResultPageInfo2:"Elles apparaitrons dès qu'une période nuageuse interviendra et que les images satellites ne seront donc pas disponibles.",
    modelisationBackToExploit:"Retour à l'exploitation",
    newModelisation:"Nouvelle modélisation",
    cropName:"Culture :",
    modelisationSpecificInfo1:"Contrôle et valide ici tes dates de semis par parcelles. ",
    modelisationSpecificInfo2:"Quand tout est correct lance la génération d'image via le bouton en bas à droite.",
    modelisationSpecificInfo3:"La génération d'image interviendra dès qu'il manquera des images à cause des nuages.",
    modelisationCrop:"Culture de modélisation :",
    modelisationGenerateImages:"Je lance la génération des images",
    modelisationCommonInfosUpdateQuestion1:"Nous avons déjà des dates de semis pour ces parcelles, ",
    modelisationCommonInfosUpdateQuestion2:"souhaites-tu les modifier ?",
    modelisationCommonInfosStart:"Pour démarrer la modélisation renseigne la date de semi de tes parcelles",
    modelisationMessageInstructionCrop:"La modélisation d'image par Spotifarm te permettra de suivre tes cultures tout au long de l'année, même en cas de période nuageuse, en t'apportant des images en permanence.",
    modelisationStep0Culture:"Choix de la culture",
    modelisationStep1Parcelle:"Choix des parcelles",
    modelisationStep2SaisieTableauRecapitulatif:"Paramétrage",
    modelisationStep3Résultat:"Résultats",
    modelisationApplySowingDateToAllSlots:"Appliquer à toutes les parcelles",
    modelisationApplySowingDateToSlotsWithoutDate:"Appliquer aux parcelles sans date de semis",
    modelisationSowingDateError:"Indique une date pour la campagne {0}",
    modelisationRemoveParcelsSelected:"Retirer les parcelles sélectionnées",
    modelisationDeleteSelected:"Supprimer les modélisations sélectionnées",
    modelisationStatusTitle:"Modelisation",
    modelisationStatusInitialisation:"Initialisation",
    modelisationStatusHarvested:"Récolté",
    modelisationNbImageModelised:"Images modélisées",
    modelisationStartNewModelisation:"Lancer de nouvelles modélisations",
    deleteModelisationTitle:"Attention",
    deleteModelisationText:"Si tu supprimes cette parcelle tu retourneras à la première étape de la modélisation",
    backToModelisation:"Retour à la modélisation",
    keepModelisation:"Conserver cette parcelle",
    //ProcessStatus
    processStatusWaiting:"En attente",
    processStatusInProgress:"En cours",
    processStatusSuccess:"Terminé",
    processStatusError:"Échoué",
    processStatusReady:"Active",
    processStatusDiferred:"Différé",
    //commonStructures
    selectCrop:"Sélectionne une culture pour commencer",
    validSlots:"Valider les parcelles",
    errorCommonStructures:"Une erreur est survenue",
    errorCommonStructuresInfos:"Si le problème persiste, ",
    //Fertilizer
    fertilizer:"Fumure",
    fertilizerData:"Données de fumure",
    nitrogenManagement:"Pilotage de l'azote",
    nitrogenManagementShort:"Pilotage",
    goBack:"Retour",
    validate:"Valider",
    doseLastInstake:"Dose du dernier apport",
    dosesProvided:"Doses apportées",
    intermediatecropFrequency:"Fréquence de culture intermédiaire",
    plowing:"Labour:",
    cultivationHabitsPlot:"Habitudes culturales de la parcelle",
    yes:"Oui",
    no:"Non",
    organicContribution:"Apport organique",
    fertilization:"Fertilisation",
    practicesFromLast10years:"Pratiques des 10 dernières années",
    productionTarget:"Objectif de production",
    agronomicInformation:"Renseignement agronomique",
    commonInfosManagementInfos:"Tu peux saisir dans cet écran un maximum d'informations qui s'appliqueront à toutes les parcelles de cette culture. Si quelques parcelles sont menées différemment, je te conseille de tout de même saisir les informations qui s'appliquent à la majorité et tu pourras saisir le détail dans l'écran suivant.",
    writeLater:"Je saisirai plus tard",
    selectCultureBeforeStarting:"Saisis une culture avant de commencer.",
    quit:"Quitter",
    associatedFrostCulture:"Si tu as une culture Gélive qui s'est bien développée avant le gel, il est recommandé de diminuer de 30 Unités la dose d'azote totale du PPF.",
    quantityToKeep:"Quantité d'azote à réserver",
    quantityAlreadySupplied:"Quantité d'azote déjà apportée",
    nitrogenContent:"Teneur en azote de l'engrais",
    nitrogenContentShort:"Teneur en azote",
    noAvailableChoice:"Pas de choix disponible",
    typeToSpread:"Type d'engrais à épandre",
    removeHorizon:"Retrancher un horizon",
    addHorizon:"Ajouter un horizon",
    fertilizerQuantity:"Quantité d'azote",
    remainderDate:"Date du reliquat *",
    relicTypeAnalysis:"Analyse",
    relicTypeRegionalValue:"Valeur régionale",
    relicTypeEstimatedValue:"Valeur estimée",
    nitrateContent:"Teneur en nitrate",
    quantityWater:"Quantité d'eau",
    irrigation:"Irrigation",
    dateContribution:"Date de l'apport",
    quantitySupplied:"Quantité apportée",
    dateBurialResiduesN1:"Date d'enfouissement des résidus N-1",
    becomingResiduesN1:"Devenir des résidus N-1",
    harvestDateN1:"Date de récolte N-1*",
    sowingDateN1:"Date de semis N-1*",
    nitrogenFertilizerN1:"Fertilisation azotée N-1",
    previousYield:"Rendement N-1",
    previousCrop:"Culture N-1",
    loading:"Chargement ...",
    plowingDepth:"Profondeur de labour",
    organicFertilizerFrequency:"Fréquence d'apport organique",
    organicFertilizerType:"Type d'apport organique",
    performanceObjective:"Objectif de rendement",
    subTextPerformanceObjective:"(Moyenne des 5 dernières années excluant les valeurs min et max)",
    soilType:"Type de Sol",
    pebbleRate:"Taux de cailloux",
    residueManagement:"Gestion des résidus",
    intermediateCultureType:"Type de culture intermédiaire",
    meadowTurning:"Retournement de prairie",
    meadowTurningDate:"Date de retournement de prairie",
    meadowDuration:"Durée de la prairie",
    errorDialogText1:"Vérifie les données que tu as saisies.",
    errorDialogText2:"Si le problème persiste, ",
    beginningWinterDate:"Date d'entrée d'hiver :",
    endingWinterDate:"Date de sortie d'hiver :",
    noFrequency:"Pas de fréquence",
    noMeadow:"Pas de prairie",
    buried:"Enfouis",
    reliquat30:"0 à 30",
    reliquat60:"30 à 60",
    reliquat90:"60 à 90",
    horizon:"horizon",
    relicTypeEstimatedValueMsg:"Attention, nous allons estimer cette valeur à l'aide d'un modèle de calcul. Dès qu'une valeur régionale est disponible, vous devez la préférez à la méthode estimée. Toutefois, si votre situation culturale n'est pas couverte par les données de votre région, vous pouvez conserver la valeur estimée.",
    winterOutingShot:"Cliché entrée d'hiver : ",
    winterEntranceShot:"Cliché sortie d'hiver : ",
    totalDose:"Dose totale",
    recommandedTotalDose:"Dose totale conseillée",
    doseAlreadyProvided:"Dose déjà apportée",
    reserveDose:"Dose de réserve",
    registerAndContinue:"Enregistrer et continuer",
    downloadPPF:"Télécharger le PPF",
    calculateAgain:"Recalculer",
    complete:"Compléter",
    parcelHasLastInputN:"Déjà ajustée",
    sowingDate:"Date de semis",
    perfGoal:"Rendement potentiel PPF",
    newPerfGoal:"Rendement ajusté",
    totalNdose:"Dose N PPF",
    modulatedNdose:"Dose N modulée",
    downloadMaps:"Télécharger mes cartes",
    modulation:"Modulation",
    quantityToSpread:"Quantité à épandre",
    historized:"Historisé",
    actual:"Actuel",
    popupIncitationFertilizer:" pour plus d'informations.",
    cropYear:"Année de campagne",
    currentCropYear:"Campagnes en cours",
    archivedCropYears:"Campagnes archivées",
    deleteFertilizers:"Supprimer le(s) conseil(s)",
    confirmFertilizersSelected:"Confirmer les fumures sélectionnées ?",
    askDeleteFertilizers:"Supprimer fumures ?",
    entryGrouped:"Saisie groupée",
    updateLocalisationErrorMessage:"Une erreur est survenue lors du changement de la localisation de la parcelle",
    newsNitrogenBoard:"Nouveau PPF",
    tipsNitrogen:"Mes PPF",
    titleInfoAlertFertilizers:"Info",
    textUnavailableAlertFertilizers:"La génération du conseil azoté est momentanément indisponible.",
    textOpenDateAlertFertilizers:"La réouverture sera le ",
    textOpenDateAlertFertilizersWithoutDate:"La réouverture se fera début janvier.",
    textGetPastAlertFertilizers:"Tu peux toujours accéder aux fumures passées.",
    popupInfoCrop:"La culture du conseil azoté est différente de la culture de la parcelle. Elle sera changée plus tard.",
    buildFertiNeedThisData:"Cette donnée est obligatoire pour la génération de ta fumure",
    buildFertiNeedThisWhenAddOrganicInput:"Donnée obligatoire si tu as fais un apport organique",
    ppf:"PPF",
    fertilizerHavingLastNitrogenInputPopupTitle:"Tu as sélectionné des parcelles où tu as déjà ajusté le dernier apport :",
    fertilizerHavingLastNitrogenInputPopupContent:"Si tu continues tes ajustements de derniers apports seront supprimés pour les parcelles concernées.",
    deleteFertilizersWithLastInputN:"Si tu continues tes ajustements de derniers apports seront également supprimés pour les parcelles concernées.",
    parcelHasFertilizer:"A un PPF",
    startPPF:"Commencer un PPF",
    // Harvest
    ListparcelsHarvestMobile:"Nouvelle récolte",
    ListparcelsHarvest:"Nouvelle récolte de maïs",
    harvestsExistsTitleMobile:"Mes récoltes",
    harvestsExistsTitle:"Mes récoltes de maïs",
    askDeleteHarvestTitle:"Supprimer le suivi de récolte sur les parcelles sélectionnées",
    askDeleteHarvestVarietyTitle:"Supprimer les variétés de maïs sélectionnées",
    askDeleteHarvests:"Es-tu sûr de vouloir supprimer le suivi de récolte sur tes parcelles ?",
    askDeleteHarvestVarieties:"Es-tu sûr de vouloir supprimer les variétés de maïs personnalisées ?",
    deleteHarvests:"Supprimer les récoltes",
    harvestCampaign:"Campagne",
    harvestCulture:"Culture",
    harvestVariety:"Variété",
    harvestDegreeOfCertainty:"Degré de certitude",
    harvestSowingdate:"Date de semis",
    harvestEstimatedDate:"Date estimée",
    harvestFloweringDate:"Date de floraison",
    harvestGenerationDate:"Date de génération",
    harvestAlreadyGenerated:"Déjà généré ?",
    accessToEstimationDates:"Accéder aux estimations de dates",
    loadingCropVarieties:"Chargement des variétés de cultures ...",
    errorHarvestGenerate1:"Pour obtenir un résultat, tu dois renseigner sur chaque parcelle l'objectif de récolte, la variété, le type de récolte et la date de semis.",
    errorHarvestGenerate2:"Si tu remplis la date de floraison, celle-ci doit être supérieure à la date de semis.",
    cornType:"Type de récolte",
    selectItem:"Sélectionner",
    grain:"Grain",
    ensilage:"Ensilage",
    percentage:"Objectif de récolte",
    percentageForPopup:"Stade",
    dryMaterial:"matière sèche",
    humidity:"humidité",
    sortBtn:"Actualiser les estimations",
    hightDegreeOfCertitude:"Notre estimation est trop incertaine pour préconiser une date pour le moment. Reviens dans quelques jours.",
    helpHarvestTitle:"Récolte de maïs",
    loadingHarvestData:"Chargement des données ...",
    dontSaveDatas:"Ne pas enregistrer",
    saveDatasBeforeGoingBackToFirstStep:"Veux-tu enregistrer les données avant de retourner au choix des parcelles ? Attention, les données de récolte soumises auparavant seront remplacées.",
    datesGenerated:"Les dates estimées des parcelles ayant des bonnes données sont disponibles dans \"Mes récoltes de maïs\".",
    myVarieties:"Mes variétés",
    deleteVarieties:"Supprimer mes variétés",
    errorSaveHarvests:"Une erreur est survenue lors de la sauvegarde des données de récolte de maïs.",
    objective:"Objectif n°",
    degreeDaysToFlowering:"°C jours à floraison",
    manufacturer:"Fabriquant",
    degreeDays:"°C.j",
    needsInDegreeDays:"Besoins en °C.j",
    dryMaterialInitials:"MS",
    addVariety:"Ajouter une variété",
    updateVariety:"Modifier la variété",
    varietyLabelRequired:"Nom de la variété est requis",
    harvestTypeRequired:"Type de récolte est requis",
    harvestObjectiveRequired:"Objectif de récolte est requis",
    harvestObjectiveGreaterThanZero:"L'objectif de récolte doit être supérieur à 0",
    degreeDaysToFloweringGreaterThanZero:"°C.j à floraison doit être supérieur à 0",
    degreeDaysGreaterThanZero:"°C.j doit être supérieur à 0",
    degreeDaysRequired:"°C.j est requis",
    fillAtLeastOneHarvestTarget:"Remplis au moins un objectif de récolte",
    sameHarvestTargetData:"Tu as remplis des objectifs avec le même type et le même stade",
    harvest:"Récolte",
    errorHarvestUsedVariety: "La suppression de la variété a échoué. Cela est probablement dû au fait qu'elle est actuellement utilisée dans une récolte. Veuillez vérifier les récoltes associées avant de réessayer.",
    showAllVarieties: "Afficher toutes les variétés",
    newDateHarvestTitle: "Estimée la date de récolte de mais",
    loadingHarvestRecords: "Chargement de mes récoltes de mais",
    deleteHarvestWithVariety:"Attention, tu vas aussi supprimer les données de récolte associées aux variétés sélectionnées.",
    //LastNitrogenInput
    lastNitrogenInput:"Dernier apport",
    newTotalNDose:"Dose N ajustée",
    lastNitrogenInputModulationButton:"Modulation",
    adjustment:"Différence PPF / Ajustement (U/ha)",
    lastInputDate:"Date d'ajustement",
    startModulationOfLastInputN:"Démarrer",
    lastNitrogenInputModulated:"Modifier",
    selectForLastInputN:"Ajustement dernier apport blé",
    selectForLastInputNMobile:"Dernier apport",
    tipsLastInputN:"Mes ajustements",
    buildLastInputNs:"Calculer l'ajustement de la prescription",
    rebuildLastInputNs:"Recalculer l'ajustement de la prescription",
    lastInputNDeleteBtn:"Supprimer le(s) ajustement(s)",
    lastInputNDeleteDialogTitle:"Supprimer derniers apports?",
    lastInputNDeleteDialogContent:"Es-tu sur de vouloir supprimer ces derniers apports?",
    lastInputDeleteDialogContentWarningArchived:"Certains derniers apports sont historisés, ils ne seront pas supprimés.",
    lastInputNDeleteError:"Une erreur est survenue lors de la suppression de dernier apport.",
    changeImageForLastInputNModulation:"Changer d'image",
    saveLastInputNModulationAndGoNext:"Enregistrer et continuer",
    ppfDifference:"Ecart par rapport au PPF :",
    doseToSpreadForLastInputN:"Dose à épandre pour le dernier apport",
    finalTotalPlotDose:"Dose totale finale à la parcelle :",
    pdfDownloadNotAvaible:"Nous finalisons encore vos documents récapitulatifs. Nous vous préviendrons lorsqu'ils seront disponibles.",
    modulationValuesAreDifferent:"La dose totale a été modifiée. Pense à mettre à jour la carte de modulation.",
    adjusted:"Ajustée",
    modulatingDose:"Dose à moduler",
    lastNInputImageChoiceHelper:"Choisi l'image la plus récente afin de refléter au mieux la réalité de l'hétérogénéité de la parcelle lors de l'apport d'engrais. Le choix de l'image ne modifiera pas la dose totale.",
    receipt:"Justificatif",
    lastInputNDose:"Dose dernier apport",
    lastInputNGenerationInfo:"Nos conseils dernier apport sont calculés à partir des données satellites, météos et agronomiques de vos parcelles. Attention toutefois vérifie bien la réglementation en vigueur sur tes parcelles pour t'assurer que tu as le droit de recourir à des outils de pilotage du dernier apport azoté",
    startCalculation:"Lancer le calcul",
    restartCalculation:"Relancer le calcul",
    infosToStartLastInputN:"Pour commencer tes pilotages de derniers apports, commence par compléter tes PPF sur les parcelles concernées.",
    generateResumePdfWeightingLastNitrogenInput:"PDF récapitulatif de(s) {0} dernier(s) apport",
    generateResumePdfOneWeightingLastNitrogenInput:"PDF récapitulatif de ce dernier apport",
    generateResumePdfWeightingAllLastNitrogenInput:"PDF récapitulatif de tous les derniers apport",
    lastInputCannotGenerateModulationForArchived: "Modulation impossible pour une campagne historisée",
    incitationDownloadLastNInputPrescriptions:"Tu disposes des droits pour générer un ajustement de dernier apport, mais le téléchargement des cartes de modulation nécessite de s'abonner à Spotifarm - modulation.",
    incitationDownloadLastNInputPrescriptionsPdf:" pour plus d'informations. Pour télécharger le justificatif de ton dernier apport, clique sur l'icône 'PDF'.",
    // message correspond aux codes d'erreur renvoyé par l'API
    msgErrorCodeFertiNPrescriptionIsNull:"Selon les données que tu as saisies, la quantité de fournitures dépasse celle des besoins. La dose d'azote à apporter est donc négative.",
    msgErrorCodeEndWinterNAbsorbedOver:"Les photos que tu as sélectionnées donnent une quantité d'azote absorbé pendant l'hiver qui semble excessive. Tu peux changer de date de photo en pensant à vérifier la présence de nuage et réessayer.",
    //autres
    formatDatePlaceHolder:"jj/mm/aaaa",
    beta:"beta",
    newFeaturesAlert:"nouveau",
    contactUs:"contacte-nous",
    //modal videos
    titleModalVideos:"Tuto vidéos",
}

export default stringLanguage_frBE;