import React from 'react';
import { connect } from 'react-redux';
import { get as lodashGet, isNil as lodashIsNil } from 'lodash';

/* MUI Components */
import { Button, Typography, CircularProgress, Grid, Stack, Box, Card, 
    CardContent, CardActions, CardHeader, Divider, Tooltip, Fab, IconButton, Chip } from '@mui/material';

/* React Components */
import AlertDialog from '../../../alertDialog';
import LinkToContactUs from '../../../linkToContactUs';

/* Utils */
import ConstantsLayers from '../../../../utils/constantsOfLayers.js';
import fertilizerHelper from '../../../../utils/fertilizerHelper';
import { ParcelsHelper } from '../../../../utils/parcelsHelper';
import dateHelper from '../../../../utils/dateHelper';
import { BiomassesHelper } from '../../../../utils/biomassesHelper';
import ModalBiomassRange from '../../../biomasse/map/modalBiomassRange';
import SlideRangeMarker from '../../../biomasse/SlideRangeMarker';
/* Constantes */
import ConstantsFertilizer from '../../../../utils/constantsFertilizer.js';

/* Translate */
import StringTranslate from '../../../../assets/i18n/stringLanguage.jsx';

/* Icones */
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import FertilizationIcon from "../../../../assets/images/fertilization/fertilizationIcon.tsx";
import { Info as InfoIcon } from '@mui/icons-material';

/* css */
import '../../../../assets/css/modulation.css';

/* Actions redux */
import { FertilizerStep, ActionGoToStepOfFertilizer, ActionBuildNitrogenSampleAndDoses, ActionShowSpecificInfosManagementPopup, 
    ActionSetStepDateWinter, ActionSelectLayerForFertilizer, ActionCleanErrorFertilizer,
    ActionRegisterWinterDate
    } from '../../../../redux/actions/fertilizer';
import { ActionSelectDateImg } from '../../../../redux/actions/contextApp.js';

/* theme */
import getTheme from "../../../../themes/index.js";
let theme = getTheme();

const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

/**
 * Composant représentant la couche de boutons sur la carte
 */
class MapButtonsPane extends React.Component {
    constructor(props) {
        super(props);

        //Récupération du specificInfos actuel :
        const fertilizerDico = lodashGet(props, `fertilizerDico`, {});
        const parcelIdSelected = lodashGet(props, `parcelIdSelected`, -1);
        const parcel = lodashGet(props, `parcelDico[${parcelIdSelected}]`, undefined);
        const isBeginningWinter = lodashGet(props, 'askBeginningWinterFirst', true);
        const currentCrop = lodashGet(props, 'cultureCrop.cropType', -1);
        const isCultureColza = (currentCrop === 3 ) ? true : false;
        const currentSpecificInfos = fertilizerHelper.getFertilizerByParcelId(fertilizerDico, parcelIdSelected);

        this.state = {
            isSelectedVisibleLayer: (props.forewardLayerSelected === ConstantsLayers.VisibleGlobalLayerName) ? true : false,
            openDialogOfErrors: (props.errorMessage !== '' && props.errorMessage !== undefined) ? true : false,
            isBeginningWinter: isBeginningWinter,
            satImageBeginningWinterDate: lodashGet(currentSpecificInfos, 'beginningWinterDate', undefined),
            satImageEndingWinterDate: lodashGet(currentSpecificInfos, 'endingWinterDate', undefined),
            satImageBeginningWinterProvider: lodashGet(currentSpecificInfos, 'beginningWinterImagProvider', undefined),
            satImageEndingWinterProvider: lodashGet(currentSpecificInfos, 'endingWinterImagProvider', undefined),
            satImageBeginningWinterId: lodashGet(currentSpecificInfos, 'beginningWinterImagId', -1),
            satImageEndingWinterId: lodashGet(currentSpecificInfos, 'endingWinterImagId', -1),
            parcel: parcel,
            colzaDatesRangeModalOpen: false,
            isCultureColza : isCultureColza,
            isSatImageEndingWinterDateValid: false, //Sélection de la date de sortie d'hiver quand on clique sur le bouton "Suivant"

            currentSpecificInfos: currentSpecificInfos
        };

        // Récupère la clef du StringTranslate du message à afficher pour l'aide du choix des dates
        this.dateRangeMsgKey = isBeginningWinter ? 
        BiomassesHelper.getRangeDateMsgKeyByDepartementForBeginningWinter(parcel) 
    : 
        BiomassesHelper.getRangeDateMsgKeyByDepartementForEndingWinter(parcel) 
        ;
        // Permet de savoir si nous disposons des informations de date pour cette parcelle (Ex: si situé à l'étranger, pas de date)
        this.hasNoDateRange = lodashIsNil(this.dateRangeMsgKey);

        this.popupErrorDialog = {
            getTitle: () => { return (<ErrorOutlineIcon />); },
            description: props.errorMessage, //Rq : sera mis à jour en foncton de la prop 'errorMessage' de redux 'fertilizerData' !
            getAdditionalDescription: () => { 
                return (
                    <Stack direction="row" spacing={1} justifyContent="center">
                        <Typography variant="subtitle1">{StringTranslate.errorDialogText1}</Typography>
                        <Typography variant="subtitle1">{StringTranslate.errorDialogText2}<LinkToContactUs displayText={StringTranslate.contactUs}/>.</Typography>
                    </Stack>
                ); 
            },
            button: StringTranslate.close,
        };
    }

    /* Sélection de la date d'entrée d'hiver / date de sortie d'hiver */
    onSelectImage() {
        const { isBeginningWinter, satImageBeginningWinterDate, satImageBeginningWinterId,
            satImageBeginningWinterProvider, satImageEndingWinterId, satImageEndingWinterDate, currentSpecificInfos } = this.state;

        const { satimageByParcelDico, parcelIdSelected, satimageIdSelectedOfParcel, 
            buildNitrogenSampleAndDoses, setStepDateWinter, registerWinterDate, 
            closingDateAzofert, openingDateAzofert, selectDateImag } = this.props;

        let date = undefined;
        let sourceProvider = undefined;
        let id = undefined;

        const satImageSelected = lodashGet(satimageByParcelDico, `[${parcelIdSelected}].satimageDico[${satimageIdSelectedOfParcel}]`, undefined);
        if (satImageSelected !== undefined) {
            //Date d'image sélectionnée
            date = satImageSelected.date;
            //Provider de l'image sélectionnée
            sourceProvider = satImageSelected.sourceProvider;
            //Id de l'image sélectionnée
            id = satImageSelected.id;

            if ((date !== undefined) && (date !== null)) {
                if (isBeginningWinter === true) { //Date d'entrée d'hiver
                    this.setState({
                        satImageBeginningWinterDate: date,
                        satImageBeginningWinterProvider: sourceProvider,
                        satImageBeginningWinterId: id,

                        isBeginningWinter: false, //pour passer à la sélection de la date de sortie d'hiver !

                        isSatImageEndingWinterDateValid: false,
                    });

                    //On enregistre la date d'entrée d'hiver en base de données
                    if (currentSpecificInfos && (currentSpecificInfos.beginningWinterDate !== date)) {
                        const fertilizerParameter = Object.assign({}, currentSpecificInfos, {
                            beginningWinterImagId: id,
                            beginningWinterDate: date,
                            beginningWinterImagProvider: sourceProvider
                        });
                        
                        registerWinterDate(fertilizerParameter);
                    }
                  
                    //Signaler à Redux qu'on se place en sortie d'hiver
                    setStepDateWinter(false); //ce qui permettra en cas de retour arrière, de se retrouver sur la seconde étape de choix de date ! (voir 'askBeginningWinterFirst')   
                
                    //On se positionne sur la bonne date d'image de sortie d'hiver
                    if ((satImageEndingWinterId > 0) && 
                        (satImageEndingWinterDate !== undefined) && (satImageEndingWinterDate !== null)) {
                            selectDateImag(satImageEndingWinterId, satImageEndingWinterDate, currentSpecificInfos.idParcel);
                    }
                }
                else { //on a finis cette étape, et on passe à la suivante (celle de visualisation du conseil dont le zonage proposé):
                    //après avoir sélectionné la 2ème date
                    this.setState({
                        satImageEndingWinterDate: date,
                        satImageEndingWinterProvider: sourceProvider,
                        satImageEndingWinterId: id,

                        isSatImageEndingWinterDateValid: true,
                    });

                    //Appel à la génération après avoir affecter les données des dtes sélectionnées:
                    //const currentFertiInfos = lodashGet(fertilizerDico, `[${parcelIdSelected}]`, undefined);
                    if (currentSpecificInfos) {

                        let cropYear = fertilizerHelper.getCurrentCropYear(closingDateAzofert, openingDateAzofert);

                        const fertilizerParameter = Object.assign({}, currentSpecificInfos, {
                            beginningWinterImagId: satImageBeginningWinterId,
                            beginningWinterDate: satImageBeginningWinterDate,
                            beginningWinterImagProvider: satImageBeginningWinterProvider,
                            endingWinterImagId: id,
                            endingWinterDate: date,
                            endingWinterImagProvider: sourceProvider,
                            cropYear: cropYear,
                            //isComplet: false, //pas nécessaire car sera remis à jour côté C# !
                        });

                        buildNitrogenSampleAndDoses(fertilizerParameter);
                        //goToStep(FertilizerStep.RESULT); //c'est le bon retour de 'buildNitrogenSampleAndDoses' qui fera l'appel au changement d'étape !
                    }
                }
            }
        }
    }

    onGoBackToManagement = () => {
        const { isBeginningWinter, satImageBeginningWinterId, satImageBeginningWinterDate, currentSpecificInfos } = this.state;
        const { goToStep, setStepDateWinter, selectDateImag } = this.props;
        
        if (isBeginningWinter === false) { 
            this.setState({ isBeginningWinter: true, isSatImageEndingWinterDateValid: false, });

            //Signaler à Redux qu'on se place en sortie d'hiver
            setStepDateWinter(true);

            //On se positionne sur la bonne date d'image d'entrée d'hiver
            selectDateImag(satImageBeginningWinterId, satImageBeginningWinterDate, currentSpecificInfos.idParcel);
        }
        else { //si 'isBeginningWinter' vaut déjà 'true' c'est que l'on était à la sélection de la première date, on retourne sur l'étape de gestion des parcelles à piloter
            goToStep(FertilizerStep.MANAGEMENT_NITROGEN);
        }
    }
    
    componentDidMount() {

        const { selectLayerForFertilizer } = this.props;

        this.setState({
            isSelectedVisibleLayer: false
        });

        // On prévient qu'il faut changer de fond de carte: 
        if (selectLayerForFertilizer) {
            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            selectLayerForFertilizer(ConstantsLayers.VisibleBaseLayerName, ConstantsLayers.NdviParcelLayerName);
        }
    }

    componentWillUnmount() {
        const { selectLayerForFertilizer } = this.props;

        // On prévient qu'il faut changer de fond de carte: 
        if (selectLayerForFertilizer) {
            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            selectLayerForFertilizer(ConstantsLayers.VisibleBaseLayerName, ConstantsLayers.NdviParcelLayerName);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { errorMessage } = this.props;

        if (((!prevProps) || (!prevProps.errorMessage) || (prevProps.errorMessage === '')) &&
            (errorMessage && (errorMessage !== ''))) {
            this.popupErrorDialog.description = errorMessage;
            this.setState({
                openDialogOfErrors: true,
            });
        }
    }

    /* Ferme le dialog des messages d'erreur */
    closeDialogOfErrors = () => {

        const { parcelDico, thumbnailParcelDico, openSpecificInfosPopUp, goToStep } = this.props;
        const { currentSpecificInfos } = this.state;

        this.setState({
            openDialogOfErrors: false,
        });

		if (this.props.cleanErrorFertilizer) {
			this.props.cleanErrorFertilizer();
		}

        //Récupération de la parcelle pour remplir les données dans le dialog de données spécifiques
        let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, currentSpecificInfos.idParcel);

        if (parcel) {
            const id = parcel.id;
            const name = parcel.name;
            let crop = undefined;
            if (parcel.details) {
                for (let itemDetailName in parcel.details) {
                    const propValue = parcel.details[itemDetailName];
                    if (itemDetailName.toLowerCase() === 'culture') {
                        crop = propValue;
                    }
                }
            }

            const thumbnailParcelItem = ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcel.id);
            const variety = lodashGet(currentSpecificInfos, 'variety.label', '');
            const nitrogenTotal = lodashGet(currentSpecificInfos, 'nitrogenTotal', '');
            const nitrogenPrescription = lodashGet(currentSpecificInfos, 'nitrogenPrescription', '');

            let dataParameters = {
                id: id,
                idParcel: id,
                nom: name,
                culture: crop,
                thumbnailInfos: thumbnailParcelItem,
                variety: variety,
                totalNDose: nitrogenTotal,
                NDoseModulated: nitrogenPrescription,
                actions: parcel,
                parcel: parcel,
                dataFertilizer: currentSpecificInfos, //les données spécifiques
                areMinimumValuesValid: lodashGet(currentSpecificInfos, 'areMinimumValuesValid', false),
            };
        
            //A la fermeture de la dialog des messages d'erreurs, on se redirige vers l'étape de saise de données de fumure
            goToStep(FertilizerStep.MANAGEMENT_NITROGEN);
            
            //A l'étape des saisies de données spécifiques, on ouvre la dialog pour saisir les données éronnées.
            openSpecificInfosPopUp(dataParameters);
        }
    }

    /**
     * fonction callback lors du click du bouton d'un choix de couche générale (par dessus celle de fond) dont son nom est passé en paramètre
     */
    onSelectGeneralLayer() {
        // ↓↓ suivant le nom du layer passé en paramètre ↓↓
        const { isSelectedVisibleLayer } = this.state;
        const { selectLayerForFertilizer } = this.props;

        let newLastLayer = undefined;

        if (isSelectedVisibleLayer === false) {
            newLastLayer = ConstantsLayers.VisibleGlobalLayerName;
        }
        else {
            newLastLayer = ConstantsLayers.NdviParcelLayerName;
        }

        //On ne modifie pas le paramétrage client dans ce cas !
        // pour ne pas qu'il se retroune sur le layer vue globale visible sur la page "Carto".

        this.setState({
            isSelectedVisibleLayer: (isSelectedVisibleLayer === true) ? false : true
        });

        // On prévient qu'il faut changer de fond de carte: 
        if (selectLayerForFertilizer) {
            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            selectLayerForFertilizer(ConstantsLayers.VisibleBaseLayerName, newLastLayer);
        }
    }

    render() {
        const { isSelectedVisibleLayer, openDialogOfErrors, isBeginningWinter, 
            satImageBeginningWinterDate, isSatImageEndingWinterDateValid, isCultureColza, parcel } = this.state;

        const { satimageIdSelectedOfParcel, building, satimageByParcelDico, parcelIdSelected, satimageDateSelectedOfParcel } = this.props;

        //date de l'image sélectionnée actuellement sur cette parcelle:
        const satImageSelectedDate = lodashGet(satimageByParcelDico, `[${parcelIdSelected}].satimageDico[${satimageIdSelectedOfParcel}].date`, undefined);
        //la date d'entrée d'hiver est : Soit l'image courante, si on cherche à la choisir ; Soit l'image d'netrée d'hiver déjà choisie.
        const dateBeginningWinter = ((isBeginningWinter === true) && (satImageSelectedDate !== undefined) && (satImageSelectedDate !== null)) ? 
            dateHelper.getDateToFrString(satImageSelectedDate) : (
                ((satImageBeginningWinterDate !== undefined) && (satImageBeginningWinterDate !== null)) ? dateHelper.getDateToFrString(satImageBeginningWinterDate) : undefined );
        //la date de sortie d'hiver est : Soit vide, si on cherche à choisir celle d'entrée d'hiver ; Soit l'image courante, si on cherche à choisir celle de srotie d'hiver ; 
        // Soit l'image de sortie d'hiver déjà choisie.
        const dateEndWinter = (isBeginningWinter === true) ? undefined :
            dateHelper.getDateToFrString(satimageDateSelectedOfParcel);
        const titleStep = (isBeginningWinter === true) ? "1/2 " + StringTranslate.selectBiomassBeginningWinter : "2/2 " + StringTranslate.selectBiomassEndingWinter;

        return (
            <>
                {/* Dialog des erreurs */}
                {(openDialogOfErrors === true) &&
                    <AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
                }

                {/* ↓ contrôle (bouton) en lien avec la sélection de layer - mobile OU tablette/PC ↓ */}
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                    {/*Modal des plages de date pour le format mobile pour la culture de colza */}
                    {(isCultureColza === true) &&
                        <ModalBiomassRange
                            open={this.state.colzaDatesRangeModalOpen}
                            parcel={parcel}
                            isBeginningWinter={isBeginningWinter}
                            onClose={() => this.setState({ colzaDatesRangeModalOpen: false })}
                        />
                    }
                    <Box className="leaflet-top leaflet-right leaflet-PosNone">
                        <Tooltip title={<Typography color="inherit">{isSelectedVisibleLayer ? StringTranslate.ComeBackToIndicevege : StringTranslate.DetectClouds}</Typography>} placement="left">
                            <Fab
                                className='leaflet-control'
                                size="small"
                                onClick={() => this.onSelectGeneralLayer()}
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    color: theme.palette.primary.dark
                                }}>
                                {isSelectedVisibleLayer ? <CloudOffIcon /> : <CloudIcon />}
                            </Fab>
                        </Tooltip>
                    </Box>

                    <div className='leaflet-bottom leaflet-PosNone leaflet-center-buttons-navigation'>
                        <Card sx={{ marginBottom: "118px", marginRight: "5px",}}>
                            <CardActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={(isCultureColza === true) ? 1 : 4}>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => this.onGoBackToManagement()}
                                            tabIndex={12}
                                            sx={{
                                                zIndex: 800,
                                                pointerEvents: "fill",
                                                justifyContent: isCultureColza === true ? "flex-start" : "auto"
                                            }}
                                        >
                                            <ArrowBackIcon />
                                        </Button>
                                    </Grid>
                                    {/*Bouton d'ouverture de la modal pour le format mobile */}
                                    {(isCultureColza === true) &&
                                        <Grid item container xs={4} alignItems={'center'} justifyContent={'center'}>
                                            <Chip
                                                label={StringTranslate.biomassRangeMobileHelper}
                                                variant={'outlined'}
                                                color='primary'
                                                onClick={() => this.setState({ colzaDatesRangeModalOpen: true })}
                                                icon={<InfoIcon />}
                                                sx={{
                                                    zIndex: 800,
                                                    cursor: "pointer",
                                                    pointerEvents: "fill",
                                                }}
                                            />
                                        </Grid>
                                    }
                                    <Grid item container xs={(isCultureColza === true) ? 3 : 4} justifyContent = {(isCultureColza === true) ? "end" : ""}>
                                        <Typography variant="subtitle1" sx={{ mt: 0.5, fontWeight: "bold" }}>{(isBeginningWinter === true) ? "Image : 1 / 2" : " Image : 2 / 2"}</Typography>
                                    </Grid>

                                    <Grid item xs={4} align="right">
                                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                                            <Button
                                                variant="text"
                                                color="primary"
                                                startIcon={
                                                    (building === false) ?
                                                        <FertilizationIcon sx={{ fontSize: 24, stroke: theme.palette.primary.main }}  />
                                                        : <CircularProgress
                                                            color="inherit"
                                                            size={24}
                                                        />}
                                                sx={{
                                                    bgcolor: theme.palette.background.paper,
                                                    color: theme.palette.primary.main,
                                                    zIndex: 800,
                                                    pointerEvents: "fill",
                                                }}
                                                onClick={() => this.onSelectImage()}
                                                disabled={(building === true) || (satimageIdSelectedOfParcel === undefined) || (satimageIdSelectedOfParcel <= 0)}
                                            >
                                                {StringTranslate.valider}
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </div>
                </Box>
                {/*Affichage PC */}
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    <Card
                        className="leaflet-bottom leaflet-left leaflet-PosNone"
                        variant_pz="card-md-bio">

                        <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                            <CardHeader
                                sx={headerSX}
                                title={<Typography variant="h5" sx={{ fontWeight: "bold" }}>{titleStep}</Typography>} />
                            <Divider />
                        </Box>

                        <CardContent className={(building === true) ? "disabledAll" : ""}>
                            <Box sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none" } }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>{titleStep}</Typography>
                            </Box>

                            { /* Layer */}
                            <Grid container alignItems="center" spacing={0} justifyContent="space-between">
                                <Grid item xs={8}>
                                    {isSelectedVisibleLayer ?
                                        <>
                                            <Typography variant="subtitle2" sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none" } }}>
                                                {StringTranslate.goBackToVegetationIndex}
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                                                {StringTranslate.ObserveVisibleLayer}
                                            </Typography>
                                        </>
                                        :
                                        <>
                                            <Typography variant="subtitle2" sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none" } }}>
                                                {StringTranslate.verifClouds}
                                            </Typography>
                                            {(this.hasNoDateRange || isCultureColza === false) ? 
                                                (
                                                    <Typography variant="subtitle2" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                                                        {StringTranslate.ObserveCloudLayer}
                                                    </Typography>
                                                )
                                            :
                                                (
                                                    /**Légende pour les différents intervalles de date */
                                                    <Stack direction={'column'}>
                                                        <Stack spacing={1} direction={'row'} alignItems={'baseline'}>
                                                            <SlideRangeMarker label={'IN_RANGE'}/>
                                                            <Stack direction={'column'}>
                                                                <Typography variant="subtitle2">{BiomassesHelper.getRangeDatesText(parcel, isBeginningWinter)}</Typography>
                                                                <Typography variant="subtitle2">{StringTranslate.biomassRangeText}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                        <Stack spacing={1} direction={'row'} alignItems={'baseline'}>
                                                            <SlideRangeMarker label={'IN_EXTRA_RANGE'}/>
                                                            <Stack direction={'column'}>
                                                                <Typography variant="subtitle2">{BiomassesHelper.getExtraRangeDatesText(parcel, isBeginningWinter)}</Typography>
                                                                <Typography variant="subtitle2">{StringTranslate.biomassExtraRangeText}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                        <Stack spacing={1} direction={'row'} alignItems={'baseline'}>
                                                            <SlideRangeMarker />
                                                            <Typography variant="subtitle2">{StringTranslate.biomassOutOfRangeText}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                )
                                            }
                                        </>}
                                </Grid>
                                <Grid item xs={2}>
                                <Tooltip 
                                    title={StringTranslate.ObserveCloudLayer} 
                                    // Si pas de date, pas besoin du tooltip (Il est déjà afficher à gauche du bouton)
                                    disableHoverListener={this.hasNoDateRange} 
                                    disableFocusListener={this.hasNoDateRange} 
                                >
                                    <IconButton
                                        sx={{
                                            bgcolor: theme.palette.primary.light,
                                            color: theme.palette.primary.main,
                                            '& .MuiSvgIcon-root': { fontSize: '1.5rem' }
                                        }}
                                        size="large"
                                        onClick={() => this.onSelectGeneralLayer()}>
                                        {(isSelectedVisibleLayer === true) ?
                                            <CloudOffIcon /> : <CloudIcon />}
                                    </IconButton>
                                </Tooltip>
                                </Grid>
                            </Grid>

                            <Grid container sx={{ mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2"><CheckIcon fontSize="small" color={isBeginningWinter === true ? "disabled" : "success"} /> {StringTranslate.beginningWinterDate} {(dateBeginningWinter !== undefined) ? dateBeginningWinter : " --- "}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2"><CheckIcon fontSize="small" color={(isSatImageEndingWinterDateValid === true) ? "success" : "disabled" } /> {StringTranslate.endingWinterDate} {(dateEndWinter !== undefined) ? dateEndWinter : " --- "}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <CardActions>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Button variant="text" color="primary"
                                        onClick={() => this.onGoBackToManagement()}
                                        tabIndex={12}
                                        disabled={(building === true)}      // on désactive le bouton de retour lors du calcul de la fumure
                                    >
                                        {`${StringTranslate.goBackStepBiomass}`}
                                    </Button>
                                </Grid>

                                <Grid item xs={4} align="center">
                                    <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: "bold" }}>{isBeginningWinter === true ? "1 / 2" : "2 / 2"}</Typography>
                                </Grid>

                                <Grid item xs={4} align="right">

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={(building === true) && (
                                            <CircularProgress color="inherit"
                                                size={24} />)}
                                        onClick={() => this.onSelectImage()}
                                        disabled={(building === true) || (satimageIdSelectedOfParcel === undefined) || (satimageIdSelectedOfParcel <= 0)}
                                    >
                                        {`${StringTranslate.next}`}
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Box>

            </>
        );
    }
}

const mapStateToProps = state => ({
    building: lodashGet(state, 'fertilizerData.building', false),
    errorMessage: lodashGet(state, 'fertilizerData.errorMessage', undefined),
    parcelIdSelected: lodashGet(state, 'fertilizerData.parcelIdSelected', -1),
    // la culture de la parcelle actuelle
    // 1 => BLE / 2 => ORGE / 3 => COLZA
    cultureCrop: lodashGet(state, 'fertilizerData.cultureSelected', undefined),
    fertilizerDico: lodashGet(state, 'fertilizerData.fertilizerDico', {}),
    fertilizerDicoCounter: lodashGet(state, 'fertilizerData.fertilizerDicoCounter', 0),
    askBeginningWinterFirst: lodashGet(state, 'fertilizerData.askBeginningWinterFirst', true),
    closingDateAzofert: lodashGet(state, 'fertilizerData.closingDateAzofert', ConstantsFertilizer.DefaultClosingDateAzofert),
    openingDateAzofert: lodashGet(state, 'fertilizerData.openingDateAzofert', ConstantsFertilizer.DefaultOpeningDateAzofert),
});

const mapDispatchToProps = dispatch => ({
    goToStep: (step) => dispatch( ActionGoToStepOfFertilizer(step) ),
    buildNitrogenSampleAndDoses: (fertilizerParameter) => dispatch( ActionBuildNitrogenSampleAndDoses(fertilizerParameter) ),
    openSpecificInfosPopUp: (infoCurrentSelectedParcel) => dispatch( ActionShowSpecificInfosManagementPopup(infoCurrentSelectedParcel) ),
    setStepDateWinter: (isBeginningWinter) => dispatch( ActionSetStepDateWinter(isBeginningWinter) ),
    selectLayerForFertilizer: (baseLayertypeValue, forewardLayertypeValue) => dispatch( ActionSelectLayerForFertilizer(baseLayertypeValue, forewardLayertypeValue) ),
	cleanErrorFertilizer: () => dispatch( ActionCleanErrorFertilizer() ),
    registerWinterDate: (fertilizerParameter) => dispatch( ActionRegisterWinterDate(fertilizerParameter) ),
    selectDateImag: (satImagId, satImagDate, idParcel) => dispatch( ActionSelectDateImg(satImagId, satImagDate, idParcel) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapButtonsPane);