import React from "react";
import { connect } from 'react-redux';

import {
    Card, Grid, Typography, Table, TableBody,
    TableCell, TableHead, TableRow, TableFooter, LinearProgress,
    Button, Tooltip, Paper, TableContainer, 
} from "@mui/material";

import { Help, ArrowBack, Balance } from "@mui/icons-material";

import getTheme from "../../themes/index.js";
import MainCard from "../../components/subLayouts/mainCard.jsx";
import SubCard from "../subLayouts/subCard.jsx";

//import { IsNativeHoster } from '../../utils/platformHelper';

/* Actions redux */
import { BiomassStep, ActionGoToStepOfWeightingBiomass,ActionGoToNextStepOfBiomass,
    ActionAskForEndingBiomass, ActionDeleteBiomasses } from '../../redux/actions/biomass';
import { ProfilIndex,ActionFitMapToBounds, ActionGoToMapAndSelectParcel, ActionShowProfilMenuDialog, ActionUnselectParcel } from '../../redux/actions/contextApp';

import format from 'date-fns/format';
import DateHelper from '../../utils/dateHelper.js';
import numberHelper from '../../utils/numberHelper.js';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* css */
import '../../assets/css/biomass.css';
import ConstantsHosters from "../../utils/constantsByConfig.js";
import lodashGet from 'lodash/get';


let theme = getTheme();

/*
    Page du compte rendu avec carte de pesée de la biomasse, après validation de l'image à utiliser pour le calcul
*/

/* Obtient la couleur de la zone de chaque zone des pesées */
const getColor = function (color) {
    let Color = require('color');
    return Color(color).hex();
}

class WeightingBiomass extends React.Component {
    /*constructor(props) {
        super(props);
    }*/ // Pas nécessaire ! 

    componentDidMount() {
        // Au lancement de la page, on met à false (équivalent à une remise à zéro) 
        // la demande par l'utilisateur de pesée de colza en sortie d'hiver.
        this.props.askForEndingBiomass(false);
    }

    componentDidUpdate(prevProps) {

    }

    getTotalParcelArea = (biomassSelected) => {
        let totalRateArea = 0;
        if ((biomassSelected.zones !== null) && (biomassSelected.zones !== undefined)) {
            biomassSelected.zones.forEach(zone => {
                totalRateArea = totalRateArea + zone.RateArea;
            });
            totalRateArea = totalRateArea * biomassSelected.parameter.parcelArea;
        }
        return totalRateArea;
    }

    onGoBackClick() {
        const {
            goToNextStepOfBiomass,
            parcelIdsSelected,
            deleteBiomassByIds,
            askToShowBiomassFromMap,
            goToMapAndSelectParcel,
            fitMapToBounds,
            biomassSelected,
            parcelIdSelected } = this.props;

        if (askToShowBiomassFromMap === true) { //retour à la carte générale des parcelles
            goToMapAndSelectParcel(parcelIdSelected);
            fitMapToBounds(parcelIdSelected);
        }
        else if (biomassSelected) {
            const toDelete = {
                id: biomassSelected.id,
                parcelId: (biomassSelected.parameter) ? biomassSelected.parameter.parcelId : -1,
                isBeginningWinter: (biomassSelected.parameter) ? biomassSelected.parameter.isBeginningWinter : true,
            }
            const readableMode = false;
            // suppression de la valeur de biomass précédemment enregistré
            deleteBiomassByIds([toDelete])
                .then(() => {
                    // on retourne sur l'étape précédente: (après mise à jour des valeurs après suppression de la valeur de biomasse non souhaitée)
                    // Passage à l'étape de la carte
                    goToNextStepOfBiomass(BiomassStep.MAP, parcelIdsSelected, readableMode);
                });
        }
    }

    /* Retour à l'étape de la carte pour la prochaine parcelle sélectionnée */
    onGoToNextPlot = () => {
        const { parcelIdsSelected, goToNextStepOfBiomass } = this.props;
        if ((!parcelIdsSelected) || (parcelIdsSelected.length <= 0)) return;

        let newParcelIdsSelected = [];
        for (var i = 1; i < parcelIdsSelected.length; i++) { //On exclut volontairement l'item à l'index zéro (car on vient de le terminer).
            newParcelIdsSelected.push(parcelIdsSelected[i]);
        }

        goToNextStepOfBiomass(BiomassStep.MAP, newParcelIdsSelected, false);
    }

    /* Passage à l'étape de la carte pour la biomasse d'hiver (à la demande de l'utilisateur) */
    goToBiomassMapForEndingWinter = () => {
        const { goToNextStepOfBiomass, parcelIdsSelected, askForEndingBiomass } = this.props;

        const readableMode = false;

        askForEndingBiomass(true);

        // Passage à l'étape de la carte
        goToNextStepOfBiomass(BiomassStep.MAP, parcelIdsSelected, readableMode);
    }

    goToParcelsChoice = () => {
        const {
            goToStepOfWeightingBiomass,
            unselectParcel,
            askToShowBiomassFromMap,
            goToMapAndSelectParcel,
            fitMapToBounds,
            biomassSelected } = this.props;

        if (askToShowBiomassFromMap === true) { //retour à la carte générale des parcelles
            goToMapAndSelectParcel(biomassSelected.parameter.parcelId);
            fitMapToBounds(biomassSelected.parameter.parcelId);
        }
        else {
            const readableMode = true;

            unselectParcel();   // fonction permettant de ne pas avoir de parcelle sélectionné lors du retour sur l'écran de biomasse
            goToStepOfWeightingBiomass(BiomassStep.CHOIX_PARCELS, undefined, readableMode);
        }
    }

    render() { //TODO : Pourquoi on n'utilise pas 'CustomDataGrid' ?
        const { biomassSelected, parcelDico, parcelIdSelected, showProfilMenuDialog,
            parcelIdsSelected, readableMode } = this.props;

        const dateEndingWinter = lodashGet(parcelDico, `[${parcelIdSelected}].imagSourceDateEndingWinter`, undefined); // Date de la sortie d'hiver de la pesée de colza

        const thisParcelIdSelected = lodashGet(biomassSelected, 'parameter.parcelId', (parcelIdSelected > 0) ? parcelIdSelected : -1);

        const biomassWithImag = (biomassSelected && (biomassSelected.dataImg || (biomassSelected.azureImagURL && (biomassSelected.azureImagURL !== '')))) ? true : false;

        return (
            <>
                <MainCard title={false}>
                    { /* Barre de progression */}
                    {(biomassWithImag === true) ? null : <LinearProgress />}

                    <Grid container spacing={3}>
                        <Grid item lg={6} xs={12}>
                            {/* Map biomass */}
                            <SubCard title={`${StringTranslate.subTitleMapZonesBiomass}`}>
                                { /* on affiche la date du cliché */}
                                {biomassSelected && (<Typography variant="h5" sx={{ color: theme.palette.text.dark }}>
                                    {`${StringTranslate.dateImag} : ${format(DateHelper.getDateFromString(biomassSelected.parameter.dateImagSource), StringTranslate.formatDate)}`}
                                </Typography>)}

                                <Typography variant="subtitle1">{ParcelsHelper.getParcelNamefromIdParcel(parcelDico, thisParcelIdSelected)}</Typography>

                                { /* on affiche la période : entrée ou sortie d'hiver */}
                                {biomassSelected && (
                                    <Typography variant="subtitle1">{(biomassSelected.parameter.isBeginningWinter === true) ? `${StringTranslate.parcelHasBeginningWinter}` : `${StringTranslate.parcelHasEndingWinter}`}</Typography>
                                )}

                                {((biomassSelected) && (biomassSelected.dataImg)) ? (
                                    <img id='imgDataB' src={`data:image/jpeg;base64,${biomassSelected.dataImg}`}
                                        alt={StringTranslate.altImgModulation} className="image-zones-biomass" />
                                ) : (
                                    <img id='imgUrlB' src={(biomassSelected) ? biomassSelected.azureImagURL : ''}
                                        alt={StringTranslate.altImgModulation} className="image-zones-biomass" />
                                )}
                            </SubCard>

                            <Card sx={{ p: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button color="secondary" variant="text" onClick={() => showProfilMenuDialog(ProfilIndex.aide_PeseeDeBiomasse)}>{StringTranslate.helpAsk}</Button>
                                    </Grid>
                                </Grid>
                                {biomassSelected && <Grid item xs={12}>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        <Grid item xs={2} sm={4} md={4}>
                                            <Button sx={{ height: '100%' }}
                                                onClick={() => (readableMode === false) ? this.onGoBackClick() : this.goToParcelsChoice()}
                                                tabIndex={12}>
                                                <ArrowBack />{`${StringTranslate.goBackStepModulation}`}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4} sx={{ textAlign: 'center' }}>
                                            {(biomassSelected.parameter.isBeginningWinter === true) && (readableMode === false) &&
                                                <Button variant="outlined" color="primary" sx={{ height: '100%' }}
                                                    onClick={() => this.goToBiomassMapForEndingWinter()} tabIndex={10}>
                                                    {((dateEndingWinter === undefined) || (dateEndingWinter === null)) ?
                                                        StringTranslate.startBiomassEndingWinter :
                                                        StringTranslate.updateBiomassEndingWinter}
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4} sx={{ textAlign: 'right' }}>
                                            {(readableMode === false) && (parcelIdsSelected.length > 1) ?
                                                <Button variant="contained" color="primary" sx={{ height: '100%' }}
                                                    onClick={() => this.onGoToNextPlot(true)} tabIndex={10}>
                                                    {StringTranslate.goToNextFieldSkipTitle}
                                                </Button> :
                                                <Button variant="contained" color="primary" sx={{ height: '100%' }}
                                                    onClick={() => this.goToParcelsChoice()} tabIndex={10}>
                                                    {StringTranslate.terminer}
                                                </Button>
                                            }
                                        </Grid>
                                        {/* {((isMobile !== true) && (IsNativeHoster() !== true) && (parcelIdsSelected.length <= 1) && (//En mode mobile, on ne permet pas le téléchargement pour l'instant.
                                    <Button variant="contained" color="primary"
                                        onClick={() => this.onOnlyDownLoadBiomassClick()}>
                                        <IconFilesDonwload />{`${StringTranslate.download}`}
                                    </Button>
                                )} */}
                                    </Grid>
                                </Grid>}
                            </Card>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {((biomassSelected) && (biomassSelected.averageQuantity>=0)) && (
                                        <SubCard
                                            sx={{
                                                background: theme.palette.info.main,
                                                position: 'relative',
                                                color: theme.palette.common.white
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    position: 'absolute',
                                                    right: 13,
                                                    top: 14,
                                                    color: theme.palette.common.white,
                                                    '&> svg': { width: 100, height: 100, opacity: '0.5' },
                                                    [theme.breakpoints.down('sm')]: {
                                                        top: 13,
                                                        '&> svg': { width: 80, height: 80 }
                                                    }
                                                }}
                                            >
                                                <Balance />
                                            </Typography>
                                            <Grid container direction={[theme.breakpoints.down('xs')] ? 'column' : 'row'} spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" color="inherit">
                                                        {StringTranslate.subTitleResultWeightingBiomass}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h3" color="inherit">
                                                        {numberHelper.fixeDecimal(biomassSelected.averageQuantity)} Kg/m²
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" color="inherit">
                                                        {StringTranslate.averageQuantityResult_1}{numberHelper.fixeDecimal(biomassSelected.averageQuantity)}{StringTranslate.averageQuantityResult_2}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </SubCard>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Calcul biomass */}
                                    <SubCard title={`${StringTranslate.subTitleYourWeightingBiomass}`}>
                                        {(biomassSelected && biomassSelected.zones) &&
                                            <TableContainer component={Paper}>
                                                <Table
                                                    aria-label="caption table" size="small">
                                                    <caption>{StringTranslate.totalArea} <strong>{numberHelper.fixeDecimal(this.getTotalParcelArea(biomassSelected))} Ha</strong></caption>
                                                    <TableHead>
                                                        <TableRow key="rowTitles">
                                                            <TableCell></TableCell>
                                                            <TableCell colSpan={3}>{`${StringTranslate.quantity} (kg/m²)`}</TableCell>
                                                        </TableRow>
                                                        <TableRow key="rowSubTitles">
                                                            <TableCell></TableCell>
                                                            <TableCell>{`Min`}</TableCell>
                                                            <TableCell>{`Max`}</TableCell>
                                                            <TableCell>{`Moy`}</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {biomassSelected.zones.map((row, index) => (
                                                            <TableRow key={row.id} className="table-row-biomass">
                                                                <TableCell style={{ background: getColor(row.Color), width: '5%' }} />
                                                                <TableCell style={{ width: '30%' }}>
                                                                    <Typography>{((row.MinQuantity <= 0) && (row.MinLaiValue === undefined || row.MinLaiValue === null)) ? "--" : numberHelper.fixeDecimal(row.MinQuantity)}</Typography>
                                                                </TableCell>
                                                                <TableCell style={{ width: '30%' }}>
                                                                    <Typography>{((row.MaxQuantity <= 0) && (row.MaxLaiValue === undefined || row.MaxLaiValue === null)) ? "--" : numberHelper.fixeDecimal(row.MaxQuantity)}</Typography>
                                                                </TableCell>
                                                                <TableCell style={{ width: '30%' }}>
                                                                    <Typography><strong>{((row.AverageQuantity <= 0) && (row.AverageLaiValue === undefined || row.AverageLaiValue === null)) ? "--" : numberHelper.fixeDecimal(row.AverageQuantity)}</strong></Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}

                                                    </TableBody>

                                                    <TableFooter>
                                                        { /* Aide pour les zones de modulation */}
                                                        {(biomassSelected.cloudyDetected === true) ?
                                                            <TableRow key="rowFooter">
                                                                <TableCell colSpan={5}>
                                                                    <Tooltip title={`${StringTranslate.helpCloudZones}`} >
                                                                        <Help />
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow> : null
                                                        }
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>}
                                    </SubCard>

                                </Grid>
                                <Grid item xs={12}>
                                    <SubCard>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item>
                                                <Typography>{StringTranslate.biomassesCheckBy}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <img 
                                                    src={ConstantsHosters.LogoTerresInovia}
                                                    height={100}
                                                    width={100}
                                                    alt="Logo-Terres-Inovia"
                                                />
                                            </Grid>
                                        </Grid>
                                    </SubCard>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </MainCard>
            </>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        //Infos provenant du reducer 'biomass':
        readableMode: state.biomassData.readableMode,
        biomassSelected: state.biomassData.biomassSelected,
        parcelIdsSelected: state.biomassData.parcelIdsSelected,

        //Infos provenant du reducer 'parcels':
        parcelDico: (state && state.parcelsData) ? state.parcelsData.parcelDico : {},

        //Infos provenant du reducer 'contextApp':
        parcelIdSelected: (state && state.contextAppData) ? state.contextAppData.parcelIdSelected : -1,
        askToShowBiomassFromMap: (state && state.contextAppData) ? state.contextAppData.askToShowBiomassFromMap : undefined,
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    unselectParcel: () => dispatch(ActionUnselectParcel()),
    goToStepOfWeightingBiomass: (biomassStep, readableMode, openFirstPanel) => dispatch(ActionGoToStepOfWeightingBiomass(biomassStep, readableMode, openFirstPanel)),
    fitMapToBounds: (parcelId) => dispatch(ActionFitMapToBounds(parcelId)),
    goToMapAndSelectParcel: (parcelId) => dispatch(ActionGoToMapAndSelectParcel(parcelId)),
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    goToNextStepOfBiomass: (nextStepOfBiomass, parcelIdsSelected, readableMode, biomassSelectedItemValue = undefined) =>
        dispatch(ActionGoToNextStepOfBiomass(nextStepOfBiomass, parcelIdsSelected, readableMode, biomassSelectedItemValue)),
    askForEndingBiomass: (hasAskedForEndingBiomass) => dispatch(ActionAskForEndingBiomass(hasAskedForEndingBiomass)),

        /* Tableau des biomasses */
    deleteBiomassByIds: (biomassIds) => dispatch(ActionDeleteBiomasses(biomassIds)),
    })

export default connect(mapStateToProps, mapDispatchToProps)(WeightingBiomass);