import React from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import { InputAdornment, FormControl, TextField, Grid, Paper, 
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TableFooter, OutlinedInput, FormLabel,
    Button, LinearProgress, CircularProgress,
    Typography, Tooltip, Checkbox, Dialog, DialogActions,
    DialogContent, DialogTitle, Radio, RadioGroup, FormControlLabel,
    ButtonGroup, styled, InputLabel, CardActions, Stack, Alert, Card, Slider } from '@mui/material';

import { Help, Add, Remove, FileCopy, SaveAlt, ArrowBack, ErrorOutline, InfoOutlined } from '@mui/icons-material';

import { ModulationStep } from '../../redux/actions/modulations';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import numberHelper from '../../utils/numberHelper.js';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';
import format from 'date-fns/format';
import { isMobile } from 'react-device-detect';
import AlertDialog from '../../components/alertDialog';
import { SupplyType, TypeDownloadChoice, ActionGetLastModulationSettingsLoaded, ActionBuildModulationSampleAndDoses,
    ActionAddPrescription, ActionInitializePrescriptions, ActionSaveModulation, ActionGoToStepOfModulation,
    ActionGoToNextStepOfModulation, ActionSelectModulation, ActionBuildListPrescriptions, 
    ActionCleanErrorModulation, ActionClearModulationValue } from '../../redux/actions/modulations';
import { ActionSaveRememberModulationParameters } from '../../redux/actions/settings.js';
import { ProfilIndex, ActionGoToModulation, ActionShowProfilMenuDialog, ActionFitMapToBounds,
    ActionGoToMapAndSelectParcel, ActionUnselectParcel } from '../../redux/actions/contextApp';
import { IsNativeHoster } from '../../utils/platformHelper';

import getTheme from "../../themes/index.js";
import MainCard from "../../components/subLayouts/mainCard.jsx";
import SubCard from "../../components/subLayouts/subCard.jsx";
import { DEFAULT_MODULATION_DISPERSION } from '../../utils/modulationsHelper';


const AzoteUnit = "U";
const LiquidUnit = "L";
const SolidUnit = "Kg";
const AzoteUnitBySurface = `${AzoteUnit}/Ha`;
const LiquidUnitBySurface = `${LiquidUnit}/Ha`;
const SolidUnitBySurface = `${SolidUnit}/Ha`;

/* Obtient la couleur de la zone de modulation */
const getColor = function (color) {
    let Color = require('color');
    return Color(color).hex();
}

const CustomTextField = styled(TextField)({

    '& label.Mui-focused': {
        color: 'transparent',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
    },
});

let theme = getTheme();

//const defaultMinZoneNbr = 2;
const defaultMaxZoneNbr = 6;

class DosesModulation extends React.Component {
    constructor(props) {
        super(props);

        const modulationSelectedInitial = props.modulationSelected;

        const lastModulationSettings = props.lastModulationSettings;
        const rememberModulationSettingsProps = props.rememberModulationSettings;

        const initialContentAvailable = (modulationSelectedInitial && (numberHelper.testDataValid(modulationSelectedInitial.content)) &&
            (modulationSelectedInitial.content > 0) && (modulationSelectedInitial.content <= 100)) ? true : false;

        const endOfBuild = (props.buildWaiting === false) ? true : false;

        const readableMode = props.readableMode;

        if (modulationSelectedInitial && (modulationSelectedInitial.zones !== undefined)) {
            modulationSelectedInitial.zones.forEach(zoneItem => {
                zoneItem.actualDose = numberHelper.fixeDecimal(zoneItem.actualDose);
                //calcul de la doze d'azote par Ha via la dose d'intrant et la teneur
                zoneItem.actualDoseUnit = (initialContentAvailable === true) ? numberHelper.fixeDecimal(zoneItem.actualDose * modulationSelectedInitial.content / 100) : zoneItem.actualDose;
                zoneItem.withContent = (initialContentAvailable === true);
            });
        }

        if (modulationSelectedInitial && (modulationSelectedInitial.ratios !== undefined)) {
            modulationSelectedInitial.ratios.forEach(ratioItem => {
                ratioItem.ratio = numberHelper.fixeDecimal(ratioItem.ratio);
                ratioItem.withContent = false;
            });
        }

        /**
         * Retourne la répartition de la modulation ou null si c'est une ancienne modulation
         * @note l'utilisation d'une fonction, rend le code plus lisible qu'un ternaire trop grand
         * @returns {number | null}
         */
        const getRepartition = () => {
			//RQ: on multiplie par 100 pour avoir le pourcentage
            if (readableMode) {
                if (modulationSelectedInitial && modulationSelectedInitial.dispersion) {
                    return modulationSelectedInitial.dispersion * 100;
                } else {
                    return null;
                }
            } else {
                if (modulationSelectedInitial && modulationSelectedInitial.dispersion) {
                    return modulationSelectedInitial.dispersion * 100;
                } else {
                    return DEFAULT_MODULATION_DISPERSION;
                }
            }
        }

        this.state = {
            isFirstCall: true,
            disabledBtnAddZone: false,
            disabledBtnRemoveZone: true,
            dataNotAvailable: props.buildWaiting, /* Si les données sont disponibles pour affichage, on arrête le chargement du spin */
            zonesIsValid: (modulationSelectedInitial) ? (modulationSelectedInitial.isValidZones === true) : false,
            hasCloudShadow: (modulationSelectedInitial) ? (modulationSelectedInitial.cloudShadowDetected === true) : false,
            doseNominal: (modulationSelectedInitial && numberHelper.testDataValid(modulationSelectedInitial.doseNominal)) ?
                numberHelper.fixeDecimal(modulationSelectedInitial.doseNominal) : '', // si doseNominal = undefined, on mettra un champ vide pour éviter les erreurs en console.
            doseMedium: rememberModulationSettingsProps === true ? lastModulationSettings.doseNominal : ((modulationSelectedInitial && numberHelper.testDataValid(modulationSelectedInitial.doseMedium)) ?
                numberHelper.fixeDecimal(modulationSelectedInitial.doseMedium) : ''), // si doseMedium = undefined, on mettra un champ vide pour éviter les erreurs en console.
            content: (modulationSelectedInitial && modulationSelectedInitial.content) ?
                modulationSelectedInitial.content : '', //jamais actualisé via le retour de génération pour nous éviter les convertions à la volée (des doses) lors de changements de teneur ! Pour éviter les erreurs en console, mettre '' plutôt que undefined
            contentAvailable: initialContentAvailable,
            oldContent: (initialContentAvailable === true) ? modulationSelectedInitial.content : 0,
            contentType: (modulationSelectedInitial && modulationSelectedInitial.supplyType === SupplyType.Liquid) ? LiquidUnit : SolidUnit,
            contentTypeByHa: (modulationSelectedInitial && modulationSelectedInitial.supplyType === SupplyType.Liquid) ? LiquidUnitBySurface : SolidUnitBySurface,
            quantityToExpand: 0,
            parcelAreaFund: (modulationSelectedInitial && numberHelper.testDataValid(modulationSelectedInitial.parcelArea)) ? modulationSelectedInitial.parcelArea : 0,
            zonesCounter: (modulationSelectedInitial && modulationSelectedInitial.zonesCounter) ? modulationSelectedInitial.zonesCounter : 2,
            //RQ: les mode de distribution et de dosage sont inversé entre l'entité de paramétrage de génération de modulation et ce 'state' !
            distribCompensation: (modulationSelectedInitial && (modulationSelectedInitial.distributionType !== undefined)) ?
                (modulationSelectedInitial.distributionType === false) : false, //optimisation par défaut !
            dosageManuel: (rememberModulationSettingsProps === false && modulationSelectedInitial && (modulationSelectedInitial.doseChange !== undefined)) ?
                (modulationSelectedInitial.doseChange === false) :
                ((rememberModulationSettingsProps === true && lastModulationSettings.doseChange !== undefined) ?
                    (lastModulationSettings.doseChange === false) :
                    false), //automatique par défaut !
            supplyType: (modulationSelectedInitial && modulationSelectedInitial.supplyType !== undefined) ?
                modulationSelectedInitial.supplyType :
                SupplyType.Solid, //solide par défaut !
            zones: (modulationSelectedInitial) ? modulationSelectedInitial.zones : undefined,
            //si on a les zones, pas besoin des ratios !
            ratios: ((!modulationSelectedInitial) || modulationSelectedInitial.zones) ? undefined : modulationSelectedInitial.ratios,
            errorBuild: (endOfBuild === true) ? props.errorModulation : undefined,
            openDialogOfErrors: (endOfBuild === true) ? (props.errorModulation !== '' ? true : false) : false,
            rememberModulationSettings: rememberModulationSettingsProps, //se rappeler des paramètres de la dernière modulation

            openConfirmDownloadModulationDialog: false, //ouverture de la dialog de téléchargement des modulations
            typeDownloadChoice: TypeDownloadChoice.shp,
            showFirstStepForDownloadingModulations: false,
            contentFrameRed: false,
            maxZonesCount: (modulationSelectedInitial)? modulationSelectedInitial.maxZonesCount : 0,  
            repartition: getRepartition(),
        }

        this.popupErrorDialog = {
            getTitle: () => { return (<ErrorOutline />); },
            description: props.errorModulation,
            button: StringTranslate.close,
        };

        this.ElementBlurred = undefined;
        this.ElementLastChanged = undefined;
        this.ElementLastChangedSaved = undefined;
        this.previousRepartition = this.state.repartition;
    }

    componentDidUpdate(prevProps) {
        const { parcelAreaFund, dosageManuel } = this.state;
        const { modulationSelected, buildWaiting, errorModulation } = this.props;

        const newModulationSelected = modulationSelected;

        const newContentAvailable = (newModulationSelected && (numberHelper.testDataValid(newModulationSelected.content)) &&
            (newModulationSelected.content > 0) && (newModulationSelected.content <= 100)) ? true : false;

        const endOfBuild = ((prevProps.buildWaiting === true) && (buildWaiting === false)) ? true : false;

        this.popupErrorDialog.description = errorModulation;

        if (newModulationSelected && (newModulationSelected.zones !== undefined)) {
            newModulationSelected.zones.forEach(zoneItem => {
                zoneItem.actualDose = numberHelper.fixeDecimal(zoneItem.actualDose);
                //calcul de la doze d'azote par Ha via la dose d'intrant et la teneur
                zoneItem.actualDoseUnit = (newContentAvailable === true) ? numberHelper.fixeDecimal(zoneItem.actualDose * newModulationSelected.content / 100) : zoneItem.actualDose;
                zoneItem.withContent = (newContentAvailable === true);
            });
        }

        if (newModulationSelected && (newModulationSelected.ratios !== undefined)) {
            newModulationSelected.ratios.forEach(ratioItem => {
                ratioItem.ratio = numberHelper.fixeDecimal(ratioItem.ratio);
                ratioItem.withContent = false;
            });
        }

        if ((prevProps.modulationSelected !== newModulationSelected) || (endOfBuild === true)) {
            const newDosageManuel = (newModulationSelected === undefined) ? false : (//automatique par défaut !
                (newModulationSelected.doseChange === undefined) ? dosageManuel : (newModulationSelected.doseChange === false));

            let quantityToExpand = 0;
            if (newModulationSelected.zones) {
                newModulationSelected.zones.forEach(zoneItem => {
                    quantityToExpand += (zoneItem.ratio * parcelAreaFund * (
                        (numberHelper.testDataValid(zoneItem.actualDose) && (zoneItem.actualDose > 0)) ? zoneItem.actualDose : 0));
                });
            }

            const maxZonesCount = lodashGet(modulationSelected, 'maxZonesCount', 0); //peut être 'undefined' si on n'a pas encore détecté de limite !
		    const maxZoneCounter = (maxZonesCount > 0) ? maxZonesCount : defaultMaxZoneNbr;
            this.setState({
                isFirstCall: false,
                disabledBtnAddZone: (newModulationSelected && newModulationSelected.zonesCounter) ? ((newModulationSelected.zonesCounter >= maxZoneCounter) || ((endOfBuild === true) ? (this.props.errorModulation !== '') : false)) : false,
                disabledBtnRemoveZone: (newModulationSelected && newModulationSelected.zonesCounter) ? newModulationSelected.zonesCounter <= 2 : false,
                dataNotAvailable: false,
                zonesIsValid: (newModulationSelected) ? (newModulationSelected.isValidZones === true) : false,
                hasCloudShadow: (newModulationSelected) ? (newModulationSelected.cloudShadowDetected === true) : false,
                doseNominal: (newModulationSelected && numberHelper.testDataValid(newModulationSelected.doseNominal)) ? numberHelper.fixeDecimal(newModulationSelected.doseNominal) : '',
                doseMedium: (newModulationSelected && numberHelper.testDataValid(newModulationSelected.doseMedium)) ? numberHelper.fixeDecimal(newModulationSelected.doseMedium) : '',
                content: (newContentAvailable === true) ? newModulationSelected.content : '', //jamais actualisé via le retour de génération pour nous éviter les convertions à la volée (des doses) lors de changements de teneur ! Pour éviter les erreurs en console, mettre '' plutôt que undefined
                contentAvailable: newContentAvailable,
                oldContent: (newContentAvailable === true) ? newModulationSelected.content : 0,
                contentType: (newModulationSelected && newModulationSelected.supplyType === SupplyType.Liquid) ? LiquidUnit : SolidUnit,
                contentTypeByHa: (newModulationSelected && newModulationSelected.supplyType === SupplyType.Liquid) ? LiquidUnitBySurface : SolidUnitBySurface,
                supplyType: (newModulationSelected && newModulationSelected.supplyType !== undefined) ? newModulationSelected.supplyType : SupplyType.Solid,
                quantityToExpand: quantityToExpand,
                parcelAreaFund: (newModulationSelected && numberHelper.testDataValid(newModulationSelected.parcelArea)) ? newModulationSelected.parcelArea : parcelAreaFund, //si KO, laisse la valeur actuelle
                zonesCounter: (newModulationSelected && newModulationSelected.zonesCounter) ? newModulationSelected.zonesCounter : 2,
                //RQ: les mode de distribution et de dosage sont inversé entre l'entité de paramétrage de génération de modulation et ce 'state' !
                distribCompensation: (newModulationSelected && (newModulationSelected.distributionType !== undefined)) ? (newModulationSelected.distributionType === false) : false, //optimisation par défaut !
                dosageManuel: newDosageManuel,
                zones: (newModulationSelected) ? newModulationSelected.zones : undefined,
                //si on a les zones, pas besoin des ratios !
                ratios: ((!newModulationSelected) || newModulationSelected.zones) ? undefined : newModulationSelected.ratios,
                errorBuild: (endOfBuild === true) ? errorModulation : undefined,
                openDialogOfErrors: (endOfBuild === true) ? (errorModulation !== '' ? true : false) : false,
                maxZonesCount: modulationSelected.maxZonesCount, //peut être 'undefined' si on n'a pas encore détecté de limite !
            });
        } else if ((prevProps.buildWaiting === false) && (buildWaiting === true)) {
            this.setState({
                dataNotAvailable: true,
                errorBuild: undefined,
                openDialogOfErrors: false,
            });
        } else if ((prevProps.buildWaiting === true) && (buildWaiting === false)) {
            this.setState({
                dataNotAvailable: false,
                errorBuild: undefined,
                openDialogOfErrors: false,
            });
        }
    }

    onGoBackClick() {
        const { askToShowModulationFromMap, goToMapAndSelectParcel, fitMapToBounds, parcelIdSelected } = this.props;

        if (askToShowModulationFromMap === true) { //retour à la carte générale des parcelles
            goToMapAndSelectParcel(parcelIdSelected);
            fitMapToBounds(parcelIdSelected);
        }
        else {
            //on retourne sur l'étape précédente: (en principe, rien à faire de plus)
            this.props.goToModulationStep(ModulationStep.MAP, false);
        }
    }

    /* Retour vers "Mes modulations" */
    onGoBackToModulations() {
        const {
            goToModulationStep,
            askToShowModulationFromMap,
            goToMapAndSelectParcel,
            fitMapToBounds,
            parcelIdSelected,
            clearModulation
        } = this.props;

        if (askToShowModulationFromMap === true) { //retour à la carte générale des parcelles
            goToMapAndSelectParcel(parcelIdSelected);
            fitMapToBounds(parcelIdSelected);
        }
        else {
            goToModulationStep(ModulationStep.CHOIX_PARCELS, false);
            clearModulation();
        }
    }

    /* On ne fait que lancer le téléchargement (car on est en mode 'consultation' dans ce cas d'appel) */
    onOnlyDownLoadModulationClick() {
        /*const { modulationSelected, downLoadModulation } = this.props;
        downLoadModulation(modulationSelected);*/

        //Les fichiers de modulation ne sont plus stockés ! alors on lance une nouvelle génération de la modulation consultée:
        const { modulationSelected, readableMode, initializePrescriptions, addPrescription } = this.props;

        if ((readableMode === true) && modulationSelected) {
            //Vide la liste des précédentes prescriptions sélectionnées (suite à génération ou séléection depuis celles existantes):
            initializePrescriptions();

            //Définit les paramètre de la prescription dont il faut générer le ZIP:
            let modulationParams = {
                modulationId: (modulationSelected && modulationSelected.id > 0) ? modulationSelected.id : 0,
                clientId: modulationSelected.clientId,
                parcelId: modulationSelected.parcelId,
                imagSourceId: modulationSelected.imagSourceId,
                dateImagSource: modulationSelected.dateImagSource,
                providerImagSource: (modulationSelected) ? modulationSelected.providerImagSource : 1, //à defaut, on met 'Sentinel' !
                zonesCounter: modulationSelected.zonesCounter,
                supplyType: modulationSelected.supplyType,
                doseChange: modulationSelected.doseChange,
                distributionType: modulationSelected.distributionType,
                doseNominal: modulationSelected.doseNominal,
                doseMedium: modulationSelected.doseMedium,
                parcelArea: modulationSelected.parcelArea,
                content: modulationSelected.content, //On passe la teneur ici pour qu'elle soit conserver en BdD 
                // => à la visualisation/modification de l'une des modulations reçues de la BdD, il faudra remettre la modulation sélectionnée en unité d'Azote !
                // pour nous éviter les convertions à la volée (des doses) lors de changements de teneur !
                zones: modulationSelected.zones ? modulationSelected.zones : [
                    { actualDose: 0, actualDoseUnit: 0, ratio: 0 },
                    { actualDose: 0, actualDoseUnit: 0, ratio: 0 }
                ],
            };
            addPrescription(modulationParams);//@@ce ne sont pas des résultats de modulation, mais des paramètres de demande !!!

            //affichage sans l'avertissement..
            this.setState({
                openConfirmDownloadModulationDialog: true,
                showFirstStepForDownloadingModulations: false,
            });
        }
    }

    /* Demande de se rappeler des paramètres de la modulation pour la prochaine modulation */
    handleRememberParameters(event, remember) {
        const { saveRememberModulationParameters } = this.props;

        this.setState({
            rememberModulationSettings: remember,
        });
        saveRememberModulationParameters(remember);
    }

    /* Lance la procedure d'enregistrement en BdD des paramètres permettant de générer la modulation (lorsqu'un appel au téléchargement sera demandé) */
    onSaveModulationClick() {
        const { clientId, parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, providerSrcImageSelectedOfParcel, parcelIdsSelected, modulationSelected,
            goToModulationStep, goToNextStepOfModulation, saveModulation,
            rememberModulationSettings, allPrescriptions, addPrescription, updateLastModulationSettings, unselectParcel } = this.props;
        const { zonesCounter, parcelAreaFund, dosageManuel, distribCompensation,
            doseNominal, doseMedium, content, contentAvailable, zones, supplyType, repartition } = this.state;

        let modulationParams = {
            modulationId: (modulationSelected && modulationSelected.id > 0) ? modulationSelected.id : 0,
            clientId: clientId,
            parcelId: parcelIdSelected,
            imagSourceId: satimageIdSelectedOfParcel,
            dateImagSource: satimageDateSelectedOfParcel,
            providerImagSource: (modulationSelected) ? modulationSelected.providerImagSource : providerSrcImageSelectedOfParcel,
            zonesCounter: zonesCounter,
            supplyType: supplyType,
            //RQ: les mode de distribution et de dosage sont inversé entre l'entité de paramétrage de génération de modulation et ce 'state' !
            doseChange: (!dosageManuel),
            distributionType: (!distribCompensation),
            doseNominal: (numberHelper.testDataValid(doseNominal)) ? parseFloat(doseNominal) : 0,
            doseMedium: doseMedium, //pas besoin d'appliquer 'testDataValid' et 'parseFloat' sur ce champs car pas saisie !
            parcelArea: parcelAreaFund,
            content: (contentAvailable === true) ? content : undefined, //On passe la teneur ici pour qu'elle soit conserver en BdD 
            // => à la visualisation/modification de l'une des modulations reçues de la BdD, il faudra remettre la modulation sélectionnée en unité d'Azote !
            // pour nous éviter les convertions à la volée (des doses) lors de changements de teneur !
            zones: zones ? zones : [
                { actualDose: 0, actualDoseUnit: 0, ratio: 0 },
                { actualDose: 0, actualDoseUnit: 0, ratio: 0 }
            ],
            CanGenerateZip: true,
            dispersion: repartition / 100,
        };

        //Si on est sur la première prescription 
        // ET qu'il y en a d'autres à faire ET que le client à demander à se souvenir de son paramétrage:
        // => on reprends ces infos pour le(s) parcelle(s) suivante(s):
        if (((!allPrescriptions) || (allPrescriptions.length === 0)) && // pas encore de prescription prête à étre téléchargée !
            (parcelIdsSelected.length > 1) && // '>1' car il doit y avoir celle-ci plus un autre à venir !
            (rememberModulationSettings && (rememberModulationSettings === true))) { //case cochée !
            const newModulationSettings = {
                doseMedium: modulationParams.doseMedium,
                doseNominal: modulationParams.doseNominal,
                content: modulationParams.content,
                distributionType: modulationParams.distributionType,
                doseChange: modulationParams.doseChange,
                supplyType: modulationParams.supplyType,
                dispersion: modulationParams.dispersion,
            };
            updateLastModulationSettings(newModulationSettings);
        }

        addPrescription(modulationParams);//@@ce ne sont pas des résultats de modulation, mais des paramètres de demande !!!

        //@@Pour le bien, il faudrait que suivant le retour, on affiche l'erreur ou passe à la suite !
        const withDoses = true;
        saveModulation(modulationParams, withDoses);

        //L'écran suivant est : Soit le choix de la date pour la parcelle suivante; Soit l'écran de fin des générations.
        if (parcelIdsSelected.length <= 1) {
            unselectParcel();   // fonction permettant de ne pas avoir de parcelle sélectionné lors du retour sur l'écran de biomasse
            goToModulationStep(ModulationStep.CONGRATULATIONS, false);
        } else {
            let newParcelIdsSelected = [];
            for (var i = 1; i < parcelIdsSelected.length; i++) { //On exclut volontairement l'item à l'index zéro (car on vient de le terminer).
                newParcelIdsSelected.push(parcelIdsSelected[i]);
            }
            goToNextStepOfModulation(ModulationStep.MAP, newParcelIdsSelected, false);
        }
    }

    /* Fonction qui annule la modulation en cours pour passer à la suivante */
    onCancelCurrentModulation() {
        const { parcelIdsSelected, goToNextStepOfModulation } = this.props;

        let newParcelIdsSelected = [];
        for (var i = 1; i < parcelIdsSelected.length; i++) { //On exclut volontairement l'item à l'index zéro (car on vient de le terminer).
            newParcelIdsSelected.push(parcelIdsSelected[i]);
        }
        goToNextStepOfModulation(ModulationStep.MAP, newParcelIdsSelected, false);
    }

    /* Lance la popup de confirmation de génération+téléchargement de l'ensemble des modulations qui viennent d'être définie(== paramétrées) */
    onBuildAllPrescriptionsClick() {
        const { clientId, parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, providerSrcImageSelectedOfParcel, modulationSelected, addPrescription,
            supplyTypeLiquidInAllPrescriptionsCounter, supplyTypeSolidInAllPrescriptionsCounter } = this.props;
        const { zonesCounter, parcelAreaFund, dosageManuel, distribCompensation,
            doseNominal, doseMedium, content, contentAvailable, zones, supplyType } = this.state;

        let modulationParams = {
            modulationId: (modulationSelected && modulationSelected.id > 0) ? modulationSelected.id : 0,
            clientId: clientId,
            parcelId: parcelIdSelected,
            imagSourceId: satimageIdSelectedOfParcel,
            dateImagSource: satimageDateSelectedOfParcel,
            providerImagSource: (modulationSelected) ? modulationSelected.providerImagSource : providerSrcImageSelectedOfParcel,
            zonesCounter: zonesCounter,
            supplyType: supplyType,
            //RQ: les mode de distribution et de dosage sont inversé entre l'entité de paramétrage de génération de modulation et ce 'state' !
            doseChange: (!dosageManuel),
            distributionType: (!distribCompensation),
            doseNominal: (numberHelper.testDataValid(doseNominal)) ? parseFloat(doseNominal) : 0,
            doseMedium: doseMedium, //pas besoin d'appliquer 'testDataValid' et 'parseFloat' sur ce champs car pas saisie !
            parcelArea: parcelAreaFund,
            content: (contentAvailable === true) ? content : undefined, //On passe la teneur ici pour qu'elle soit conserver en BdD 
            // => à la visualisation/modification de l'une des modulations reçues de la BdD, il faudra remettre la modulation sélectionnée en unité d'Azote !
            // pour nous éviter les convertions à la volée (des doses) lors de changements de teneur !
            zones: zones ? zones : [
                { actualDose: 0, actualDoseUnit: 0, ratio: 0 },
                { actualDose: 0, actualDoseUnit: 0, ratio: 0 }
            ],
            CanGenerateZip: true,
        };

        addPrescription(modulationParams);

        //Attends la confirmation de l'utilisateur par le biais de l'affichage de la popup pour procéder à la génération complètes de toutes les prescriptions !

        //Vérifie si la popup de confirmation doit être ouverte avec l'avertissement à propos du type d'apport "mixte" !
        const withWarning = ((supplyTypeLiquidInAllPrescriptionsCounter + ((modulationParams.supplyType === SupplyType.Liquid) ? 1 : 0) > 0) &&
            (supplyTypeSolidInAllPrescriptionsCounter + ((modulationParams.supplyType === SupplyType.Solid) ? 1 : 0) > 0));

        this.setState({
            openConfirmDownloadModulationDialog: true,
            showFirstStepForDownloadingModulations: withWarning, //info de l'avertissement.
        });
    }

    /* Désaffiche la boite de dialog pour le téléchargement des modulations */
    handleCloseConfirmDialogForDownloading = () => {
        this.setState({
            openConfirmDownloadModulationDialog: false
        });
    }

    /* Fonction appelée lors du choix du type de téléchargement : SHP ou ISOXML */
    handleChangeTypeDownloadChoice = (event) => {
        this.setState({
            typeDownloadChoice: event.target.value
        });
    }

    /* Fonction qui désaffiche la première étape du popup de téléchargement qui est l'avertissement
    que les types d'apport sont différents. Puis Passage à l'étape suivante 
    qui est le choix entre le SHP et l'ISOXML */
    onGoToNextStepToDownload = () => {
        this.setState({
            showFirstStepForDownloadingModulations: false, //info de l'avertissement.
        });
    }

    /* Fonction de confirmation de téléchargement de modulations */
    onConfirmDownloadModulations = () => {
        const { typeDownloadChoice } = this.state;
        const { parcelIdsSelected, downloadListPrescriptions, goToModulationStep,
            goToNextStepOfModulation, allPrescriptions, initializePrescriptions } = this.props;

        let formatToExport = TypeDownloadChoice.shp;
        switch (typeDownloadChoice) {
            case TypeDownloadChoice.isoXml: {
                formatToExport = 2;
                break;
            }

            case TypeDownloadChoice.rds: {
                formatToExport = 3;
                break;
            }

            default: { // == TypeDownloadChoice.shp
                formatToExport = 1;
                break;
            }
        }

        /* Lance la procedure de génération et téléchargement de l'ensemble des fichiers constituant les modulations qui viennent d'être définie(== paramétrées) */
        //@@Pour le bien, il faudrait que suivant le retour, on affiche l'erreur ou passe à la suite !
        if (allPrescriptions) {
            downloadListPrescriptions(allPrescriptions, formatToExport);
        }

        //L'écran suivant est : Soit le choix de la date pour la parcelle suivante; Soit l'écran de fin des générations.
        if (parcelIdsSelected.length <= 1) {
            goToModulationStep(ModulationStep.CONGRATULATIONS, false);
            initializePrescriptions(); //Remise à vide de la liste des prescriptions
        } else { //RQ: On ne devrait jamais passer ici !
            let newParcelIdsSelected = [];
            for (var i = 1; i < parcelIdsSelected.length; i++) { //On exclut volontairement l'item à l'index zéro (car on vient de le terminer).
                newParcelIdsSelected.push(parcelIdsSelected[i]);
            }
            goToNextStepOfModulation(ModulationStep.MAP, newParcelIdsSelected, false);
        }

        this.setState({
            openConfirmDownloadModulationDialog: false,
            showFirstStepForDownloadingModulations: false,
        });
    }

    ApplyChangeAfterContentValided() {
        const { zones, content, contentAvailable, oldContent, supplyType } = this.state;

        let newZones = zones;

        //si la teneur a changé, il faut :
        // - formater les décimales;
        // - actualiser les doses (si possible == ancienne et nouvelle valeur ok et différente)
        if (contentAvailable === true) {
            const newContentValue = numberHelper.fixeDecimal(content);
            let newZones = zones; //zones actuelles du State !

            //on actualise la dose de chaque zone (par proportionnalité entre l'ancienne et nouvelle teneur !)
            if ((numberHelper.testDataValid(oldContent) && (oldContent > 0) && (oldContent <= 100)) &&
                (oldContent !== content)) { //Rq content est ok car 'contentAvailable' est à vrai !
                if (newZones !== undefined) {
                    newZones.forEach(zoneItem => {
                        if (zoneItem && (zoneItem.withContent === true)) {//pour éviter de faire la conversion deux fois !
                            //Rq : on devrait toujours passer ici !
                            zoneItem.actualDose = (numberHelper.testDataValid(zoneItem.actualDose) !== true) ?
                                zoneItem.actualDose : numberHelper.fixeDecimal((zoneItem.actualDose * oldContent) / newContentValue);
                        } else {//On ne devrait jamais être dans ce cas !
                            zoneItem.actualDose = (numberHelper.testDataValid(zoneItem.actualDose) !== true) ?
                                zoneItem.actualDose : numberHelper.fixeDecimal((zoneItem.actualDose * 100.0) / newContentValue);

                            zoneItem.withContent = true;
                        }
                    });
                }
            } else { //on considère donc que l'ancienne valeur n'était pas bonne et que donc, les doses était exprimées en U/Ha !
                //Il faut convertir les doses en tenant compte de la teneur nouvellement définie:
                if (newZones !== undefined) {
                    newZones.forEach(zoneItem => {
                        if (zoneItem && (zoneItem.withContent !== true)) {//pour éviter defaire la conversion deux fois !
                            zoneItem.actualDose = (numberHelper.testDataValid(zoneItem.actualDose) !== true) ?
                                zoneItem.actualDose : numberHelper.fixeDecimal((zoneItem.actualDose * 100.0) / newContentValue);

                            zoneItem.withContent = true;
                        } //else //ne touche à rien ! (car n'aurai jamais dû arriver ET dispose du bon état désormais)
                    });
                }
            }

            this.setState({
                content: newContentValue,
                oldContent: newContentValue,
                contentType: (supplyType !== undefined && supplyType === SupplyType.Liquid) ? LiquidUnit : SolidUnit,
                contentTypeByHa: (supplyType !== undefined && supplyType === SupplyType.Liquid) ? LiquidUnitBySurface : SolidUnitBySurface,
                zones: newZones,
            });
        } else { //si on arrive ici, c'est que la nouvelle valeur de teneur n'est pas bonne!
            //MAis si l'ancienne l'était et que donc, les doses était exprimées en Kg|L/Ha !
            //Il faut convertir les doses pour ne plus tenir compte de l'ancienne teneur :
            if ((numberHelper.testDataValid(oldContent) && (oldContent > 0) && (oldContent <= 100)) &&
                (oldContent !== content)) { //Rq content est ok car 'contentAvailable' est à vrai !
                if (newZones !== undefined) {
                    newZones.forEach(zoneItem => {
                        if (zoneItem && (zoneItem.withContent === true)) {//pour éviter defaire la conversion deux fois !
                            //Rq : on devrait toujours passer ici !
                            zoneItem.actualDose = (numberHelper.testDataValid(zoneItem.actualDose) !== true) ?
                                zoneItem.actualDose : numberHelper.fixeDecimal((zoneItem.actualDose * oldContent) / 100.0);

                            zoneItem.withContent = false;
                        } //else //ne touche à rien ! (car n'aurai jamais dû arriver ET dispose du bon état désormais)
                    });
                }
            } //else //RAS !!!

            this.setState({
                oldContent: 0,
                contentType: (supplyType === SupplyType.Liquid) ? LiquidUnit : SolidUnit,
                contentTypeByHa: (supplyType === SupplyType.Liquid) ? LiquidUnitBySurface : SolidUnitBySurface,
                zones: newZones,
            });
        }

        return newZones;
    }

    // - Calcul de la dose moyenne dans mode manuel,
    // - Calcul des doses des zones dans le mode automatique,
    // Quand on tape sur la touche "enter".
    onKeyEnterPressChange = (event, index) => {
        const { clientId, parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, providerSrcImageSelectedOfParcel, modulationSelected } = this.props;
        const { zonesCounter, parcelAreaFund, dosageManuel, distribCompensation,
            doseNominal, content, contentAvailable, doseMedium, zones, supplyType } = this.state;

        let newZones = zones;
        if (this.ElementLastChanged === 'content') {
            //potentiellement, le changement de teneur peut avoir un impact sur la valeur de chaque dose !
            newZones = this.ApplyChangeAfterContentValided();
        }

        if (((dosageManuel === false) && (numberHelper.testDataValid(doseNominal)) && (doseNominal > 0)) || //dosage auto + dose nominale valable
            (dosageManuel === true)) { //ou cas dosage manusel

            //En dosage automatique, après un "Enter" sur la dose nominale pour validation, le focus passe 
            //sur l'élément suivant qui est la teneur (seulement si celle-ci n'est pas renseignée).
            if ((contentAvailable === false) && (dosageManuel === false)) {
                document.getElementById("content").focus();
                this.setState({ contentFrameRed: false });
            }

            //pour éviter que la perte de focus suite à cette validation relance une génération !
            this.ElementLastChanged = undefined;

            let modulationParameter = {
                modulationId: (modulationSelected && modulationSelected.id > 0) ? modulationSelected.id : 0,
                clientId: clientId,
                parcelId: parcelIdSelected,
                imagSourceId: satimageIdSelectedOfParcel,
                dateImagSource: satimageDateSelectedOfParcel,
                providerImagSource: (modulationSelected) ? modulationSelected.providerImagSource : providerSrcImageSelectedOfParcel,
                zonesCounter: zonesCounter,
                supplyType: supplyType,
                //RQ: les mode de distribution et de dosage sont inversé entre l'entité de paramétrage de génération de modulation et ce 'state' !
                doseChange: (!dosageManuel),
                distributionType: (!distribCompensation),
                doseNominal: (numberHelper.testDataValid(doseNominal)) ? parseFloat(doseNominal) : 0,
                doseMedium: doseMedium, //pas besoin d'appliquer 'testDataValid' et 'parseFloat' sur ce champs car pas saisie !
                parcelArea: parcelAreaFund,
                content: (contentAvailable === true) ? content : undefined,
                zones: newZones ? newZones : [
                    { actualDose: 0, actualDoseUnit: 0, ratio: 0 },
                    { actualDose: 0, actualDoseUnit: 0, ratio: 0 }
                ],
            };

            let quantityToExpand = 0;
            //Si une zone contient une dose non valide (exemple, une dose vide), alors mettre la dose à 0
            modulationParameter.zones.forEach(zoneItem => {
                if (numberHelper.testDataValid(zoneItem.actualDose) === false) {
                    zoneItem.actualDose = 0;
                    zoneItem.actualDoseUnit = 0;
                }
                quantityToExpand += (zoneItem.ratio * parcelAreaFund * zoneItem.actualDose);
            });

            this.setState({
                quantityToExpand: quantityToExpand,
                dataNotAvailable: true,
                errorBuild: undefined,
                openDialogOfErrors: false,
            }, () => this.props.buildModulationSampleAndDoses(clientId, modulationParameter));
            //Rq: l'appel à 'buildModulationSampleAndDoses' va actualiser 'this.prop.modulationSelected'         
        }//else //ne fait rien !

    }

    //RQ: 'onFocus' : détecte la prise de focus.
    // 'onBlur' : détecte la perte de focus.
    // 'onchange' : détecte le changement de valeur.
    // 'onKeyEnterPressChange': fonctionne avec la dose nominale et les doses à renseigner. Les fois où on tape sur "enter", la fonction est déclenchée.

    //détection de qui a perdu le focus (dose nominale, teneur, chaque ratio, chaque dose, dosage et distribution)
    onBlurChange = (event, index) => {
        const { clientId, parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, providerSrcImageSelectedOfParcel, modulationSelected } = this.props;
        const { zonesCounter, parcelAreaFund, dosageManuel, distribCompensation,
            doseNominal, content, contentAvailable, doseMedium, zones, supplyType, repartition } = this.state;

        //On stocke le nom de l'élément ayant perdu le focus:
        this.ElementBlurred = (event && event.target) ? event.target.name : undefined;
        if ((index !== undefined) && (index >= 0)) {
            this.ElementBlurred += `_${index}`;

            //on rectifie sa valeur pour limiter les décimales :
            if ((zones !== undefined) && (index < zones.length)) {
                let newZones = zones; //zones actuelles du State !
                newZones[index].actualDose = numberHelper.fixeDecimal(newZones[index].actualDose);
                newZones[index].actualDoseUnit = numberHelper.fixeDecimal(newZones[index].actualDoseUnit);
                this.setState({
                    zones: newZones,
                });
            }
        } else if (this.ElementLastChanged === 'content') {
            //potentiellement, le changement de teneur peut avoir un impact sur la valeur de chaque dose !
            this.ApplyChangeAfterContentValided();
        }

        if (((dosageManuel === false) && (numberHelper.testDataValid(doseNominal)) && (doseNominal > 0)) || //dosage auto + dose nominale valable
            (dosageManuel === true)) { //ou cas dosage manusel
            //On contrôle s'il s'agit de l'un des switch:
            let isSwitchChange = ((this.ElementLastChangedSaved === "distributionType") || (this.ElementLastChangedSaved === "doseChange"));

            //si ce n'est pas un switch qui vient de prendre le focus et qu'il ne s'agit pas non-plus de celui qui vient de le perdre:
            // Ajout d'une condition qui permet d'éviter de regénérer si on change de saisie sans y avoir modifié la valeur !
            if ((!isSwitchChange) && (this.ElementLastChanged !== undefined)) {
                this.ElementLastChanged = undefined;

                //On actualise le paramétrage sans lancer une nouvelle génération:
                let modulationParameter = {
                    modulationId: (modulationSelected && modulationSelected.id > 0) ? modulationSelected.id : 0,
                    clientId: clientId,
                    parcelId: parcelIdSelected,
                    imagSourceId: satimageIdSelectedOfParcel,
                    dateImagSource: satimageDateSelectedOfParcel,
                    providerImagSource: (modulationSelected) ? modulationSelected.providerImagSource : providerSrcImageSelectedOfParcel,
                    zonesCounter: zonesCounter,
                    supplyType: supplyType,
                    dispersion: repartition / 100,
                    //RQ: les mode de distribution et de dosage sont inversé entre l'entité de paramétrage de génération de modulation et ce 'state' !
                    doseChange: (!dosageManuel),
                    distributionType: (!distribCompensation),
                    doseNominal: (numberHelper.testDataValid(doseNominal)) ? parseFloat(doseNominal) : 0,
                    doseMedium: doseMedium, //pas besoin d'appliquer 'testDataValid' et 'parseFloat' sur ce champs car pas saisie !
                    parcelArea: parcelAreaFund,
                    content: (contentAvailable === true) ? content : undefined,
                    zones: zones ? zones : [
                        { actualDose: 0, actualDoseUnit: 0, ratio: 0 },
                        { actualDose: 0, actualDoseUnit: 0, ratio: 0 }
                    ],
                };

                let quantityToExpand = 0;
                //Si une zone contient une dose non valide (exemple, une dose vide), alors mettre la dose à 0
                modulationParameter.zones.forEach(zoneItem => {
                    if (numberHelper.testDataValid(zoneItem.actualDose) === false) {
                        zoneItem.actualDose = 0;
                        zoneItem.actualDoseUnit = 0;
                    }
                    quantityToExpand += (zoneItem.ratio * parcelAreaFund * zoneItem.actualDose);
                });

                this.setState({
                    quantityToExpand: quantityToExpand,
                    dataNotAvailable: true,
                    errorBuild: undefined,
                    openDialogOfErrors: false,
                }, () => this.props.buildModulationSampleAndDoses(clientId, modulationParameter));
                //Rq: l'appel à 'buildModulationSampleAndDoses' va actualiser 'this.prop.modulationSelected'
            }//else //ne fait rien !
        }//else //ne fait rien ! 

        //En dosage automatique, l'encadré de la teneur devient rouge si :
        //  - la dose nominale est remplie et
        //  - il n'y a plus de focus sur la teneur et
        //  - la teneur n'est pas remplie
        //OU en dosage manuel :
        //  - la dose medium est remplie et
        //  - il n'y a plus de focus sur la teneur et
        //  - la teneur n'est pas remplie
        if (((dosageManuel === false) && (document.activeElement !== document.getElementById("content")) && (numberHelper.testDataValid(doseNominal)) && (doseNominal > 0) && (!numberHelper.testDataValid(content))) ||
            ((dosageManuel === true) && (document.activeElement !== document.getElementById("content")) && (numberHelper.testDataValid(doseMedium)) && (doseMedium > 0) && (!numberHelper.testDataValid(content)))) {
            this.setState({ contentFrameRed: true });
        }
        else {
            this.setState({ contentFrameRed: false });
        }
    }

    onChangeModulationSupply = (event) => {
        const { supplyType } = this.state;

        this.setState({
            supplyType: (supplyType === SupplyType.Solid) ? SupplyType.Liquid : SupplyType.Solid,
            contentType: (supplyType === SupplyType.Solid) ? LiquidUnit : SolidUnit,
            contentTypeByHa: (supplyType === SupplyType.Solid) ? LiquidUnitBySurface : SolidUnitBySurface,
        });
    }

    onChangeModulationData = (event, index) => {
        const { dosageManuel, distribCompensation, zonesCounter, parcelAreaFund, doseNominal, doseMedium,
            content, contentAvailable, ratios, zones, supplyType, repartition } = this.state;
        const { clientId, parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, providerSrcImageSelectedOfParcel, modulationSelected } = this.props;

        let newValue = event.target.value;

        //On stocke le nom de l'élément ayant perdu le focus:
        this.ElementLastChangedSaved = (event && event.target) ? event.target.name : undefined;
        if ((index !== undefined) && (index >= 0)) {
            this.ElementLastChangedSaved += `_${index}`;
        }

        this.ElementLastChanged = this.ElementLastChangedSaved;

        //console.log(`get change on ${this.ElementLastChanged}`);

        let name = event.target.name;
        let isSwitchChange = ((name === "distributionType") || (name === "doseChange"));
        let newContentAvailable = contentAvailable;
        let newRatios = ratios;
        let newZones = zones; //zones actuelles du State !

            if ((name === "zone") && (newZones !== undefined) && (index < newZones.length)) {
                newZones[index].actualDose = newValue;
                //on pourrai forcer 'newRatios' à undefined, mais ce doit déjà être le cas !
                newZones[index].actualDoseUnit = numberHelper.fixeDecimal(newValue * content / 100);
            }
            /*else if (name === "ratio") { //Ne devrait jamais arriver !
                newRatios[index].ratio = newValue;
                newZones[index].actualDose = undefined;
            }*/
            else if ((name === "zone_unit") && (newZones !== undefined) && (index < newZones.length)) {
                newZones[index].actualDoseUnit = newValue;
                newZones[index].actualDose = numberHelper.fixeDecimal(newValue * (100 / content)); //calcul de la dose en kg ou L /Ha en fonction de la dose en Unité/Ha et de la teneur
            }
            else if (name === 'content') {
                let newContent = (numberHelper.testDataValid(newValue)) ? parseFloat(newValue) : '';
                if (newContent < 0) {
                    newContent = '';
                } else if (newContent > 100) {
                    newContent = 100;
                } else {
                    newContent = newValue;
                }
    
                newContentAvailable = ((numberHelper.testDataValid(newContent)) &&
                    (newContent > 0) && (newContent <= 100)) ? true : false;
                //newValue = newContent; //on laisse 'newValue avec sa valeur siaise même si KO !
    
                //@@A voir s'il faut, à la sortie de la saisie de la teneur, si il faut actualiser
                //les convertions à la volée (des doses) lors de changements de teneur !
            } 

        this.setState({
            [name]: newValue,//Soit c'est l'un des champs qui a changé (dose nominale, teneur)
            contentAvailable: newContentAvailable,
            //oldContent: ..., //ancienne valeur retenue sera celle lors de la sortie de saisie (touche tab ou entrée).
            //Soit il s'agit d'un changement sur l'un des 'switch : alors on inverse la valeur du switch en question 
            // (l'autre reste tel que sauf si sans valeur initiale)
            dosageManuel: (name === "doseChange") ? (!dosageManuel) : dosageManuel,
            distribCompensation: (name === "distributionType") ? (!distribCompensation) : distribCompensation,
            //Soit C'est une valeur de ratio / zone (le changement a été opéré dans l'item qui convient)
            ratios: newRatios,
            zones: newZones,
            errorBuild: undefined,
            openDialogOfErrors: false,
            contentTypeByHa: (supplyType === SupplyType.Liquid) ? LiquidUnitBySurface : SolidUnitBySurface,
        });//@@Voir ne devrait pas être fait qu'une seule fois ! quitte à mettre tout le code si-dessous dans la call back de setstate !
        /* //@@Placeer aussi !
        if (((dosageManuel === false) && (testDataValid(doseNominal)) && (doseNominal > 0)) || //dosage auto + dose nominale valable
            (dosageManuel === true)) { //ou cas dosage manusel
                if (((dosageManuel === false) && (testDataValid(doseNominal)) && (doseNominal > 0)) || //dosage auto + dose nominale valable
                    (dosageManuel === true)) { //ou cas dosage manusel
        */


        //On contrôle s'il s'agit de l'un des switch:
        // on autorise la génération :
        // -si changement de distribution 
        //OU
        // -si on repasse en dosage automatique (donc si on était en manuel => 'dosageManuel' contient encore l'ancienne valeur à ce moment là)
        if (isSwitchChange) {
            const returnToAutomatic = (dosageManuel === true) ? true : false;
            if ((name === "distributionType") || (returnToAutomatic)) {
                this.ElementLastChanged = undefined;

                //@@parseFloat est-il réellement utile ?
                let modulationParameter = {
                    modulationId: (modulationSelected && modulationSelected.id > 0) ? modulationSelected.id : 0,
                    clientId: clientId,
                    parcelId: parcelIdSelected,
                    imagSourceId: satimageIdSelectedOfParcel,
                    dateImagSource: satimageDateSelectedOfParcel,
                    providerImagSource: (modulationSelected) ? modulationSelected.providerImagSource : providerSrcImageSelectedOfParcel,
                    zonesCounter: zonesCounter,
                    //RQ: les mode de distribution et de dosage sont inversé entre l'entité de paramétrage de génération de modulation et ce 'state' !
                    //MAIS ATTENTION, on est dans cette méthode car on a détecté un changement de valeur !  
                    doseChange: (name === "doseChange") ? dosageManuel : (!dosageManuel), //donc si on était en manuel, on passe en auto.
                    distributionType: (name === "distributionType") ? distribCompensation : (!distribCompensation), //donc si on était en compensation, on passe en optimisation.
                    doseNominal: (returnToAutomatic && numberHelper.testDataValid(doseMedium)) ? doseMedium : (
                        (numberHelper.testDataValid(doseNominal)) ? parseFloat(doseNominal) : 1),
                    doseMedium: doseMedium, //pas besoin d'appliquer 'testDataValid' et 'parseFloat' sur ce champs car pas saisie !
                    parcelArea: parcelAreaFund,
                    supplyType: supplyType,
                    content: (contentAvailable === true) ? content : undefined,
                    //content: content, //On ne passe jamais la teneur pour nous éviter les convertions à la volée (des doses) lors de changements de teneur !
                    zones: zones ? zones : [
                        { actualDose: 0, actualDoseUnit: 0, ratio: 0 },
                        { actualDose: 0, actualDoseUnit: 0, ratio: 0 }
                    ],
                    dispersion: repartition / 100,
                };
                
                this.setState({
                    dataNotAvailable: true,
                    errorBuild: undefined,
                    openDialogOfErrors: false,
                }, () => this.props.buildModulationSampleAndDoses(clientId, modulationParameter));
                //Rq: l'appel à 'buildModulationSampleAndDoses' va actualiser 'this.prop.modulationSelected'
            } else { //seul cas possible : on passe du dosage automatique au mode manuel !
                // On affecte les doses reçu de la génération automatique sur les valeurs 'initiales qui seront utilisées (si besoin de générer).
                const { modulationSelected } = this.props;
                let newModulationSelected = { ...modulationSelected };

                newModulationSelected.doseMedium = (numberHelper.testDataValid(doseNominal)) ? parseFloat(doseNominal) : 0;
               
                if (newModulationSelected.doseMedium === 0) {
                    if (newModulationSelected.zones) {
                        newModulationSelected.zones.forEach((zone) => {
                            zone.actualDose = 0;
                            zone.actualDoseUnit = 0;
                        });
                    }
                } 

                newModulationSelected.doseChange = dosageManuel; //donc si on était en auto, on passe en manuel.
                //Rq: 'distributionType' n'est pas inpacté ! uniquement 'doseChange'

                // si on change de dosage automatique à manuel ET que la liste des zones n'existe pas => on doit les créer !
                if ((!newZones) || (newZones.length <= 0) || (numberHelper.testDataValid(newZones[0]) !== true) || (newZones[0].ratio === 0)) {
                    newRatios = undefined;

                    if (modulationSelected && modulationSelected.ratios) {
                        newZones = [];
                        modulationSelected.ratios.forEach((ratioItem, index) => newZones.push(
                            {
                                clientId: ratioItem.clientId,
                                color: ratioItem.color,
                                id: ratioItem.id,
                                modulationId: ratioItem.modulationId,
                                parcelId: ratioItem.parcelId,
                                ratio: ratioItem.ratio,
                                actualDose: 0,
                                actualDoseUnit: 0,
                                zoneNumber: ratioItem.zoneNumber,
                            }
                        ));
                    } else {
                        newZones = [
                            { actualDose: 0, actualDoseUnit: 0, ratio: 0 },
                            { actualDose: 0, actualDoseUnit: 0, ratio: 0 }
                        ];
                    }

                    newModulationSelected.ratios = newRatios; // = undefined
                    newModulationSelected.zones = newZones;
                }

                newModulationSelected.supplyType = supplyType;

                this.props.selectModulation(newModulationSelected); //ce qui actualisera le state de ce composant !
            }
        }
    }

    /* fonction d'ajout ou de suppression de zones lors des clics sur les boutons +, - */
    addOrRemoveZones = (addOrRemove) => {
        const { clientId, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, providerSrcImageSelectedOfParcel, parcelIdSelected, modulationSelected } = this.props;
        const { zonesCounter, parcelAreaFund, dosageManuel, distribCompensation,
            doseNominal, doseMedium, content, supplyType, contentAvailable, zones, repartition } = this.state;

        let newZonesCounter = zonesCounter;
        let zonesCounterInitial = zonesCounter;

        let newZones = zones;
        if (addOrRemove === "remove") {
            if (zonesCounter >= 3) {
                newZonesCounter = zonesCounter - 1;
                //@@il faut réduire le nombre de zones si mode manuel. Attention cas d'ennuagement !
            }
        }
        else {
            if (zonesCounter < 6) {
                newZonesCounter = zonesCounter + 1;

                //si on est en dosage manuel et que l'on a la liste des doses, il faut ajouter celle 'vide'
                // RQ: Avant celle de la zone ennuagée (si présente).
                if (dosageManuel === true) {
                    if (newZones) {
                        if (newZones.length === newZonesCounter) { //contient la zone d'ennuagement
                            newZones[(newZonesCounter - 1)].actualDose = 0;
                            newZones[(newZonesCounter - 1)].actualDoseUnit = 0;
                            newZones[(newZonesCounter - 1)].ratio = 0;
                        } else {
                            newZones.push({ id: 99, color: 0xffffffff, actualDose: 0, actualDoseUnit: 0, ratio: 0 });
                        }
                    } else {
                        newZones = [];
                        for (let indexI = 0; indexI < newZonesCounter; indexI++) {
                            newZones.push({ id: 99, color: 0xffffffff, actualDose: 0, actualDoseUnit: 0, ratio: 0 });
                        }
                    }
                }
            }
        }

        if (newZonesCounter !== zonesCounterInitial) {
            let modulationParameter = {
                modulationId: (modulationSelected && modulationSelected.id > 0) ? modulationSelected.id : 0,
                clientId: clientId,
                parcelId: parcelIdSelected,
                imagSourceId: satimageIdSelectedOfParcel,
                dateImagSource: satimageDateSelectedOfParcel,
                providerImagSource: (modulationSelected) ? modulationSelected.providerImagSource : providerSrcImageSelectedOfParcel,
                zonesCounter: newZonesCounter,
                //RQ: les mode de distribution et de dosage sont inversé entre l'entité de paramétrage de génération de modulation et ce 'state' !
                doseChange: (!dosageManuel),
                distributionType: (!distribCompensation),
                doseNominal: (numberHelper.testDataValid(doseNominal)) ? parseFloat(doseNominal) : 0,
                doseMedium: doseMedium, //pas besoin d'appliquer 'testDataValid' et 'parseFloat' sur ce champs car pas saisie !
                parcelArea: parcelAreaFund,
                supplyType: supplyType,
                content: (contentAvailable === true) ? content : undefined,
                zones: newZones ? newZones : [
                    { actualDose: 0, actualDoseUnit: 0, ratio: 0 },
                    { actualDose: 0, actualDoseUnit: 0, ratio: 0 }
                ],
                dispersion: repartition / 100,
            };

            this.setState({
                dataNotAvailable: true,
                zones: newZones,
                errorBuild: undefined,
                openDialogOfErrors: false,
            }, () => this.props.buildModulationSampleAndDoses(clientId, modulationParameter));
            //Rq: l'appel à 'buildModulationSampleAndDoses' va actualiser 'this.prop.modulationSelected'
        }
    }

    /* Ferme le dialog des messages d'erreur */
    closeDialogOfErrors = () => {
        this.setState({
            openDialogOfErrors: false,
        });

        //Supprime l'erreur du state Redux !
        if (this.props.cleanErrorModulation) {
            this.props.cleanErrorModulation();
        }
    }

    /**
     * Modifie la valeur de repartition pour une coherence visuelle
     * @param {Number} value 
     */
    handleRepartitionChange = (value) => {
        this.setState({ repartition: value });
    }

    /**
     * Permet de changer la répartition de la dose et de rafraichir la modulation seulement après que l'utilisateur ait relaché le bouton de la souris
     * @param {Number} value valeur de la répartition
     */
    handleRepartitionChangeCommitted = (value) => {
        if (value === this.previousRepartition) return;

        this.previousRepartition = value;

        // L'event du Slider étant un peu différent, on doit le transformer en un event classique
        const repartitionEvent = {
            target: {
                name: 'repartition',
                value: value
            }
        };

        //On stocke le nom de l'élément ayant perdu le focus:
        this.ElementLastChangedSaved = 'repartition';
        this.ElementLastChanged = 'repartition';
        this.onBlurChange(repartitionEvent);
    }

    render() {
        const { readableMode, downloadWaiting, showProfilMenuDialog, parcelIdsSelected,
            modulationSelected /*, satimageDateSelectedOfParcel*/, parcelDico, parcelIdSelected } = this.props; //besoin de 'modulationSelected' JUSTE pour l'image !
        const { dataNotAvailable, doseNominal, doseMedium, content, hasCloudShadow,
            zonesCounter, maxZonesCount, parcelAreaFund, contentType, contentTypeByHa, oldContent, distribCompensation, dosageManuel, supplyType,
            zones, ratios, errorBuild, openDialogOfErrors, quantityToExpand, rememberModulationSettings,
            openConfirmDownloadModulationDialog, showFirstStepForDownloadingModulations, typeDownloadChoice, contentFrameRed, disabledBtnRemoveZone, repartition } = this.state;

        const enabledDownload = ((dosageManuel === true) ? (numberHelper.testDataValid(doseMedium)) : (numberHelper.testDataValid(doseNominal))) &&
            ( (errorBuild === undefined) || (errorBuild === '') || (errorBuild.includes(StringTranslate.unvalidZonesFromBuild.slice(0, 25))) );

        const disableSaveModulation = (((dosageManuel === true) && (doseMedium <= 0)) || ((dosageManuel !== true) && (doseNominal <= 0))) ? true : false;

        let quantityToExpandForReadableModeUnit = 0;
        let quantityToExpandForReadableMode = 0;
        /*if (zones && (readableMode !== undefined) && (readableMode === true)) {
            zones.forEach(zoneItem => {
                quantityToExpandForReadableMode += (zoneItem.ratio * parcelAreaFund * (
                    (numberHelper.testDataValid(zoneItem.actualDose) && (zoneItem.actualDose > 0)) ? zoneItem.actualDose : 0));
            });
        }
        else if (zones && (rememberModulationSettings !== undefined) && (rememberModulationSettings === true)) {
            zones.forEach(zoneItem => {
                quantityToExpandForReadableMode += (zoneItem.ratio * parcelAreaFund * (
                    (numberHelper.testDataValid(zoneItem.actualDose) && (zoneItem.actualDose > 0)) ? zoneItem.actualDose : 0));
            });
        }//else // 'quantityToExpandForReadableMode' reste à zéro ! */
        if (zones) { //@@ ?? est-ce nécessaire puisqu'il y a la props de state local 'quantityToExpand' ??
            zones.forEach(zoneItem => {
                quantityToExpandForReadableMode += (zoneItem.ratio * parcelAreaFund * (
                    (numberHelper.testDataValid(zoneItem.actualDose) && (zoneItem.actualDose > 0)) ? zoneItem.actualDose : 0));
            });
        }
        quantityToExpandForReadableModeUnit = quantityToExpandForReadableMode;
        if (numberHelper.testDataValid(oldContent) && (oldContent > 0) && (oldContent <= 100) &&
            (quantityToExpandForReadableMode > 0)) { //si la teneur est définie et valide, on transforme la quantité exprimée de 'Kg|L / Ha' en 'U / Ha' :
            quantityToExpandForReadableModeUnit = numberHelper.fixeDecimal((quantityToExpandForReadableMode * ((oldContent <= 0) ? 100 : oldContent)) / 100);
        }

        //Rq: La gestion de la teneur est déjà géré dans les valeur de zones !

        const thisParcelIdSelected = (modulationSelected !== undefined) ? modulationSelected.parcelId : parcelIdSelected;
        const nativeAppHoster = IsNativeHoster();

        const maxZonesCounter = (maxZonesCount > 0) ? maxZonesCount: defaultMaxZoneNbr;

        return (
            <>
                {/* Dialog des messages d'erreur */}
                {(errorBuild && (errorBuild !== '')) && (openDialogOfErrors === true) && (
                    <AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
                )}

                {/* ↓↓ Partie visuel - affichage Popup pour le téléchargement des modulations ↓↓ */}
                <Dialog open={openConfirmDownloadModulationDialog}
                    onClose={this.handleCloseConfirmDialogForDownloading}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{StringTranslate.ColumnMdlToDownLoad}</DialogTitle>
                    {(showFirstStepForDownloadingModulations === false) ?
                        <>
                            <DialogContent>
                                {/* Choix entre l'IsoXml, le RDS et le shp */}
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Button 
                                            variant="text" color="secondary" 
                                            onClick={() => showProfilMenuDialog(ProfilIndex.aide_ModulationFormatFichier)}
                                            sx={{mb:2}}
                                        >
                                            {StringTranslate.helpAsk}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{StringTranslate.dialogSubtitleTypeShape1}</FormLabel>
                                            <RadioGroup aria-label="gender" name="gender1" value={typeDownloadChoice} onChange={this.handleChangeTypeDownloadChoice}>
                                                <FormControlLabel value={TypeDownloadChoice.shp} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceShp} />
                                                <FormControlLabel value={TypeDownloadChoice.isoXml} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceIsoXml} />
                                                <FormControlLabel value={TypeDownloadChoice.rds} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceRds} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            {StringTranslate.dialogSubtitleTypeShape2}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseConfirmDialogForDownloading} variant="text" color="error" autoFocus>
                                    {StringTranslate.cancelDeleteAction}
                                </Button>
                                <Button onClick={this.onConfirmDownloadModulations} variant="contained" color="primary">
                                    {StringTranslate.confirmDeleteAction}
                                </Button>
                            </DialogActions>
                        </> 
                        :
                        <>
                            <DialogContent>
                                {/* Attention, 2 types d'apports différents, on affiche l'étape suivante */}
                                <Typography>{StringTranslate.twoSupplyTypesSelected}</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.onGoToNextStepToDownload} variant="outlined" color="primary">
                                    {StringTranslate.continueStep1}
                                </Button>
                                <Button onClick={this.handleCloseConfirmDialogForDownloading} variant="contained" color="primary" autoFocus>
                                    {StringTranslate.goBackToModulationTable}
                                </Button>
                            </DialogActions>
                        </>
                    }
                </Dialog>

                { /* Barre de progression */}
                {(dataNotAvailable === true) ? <LinearProgress /> : null}

                <MainCard>

                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6} lg={4}>
                            {/* Map modulation */}
                            <SubCard title={<Typography sx={{fontWeight:"bold"}}>{StringTranslate.subTitleDefinitionZones}</Typography>}>
                                <Grid container rowSpacing={2} className={(dataNotAvailable === true) ? "disabledAll" : null}>

                                    <Grid item xs={12}>
                                        { /* on affiche la date du cliché */}
                                        {modulationSelected &&
                                            (<Typography variant="h5" sx={{ color: theme.palette.text.primary, pt: 2 }}>
                                                {`${StringTranslate.dateImag} : ${format(modulationSelected.dateImagSource, StringTranslate.formatDate)}`}
                                            </Typography>)}

                                        { /* on affiche le nom de la parcelle */}
                                        <Typography variant="subtitle1" sx={{pt : 2}}>
                                            {ParcelsHelper.getParcelNamefromIdParcel(parcelDico, thisParcelIdSelected)}
                                        </Typography>
                                    </Grid>
                                    {((maxZonesCount > 0 && maxZonesCount < 6) && (zonesCounter === maxZonesCount)) &&
                                        <Grid item xs={12}>
                                            <Card>
                                                <Alert
                                                    severity="warning"
                                                    icon={<InfoOutlined />}
                                                    sx={{backgroundColor: theme.palette.warning.light}}
                                                >
                                                    <Typography>{StringTranslate.badNumberOfAreas}</Typography>
                                                </Alert>
                                            </Card>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Grid item container spacing={1} xs={12} sx={{alignItems:"center"}}>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{`${StringTranslate.nbZones}`}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ButtonGroup size="large" variant="text" color="inherit" sx={{ border: '1px solid', borderColor: 'grey.400' }}>
                                                    <Button
                                                        key="three"
                                                        onClick={() => this.addOrRemoveZones("remove")}
                                                        disabled={disabledBtnRemoveZone || (readableMode === true)}
                                                        sx={{ border: 'none !important', minWidth: '0px !important' }}
                                                    >
                                                        <Remove color={disabledBtnRemoveZone ? theme.palette.grey[200] : "primary"} fontSize="inherit" />
                                                    </Button>
                                                    <CustomTextField key="two"
                                                        id="modulation_nb_zones"
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                max: maxZonesCounter, min: 2,
                                                                style: { textAlign: "center" },
                                                            }
                                                        }}
                                                        value={zonesCounter}
                                                        name="zonesCounter"
                                                    />
                                                    {/*   {zonesCounter} */}
                                                    <Button
                                                        key="one"
                                                        disabled={this.state.disabledBtnAddZone || (readableMode === true)}
                                                        onClick={() => this.addOrRemoveZones("add")}
                                                        sx={{ minWidth: '0px !important' }}>
                                                        <Add color={(disabledBtnRemoveZone || (zonesCounter >= maxZonesCounter)) ? theme.palette.grey[200] : "primary"} fontSize="inherit" />
                                                    </Button>
                                                </ButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    { /* on affiche la carte */}
                                    {(modulationSelected) && (
                                        <Grid item xs={12}>
                                            {(modulationSelected.dataImg) ? (
                                                <img id='imgDataM' src={`data:image/jpeg;base64,${modulationSelected.dataImg}`}
                                                    alt={StringTranslate.altImgModulation} className="image-doses-modulation" />
                                            ) : (
                                                <img id='imgUrlM' src={modulationSelected.azureImagURL}
                                                    alt={StringTranslate.altImgModulation} className="image-doses-modulation" />
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                            </SubCard>
                        </Grid>

                        {/* Zones de modulation */}
                        <Grid item xs={12} md={6} lg={4}>
                            <SubCard title={<Typography sx={{fontWeight:"bold"}}>{StringTranslate.subTitleDoses}</Typography>}>
                                {((dataNotAvailable === undefined) || (dataNotAvailable === false) || modulationSelected) ?
                                    <Grid container rowSpacing={2} className={(dataNotAvailable === true) ? "disabledAll" : null}>
                                        { /* liquide ou solide */}
                                        <Grid item xs={12}>
                                            <InputLabel sx={{color: theme.palette.text.primary}}>{StringTranslate.supplyType}</InputLabel>
                                            <Grid item xs={12}>
                                                {/* supplyType === SupplyType.Liquid a true => StringTranslate.supplyTypeSolid, false => StringTranslate.supplyTypeLiquid */}
                                                <Stack 
                                                    direction="row"
                                                    sx={{
                                                        mt: 1,
                                                        mb: 2,
                                                        ml: "3px"
                                                    }}
                                                >
                                                    <ButtonGroup disableElevation variant='contained'>
                                                        <Button 
                                                            size='medium'
                                                            name="supplyType"
                                                            onClick={(value) => this.onChangeModulationSupply(value)}
                                                            sx={{
                                                                bgcolor: theme.palette.primary.main,
                                                                color: theme.palette.common.white,
                                                                opacity: "0.4",
                                                                '&:disabled': {
                                                                    bgcolor: (readableMode === true) ? 
                                                                        ( !(supplyType === SupplyType.Liquid) ? theme.palette.grey[500] : theme.palette.grey[200]) 
                                                                        : 
                                                                        theme.palette.primary.main,
                                                                    color: (readableMode === true) ? 
                                                                        ( !(supplyType === SupplyType.Liquid) ? theme.palette.common.white : theme.palette.grey[500]) 
                                                                        : 
                                                                        theme.palette.common.white,
                                                                    opacity: "1"
                                                                }
                                                            }}
                                                            disabled={(!(supplyType === SupplyType.Liquid)) || (readableMode === true) }
                                                        >
                                                            {StringTranslate.supplyTypeSolid}
                                                        </Button>
                                                        <Button 
                                                            size='medium'
                                                            name="supplyType"
                                                            onClick={(value) => this.onChangeModulationSupply(value)}
                                                            sx={{
                                                                bgcolor: theme.palette.primary.main,
                                                                color: theme.palette.common.white,
                                                                opacity: "0.4",
                                                                '&:disabled': {
                                                                    bgcolor: (readableMode === true) ? 
                                                                        ( (supplyType === SupplyType.Liquid) ? theme.palette.grey[500] : theme.palette.grey[200]) 
                                                                        : 
                                                                        theme.palette.primary.main,
                                                                    color: (readableMode === true) ? 
                                                                        ( (supplyType === SupplyType.Liquid) ? theme.palette.common.white : theme.palette.grey[500]) 
                                                                        : 
                                                                        theme.palette.common.white,
                                                                    opacity: "1"
                                                                }
                                                            }}
                                                            disabled={(supplyType === SupplyType.Liquid) || (readableMode === true) }
                                                        >
                                                            {StringTranslate.supplyTypeLiquid}
                                                        </Button>
                                                    </ButtonGroup>
                                                </Stack>
                                            </Grid>
                                        </Grid>

                                        { /* dose medium */}
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs>
                                                    {/* RQ: même si on a définie une teneur, la dose moyenne reçue ne la prend pas en compte */}
                                                    {(dosageManuel === false) ?
                                                        <TextField
                                                            type="number"
                                                            id="dose_medium"                                                          
                                                            error={doseNominal <= 0 ? true : false}
                                                            helperText={(doseNominal <= 0) ? 'Saisie une valeur supèrieur à 0 ' : undefined}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">{AzoteUnitBySurface}</InputAdornment>,
                                                            }}
                                                            label={`${StringTranslate.mediumDose}`}
                                                            variant="outlined"
                                                            sx={{
                                                                marginRight: "3px",
                                                                marginBottom: "10px",
                                                                marginLeft: "3px",
                                                                borderRadius: "6px"
                                                            }}
                                                            placeholder={`${StringTranslate.mediumDose}`}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={(readableMode === true)}
                                                            value={doseNominal}
                                                            name="doseNominal"
                                                            onBlur={(value) => this.onBlurChange(value)}
                                                            onChange={(value) => this.onChangeModulationData(value)}
                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    let value = event.target.value;
                                                                    this.onBlurChange(value);
                                                                }
                                                            }}
                                                            autoFocus={((isMobile === true) || (nativeAppHoster === true)) ? false : true} tabIndex={0}
                                                            ref="mediumDose"
                                                        />
                                                        :
                                                        <Typography>{`${StringTranslate.mediumDose} : ${numberHelper.fixeDecimal(doseMedium)} ${AzoteUnitBySurface}`}</Typography> 
                                                    }
                                                </Grid>

                                                <Grid item xs >
                                                    { /* content */}
                                                    <TextField
                                                        type="number"
                                                        id="content"
                                                        error={contentFrameRed}
                                                        helperText={(contentFrameRed) ? 'Saisie une valeur entre 0 et 100.' : undefined}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                            inputProps: {
                                                                max: 100, min: 0, step: 0.01,
                                                            }
                                                        }}
                                                        label={`${StringTranslate.content}`}
                                                        placeholder={`${StringTranslate.content}`}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        variant="outlined"
                                                        sx={{
                                                            margin: "0px 3px 10px 3px",
                                                            fontSize: "0.9rem",
                                                            paddingRight: "5px"
                                                        }}
                                                        disabled={(readableMode === true)}
                                                        value={content}
                                                        name="content"
                                                        onChange={(value) => this.onChangeModulationData(value)}
                                                        onBlur={(value) => this.onBlurChange(value)}
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Enter') {
                                                                let value = event.target.value;
                                                                this.onBlurChange(value);
                                                            }
                                                        }}
                                                        tabIndex={1}
                                                        ref="content" />


                                                    { /* aide pour l'utilisateur pour renseigner les doses */}
                                                    <Tooltip title={`${StringTranslate.helpContent}`} placement="bottom">
                                                        <Help sx={{ mt: 1.5 }} />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        { /* Tableau des doses */}
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table
                                                    sx={{
                                                        maxWidth: "650px"
                                                    }}
                                                    aria-label="caption table"
                                                    size="small"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell>{`${StringTranslate.area} (Ha)`}</TableCell>
                                                            <TableCell>{<><div>{StringTranslate.doseNitrogen}</div> ({AzoteUnitBySurface})</>}</TableCell>
                                                            <TableCell>{<><div>{StringTranslate.doseInputs}</div> ({contentTypeByHa})</>}</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    {(ratios !== undefined) ?
                                                        <TableBody>
                                                            {ratios.map((row, index) => (
                                                                <TableRow key={row.id} className="table-row-modulation">
                                                                    <TableCell style={{ background: getColor(row.color), width: '5%' }} />
                                                                    <TableCell className="cell-ratio">
                                                                        <Typography>{numberHelper.fixeDecimal(row.ratio * parcelAreaFund)}</Typography>
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '30%' }}>
                                                                        {/* on laisse vide ! */}
                                                                        <Typography>---</Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {/* on laisse vide ! */}
                                                                        <Typography>---</Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                        :
                                                        <TableBody>
                                                            {(zones) && zones.map((row, index) => (
                                                                <TableRow key={row.id} className="table-row-modulation">
                                                                    <TableCell style={{ background: getColor(row.color), width: '5%' }} />
                                                                    <TableCell className="cell-zone">
                                                                        <Typography>{numberHelper.fixeDecimal(row.ratio * parcelAreaFund)}</Typography>
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '30%' }}>
                                                                        {/* Dose actuelle sans la teneur */}
                                                                        {(dosageManuel !== true) ?
                                                                            <Typography>{/*numberHelper.fixeDecimal(row.actualDose * (oldContent <= 0 ? 100 : oldContent) / 100) */ row.actualDoseUnit}</Typography>
                                                                            :
                                                                            <OutlinedInput
                                                                                className="actualDose-input"
                                                                                type="number"
                                                                                id={"hectare_unit_" + index}
                                                                                value={row.actualDoseUnit}
                                                                                aria-describedby="outlined-weight-helper-text"
                                                                                inputProps={{
                                                                                    'aria-label': 'weight',
                                                                                }}
                                                                                labelwidth={0}
                                                                                size="small"
                                                                                disabled={(readableMode === true)}
                                                                                name={"zone_unit"}
                                                                                onBlur={(value) => this.onBlurChange(value, index)}
                                                                                onChange={(value) => this.onChangeModulationData(value, index)}
                                                                                onKeyDown={(event) => {
                                                                                    if (event.key === 'Enter') {
                                                                                        let value = event.target.value;
                                                                                        this.onBlurChange(value, index);
                                                                                    }
                                                                                }}
                                                                                error={(doseMedium <= 0) ? true : false}
                                                                                tabIndex={2 + index}
                                                                                ref={"zone_unit_" + index}
                                                                            />
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {/* RQ: même si on a renseigné une teneur, les doses reçues neprennent en compte la teneur */}
                                                                        {/* => on les affichage suivant la présence ou non de la teneur ! MAIS on stocke leur changement en Unité d'azote (Cf. 'onChangeModulationData') ! */}
                                                                        {/* on ne met plus l'unité dans les saisies des doses : endAdornment={<InputAdornment position="end">{contentTypeByHa}</InputAdornment>} */}
                                                                        {(dosageManuel !== true) ?
                                                                            <Typography>{row.actualDose}</Typography>
                                                                            :
                                                                            <OutlinedInput
                                                                                className="actualDose-input"
                                                                                type="number"
                                                                                id={"hectare_" + index}
                                                                                value={row.actualDose}
                                                                                aria-describedby="outlined-weight-helper-text"
                                                                                inputProps={{
                                                                                    'aria-label': 'weight',
                                                                                }}
                                                                                labelwidth={0}
                                                                                size="small"
                                                                                disabled={(readableMode === true)}
                                                                                name={"zone"}
                                                                                onBlur={(value) => this.onBlurChange(value, index)}
                                                                                onChange={(value) => this.onChangeModulationData(value, index)}
                                                                                onKeyDown={(event) => {
                                                                                    if (event.key === 'Enter') {
                                                                                        let value = event.target.value;
                                                                                        this.onBlurChange(value, index);
                                                                                    }
                                                                                }}
                                                                                error={(doseMedium <= 0) ? true : false}
                                                                                tabIndex={2 + index}
                                                                                ref={"zone_" + index}
                                                                            />
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}                                                     
                                                        </TableBody>
                                                    }

                                                    <TableFooter>
                                                        { /* Aide pour les zones de modulation */}
                                                        {(hasCloudShadow === true) ?
                                                            <TableRow>
                                                                <TableCell colSpan={4}>
                                                                    <Tooltip
                                                                        title={`${StringTranslate.helpCloudZones}`}
                                                                        placement="bottom">
                                                                        <Help sx={{ verticalAlign: "middle", pl: 1, color: theme.palette.grey[700]}} />
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow> : null
                                                        }
                                                        <TableRow>
                                                            <TableCell colSpan={2}>
                                                                <Typography>{`${StringTranslate.quantityToExpand}`}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>
                                                                    {`${numberHelper.fixeDecimal((readableMode === true) ?
                                                                        quantityToExpandForReadableModeUnit
                                                                        : (quantityToExpand * ((oldContent <= 0) ? 100 : oldContent)) / 100
                                                                    )} ${AzoteUnit}`}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>
                                                                    {`${numberHelper.fixeDecimal((readableMode === true) ?
                                                                        quantityToExpandForReadableMode
                                                                        : quantityToExpand
                                                                    )} ${contentType}`}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                            {(disableSaveModulation === true && doseMedium <= 0) ? <Typography sx={{ color: theme.palette.error.main, mt: 2 }}>{StringTranslate.errorDoseNominalZero}</Typography> : undefined}
                                        </Grid>
                                    </Grid> : null}
                            </SubCard>
                        </Grid>

                        { /* Settings */}
                        <Grid item xs={12} md={12} lg={4}>

                            <SubCard title={<Typography sx={{fontWeight:"bold"}}>{StringTranslate.subTitleSettings}</Typography>}>
                                <Grid container rowSpacing={2} className={(dataNotAvailable === true) ? "disabledAll" : null}>

                                    <Grid item xs={12}>
                                        <InputLabel sx={{color: theme.palette.text.primary}}>
                                            {`${StringTranslate.distributionType}`}
                                            <Tooltip
                                                title={`${StringTranslate.helpOptimization}`}
                                                placement="bottom"
                                            >
                                                <Help sx={{ verticalAlign: "middle", pl: 1 }} />
                                            </Tooltip>
                                        </InputLabel>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {/* distribCompensation a true => Optimisation, false => Compensation */}
                                        <Stack direction="row">
                                            <ButtonGroup disableElevation variant='contained'>
                                                <Button 
                                                    size='medium'
                                                    name="distributionType"
                                                    onClick={(value) => this.onChangeModulationData(value)}
                                                    sx={{
                                                        bgcolor: theme.palette.primary.main,
                                                        color: theme.palette.common.white,
                                                        opacity: "0.4",
                                                        '&:disabled': {
                                                            bgcolor: (readableMode === true) ? 
                                                                ( !distribCompensation ? theme.palette.grey[500] : theme.palette.grey[200]) 
                                                                : 
                                                                theme.palette.primary.main,
                                                            color: (readableMode === true) ? 
                                                                ( !distribCompensation ? theme.palette.common.white : theme.palette.grey[500]) 
                                                                : 
                                                                theme.palette.common.white,
                                                            opacity: "1"
                                                        }
                                                    }}
                                                    disabled={(!distribCompensation) || (readableMode === true) }
                                                >
                                                    {StringTranslate.optimization}
                                                </Button>
                                                <Button 
                                                    size='medium'
                                                    name="distributionType"
                                                    onClick={(value) => this.onChangeModulationData(value)}
                                                    sx={{
                                                        bgcolor: theme.palette.primary.main,
                                                        color: theme.palette.common.white,
                                                        opacity: "0.4",
                                                        '&:disabled': {
                                                            bgcolor: (readableMode === true) ? 
                                                                ( distribCompensation ? theme.palette.grey[500] : theme.palette.grey[200]) 
                                                                : 
                                                                theme.palette.primary.main,
                                                            color: (readableMode === true) ? 
                                                                ( distribCompensation ? theme.palette.common.white : theme.palette.grey[500]) 
                                                                : 
                                                                theme.palette.common.white,
                                                            opacity: "1"
                                                        }
                                                    }}
                                                    disabled={distribCompensation || (readableMode === true) }
                                                >
                                                    {StringTranslate.compensation}
                                                </Button>
                                            </ButtonGroup>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel sx={{color: theme.palette.text.primary}}>
                                            {`${StringTranslate.doseChange}`}
                                            <Tooltip title={`${StringTranslate.helpDoseChange}`} placement="bottom">
                                                <Help sx={{ verticalAlign: "middle", pl: 1 }} />
                                            </Tooltip>
                                        </InputLabel>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {/* dosageManuel a true => Automatique, false => Manuel */}
                                        <Stack direction="row">
                                            <ButtonGroup disableElevation variant='contained'>
                                                <Button 
                                                    size='medium'
                                                    name="doseChange"
                                                    onClick={(value) => this.onChangeModulationData(value)}
                                                    sx={{
                                                        bgcolor: theme.palette.primary.main,
                                                        color: theme.palette.common.white,
                                                        opacity: "0.4",
                                                        '&:disabled': {
                                                            bgcolor: (readableMode === true) ? 
                                                                ( !dosageManuel ? theme.palette.grey[500] : theme.palette.grey[200]) 
                                                                : 
                                                                theme.palette.primary.main,
                                                            color: (readableMode === true) ? 
                                                                ( !dosageManuel ? theme.palette.common.white : theme.palette.grey[500]) 
                                                                : 
                                                                theme.palette.common.white,
                                                            opacity: "1"
                                                        }
                                                    }}
                                                    disabled={(!dosageManuel) || (readableMode === true) }
                                                >
                                                    {StringTranslate.automaticDoses}
                                                </Button>
                                                <Button 
                                                    size='medium'
                                                    name="doseChange"
                                                    onClick={(value) => this.onChangeModulationData(value)}
                                                    sx={{
                                                        bgcolor: theme.palette.primary.main,
                                                        color: theme.palette.common.white,
                                                        opacity: "0.4",
                                                        '&:disabled': {
                                                            bgcolor: (readableMode === true) ? 
                                                                ( dosageManuel ? theme.palette.grey[500] : theme.palette.grey[200]) 
                                                                : 
                                                                theme.palette.primary.main,
                                                            color: (readableMode === true) ? 
                                                                ( dosageManuel ? theme.palette.common.white : theme.palette.grey[500]) 
                                                                : 
                                                                theme.palette.common.white,
                                                            opacity: "1",
                                                        }
                                                    }}
                                                    disabled={dosageManuel || (readableMode === true) }
                                                >
                                                    {StringTranslate.manuelDoses}
                                                </Button>
                                            </ButtonGroup>
                                        </Stack>
                                    </Grid>

                                    {(repartition !== null) && (
                                        <>
                                            <Grid item xs={12}>
                                                <Stack direction={'row'}>
                                                    <InputLabel sx={{color: theme.palette.text.primary}}>
                                                        {StringTranslate.repartitionLabel}
                                                    </InputLabel>
                                                    <Tooltip title={StringTranslate.repartitionTip} placement="bottom">
                                                        <Help sx={{ verticalAlign: "middle", pl: 1 }} />
                                                    </Tooltip>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={12} p={2}>
                                                {(!readableMode) ? (
                                                    <Stack sx={{ mt: 2 }} spacing={1} direction={'row'} alignItems={'center'}>
                                                        <Typography sx={{ transform: 'translateX(-20%)' }} variant={'body2'}>5%</Typography>
                                                            <Slider
                                                                aria-label={StringTranslate.repartitionTip}
                                                                min={5}
                                                                max={50}
                                                                step={5}
                                                                valueLabelDisplay="on"
                                                                valueLabelFormat={(value) => `${value}%`}
                                                                marks={[
                                                                    {
                                                                        value: 5,
                                                                        label: StringTranslate.lowerRepartitionLabel,
                                                                    },
                                                                    {
                                                                        value: 50,
                                                                        label: StringTranslate.higherRepartitionLabel,
                                                                    }
                                                                ]}
                                                                value={repartition}
                                                                onChange={(_, value) => this.handleRepartitionChange(value)}
                                                                onChangeCommitted={(_, value) => this.handleRepartitionChangeCommitted(value)}
                                                                disabled={dosageManuel}
                                                            />
                                                        <Typography sx={{ transform: 'translateX(20%)' }} variant={'body2'}>50%</Typography>
                                                    </Stack>
                                                ) : (
                                                    <Typography>{`${repartition}%`}</Typography>
                                                )}
                                            </Grid>
                                        </>
                                    )}

                                    {!readableMode && (
                                        <Grid item xs={12}>
                                        { /* Checkbox : se souvenir des données saisies */}
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={rememberModulationSettings}
                                                        onChange={(evt, value) => this.handleRememberParameters(evt, value)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }} 
                                                    />
                                                }
                                                label={`${StringTranslate.rememberModulationSettings}`}
                                                labelPlacement="end"
                                            />
                                        </Grid>
                                    )}

                                </Grid>

                            </SubCard>

                        </Grid>

                    </Grid>

                    <CardActions>

                        <Grid container spacing={2}>

                            { /* Boutons de téléchargement */}
                            <Grid item xs={12}>
                                {(readableMode === true) && ((isMobile !== true) && (nativeAppHoster !== true)) && (//En mode mobile, on ne permet pas le téléchargement pour l'instant.
                                    <Button variant="contained" color="primary"
                                        onClick={() => this.onOnlyDownLoadModulationClick()}
                                        disabled={disableSaveModulation || !enabledDownload} tabIndex={11}
                                        startIcon={<SaveAlt />}>
                                        {`${StringTranslate.downloadModulation}`}
                                    </Button>
                                )}

                                {(readableMode === false) && ((isMobile !== true) && (nativeAppHoster !== true)) && (parcelIdsSelected.length <= 1) && (//En mode mobile, on ne permet pas le téléchargement pour l'instant.
                                    <Button variant="contained" color="primary"
                                        onClick={() => this.onBuildAllPrescriptionsClick()}
                                        disabled={disableSaveModulation || !enabledDownload} tabIndex={11}
                                        startIcon={<SaveAlt />}>
                                        {`${StringTranslate.downloadModulation}`}
                                    </Button>
                                )}

                                {(downloadWaiting) &&
                                    (<CircularProgress
                                        color="inherit"
                                        fontSize='small'
                                        sx={{
                                            '& root': {
                                                maxWidth: "15px",
                                                maxHeight: "15px"
                                            }
                                        }}
                                    />)}
                            </Grid>

                            { /* Bouton de retour */}
                            <Grid item xs={4}>
                                <Button
                                    onClick={() => (readableMode !== true) ? this.onGoBackClick() : this.onGoBackToModulations()}
                                    tabIndex={12}
                                    startIcon={<ArrowBack />}
                                    sx={{
                                        height: '100%'
                                    }}>
                                    {`${StringTranslate.goBackStepModulation}`}
                                </Button>
                            </Grid>

                            <Grid item xs={8}>
                                <Stack direction="row" spacing={1} justifyContent="flex-end">
                                    { /* Bouton annulation */}
                                    {(parcelIdsSelected.length > 1) && (readableMode !== true) && (
                                        <Button
                                            color="error"
                                            varient="text"
                                            onClick={() => this.onCancelCurrentModulation()}
                                            sx={{
                                                height: '100%'
                                            }}>
                                            {StringTranslate.cancelModulationAndGoNext}
                                        </Button>)}

                                    { /* Bouton d'enregistrement */}
                                    {(readableMode !== true) && (
                                        <Button variant="contained" color="primary"
                                            onClick={() => this.onSaveModulationClick()}
                                            disabled={(disableSaveModulation || !enabledDownload)} tabIndex={10}
                                            startIcon={<FileCopy />}>
                                            {(parcelIdsSelected.length <= 1) ? `${StringTranslate.saveModulation}` : `${StringTranslate.saveModulationAndGoNext}`}
                                        </Button>)}
                                </Stack>
                            </Grid>

                        </Grid>

                    </CardActions>

                </MainCard>
            </>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'modulations':
    allPrescriptions: state.modulationsData.allPrescriptions, //sert surtout pour la multi saisie de parcelles à moduler, pour le téléchargement futur
    supplyTypeLiquidInAllPrescriptionsCounter: state.modulationsData.supplyTypeLiquidInAllPrescriptionsCounter, //compteur du type d'apport liquide dans les prescriptions à télécharger
    supplyTypeSolidInAllPrescriptionsCounter: state.modulationsData.supplyTypeSolidInAllPrescriptionsCounter, //compteur du type d'apport solide dans les prescriptions à télécharger
    modulationSelected: state.modulationsData.modulationSelected,
    parcelIdsSelected: state.modulationsData.parcelIdsSelected,
    readableMode: state.modulationsData.readableMode,
    downloadWaiting: (state && state.modulationsData) ? state.modulationsData.downloading : false,
    buildWaiting: (state && state.modulationsData) ? state.modulationsData.building : false,
    errorModulation: (state && state.modulationsData) ? state.modulationsData.errorMessage : undefined,
    lastModulationSettings: (state && state.modulationsData && state.modulationsData.settings && state.modulationsData.settings.modulationSettings) ? state.modulationsData.settings.modulationSettings : undefined,

    //Infos provenant du reducer 'parcels':
    parcelDico: state.parcelsData.parcelDico,
    
    //Infos provenant du reducer 'settings':
    rememberModulationSettings: (state && state.settingsData && state.settingsData.settings) ? state.settingsData.settings.rememberModulationSettings : true,
    
    //Infos provenant du reducer 'contextApp':
    askToShowModulationFromMap: (state && state.contextAppData) ? state.contextAppData.askToShowModulationFromMap : undefined,
    providerSrcImageSelectedOfParcel: state.contextAppData.providerSrcImageSelectedOfParcel,
    satimageDateSelectedOfParcel: state.contextAppData.satimageDateSelectedOfParcel,
    parcelIdSelected: state.contextAppData.parcelIdSelected,
    satimageIdSelectedOfParcel: state.contextAppData.satimageIdSelectedOfParcel,
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    saveRememberModulationParameters: (remember) => dispatch(ActionSaveRememberModulationParameters(remember)),
    updateLastModulationSettings: (modulationSettings) => dispatch(ActionGetLastModulationSettingsLoaded(modulationSettings)),
    goToModulation: () => dispatch(ActionGoToModulation()),
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    buildModulationSampleAndDoses: (clientId, modulationParameter) => dispatch(ActionBuildModulationSampleAndDoses(clientId, modulationParameter)),
    addPrescription: (modulationParams) => dispatch(ActionAddPrescription(modulationParams)),
    initializePrescriptions: () => dispatch(ActionInitializePrescriptions()),
    saveModulation: (modulationParameter, withDoses) => dispatch(ActionSaveModulation(modulationParameter, withDoses)),
    goToModulationStep: (modulationStep, readableMode) => dispatch(ActionGoToStepOfModulation(modulationStep, readableMode)),
    goToNextStepOfModulation: (nextStepOfModulation, parcelIdsSelected, readableMode, modulationSelectedItemValue = undefined) =>
        dispatch(ActionGoToNextStepOfModulation(nextStepOfModulation, parcelIdsSelected, readableMode, modulationSelectedItemValue)),
    selectModulation: (modulationSelected) => dispatch(ActionSelectModulation(modulationSelected)),
    downloadListPrescriptions: (modulationParameterList, exportFormat) => dispatch(ActionBuildListPrescriptions(modulationParameterList, exportFormat)),
    cleanErrorModulation: () => dispatch(ActionCleanErrorModulation()),
    fitMapToBounds: (parcelId) => dispatch(ActionFitMapToBounds(parcelId)),
    goToMapAndSelectParcel: (parcelId) => dispatch(ActionGoToMapAndSelectParcel(parcelId)),
    unselectParcel: () => dispatch(ActionUnselectParcel()),
    clearModulation: () => dispatch(ActionClearModulationValue),
})

export default connect(mapStateToProps, mapDispatchToProps)(DosesModulation);