import { ActionTypeModulation, ModulationStep, ModulationTab } from '../actions/modulations';
import { ModulationsHelper } from '../../utils/modulationsHelper.js';
import ConstantsLayers from '../../utils/constantsOfLayers.js';
import { GetBaseLayerIndex, GetLastLayerIndex } from '../../utils/constantsOfLayers.js';

import lodashIsEmpty from 'lodash/isEmpty'; //pour savoir si un objet a une valeur différente de undefined, null, ou [] (pour une liste)
import lodashOmit from 'lodash/omit'; //pour suppression d'objet(s) dans une liste

/* state initial */
const initialState = {
    modulationTab: ModulationTab.NewModulation, // Par défaut, on affiche l'onglet pour créer de nouvelle modulation

    loading: false, //permettra de mettre une waiting cursor dans le tableau et le sidebar...
    downloading: false, //permettra de mettre une waiting cursor,
    building: false, //permettra de mettre une waiting cursor,
    errorMessage: undefined,
    deleting: false, //permettra de mettre un waiting cursor dans le tableau des modulations quand suppression
    
    generatingFile: false, //permettra de mettre un waiting cursor lors de la création/téléchargement du fichier de modulation

    modulationDico: {}, //dico dont la clef est l'ID de parcelle associée - valeur l'entité 'Modulationdata'. => Uniquement les modulation complète en BdD.
    modulationDicoCounter: 0,
    allPrescriptions: [], //liste des prescription lors de la session courante
    supplyTypeSolidInAllPrescriptionsCounter: 0, //compteur du type d'apport solide dans les prescriptions à télécharger
    supplyTypeLiquidInAllPrescriptionsCounter: 0, //compteur du type d'apport liquide dans les prescriptions à télécharger
    parcelIdsToDesign: undefined, //liste des parcelles visées par une nouvelle demande de génération (cette liste reste complète tous le temps des étapes de paramétrage).
    parcelIdsSelected: undefined, //liste RESTANTES des parcelles visées par une nouvelle demande de génération.
    modulationSelected: undefined,
    modulationIdSelected: undefined,
    modulationDateImageIdSelected: undefined,
    modulationDateImageDateSelected: undefined,
    modulationsParcelIdDeleted: [], //liste des parcels id dont les modulations ont été supprimées du tableau des modulations sauvegardées.
    modulationStep: ModulationStep.CHOIX_PARCELS,
    newModulationCounter: 0, //Nombre de nouvelle(s) modulation(s) complète(s) produite(s) !
    hasModulationUpdate: false, //au moins une des modulation a été actualisé en base, dû fait d'une nouvelle génération (à minima) commencée
    openCongratulationsDialog: false,
    readableMode: false, //permet de visualiser le paramétrage d'une modulation sans rien pouvoir en changer (mode consultation).
 
    settings: { //settings for map modulation
        lastLayerForModulation: 4, //correspond à 'ConstantsLayers.NdviParcelLayerName'
        baseLayerForModulation: 'satellite',
        showNdviCharts: true,
        isPanelOpened: true, //RQ : Pour le moment, on n'autorise pas son 'masquage' !

        modulationSettings: { //settings de la dernière modulation enregistrée
            doseMedium: undefined,
            doseNominal: undefined,
            content: undefined,
            distributionType: undefined,
            doseChange: undefined,
            supplyType: undefined,
            dispersion: undefined,
        }
    },

    //Choix des couches: 
    baseLayerSelectedForModulation: ConstantsLayers.VisibleBaseLayerName, // VisibleBaseLayerName ou RoadBaseLayerName !
    forewardLayerSelectedForModulation: ConstantsLayers.NdviParcelLayerName, // NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
}

function modulationsManagerAction(state = initialState, action) {

    switch (action.type) {
        case ActionTypeModulation.GO_TO_TAB_OF_MODULATION: {
            return Object.assign({}, state, {
                modulationTab: action.modulationTab
            });
        }

        case ActionTypeModulation.GET_LAST_MODULATION_SETTINGS_LOADED: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: action.errorMessage,
                settings : {
                    ...state.settings,
                    modulationSettings: {
                        ...state.settings.modulationSettings,
                        doseMedium: (action.modulationSettings.doseMedium !== null) ? action.modulationSettings.doseMedium : undefined,
                        doseNominal: (action.modulationSettings.doseNominal !== null) ? action.modulationSettings.doseNominal : undefined,
                        content: (action.modulationSettings.content !== null) ? action.modulationSettings.content : undefined,
                        distributionType: action.modulationSettings.distributionType,
                        doseChange: action.modulationSettings.doseChange,
                        supplyType: action.modulationSettings.supplyType,
                        dispersion: action.modulationSettings.dispersion,
                    }
                }
                
            });
        }

        case ActionTypeModulation.ERROR_LOAD_MODULATIONS: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: action.errorMessage,
                readableMode: false,
            });
        }

        case ActionTypeModulation.ERROR_DOWNLOAD_MODULATION: {
            return Object.assign({}, state, {
                building: false,
                downloading: false,
                errorMessage: action.errorMessage,
            });
        }
        case ActionTypeModulation.ERROR_BUILD_MODULATION: {
            return Object.assign({}, state, {
                building: false,
                errorMessage: action.errorMessage,
                readableMode: false,
            });
        }

        case ActionTypeModulation.DELETING_MODULATION: {
            return Object.assign({}, state, {
                deleting: true,
                errorMessage: '',
                readableMode: false,
            });
        }

        case ActionTypeModulation.DELETING_MODULATIONS: {
            return Object.assign({}, state, {
                deleting: true,
                errorMessage: '',
                readableMode: false,
            });
        }

        case ActionTypeModulation.DELETE_MODULATION: {
            let newModulationDico = {};
            const actualModulations = state.modulationDico;

            if (actualModulations && (state.modulationDicoCounter > 0) &&
                (action.modulationIdDeleted !== undefined) && (action.modulationIdDeleted > 0)) {
                for (const key in actualModulations) {
                    const modulationItem = actualModulations[key];
                    if (modulationItem) {
                        if (modulationItem.id !== action.modulationIdDeleted) {
                            newModulationDico[modulationItem.id] = modulationItem;
                        }
                    }
                } 
            } else {
                newModulationDico = actualModulations; //Ne change rien !
            }

            return Object.assign({}, state, {
                deleting: false,
                errorMessage: '',
                modulationDico: newModulationDico,
                modulationDicoCounter: ModulationsHelper.count(newModulationDico),
            });
        }

        case ActionTypeModulation.DELETE_MODULATIONS: {
            let newModulationDico = {};

            //Utilisation de lodash/isEmpty :
            //si state.modulationDico est undefined : 'lodashIsEmpty' renvoie true
            //si state.modulationDico est null : 'lodashIsEmpty' renvoie true
            //si state.modulationDico est [] : 'lodashIsEmpty' renvoie true
            //sinon renvoie false
            if (!lodashIsEmpty(state.modulationDico) && (state.modulationDicoCounter > 0) &&
                (!lodashIsEmpty(action.modulationIdsDeleted)) && (action.modulationIdsDeleted.length > 0)) {
                newModulationDico = lodashOmit(state.modulationDico, action.modulationIdsDeleted);

                return Object.assign({}, state, {
                    deleting: false,
                    errorMessage: '',
                    modulationDico: newModulationDico,
                    modulationDicoCounter: ModulationsHelper.count(newModulationDico)
                });
            }
            else {
                //Ne change rien !
                return Object.assign({}, state, {
                    deleting: false,
                    errorMessage: ''
                });
            }
        }

        case ActionTypeModulation.LOADING_MODULATIONS: {
            return Object.assign({}, state, {
                loading: true,
                errorMessage: '',
                modulationDico: action.modulationDico,
                modulationDicoCounter: ModulationsHelper.count(action.modulationDico),
            });
        }

        case ActionTypeModulation.LOAD_MODULATIONS: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: '',
                modulationDico: action.modulationDico,
                modulationDicoCounter: ModulationsHelper.count(action.modulationDico),
            });
        }

        case ActionTypeModulation.SELECT_MODULATIONS: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: '',
                parcelIdsToDesign: action.parcelIdsSelected, //initialisation de la liste des parcelles que l'on vient de sélectionner pour y paramétrer leur modulation.
                parcelIdsSelected: action.parcelIdsSelected, //@@vérif si ids de parcelles ou de modulations ?!
            });
        }

        case ActionTypeModulation.SELECT_MODULATION: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: '',
                readableMode: (action.readableMode !== undefined) ? action.readableMode : true,
                modulationSelected: action.modulationSelected,
                building: false,
            });
        }//@@il faudra penser, lorsque l'on génère la dernière modulation, à désélectionner celle en cours !
        //pour éviter de ne pas détecter une nouvelle demande de génértion sur cette même dernière parcelle...
        //=> modulationSelected: undefined

        case ActionTypeModulation.GO_TO_STEP_OF_MODULATION: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: '',
                modulationStep: action.modulationStep,
                //isExpandedAccordion: (action.openFirstPanel === true) ? true : false,
                openCongratulationsDialog: (action.modulationStep === ModulationStep.CONGRATULATIONS) ? true : false,
                //A partir du moment où l'on arrive sur l'étape des doses, on a au moins lancer une génération ! sinon, on ne change pas l'état actuel.
                hasModulationUpdate: (action.modulationStep === ModulationStep.DOSES) ? true : state.hasModulationUpdate,
                building: (action.modulationStep === ModulationStep.CHOIX_PARCELS) ? false : state.building,
                readableMode: action.readableMode,
            });
        }

        case ActionTypeModulation.GO_TO_NEXT_STEP_OF_MODULATION: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: '',
                //RQ: on ne touche pas à 'parcelIdsToDesign' qui doit en permanence contenir la liste complète des parcelles qui est/vient d'être configurées !
                parcelIdsSelected: action.parcelIdsSelected,
                modulationStep: action.modulationStep,
                openCongratulationsDialog: (action.modulationStep === ModulationStep.CONGRATULATIONS) ? true : false,
                //A partir du moment où l'on arrive sur l'étape des doses, on a au moins lancer une génération ! sinon, on ne change pas l'état actuel.
                hasModulationUpdate: (action.modulationStep === ModulationStep.DOSES) ? true : state.hasModulationUpdate,
                building: (action.modulationStep === ModulationStep.CHOIX_PARCELS) ? false : state.building,
                readableMode: action.readableMode,
            });
        }

        case ActionTypeModulation.CLEAR_NEW_MODULATION: {
            return Object.assign({}, state, {
                //remise à zéro 
                errorMessage: '',
                newModulationCounter: 0,
                hasModulationUpdate: false,
            });
        }

        case ActionTypeModulation.UP_NEW_MODULATION: {
            
            return Object.assign({}, state, {
                //incrémente 
                newModulationCounter: state.newModulationCounter + 1,
                hasModulationUpdate: true,
                building: false,
            });
        }

        case ActionTypeModulation.START_BUILD_MODULATION: {
            return Object.assign({}, state, {
                building: true,
                errorMessage: '',
            });
        }

        case ActionTypeModulation.START_DOWNLOAD_MODULATION: {
            return Object.assign({}, state, {
                downloading: true,
                errorMessage: '',
            });
        }

        case ActionTypeModulation.DOWNLOAD_MODULATION_END: {
            return Object.assign({}, state, {
                building: false,
                downloading: false,
                errorMessage: '',
            });
        }

        case ActionTypeModulation.OPEN_CONGRATULATIONS_DIALOG: {
            return Object.assign({}, state, {
                //errorMessage: '',
                openCongratulationsDialog: action.openCongratulationsDialog
            });
        }

        case ActionTypeModulation.CLEAR_MODULATION_VALUE: {
            return Object.assign({}, state, {
                modulationSelected: undefined,
                errorMessage: '',
                openCongratulationsDialog: false,
            });
        }

        case ActionTypeModulation.CLEAN_ERROR: {
            return Object.assign({}, state, {
                loading: false, 
                downloading: false, 
                building: false, 
                errorMessage: '',
            });
        }

        case ActionTypeModulation.SELECT_LAYER_FOR_MODULATION: {
            //baseLayerSelected peut valoir: ConstantsLayers.VisibleBaseLayerName, // VisibleBaseLayerName ou RoadBaseLayerName !
            //forewardLayerSelected peut valoir: NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            let newBaseLayersSelected = action.baseLayertypeForModulation;
            if ((!newBaseLayersSelected) || (
                (newBaseLayersSelected !== ConstantsLayers.VisibleBaseLayerName) && (newBaseLayersSelected !== ConstantsLayers.RoadBaseLayerName))) {
                newBaseLayersSelected = ConstantsLayers.VisibleBaseLayerName;
            };
            let newForewardLayersSelected = action.forewardLayertypeForModulation;
            if ((!newForewardLayersSelected) || (
                (newForewardLayersSelected === ConstantsLayers.VisibleBaseLayerName) || (newForewardLayersSelected === ConstantsLayers.RoadBaseLayerName))) {
                newForewardLayersSelected = ConstantsLayers.NdviParcelLayerName;
            };

            return Object.assign({}, state, {
                errorMessage: '',
                settings: {
                    ...state.settings,
                    baseLayerForModulation: GetBaseLayerIndex(newBaseLayersSelected),
                    lastLayerForModulation: GetLastLayerIndex(newForewardLayersSelected),
                },
                baseLayerSelectedForModulation: newBaseLayersSelected,
                forewardLayerSelectedForModulation: newForewardLayersSelected,
            });
        }

        case ActionTypeModulation.ADD_PRESCRIPTION: {
            state.allPrescriptions.push(action.modulationParameters);
            if (action.modulationParameters.supplyType === "Solid") { 
                state.supplyTypeSolidInAllPrescriptionsCounter ++;
            }
            if (action.modulationParameters.supplyType === "Liquid") {
                state.supplyTypeLiquidInAllPrescriptionsCounter ++;
            }
            
            return Object.assign({}, state, {
                allPrescriptions: state.allPrescriptions,
                supplyTypeSolidInAllPrescriptionsCounter: state.supplyTypeSolidInAllPrescriptionsCounter,
                supplyTypeLiquidInAllPrescriptionsCounter: state.supplyTypeLiquidInAllPrescriptionsCounter, 
            });
        }

        case ActionTypeModulation.INITIALIZE_PRESCRIPTION: {
            return Object.assign({}, state, {
                allPrescriptions: [],
                supplyTypeSolidInAllPrescriptionsCounter: 0,
                supplyTypeLiquidInAllPrescriptionsCounter: 0
            });
        }

        case ActionTypeModulation.GENERATING_MODULATION_FILE: {
            return Object.assign({}, state, {
                generatingFile: true,
            });
        }

        case ActionTypeModulation.MODULATION_FILE_GENERATED: {
            return Object.assign({}, state, {
                generatingFile: false,
            });
        }

        default:
            return state;
    }
}

export default modulationsManagerAction;