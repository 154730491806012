///////////////////////////////////////////////////////////////////////////
// Import des éléments utilisés par le composant <Content>
///////////////////////////////////////////////////////////////////////////
import React, { Component } from "react";
//import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';
import Swiper from 'react-id-swiper/lib/custom';
import 'react-id-swiper/src/styles/css/swiper.css';
import "../../../assets/css/imageSwiper.css";
import { get as lodashGet, isNil as lodashIsNil } from 'lodash';
import { addMonths /*, isAfter*/ } from 'date-fns';
// import sendError from '../../../utils/errorService.js';
//import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { SatimageState } from '../../../models/stateYearMonthOfSatimages.js';
import dateHelper from '../../../utils/dateHelper';
import { ActionSelectDateImg, ActionShowInviteToPremium, ReasonInviteToPremium } from '../../../redux/actions/contextApp.js';
import { ActionAskOldHistoForSelectedParcel, ActionGetHistoForParcelByMonthYear,ActionAskFirstHistoForSelectedParcel } from '../../../redux/actions/satImage.js';
// Mui
import { Box, Button, Grid } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import getTheme from "../../../themes/index.js";
import { BiomassesHelper } from "../../../utils/biomassesHelper";
import { SatimageHelper } from "../../../utils/satimageHelper";
import ImageSlide, { ProgressSlide } from "../../imageSlide";
import sendError from "../../../utils/errorService";


const theme = getTheme(); /* Permet d'obtenir le thme de berry */

/*const Slide_Per_View_MobileSmall = 2; //pour type 'IPhone 5'... (si Width <= 'MinWidthMobile')
const MinWidthMobile = 370; //si >, alors on est au moins sur un type 'IPhone 6' !
const Slide_Per_View_Mobile = 3; // (si Width <= 'MinWidthPad')
const MinWidthPad = 480;
const Slide_Per_View_Pad = 4; // (si Width <= 'MinWidthPadBig')
const MinWidthPadBig = 600;*/
const Slide_Per_View_Pc = 4; // (si Width <= 'MinWidthPcSmall') RQ: repasse à 3 slides car on replace la date à côtéde l'image !
/*const MinWidthPcSmall = 840;
const Slide_Per_View_Big = 4;*/ // (si Width > 'MinWidthMobile') Reste à 4 car on est sur des slide dont la date est placée à côte de la vignette

const IdProgressSlide = -10;
const LabelProgressSlide = 'progressSlide';
const IdVoidSlide = -20;
const LabelVoidSlide = 'voidSlide';

///////////////////////////////////////////////////////////////////////////
// Composant permettant d'afficher un calendrier avec les informations des images disponibles
///////////////////////////////////////////////////////////////////////////
class ImageSwiperBiomass extends Component {
    constructor(props) {
        super(props);

        this.state = {
            askOlderSatimages: 0,
            // selectedSlide: undefined
            selectedSlide: this.props.satimageIdSelectedOfParcel ? this.props.satimageIdSelectedOfParcel : undefined
        };

        this.swiperRef = undefined; // référence du composant swiper -(permet de récupérer l'index en cours des slides(images))

        this.contentOfSwipper = [];
        this.slideCounter = 0;
        this.progressSlideIndex = -1; //et pas 'undefined'!
        this.voidSlideIndex = -1; //et pas 'undefined'!
        this.activeIndex = 0; // index de la slide la + à droite (ctl=true) vue dans le swipper [slide1,slide2,slide3,...] => 1 , [slide2,slide3,slide1,...] => 2, ...
        this.slideSelected = undefined; //element HTML correspondant à la slide sélectionnée.
        this.lastActiveIndex = 0;

        //Paramétrage du swiperJs:
        this.slidePerView = Slide_Per_View_Pc;
        this.spaceBetween = 0; //10; //<= Ne pas définir d'espacement pour laisser gérer le swiper; Sans quoi, il ne gère pas bien le changement de position car se base sur ses tailles de slides !
        /*if (window) {
            if (window.innerWidth > MinWidthPcSmall) {
                this.slidePerView = Slide_Per_View_Big;
                //    this.spaceBetween = 30;
            } else if (window.innerWidth > MinWidthPadBig) {
                this.slidePerView = Slide_Per_View_Pc;
                //    this.spaceBetween = 20;
            } else if (window.innerWidth > MinWidthPad) {
                this.slidePerView = Slide_Per_View_Pad;
                //    this.spaceBetween = 20;
            } else if (window.innerWidth > MinWidthMobile) {
                this.slidePerView = Slide_Per_View_Mobile;
                //    this.spaceBetween = 10;
            } //else //laisse la valeur de 'MinWidthMobile' donc 'Slide_Per_View_Mobile' !
        } //else //laisse la valeur de 'MinWidthMobile' donc 'Slide_Per_View_Mobile' !*/

        const notSmallScreen = (window && window.innerWidth && (window.innerWidth > theme.breakpoints.values.md)) ? true : false;
        
        this.paramsSwiper = {
            slidesPerView: (notSmallScreen === true) ? this.slidePerView : "auto",
            initialSlide: this.activeIndex,
            spaceBetween: this.spaceBetween,
            //centeredSlides: true, //nous , on ne centre pas la slide... on fait en sorte d'en avoir une plus récente à droite.
            rtl: true, // sens droite => gauche
            rebuildOnUpdate: true, // permet de recréer un objet swiper lors d'un update du tableau d'historique
            updateOnImagesReady: true,
            on: {
                click: this.onClickSlide.bind(this),
                tap: this.onClickSlide.bind(this),
                //Je préfère utiliser cet événement car déclenché uniquement si j'arrive pour la première fois en fin de liste (tout à gauche) !
                reachEnd: this.handleReachEnd.bind(this), // Et ne sera rédéclencher que si je retourne, entre temps, plus tôt (vers la droite de la liste)
            },
        };

        this.handleMoveLeft = this.handleMoveLeft.bind(this);
        this.handleMoveRight = this.handleMoveRight.bind(this);

        const parcel = lodashGet(props, `parcelDico[${props.parcelIdSelected}]`, undefined);
        
        const dateBeginningWinter = lodashGet(parcel, 'imagSourceDateBeginningWinter', undefined);
        const dateEndingWinter = lodashGet(parcel, 'imagSourceDateEndingWinter', undefined);
        
        //* A l'idéal demain il faudrait centralisé le range au niveau de Redux pour éviter de le calculer à chaque fois.
        // définie si l'on gère la date d'entrée ou sortie hivers
        const isBeginningWinter = 
            // Si on a demander à gérer la sortie d'hivers
            ((props.hasAskedForEndingBiomass === true) ?
                false 
            : 
                // Si on a pas de date d'entrée hivers
                (lodashIsNil(dateBeginningWinter) ?
                    true
                : 
                    // Si on a pas de date de sortie hivers
                    (lodashIsNil(dateEndingWinter) ? 
                        false 
                    : 
                        true
                    )
                )
            );
        
        this.range = isBeginningWinter ? BiomassesHelper.getRangeDateByParcelForBeginningWinter(parcel) : BiomassesHelper.getRangeDateByParcelForEndingWinter(parcel);
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de cycle de vie react.js - à la création du document
    ///////////////////////////////////////////////////////////////////////////
    /*La méthode componentWillMount n'est pas recommandée pour la version de React actuelle. 
    Utiliser le préfixe UNSAFE */
    UNSAFE_componentWillMount() {
        //A l'ouverture du panneau du carrousel, on charge ce composant.
        // => on charge alors la liste des slides à générer sur base de la liste des dates d'images connues de cette parcelle.
        const { parcelIdSelected } = this.props;
        this.loadSlidesOfParcel(parcelIdSelected, true); // chargement des slides - données imageHistoryBase (date, images, ...) - la première fois que le comosant est créé   
    }

    componentDidMount() {
        // on sélectionne l'image en cours avec un petit délai (le temps de charger et afficher ce composant et ses slides) pour attendre que l'élément swiper soit prêt
        this.selectCurrentImageOfParcel();
    }

    // Méthode définissant le nombre de slides affichées (qu'elles visent une vrai image, la fausse slide de progression ou les fausses slides de remplissage)
    totalSlideCounter() {
        let badSlideCounter = (this.progressSlideIndex >= 0) ? 1 : 0;
        badSlideCounter += (this.voidSlideIndex >= 0) ? 1 : 0;
        return this.slideCounter + badSlideCounter;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // fonction permettant de créer les Slides associées aux images de la parcelle suivant l'id fourni
    // (et de faire appel à l'algo de complément de slides s'il n'y a pas assez de vrai images)
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    loadSlidesOfParcel(forceParcelId, forceAsk = false) { //'forceAsk' définit uniquement au chargement du composant car ensuite c'est 'shouldComponentUpdate' qui fera le taff !
        const { satimagesByParcelDico, satimagesGlobalStateAsk } = this.props;

        if (satimagesByParcelDico && forceParcelId && (forceParcelId > 0)) {
            const allSatimageDico = lodashGet(satimagesByParcelDico, `[${forceParcelId}].satimageDico`, undefined);

            if (satimagesGlobalStateAsk) {
                const satImageByParcel = lodashGet(satimagesByParcelDico, `[${forceParcelId}]`);
                const loadingMonth = SatimageHelper.getMonthInProgress(forceParcelId, satImageByParcel);

                if (loadingMonth !== undefined) {
                    loadingMonth.forEach((e) => {
                        this.insertNewSlide({ id: IdProgressSlide, label: LabelProgressSlide, date: new Date(e.year, e.month) });
                    });
                }
            }

            if (allSatimageDico) {
                for (const key in allSatimageDico) {
                    const itemSatimage = allSatimageDico[key];
                    if (itemSatimage && itemSatimage.id) {
                        this.insertNewSlide(itemSatimage);
                    }
                }

                //trie ce tableau, maintenant qu'il est rempli:
                this.contentOfSwipper.sort((a, b) => { //'a' et 'b' sont des 'ImageSlide'
                    if (a && b && a.props && b.props && a.props.satimage && b.props.satimage) {
                        if (a.props.satimage.date && b.props.satimage.date) {
                            let dateValueA = undefined;
                            if (a.props.satimage.date instanceof Date) {
                                dateValueA = a.props.satimage.date;
                            } else {
                                dateValueA = new Date(a.props.satimage.date);
                                a.props.satimage.date = dateValueA; //@@A voir si ce ne serait pas judicieux de faire cela partout !
                            }

                            let dateValueB = undefined;
                            if (b.props.satimage.date instanceof Date) {
                                dateValueB = b.props.satimage.date;
                            } else {
                                dateValueB = new Date(b.props.satimage.date);
                                b.props.satimage.date = dateValueB; //@@A voir si ce ne serait pas judicieux de faire cela partout !
                            }

                            return (dateHelper.Compare(dateValueA, dateValueB) * -1); // inverse la "négativité" pour avoir l'ordre décroissant !
                        } else if (a.props.satimage.date) {
                            return -1; //pourt le considérer avant le faux Satimage (b) !
                        } else if (b.props.satimage.date) {
                            return 1; //pourt le considérer avant le faux Satimage (a) !
                        }
                    }

                    return 0; //pas moyen de les comparer, on ne change pas l'ordre !
                });

                //↓ va permettre d'insérer des faux slides si un historique est en cours d'obtention ↓
                //↓ Ou s'il n'y pas a moins de trois Slide de créé ↓
                this.checkForMoreImageToDisplay(forceParcelId, forceAsk);
            } else {
                //si on n'a pas l'info de la parcelle, on n'ajoute pas des faux Slides d'attente:
                const entityOfParcel = lodashGet(satimagesByParcelDico, `[${forceParcelId}]`, undefined);
                if (entityOfParcel) {
                    //↓ va permettre d'insérer des faux slides si un historique est en cours d'obtention ↓
                    //↓ Ou s'il n'y pas a moins de trois Slide de créé ↓
                    this.checkForMoreImageToDisplay(forceParcelId, forceAsk);
                }
                else
                    this.ClearSlidesOfParcel();
            }
        }
        else
            this.ClearSlidesOfParcel();
    }

    // Méthode permettant de rajouter des slides (fausses) si le nombre de slides total n'est pas suffisantes
    checkForMoreImageToDisplay(forceParcelId, forceAsk = false) { //'forceAsk' définit uniquement au chargement du composant car ensuite c'est 'shouldComponentUpdate' qui fera le taff ! 
        const totalSlides = this.totalSlideCounter();
        if ((totalSlides >= 0) && (totalSlides < this.slidePerView)) {
            const { satimagesByParcelDico, askFirstHistoForSelectedParcel, parcelIdSelected, parcelDico } = this.props;

            const stateOfParcel = lodashGet(satimagesByParcelDico, `[${forceParcelId}].stateAsk`, SatimageState.stateAskOnProgress);
            if (stateOfParcel && (stateOfParcel === SatimageState.stateAskOnProgress)) {
                //↓↓ slide d'attente - sera retiré lors de la récupération de nouvelles images ↓↓
                this.insertNewSlide({ id: IdProgressSlide, label: LabelProgressSlide });
            } else {
                const stateCounterOnProgress = lodashGet(satimagesByParcelDico, `[${forceParcelId}].yearMonthStateInProgressCounter`, -1);
                if (stateCounterOnProgress && stateCounterOnProgress > 0) {
                    //↓↓ slide d'attente - sera retiré lors de la récupération de nouvelles images ↓↓
                    this.insertNewSlide({ id: IdProgressSlide, label: LabelProgressSlide });
                } else {
                    const stateAskAll = lodashGet(parcelDico, `[${parcelIdSelected}].alreadyAskAllImagesForThisMonth`, false);
                    if ((this.slideCounter <= 0) || //on n'en a pas encore ! on demande celui du mois en cours...
                        // OU par ce que pour le mois en cours, personne n'a encore demandé toutes les images !
                        (stateAskAll === undefined) || (stateAskAll === false)) {
                        askFirstHistoForSelectedParcel(forceParcelId);
                    } else {
                        //alors il faut demander l'obtention du mois précédent la dernière image
                        if (forceAsk) {
                            this.askPreviewMonth(forceParcelId);
                        }
                    }
                }
            }

            //RQ: en cas d'erreur sur la parcelle, on n'affiche pas d'un faux slide expliquant le prbl !

            //↓↓ si le total de slides est toujours inférieur au minimum, on ajoute la dernière fausse slide ↓↓
            const newTotalSlides = this.totalSlideCounter();
            if ((newTotalSlides >= 0) && (newTotalSlides < this.slidePerView)) {
                this.insertNewSlide({ id: IdVoidSlide, label: LabelVoidSlide });
            }
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // fonction permettant de créer une nouvelle Slide !
    // Soit réeelle = associéée à une vrai image (dont on a ou pas encore le flux)
    // Soit fictive et représentant une obtension d'historique en cours
    // Soit fictive et là pour "remplir" le carrousel (en attendant d'obtenir les images du mois précédent)
    // (et de faire appel à l'algo de complément de slides s'il n'y a pas assez de vrai images)
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    insertNewSlide(thisSatimage) {
        if ((!thisSatimage) || (!thisSatimage.id)) return;

        // Définit la Slide:
        let index = this.totalSlideCounter();
        if (thisSatimage.id === IdProgressSlide) {
            if (this.progressSlideIndex < 0) { //si pas déjà présent ; RQ: Ne pas tester si = 'undefined'!
                this.progressSlideIndex = index;
                // this.contentOfSwipper.push(<ImageSlide key={index} satimage={thisSatimage} isWaitingSlide={true} />);
                this.contentOfSwipper.push(<ProgressSlide key={index} />)
            } else { // si déjà présent, on peut potentiellement ajouter un entre deux mois, mais il doit etre daté
                if (thisSatimage.date) {
                    this.contentOfSwipper.push(
                        <ImageSlide
                            key={thisSatimage.date.toString()}
                            satimage={thisSatimage}
                        />
                    );
                }
                else { 
                    return; //pour ne pas comptabiliser.
                }
            }
        // } else if (thisSatimage.id === IdVoidSlide) {
        //     if (this.voidSlideIndex < 0) { //si pas déjà présent ;RQ: Ne pas tester si = 'undefined'!
        //         this.voidSlideIndex = index;
        //         this.contentOfSwipper.push(<ImageSlide key={index} satimage={thisSatimage} />);
        //     } else {//si déjà présent, on n'en ajoute pas un second...
        //         return; //pour ne pas comptabiliser.
        //     }
        } else {
            const range = this.checkDateInInterval(thisSatimage.date);
            this.contentOfSwipper.push(
                <ImageSlide
                    key={thisSatimage.id}
                    satimage={thisSatimage}
                    range={range}
                    selected={this.state.selectedSlide === thisSatimage.id}
                />
            );
        }

        //Comptabilise cette nouvelle Slide:
        if ((thisSatimage.id !== IdProgressSlide) && (thisSatimage.id !== IdVoidSlide)) {
            this.slideCounter++;
        }
    }

    ClearSlidesOfParcel() {

        this.contentOfSwipper = [];
        this.slideCounter = 0;
        this.progressSlideIndex = -1; //et pas 'undefined'!
        this.voidSlideIndex = -1; //et pas 'undefined'!
        this.activeIndex = 0;
        // dé-selection de la slide précèdente:
        /*if (this.slideSelected)
            this.slideSelected.className = 'is-slide-content';*/
        this.slideSelected = undefined;
    }

    RemoveFakesSlides(forceAsk = false) {
        let newContentOfSwipper = [];
        let newSlideCounter = 0;
        this.contentOfSwipper.forEach((item) => {
            if (item && item.props && item.props.satimage && //rq, certaines slides 'IdProgressSlide' n'ont même pas de 'props' !
                (item.props.satimage.id !== IdProgressSlide) && (item.props.satimage.id !== IdVoidSlide)) { //on exclut les faux Slides !
                newContentOfSwipper.push(item);

                newSlideCounter++;
            } //else //c'est de celles que l'on ne souhaite pas garder !
        });

        this.contentOfSwipper = newContentOfSwipper;
        this.slideCounter = newSlideCounter;
        this.progressSlideIndex = -1; //et pas 'undefined'!
        this.voidSlideIndex = -1; //et pas 'undefined'!
        this.activeIndex = null;
        // dé-selection de la slide précèdente:
        /*if (this.slideSelected)
            this.slideSelected.className = 'is-slide-content';*/
        this.slideSelected = undefined;

        //↓ va permettre d'insérer des faux slides si un historique est en cours d'obtention ↓
        //↓ Ou s'il n'y pas a moins de trois Slide de créé ↓
        const { parcelIdSelected } = this.props;
        this.checkForMoreImageToDisplay(parcelIdSelected, forceAsk);
    }

    askPreviewMonth(forceParcelId) {
        if ((!forceParcelId) || (forceParcelId <= 0)) return;
        if (!this.props.askOldHistoForSelectedParcel) return;
        if (!this.props.getOldHistoForSelectedParcelFromDB) return;
        if (this.progressSlideIndex >= 0) return; //RQ: Ne pas tester si <> 'undefined'!

        const { satimagesByParcelDico, parcelIdSelected, datasClient } = this.props;
        if (!datasClient) return;

        //↓↓ slide d'attente - sera retiré lors de la récupération de nouvelles images ↓↓
        this.insertNewSlide({ id: IdProgressSlide, label: LabelProgressSlide });

        //Définis qu'il faut se placer sur cette nouvelle slide à venir (si la demande vient du fait que l'on se trouve en fin de liste):
        let indexToProgressSlideShowOnLeft = this.activeIndex;
        if (this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper && this.swiperRef.current.swiper.isEnd) {
            indexToProgressSlideShowOnLeft = this.slideCounter - this.slidePerView + 1;
        }

        this.paramsSwiper.initialSlide = (indexToProgressSlideShowOnLeft >= 0) ? indexToProgressSlideShowOnLeft : 0;

        //Détermine l'année/mois de la dernière image dispo:
        let olderDate = this.getOlderDateImage();

        // A partir de cette dernière date d'image dispo, on en déduit l'année/mois précédent:
        if (!olderDate) olderDate = new Date();

        let yearAsked = olderDate.getUTCFullYear();
        let monthAsked = olderDate.getUTCMonth() + 1;
        // avant de faire la demande, on vérifie si l'année/mois n'a pas déjà été demandé ET que le retour était vide ou en erreur:
        //si c'est le cas, on remonte d'un mois encore (sans limite de remonté car le but est de ressortir avec des dates d'images):
        let prevMonthOffset = 1;
        let loopContinue = true;
        let isOkToAsk = false;
        while (loopContinue) {
            const newDatePrevMonth = addMonths(olderDate, -prevMonthOffset);
            if (newDatePrevMonth) {
                yearAsked = newDatePrevMonth.getUTCFullYear();
                monthAsked = newDatePrevMonth.getUTCMonth() + 1;
                prevMonthOffset++;

                // contrôle...
                const stateofYearMonth = lodashGet(satimagesByParcelDico, `[${parcelIdSelected}].stateByYearMonthDico[${yearAsked}_${monthAsked}]`, SatimageState.stateNotAsk);
                if (stateofYearMonth === SatimageState.stateAskOnProgress) {//ne devrait pas arriver !
                    loopContinue = false;
                    isOkToAsk = false;
                } else if (stateofYearMonth === SatimageState.stateNotAsk) {
                    loopContinue = false;
                    isOkToAsk = true; //on demande cette année/mois qui n'a pas encore été demandé!
                } else {
                    loopContinue = true;
                    isOkToAsk = false; //on passe au mois encore avant car celui-ci ne nous avancera à rien !
                }
            } else  {
                loopContinue = false;
                isOkToAsk = true; //on considère cette année/mois comme non-encore demandé!
            }
        }

        if (isOkToAsk) { //@@Et si l'année/mois est antérieur à 01/2017 ???
            //⚠️ ↓↓ Algo de génération de l'historique non factorisé - Attention - impact important si modification ↓↓date.getUTCMonth()+1, date.getUTCFullYear());
            if (datasClient && (datasClient.authorizeHistoric === true)) {
                this.props.askOldHistoForSelectedParcel(forceParcelId, yearAsked, monthAsked);  //le retour (si positif) déclenchera la mise à jour du composant car de nouvelles images seront dispo pour cette parcelle !
            }
            else {
                // si l'historique ne doit pas être générée, on affiche uniquement ce qui existe en base en fonction du mois et de l'année
                this.props.getOldHistoForSelectedParcelFromDB(forceParcelId, monthAsked, yearAsked);
            }
        }
    }

    getOlderDateImage() {
        //Détermine l'année/mois de la dernière image dispo:
        let olderDate = null;
        try {
            //on ne peut pas juste s'occuper du dernier, car potentiellement c'est une fausse slide (progress ou void) !
            //const lastSlideOfSatimage = this.contentOfSwipper[(this.slideCounter - 1)]; ...
            
            //on doit parcourrir depuis le dernier jusqu'à trouver une date ok !
            const lastIndex = (this.slideCounter - 1);
            for (let i = lastIndex; ((i >= 0) && (!olderDate)); i--) {
                const lastSlideOfSatimage = lodashGet(this.contentOfSwipper, `[${i}]`, undefined);
                const dateOfLastSlideOfSatimage = lodashGet(lastSlideOfSatimage, `props.satimage.date`, null);
                if (!dateOfLastSlideOfSatimage) { /* suivant... */} 
                else if ((dateOfLastSlideOfSatimage instanceof Date) && (dateHelper.isValidDate(dateOfLastSlideOfSatimage))) {
                    olderDate = dateOfLastSlideOfSatimage;
                } else {
                    const thisOlderDate = new Date(dateOfLastSlideOfSatimage);
                    if (dateHelper.isValidDate(thisOlderDate)) {
                        olderDate = thisOlderDate;
                        lastSlideOfSatimage.props.satimage.date = olderDate;
                    }
                    //else // on continue...
                }
            }
        }
        catch (e) {
            //RAS! 'olderDate' vaut undefined
        }

        return olderDate;
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de sélectionner la slide relative à l'image courant de cette parcelle
    ///////////////////////////////////////////////////////////////////////////
    selectCurrentImageOfParcel() {
        const { satimageIdSelectedOfParcel, parcelDico, parcelIdSelected } = this.props;

        if (satimageIdSelectedOfParcel && (satimageIdSelectedOfParcel > 0)) {
            this.selectSlide(satimageIdSelectedOfParcel);
        } else if (parcelDico && parcelIdSelected && (parcelIdSelected > 0)) {
            const currentSatimageId = lodashGet(parcelDico, `[${parcelIdSelected}].currentSatimageId`, -1);
            if (currentSatimageId && (currentSatimageId > 0)) {
                this.selectSlide(currentSatimageId);
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant d'actualiser le visuel de la slide relative à l'image courant de cette parcelle
    ///////////////////////////////////////////////////////////////////////////
    showCurrentImageOfParcel(satimageId) {
        if ((satimageId === undefined) || (satimageId <= 0)) return;
        const { selectedSlide } = this.state;

        if (selectedSlide !== undefined) {
            const updatedContentOfSwipper = this.contentOfSwipper.map(
                (slide) => {
                    if (slide.props.selected !== undefined) {
                        return React.cloneElement(slide, {
                            selected: (slide.props.satimage.id === satimageId) ? true : false,
                        });
                    }
    
                    return slide;
                }
            );

            this.contentOfSwipper = updatedContentOfSwipper;
        } else {
            return;
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // Update les elements du swipper
    ///////////////////////////////////////////////////////////////////////////
    updateContentOfSwipper() {
        const { satimageIdSelectedOfParcel } = this.props;
        const { selectedSlide } = this.state;

        if (selectedSlide !== undefined) {
            const updatedContentOfSwipper = this.contentOfSwipper.map(
                (slide) => {
                    if (slide.props.selected !== undefined) {
                        return React.cloneElement(slide, {
                            selected: (slide.props.satimage.id === satimageIdSelectedOfParcel) ? true : false,
                        });
                    }
    
                    return slide;
                }
            );

            this.contentOfSwipper = updatedContentOfSwipper;
        } else {
            return;
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de sélectionner la slide relative à l'image visée
    ///////////////////////////////////////////////////////////////////////////
    selectSlide(satimageId) {
        if ((!satimageId) || (satimageId <= 0)) return;

        const { selectSatimage, parcelIdSelected } = this.props;
        // selectionne la slide
        selectSatimage(satimageId, undefined, parcelIdSelected);
        
        this.setState({
            selectedSlide: satimageId,
        });
        
        //↓↓ centrage de la slide sélectionnée - pour cela on va trouver la position de la slide parmi l'ensemble des slides du swiper ↓↓
        if (this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper) {
            const index = this.contentOfSwipper.findIndex((item) => {
                return (item && item.props && item.props.satimage && item.props.satimage.id && (item.props.satimage.id === satimageId));
            });

            // Par rapport au slide 'actif' (c'est à dire celui qui est tout à gauche dans la zone des 5 visibles),
            // on se déplace que si l'index du slide associé à l'image sélectionnée ne fait pas parti de ces 5 là!
            if (index >= 0) {
                this.lastActiveIndex = index;

                const currentActiveIndex = this.swiperRef.current.swiper.activeIndex;//realIndex;
                const offsetSlideRight = (this.slidePerView - 1); // (this.slidePerView - 1) == offset pour arriver à la dernière slide visible en partant de 'activeIndex'
                const maxIndexShowed = currentActiveIndex + offsetSlideRight;
                if ((currentActiveIndex < 0) || ((index < currentActiveIndex) || (index > maxIndexShowed))) {
                    if (this.lastActiveIndex > 0) {
                        this.activeIndex = this.lastActiveIndex - 1; // pour avoir une image plus récente que celle sélectionnée !
                    } else {
                        this.activeIndex = 0; //=this.lastActiveIndex;
                    }
                    this.paramsSwiper.initialSlide = this.activeIndex;

                    this.swiperRef.current.swiper.slideTo(this.activeIndex, 100); //100 => vitesse d'exécution (en ms).
                }
                //else //l'index visé semble être parmis ceux visualisés !
            } else if (this.lastActiveIndex >= 0) {
                this.swiperRef.current.swiper.slideTo(this.lastActiveIndex, 100); //100 => vitesse d'exécution (en ms).
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction lancée lors du click sur une slide dans le swiper
    ///////////////////////////////////////////////////////////////////////////
    onClickSlide(event) {

        const { parcelIdSelected } = this.props;
        if (!parcelIdSelected) return;

        if (this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper) {
            this.activeIndex = this.swiperRef.current.swiper.activeIndex;//realIndex;
            this.paramsSwiper.initialSlide = this.activeIndex;

            //↓↓ récupération de l'imageId de la slide cliquée ↓↓
            var imageId = -1;
            try {
                const idOfImageSlide = this.swiperRef.current.swiper.clickedSlide.id;
                imageId = parseInt(this.swiperRef.current.swiper.clickedSlide.id, 10); //'clickedSlide' est l'instance de 'ImageSlide'

                if ((idOfImageSlide === `${IdProgressSlide}`) || (imageId === IdProgressSlide)) {
                    return; //on ne permet pas la sélection d'un faux Slide !
                } else if ((idOfImageSlide === `${IdVoidSlide}`) || (idOfImageSlide === IdVoidSlide)) {
                    //on fait la demande pour l'année/mois précédent, s'il n'y en a pas déjà une en cours):
                    if (this.progressSlideIndex < 0) { //RQ: Ne pas tester si = 'undefined'! 
                        this.askPreviewMonth(parcelIdSelected);
                    }
                }
            }
            catch (err) {
                sendError('imageSwiper - slideClicked', { "err": err, "parcelId": parcelIdSelected });
            }

            //↓↓ Signale le changement de l'image sélectionnée ↓↓
            if (imageId >= 0) {
                if (this.props.selectSatimage) {
                    this.props.selectSatimage(imageId, undefined, parcelIdSelected);
                }

                //↓↓ sélection de la slide sans attendre le changement de sélection ↓↓
                this.selectSlide(imageId);
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // méthode permettant d'être notifier lorsque les slides affichées sont les dernières 
    // (celles tout à gauche). Elle n'est déclenchée qu'une seule fois !
    // ET ne sera re-déclenchable à nouveau que si au moins la dernière slide n'est plus visible 
    // (car l'utilisateur s'est déplacé dans la liste... vers la droite).
    ///////////////////////////////////////////////////////////////////////////
    handleReachEnd() {
        const { parcelIdSelected } = this.props;
        if (!parcelIdSelected) return;

        if (this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper) {
            // si on arrive en fin de liste et qu'il n'y a pas déjà une demande de génértion d'historique en cours, 
            //on fait la demande pour l'année/mois précédent:
            if (this.swiperRef.current.swiper.isEnd && (this.progressSlideIndex < 0)) { //RQ: Ne pas tester si = 'undefined'! 
                this.askPreviewMonth(parcelIdSelected);
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // callback permettant de faire défiler le slider vers la gauche et remonter dans l'historique - Demande d'historique
    ///////////////////////////////////////////////////////////////////////////
    handleMoveLeft() {
        const { datasClient, showInviteToPremium, inviteToPremium } = this.props;

        if (this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper) {

            this.activeIndex = this.swiperRef.current.swiper.activeIndex;//realIndex;
            this.paramsSwiper.initialSlide = this.activeIndex;

            //↓↓ on arrive à la slide la + ancienne ↓↓
            if (this.swiperRef.current.swiper.isEnd) {
                this.lastActiveIndex = this.swiperRef.current.swiper.activeIndex;

                // Si le client ne dispose pas de droit d'accès à la génération de l'historique alors on l'incite à l'abonnement
                if (datasClient && (datasClient.authorizeHistoric === false)) {

                    if ((showInviteToPremium === false) && (inviteToPremium)) {
                        //l'écran d'incitation n'est pas ouvert, 
                        //on lance la demande de présentation de l'incitation !
                        inviteToPremium(ReasonInviteToPremium.NoLimitParcels);
                    }
                }
                else {
                    // alors il faut demander l'obtention du mois précédent la dernière image
                    this.setState({ askOlderSatimages: this.state.askOlderSatimages + 1, }); //permet de déclencher une mise à jour du rendu de ce composant (qui fera la demande d'historique)
                }
            }
            else {
                this.swiperRef.current.swiper.slideNext();
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // callback permettant de faire défiler le slider vers la droite et de revenir vers l'image la + rcente
    ///////////////////////////////////////////////////////////////////////////
    handleMoveRight() {
        const { datasClient, showInviteToPremium, inviteToPremium } = this.props;

        // Si le client ne dispose pas de droit d'accès à la génération de l'historique alors on l'incite à l'abonnement
        if (datasClient && (datasClient.authorizeHistoric === false)) {
            if ((showInviteToPremium === false) && (inviteToPremium)) {
                //l'écran d'incitation n'est pas ouvert, 
                //on lance la demande de présentation de l'incitation !
                inviteToPremium(ReasonInviteToPremium.NoLimitParcels);
            }
        }
        else {
            if (this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper) {

                this.activeIndex = this.swiperRef.current.swiper.activeIndex;//realIndex;
                this.paramsSwiper.initialSlide = this.activeIndex;

                this.swiperRef.current.swiper.slidePrev();
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // méthode retournant la range correspondant à une date
    ///////////////////////////////////////////////////////////////////////////
    checkDateInInterval(dateString) {
        if (dateString !== undefined) {
            const date = new Date(dateString);

            // on s'interesse qu'a la date du jour, pour ne pas fausser les resultats on doit mettre a 0 le reste :
            date.setHours(0, 0, 0, 0);
    
            if (date && this.range) {
                if (date >= this.range.beginDate && date <= this.range.endDate) {
                    return "IN_RANGE";
                } else if (
                    (this.range.beginExtraDate && (date >= this.range.beginExtraDate) && (date < this.range.beginDate)) ||
                    (this.range.endExtraDate && (date <= this.range.endExtraDate) && (date > this.range.endDate))
                ) {
                    return "IN_EXTRA_RANGE";
                }
            }
        }
        return "OUT_OF_RANGE";
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de cycle de vie react.js
    ///////////////////////////////////////////////////////////////////////////
    shouldComponentUpdate(nextProps, nextState) {
        const { parcelIdSelected, /*satimagesByParcelDico,*/ satimageIdSelectedOfParcel, satimagesGlobalStateAsk } = this.props;

        //↓↓ Cas d'une sélection / changement de parcelle ↓↓
        if (parcelIdSelected !== nextProps.parcelIdSelected) {
            if (parcelIdSelected && (parcelIdSelected > 0)) {
                // précédement, on avait une parcelle de sélectionnée, on vide le Swipper !
                // car soit on change de parcelle ; soit on vient de désélectionner cette parcelle.
                this.ClearSlidesOfParcel();
            }
            //ce n'est pas un 'else if' !
            if (nextProps.parcelIdSelected && (nextProps.parcelIdSelected > 0)) {
                this.loadSlidesOfParcel(nextProps.parcelIdSelected); // chargement des slides - données imageHistoryBase (date, images, ...)
            } //else //si aucune parcelle n'est sélectionné, en principe, la fermeture de ce panneau aurai dû être demandé!

            return true;
        } //else //on n'a pas changer de parcelle sélecttionée (ou toujours pas de parcelle sélectionnée) !
        
        //RQ: A partir d'ici, 'parcelIdSelected' vaut 'nextProps.parcelIdSelected'!

        if (nextProps.satimagesByParcelDico && parcelIdSelected && (parcelIdSelected > 0)) {
            //↓↓ Cas d'une mise à jour du nombre d'images associées à cette parcelle sélectionnée ↓↓
            const counterSatimage = lodashGet(nextProps.satimagesByParcelDico, `[${parcelIdSelected}].satimageDicoCounter`, 0);
            if ((counterSatimage >= 0) && (counterSatimage !== this.slideCounter)) {
                this.ClearSlidesOfParcel();

                this.loadSlidesOfParcel(parcelIdSelected); // chargement des slides - données imageHistoryBase (date, images, ...)

                return true;
            }//else //le cas où le retour d'une demande de génération du mois précédent a retourner une liste vide où une erreur sera traité plus bas...


            //↓↓ Cas d'une fin (bonne ou pas) de demande d'images associées à cette parcelle sélectionnée ↓↓
            const stateOfParcel = lodashGet(nextProps.satimagesByParcelDico, `[${parcelIdSelected}].stateAsk`, SatimageState.stateAskOnProgress);
            const stateCounterOnProgress = lodashGet(nextProps.satimagesByParcelDico, `[${parcelIdSelected}].yearMonthStateInProgressCounter`, -1);
            if (stateOfParcel && stateCounterOnProgress) {
                let hasMoreStateOnProgress = true; // = this.progressSlideIndex !== undefined;
                if ((stateOfParcel !== SatimageState.stateAskOnProgress) && (stateCounterOnProgress <= 0)) {
                    hasMoreStateOnProgress = false;
                }

                // Si on détecte qu'il n'y a plus de génération en cours...et fini en erreur!
                if (stateOfParcel === SatimageState.stateOnError) {
                    // supprime les faux slides existants:
                    this.RemoveFakesSlides(true); //et potentiellement, va relancer une demande d'historique sur un mois plus ancien !

                    return true;
                }
                // Si on détecte qu'il n'y a plus de génération en cours...(mais que l'on a une fausse slide de progression existante)
                else if ((hasMoreStateOnProgress === false) && (this.progressSlideIndex < 0)) { //RQ: Ne pas tester si = 'undefined'! 
                    // supprime les faux slides existants:
                    this.RemoveFakesSlides(true); //et potentiellement, va relancer une demande d'historique sur un mois plus ancien !

                    return true;
                }
                // Si on détecte qu'il y a au moins une demande de génération en cours...(et que l'on n'a pas créé la fausse slide de progression)
                else if ((hasMoreStateOnProgress === true) && (this.progressSlideIndex < 0)) { //RQ: Ne pas tester si = 'undefined'! 
                    //↓↓ slide d'attente - sera retiré lors de la récupération de nouvelles images ↓↓
                    this.insertNewSlide({ id: IdProgressSlide, label: LabelProgressSlide });

                    return true;
                }
            }
        }

        //↓↓ Cas d'une demande d'obtension des images du mois précédent (précédent celles déjà reçues) pour cette parcelle ↓↓
        const { askOlderSatimages } = this.state;
        if (askOlderSatimages !== nextState.askOlderSatimages) {
            // alors il faut demander l'obtention du mois précédent la dernière image
            this.askPreviewMonth(parcelIdSelected);

            return true;
        }

        //↓↓ Cas d'un changement d'image sélectionnée pour cette parcelle ↓↓
        if ((satimageIdSelectedOfParcel !== nextProps.satimageIdSelectedOfParcel) &&
            nextProps.satimageIdSelectedOfParcel && (nextProps.satimageIdSelectedOfParcel > 0) &&
            parcelIdSelected && (parcelIdSelected > 0)) {
            return true;
        }

        //↓↓ Cas d'une demande d'obtension des images d'un mois précédent, pour lequel il n'y en a pas,
        // et qu'il manque des slides pour remplir le carrousel:
        if (parcelIdSelected && (parcelIdSelected > 0) &&
            (satimagesGlobalStateAsk !== nextProps.satimagesGlobalStateAsk) && (nextProps.satimagesGlobalStateAsk === SatimageState.stateAskOk)) {
            const stateOfParcel = lodashGet(nextProps.satimagesByParcelDico, `[${parcelIdSelected}].stateAsk`, SatimageState.stateAskOnProgress);
            const counterSatimage = lodashGet(nextProps.satimagesByParcelDico, `[${parcelIdSelected}].satimageDicoCounter`, undefined);
            if ((counterSatimage < this.slidePerView) && (stateOfParcel !== SatimageState.stateAskOnProgress)) {
                // on supprime d'éventuel faux slides:
                // alors il faut demander l'obtention du mois précédent la dernière image
                //this.askPreviewMonth(parcelIdSelected);
                this.RemoveFakesSlides(true); //et potentiellement, va relancer une demande d'historique sur un mois plus ancien !

                return true;
            }
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        const { parcelIdSelected, satimageIdSelectedOfParcel } = this.props;

        //↓↓ Cas d'une sélection / changement de parcelle ↓↓
        if ((prevProps.parcelIdSelected !== parcelIdSelected) && parcelIdSelected && (parcelIdSelected > 0)) {
            this.selectCurrentImageOfParcel(); // on sélectionne l'image en cours
        }

        //↓↓ Cas d'un changement d'image sélectionnée pour cette parcelle ↓↓
        if ((prevProps.satimageIdSelectedOfParcel !== satimageIdSelectedOfParcel) &&
            satimageIdSelectedOfParcel && (satimageIdSelectedOfParcel > 0) &&
            parcelIdSelected && (parcelIdSelected > 0)) {
            this.selectSlide(satimageIdSelectedOfParcel);
        } else {
            // this.showCurrentImageOfParcel(satimageIdSelectedOfParcel); // actualise seulement le visuel sélectionné
        }

    }

    getCustomSwiper() {
        this.swiperRef = React.createRef();
        this.updateContentOfSwipper();

        return (<Swiper {...this.paramsSwiper} ref={this.swiperRef}>{this.contentOfSwipper}</Swiper>);
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de cycle de vie react.js
    ///////////////////////////////////////////////////////////////////////////
    render() {
        return (
            <Grid id='divGlobalSwipperId'
                sx={{
                    height: "92px",
                    width: "100%",
                    display: "flex",
                    position: "relative",
                    zIndex: 1100,
                    pointerEvents: 'all'
                }}>
                <Grid>
                    {/* Bouton de navigation */}
                    <Box sx={{ height: '100%', display: { xs: 'flex', sm: 'flex', md: 'flex' } }}>
                        <Button sx={{ paddingRight: 0, minWidth: '29px' }} color="primary" aria-label="go to the left" onClick={this.handleMoveLeft}>
                            <ArrowBackIosNew />
                        </Button>
                    </Box>
                </Grid>
                <Grid className="swiper-container-bioAndMd">
                    {/* caroussel */}
                    {this.getCustomSwiper()}
                </Grid>
                <Grid>
                    {/* Bouton de navigation */}
                    <Box sx={{ height: '100%', display: { xs: 'flex', sm: 'flex', md: 'flex' } }}>
                        <Button sx={{ paddingLeft: 0, minWidth: '29px' }} color="primary" aria-label="go to the right" onClick={this.handleMoveRight}>
                            <ArrowForwardIos />
                        </Button>
                    </Box>
                </Grid>
            </Grid>

        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        //Infos provenant du reducer 'contextApp':
        parcelIdSelected: (state && state.contextAppData) ? state.contextAppData.parcelIdSelected : -1,
        satimageIdSelectedOfParcel: (state && state.contextAppData) ? state.contextAppData.satimageIdSelectedOfParcel : -1,
        showInviteToPremium: (state && state.contextAppData) ? state.contextAppData.showInviteToPremium : false,

        //Infos provenant du reducer 'satimage':
        satimagesGlobalStateAsk: (state && state.satimageData) ? state.satimageData.stateAsk : SatimageState.stateNotAsk,
        satimagesByParcelDico: (state && state.satimageData) ? state.satimageData.satimagesByParcelDico : {},

        //Infos provenant du reducer 'clientUser':
        datasClient: (state && state.clientUserData) ? state.clientUserData.clientDatas : null,

        //Infos provenant du reducer 'parcel':
        parcelDico: (state && state.parcelsData) ? state.parcelsData.parcelDico : {},

        //Info provenant du reducer 'biomass':
        hasAskedForEndingBiomass: lodashGet(state , 'biomassData.hasAskedForEndingBiomass', false),
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    askFirstHistoForSelectedParcel: (parcelId) => dispatch(ActionAskFirstHistoForSelectedParcel(0, parcelId)),
    inviteToPremium: (reasonCase) => dispatch(ActionShowInviteToPremium(reasonCase)),
    selectSatimage: (imgIdValue, imgDateValue, parcelIdValue) => dispatch(ActionSelectDateImg(imgIdValue, imgDateValue, parcelIdValue)),
    askOldHistoForSelectedParcel: (parcelId, yearValue, monthValue) => dispatch(ActionAskOldHistoForSelectedParcel(parcelId, yearValue, monthValue)),
    getOldHistoForSelectedParcelFromDB: (parcelId, monthValue, yearValue) => dispatch(ActionGetHistoForParcelByMonthYear(parcelId, monthValue, yearValue)),
})

//export default connect( mapStateToProps, mapDispatchToProps )(withWidth()(LayerSelection));
export default connect(mapStateToProps, mapDispatchToProps)(ImageSwiperBiomass);
