import * as React from 'react';
import { connect } from 'react-redux';

/* Aides lodash pour obtention d'objet  dans un dico/savoir si un objet est vide/savoir si un objet est une date */
import lodashGet from 'lodash/get';

/* React components */
import CustomDataGrid from "../customDataGrid";
import { ThumbnailParcelShapeFromPathInfos } from '../thumbnail/ThumbnailParcelShape';
import AlertDialog from '../alertDialog';
import LastNitrogenInputResult from './lastNitrogenInputResult';

/* Redux */
import { ActionGetAllLastNitrogenInputs, ActionDeleteLastNitrogenInput, ActionBuildLastNitrogenInputs, ActionBuildLastNitrogenInputListPrescriptions, 
    ActionClearLastNitrogenInputError, ActionGoToSelectImage, ActionGoToModifyModulation, ActionSelectLastNitrogenInput, ActionUnselectLastNitrogenInput, ActionGenerateFileOfThisLastNitrogen, ActionGenerateFileOfResumeLastNitrogen } from '../../redux/actions/lastNitrogenInput';
import { TableType } from '../../redux/actions/settings.js';
import { SupplyType, TypeDownloadChoice } from '../../redux/actions/modulations';
import { ProfilIndex, ActionShowProfilMenuDialog } from "../../redux/actions/contextApp.js";

/* Helpers */
//import lastNitrogenInputHelper from '../../utils/lastNitrogenInputHelper';
import DateHelper from '../../utils/dateHelper';
import { ParcelsHelper } from '../../utils/parcelsHelper';
import { IsNativeHoster } from '../../utils/platformHelper';
import numberHelper from '../../utils/numberHelper';

/* Lien de contact */
import LinkToContactUs from '../linkToContactUs';

/* mui components */
import { CircularProgress, Typography, Button, Grid, TableContainer, Box, Stack, IconButton, 
	Dialog, DialogContentText, DialogActions, DialogTitle, DialogContent, Chip, chipClasses, 
	Radio, RadioGroup, FormControlLabel, FormLabel, FormControl, Tooltip, Alert, Card, 
	MenuItem,
	Checkbox,
	ListSubheader,
	ListItemText,
	InputLabel,
	OutlinedInput,
	Select} from '@mui/material';

/* Icones */
import Calculate from "@mui/icons-material/Calculate";
import IconPdf from '@mui/icons-material/PictureAsPdf';
import WarningIcon from '@mui/icons-material/Warning';
import { ErrorOutline, WarningOutlined } from '@mui/icons-material';
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

/* Traduction */
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
/* theme */
import getTheme from "../../themes/index.js";
import ConstantsFertilizer from '../../utils/constantsFertilizer';
import FertilizerHelper from '../../utils/fertilizerHelper';

let theme = getTheme();


class LastNitrogenInputsList extends React.Component{
    constructor(props){
        super (props);

		const errorMessage = lodashGet(props, 'errorMessage', undefined);

		
		this.currentCropYear = FertilizerHelper.getCurrentCropYear(props.closingDateAzofert, props.openingDateAzofert); //Année de campagne en cours


        this.state = {
			lastNitrogenInputToDelete: [],
			parcelIdsOfLastInputNsSelected: [],
			rowsOfTable: [], //Lignes contenant les données des derniers apports
			currentRowsOfTable: [],
			totalDoseToExpand: 0,
			totalDoseSupplyType: SupplyType.Solid,
			bothSupplyType: false,
			hasLastInputArchievedSelected: false,
			onlyLastInputArchivedSelected: false,

			openConfirmDeleteLastInputNDialog: false,
			openDialogOfErrors: (errorMessage !== undefined),
            openLastNitrogenInputResultDialog: false,
            recapLastNitrogenGenerating: false,
			
            showUseComputerForDownload: false, //ouverture de la dialog en mobile, indiquant qu'il faut passer sur l'appli PC !
			
            openConfirmDownloadModulationDialog: false, //ouverture de la dialog de téléchargement des modulations
            idsToDownload: [], //Identifiants des derniers apports à exporter via la prescription !
            typeDownloadChoice: TypeDownloadChoice.shp, //Par défaut, le type de fichier pour le téléchargement est SHP.
            showFirstStepForDownloadingModulations: false, //Si le client a des modulations dont les types d'apport sont différents, on visualise l'étape 1
            showFirstStepExcludeThemWithoutModulations: false, //Si le client a choisi des derniers apports dont certains n'ont pas de données de modulation !
			showIncitationModulFumure: false, // Si le client veut télécharger les cartes de modulation alors qu'il ne dispose pas des droits

			/* Historique */
			cropYears: [],
			cropYearsSelected: [this.currentCropYear],
		};

		this.columnsTableDefinition = this.getColumnsTable();

		// Définition du popup contenant le message d'erreur à diffuser au client:
		this.popupErrorDialog = {
			getTitle: () => { return (<ErrorOutline />); },
			description: errorMessage, //Rq : sera mis à jour en fonction de la prop 'errorMessage' !
			getAdditionalDescription: () => {
				return (
					<Typography variant="subtitle1">
						{StringTranslate.errorDialogText2} <LinkToContactUs displayText={StringTranslate.contactUs} />. 
					</Typography>
				);
			},
			button: StringTranslate.close,
		};
		
		// Définition du popup permettant au client de faire son choix de format d'exportation de la prescription:
		this.popupUseComputerForDownloadDialog = {
            getTitle: () => { return (<WarningIcon />); },
            description: StringTranslate.msgUseComputerForDownloadModulation, //message d'incitation si on tente de télécharger depuis l'appli mobile !
            button: StringTranslate.close,
        }

		// Définition du popup informant le client que le télécharement du pdf n'est pas encore implémenté dans l'application
		this.popupDownloadPdfDialog = {
			getTitle: () => { 
				return (<>
					<div style={{ display:'flex', alignItems:'center'}}>
						<InfoOutlined/><Typography sx={{ml: 1 }} variant="subtitle1">{StringTranslate.downloadModulPdf}</Typography>
					</div>
				</>); },
			description: StringTranslate.pdfDownloadNotAvaible,
			button: StringTranslate.close,
		}

		// Définition du popup contenant le message invitant le client à passer au niveau suppérieur:
		//Rq: On ne va pas sur la page d'abonnement (via 'ProfilIndex.abonnements') car les plans Stripe ne seront pas activés pour le moment !
		this.popupIncitationModulEtFumureDialog = {
			getTitle: () => { return (<EmojiObjectsIcon sx={{ color: theme.palette.info.main, }} />); },
			description: StringTranslate.incitationDownloadLastNInputPrescriptions,
			getAdditionalDescription: () => {
				return (
					<Typography variant="subtitle1">
						<LinkToContactUs displayText={StringTranslate.contactUs} />{StringTranslate.incitationDownloadLastNInputPrescriptionsPdf}
					</Typography>
				);
			},
			button: StringTranslate.close,
		};

		this.thumbnailInfosSelectedToShowModulation = undefined;
		this.parcelIdSelectedToShowModulation = undefined;
		
		this.closeDialogOfErrors = this.closeDialogOfErrors.bind(this);
		this.onCloseLastNitrogenInputResultDialog = this.onCloseLastNitrogenInputResultDialog.bind(this);
        this.onGenerateLastNitrogenFileClick = this.onGenerateLastNitrogenFileClick.bind(this);
    };

	componentDidMount() {
		this.loadContentTable();
	}

	componentDidUpdate(prevProps) {
		const { errorMessage, lastNitrogenInputDicoIsLoaded, lastNitrogenInputDicoCounter, deleting, building } = this.props;
	 
		if (((!prevProps) || (!prevProps.errorMessage) || (prevProps.errorMessage === '') || (prevProps.errorMessage === undefined)) &&
			(errorMessage && (errorMessage !== '') && (errorMessage !== undefined))) {
			this.popupErrorDialog.description = errorMessage;

			this.setState({
				openDialogOfErrors: true,
			});
		}

		//On alimente le tableau des derniers apports
		if (((prevProps.lastNitrogenInputDicoIsLoaded === false) && (lastNitrogenInputDicoIsLoaded === true)) ||
			(prevProps.lastNitrogenInputDicoCounter !== lastNitrogenInputDicoCounter) ||
			((prevProps.deleting === true) && (deleting === false)) ||
			((prevProps.building === true) && (building === false))) {
			this.loadContentTable();
		}
	}

    //Titre des colonnes de la table des derniers apports
	getColumnsTable() {
		const columns = [
			{ headerName: 'id', field: 'id', hideable: false },
			{
				field: 'parcelName',
				minWidth: 150,
				hideable: false,
				cellClassName: 'select-modulation',
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.ColumnPclName}</Typography>)
				},
			},
			{
				field: 'variety',
				minWidth: 140,
				hideable: false,
				cellClassName: 'select-modulation',
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.libelecolumnvariete}</Typography>)
				}
			},
			{
				field: 'cropYear',
				minWidth: 100,
				hideable: false,
				cellClassName: 'select-modulation',
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.libelecolumncampagne}</Typography>)
				},
			},
			{
				field: 'isArchived',
				hideable: false,
				cellClassName: 'select-modulation',
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.statut}</Typography>)
				},
				renderCell: (params) => {
					return (
						<Chip 
							label={ (params.row.isArchived === true) ? StringTranslate.historized : StringTranslate.actual } 
							size="small" 
							color={ (params.row.isArchived === true) ? "primary" : "success" }
						/>
					)
				}
			},
			{
				field: 'adjustment',
				minWidth: 150,
				hideable: false,
				cellClassName: 'select-modulation',
				renderHeader: (params) => {
					return (<Typography sx={{ textWrap: 'wrap', textAlign: 'start' }} fontWeight='bold'>{StringTranslate.adjustment}</Typography>)
				},
				renderCell: (params) => {
					return(
						<Chip 
						label={(params.row.adjustment === 0) ? '0' : ((params.row.adjustment > 0) ? `+ ${params.row.adjustment}` : `- ${Math.abs(params.row.adjustment)}`)}
						icon={(params.row.adjustment > 5) ? <TrendingUpIcon />  : (params.row.adjustment < -5) ? <TrendingDownIcon/> : <TrendingFlatIcon/>}
						size="small"
						sx={{
							color: ((params.row.adjustment >= -5) && (params.row.adjustment <= 5)) ? theme.palette.common.black : theme.palette.common.white,
							[`& .${chipClasses.icon}`] : {color: ((params.row.adjustment >= -5) && (params.row.adjustment <= 5)) ? theme.palette.common.black : theme.palette.common.white }, // Pour changer la couleur de l'icône à l'intérieur de la Chip
							bgcolor: ((params.row.adjustment > 5) ? theme.palette.colorsFade[500] : (params.row.adjustment < -5) ? theme.palette.colorsFade[100] : theme.palette.colorsFade[300])
						}}
						/>
					);
				}
			},
			{
				field: 'adjustmentButton',
				minWidth: 200,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.lastNitrogenInputModulationButton}</Typography>)
				},
                renderCell: (params) => {
					const needToUpdateModulation = (params.row.nitrogenPrescription !== params.row.doseMedium);
					let onClick = () => {};
					if (!params.row.isArchived){
						if (params.row.hasModulation)
							onClick = () => this.onModifyModulationLastInputN(params.row.parcelId);
						else
							onClick = () => this.onStartModulationLastInputN(params.row.parcelId);
					}
                    return (
						<>
							<Tooltip 
								disableFocusListener 
								placement='top'
								title={ (params.row.isArchived)?
										StringTranslate.lastInputCannotGenerateModulationForArchived
									:
										((params.row.hasModulation) && (needToUpdateModulation))?
											StringTranslate.modulationValuesAreDifferent
										: undefined
								}
								enterTouchDelay={0}
							>
								<Button color={ ((params.row.hasModulation) && (!needToUpdateModulation)) ? "success" : "primary" } 
									fullWidth 
									variant={ params.row.hasModulation ? "contained" : "outlined" }
									disabled={(this.props.building === true) || (this.props.deleting === true)}
									onClick={onClick}
									startIcon={ ((params.row.hasModulation) && (needToUpdateModulation)) ? <WarningOutlined/> : ''}
									sx={(params.row.isArchived)?{
										borderColor: theme.palette.action.disabledBackground,
										backgroundColor: theme.palette.action.disabledBackground,
										color: theme.palette.action.disabled,
										opacity: theme.palette.action.disabledOpacity,
										":focus": {
											borderColor: theme.palette.action.disabledBackground,
											backgroundColor: theme.palette.action.disabledBackground,
										},
										":hover": {
											borderColor: theme.palette.action.disabledBackground,
											backgroundColor: theme.palette.action.disabledBackground,
										}
									}:{}}	
								>
										{ params.row.hasModulation ? StringTranslate.lastNitrogenInputModulated : StringTranslate.startModulationOfLastInputN }
								</Button>
							</Tooltip>
						</>
                    )
                }
			},
			/*{
				field: 'sowingDate',
				width: 110,
				type: "date",
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.sowingDate}</Typography>)
				}
			},*/ //pas sûre de la mettre !
			{
				field: 'dosePreviouslyInput',
				minWidth: 110,
				hideable: false,
				cellClassName: 'select-modulation',
				renderHeader: (params) => {
					return (<Typography sx={{ textWrap: 'wrap', textAlign: 'start' }} fontWeight='bold'>{StringTranslate.doseAlreadyProvided}</Typography>)
				},
				renderCell: (params) => {
					return (<Typography>{params.value}</Typography>)
				}
			},
			{
				field: 'nitrogenPrescription',
				minWidth: 110,
				hideable: false,
				headerClassName: 'coloredCell',
				cellClassName: 'coloredCell select-modulation',
				renderHeader: (params) => {
					return (<Typography sx={{ textWrap: 'wrap', textAlign: 'start' }} fontWeight='bold'>{StringTranslate.lastInputNDose}</Typography>)
				},
				renderCell: (params) => {
                    return (<Typography fontWeight='bold'>{params.value}</Typography>)
                },
			},
			{
				field: 'totalNDose',
				minWidth: 110,
				hideable: false,
				cellClassName: 'select-modulation',
				renderHeader: (params) => {
					return (<Typography sx={{ textWrap: 'wrap', textAlign: 'start' }} fontWeight='bold'>{StringTranslate.totalNdose}</Typography>)
				}
			},
			{
				field: 'newTotalNDose',
				minWidth: 110,
				hideable: false,
				headerClassName: 'coloredCell',
				cellClassName: 'coloredCell select-modulation',
				renderHeader: (params) => {
					return (<Typography sx={{ textWrap: 'wrap', textAlign: 'start' }} fontWeight='bold'>{StringTranslate.newTotalNDose}</Typography>)
				},
				renderCell: (params) => {
                    return (<Typography fontWeight='bold'>{params.value}</Typography>)
                },
			},
			{
                field: 'pdf',
                minWidth: 60,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.receipt}</Typography>)
                },
                renderCell: (params) => {
                    return (
                        <IconButton color="primary"
							onClick={(event) => this.onGenerateLastNitrogenFileClick(event, params.row.parcelId)}
							>
							<IconPdf />
						</IconButton>
                    )
                }
            },
			{
				field: 'lastInputDate',
				minWidth: 180,
				hideable: false,
				cellClassName: 'select-modulation', 
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.lastInputDate}</Typography>)
				}
			},
		];

		return columns;
	}

	//Données des derniers apports pour remplir le tableau
	loadContentTable() {
		const { lastNitrogenInputDico, lastNitrogenInputDicoCounter, thumbnailParcelDico } = this.props;
		const { cropYearsSelected } = this.state;

		let cropExistingYears = [];
		let rowsOfTable = [];
	
		if (lastNitrogenInputDico && lastNitrogenInputDicoCounter > 0) {
			for (const key in lastNitrogenInputDico) {
				const lastNitrogenInputItem = lastNitrogenInputDico[key];
		
				if (lastNitrogenInputItem) {
					const totalNDose = lastNitrogenInputItem.fertiNitrogenTotal;
					const newTotalNDose = lodashGet(lastNitrogenInputItem, 'newNitrogenTotal', 0); //potentiellement pas dispo car calcul pas encore lancé !

					if ((lastNitrogenInputItem.id > 0) && (lastNitrogenInputItem.parcelId > 0) && 
						(totalNDose > 0) && (newTotalNDose > 0)) {
						let adjustment = newTotalNDose - totalNDose;
						const newPerfGoal = lodashGet(lastNitrogenInputItem, 'newPerformance', 0); //potentiellement pas dispo car calcul pas encore lancé !
						let creationDateStr = '';
						if (lastNitrogenInputItem.creationDate && (lastNitrogenInputItem.creationDate instanceof (Date))) {
							creationDateStr = DateHelper.formati18n(lastNitrogenInputItem.creationDate, 'P');
						}
						else if (lastNitrogenInputItem.creationDate && (DateHelper.getDateFromString(lastNitrogenInputItem.creationDate) instanceof Date)) {
							creationDateStr = DateHelper.formati18n(new Date(lastNitrogenInputItem.creationDate), 'P');
						}

						const fertiCropYear = lodashGet(lastNitrogenInputItem, 'cropYear', this.currentCropYear);

						rowsOfTable.push({
							// Ceci est l'identifieur de ligne pour MUI
							// Comme l'id de l'entité LastNitrogenInput peut être à 0, nous avons besoin d'autre chose pour servir d'identifiant
							id: lastNitrogenInputItem.parcelId,
							clientId: lastNitrogenInputItem.clientId,
							parcelId: lastNitrogenInputItem.parcelId,
							//ee...
							variety:lastNitrogenInputItem.variety, 
							parcelName: lastNitrogenInputItem.parcelName, //l'entité C# nous la donne !

							sowingDate: lastNitrogenInputItem.fertiSowingDate,
							perfGoal: lastNitrogenInputItem.fertiPerformanceGoal,
							totalNDose: totalNDose,

							hasLastInputN: (newTotalNDose > 0) ? true : false,

							cropYear: fertiCropYear,
							isArchived: lastNitrogenInputItem.isArchived,

							newPerfGoal: newPerfGoal,
							newTotalNDose: newTotalNDose,
							adjustment: adjustment,
							lastInputDate: creationDateStr,

							nitrogenPrescription: lodashGet(lastNitrogenInputItem, 'nitrogenPrescription', 0),

							dosePreviouslyInput: lodashGet(lastNitrogenInputItem, 'dosePreviouslyInput', 0),
							doseMedium: lodashGet(lastNitrogenInputItem, 'doseMedium', 0),

							hasModulation: lodashGet(lastNitrogenInputItem, 'hasModulation', false), 

							thumbnailInfos: ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, lastNitrogenInputItem.parcelId),
						});

						//Récupération des années de campagnes des fumures actuelles et archivées
						if ((!cropExistingYears.includes(fertiCropYear)) && (fertiCropYear !== undefined)) 
							cropExistingYears.push(fertiCropYear);
					}
				}
			}	
		}

		//Return de 2 valeurs : les années de campagnes existantes dans les fumures et les fumures (actuelles et archivées)
		this.setState({
			rowsOfTable: rowsOfTable, //Toutes les fumures (actuelles et archivées)
			cropYears: cropExistingYears, //Les années de campagne présentes dans les fumures (actuelles et archivées)
			currentRowsOfTable: rowsOfTable.filter(row => cropYearsSelected.includes(row.cropYear)) //Les fumures à afficher par défaut en fonction de l'année de campagne à afficher par défaut
		});
	}

	setLastInputNsSelected = (newIdsOfLastInputNsSelected) => {
		const {lastNitrogenInputDico} = this.props;
		let totalDoseToExpand = 0;
		let selectedSupplyType = undefined;
        let bothSupplyType = false;
		let hasLastInputArchievedSelected = false;
		let onlyLastInputArchivedSelected = true;

		//Pour récupérer la dose à épandre et afficher le total pour les derniers apports sélectionnés
		newIdsOfLastInputNsSelected.forEach(newIdOfLastInputNsSelected => {
            let lastNitrogenInput = lodashGet(lastNitrogenInputDico, `[${newIdOfLastInputNsSelected}]`, {});
            if (lastNitrogenInput) {
                if (lastNitrogenInput.quantityToExpand) {
                    totalDoseToExpand = totalDoseToExpand + parseFloat(lastNitrogenInput.quantityToExpand);
                }

                if (selectedSupplyType === undefined) {
                    selectedSupplyType = lastNitrogenInput.supplyType;
                } else if (selectedSupplyType !== lastNitrogenInput.supplyType) {
                    bothSupplyType = true; //les deux types d'intrant sont sélectionnés !
                }

				if (lastNitrogenInput.isArchived)
					hasLastInputArchievedSelected = true;
				else {
					onlyLastInputArchivedSelected = false;
				}
            }
        });

        this.setState({
            parcelIdsOfLastInputNsSelected: newIdsOfLastInputNsSelected,
			totalDoseToExpand: totalDoseToExpand,
			totalDoseSupplyType: selectedSupplyType,
			bothSupplyType: bothSupplyType,
			hasLastInputArchievedSelected: hasLastInputArchievedSelected,
			onlyLastInputArchivedSelected: onlyLastInputArchivedSelected,
        });
	}

	/* Fonction qui permet la recherche dans le tableau */
	// Mets à jour également les années de campagne
	requestSearch = (searchValue, rowsOfTable) => {
		let filteredRows = rowsOfTable;

		filteredRows = filteredRows.filter((row) => {
			return searchValue.includes(row.cropYear); // new Date().getFullYear() - searchValue;
		});

		//Mets à jour les années de campgane sélectionnées
		this.setState({
			cropYearsSelected: searchValue
		});

		this.setState({ currentRowsOfTable: filteredRows });
	}
	
	// #region Suppression des Last Input N
	/**
	 * Demande de suppression des derniers apports sélectionnées
	 */
	onDeleteLastInputN() {
		this.setState({
			openConfirmDeleteLastInputNDialog: true,
		});
	}

	/**
	 * Désaffiche la boite de dialog pour la suppression des derniers apports
	 */
	handleCloseConfirmDialogForDeleting = () => {
		this.setState({
			openConfirmDeleteLastInputNDialog: false,
		});
	}

	/**
	 * Fonction de suppression des derniers apports
	 */
	onConfirmDeleteLastInputN = () => {
		const { parcelIdsOfLastInputNsSelected } = this.state;
		const { deleteLastNitrogenInput, lastNitrogenInputDico } = this.props;
        
		// Pour toutes les valeurs du dico
		let parcelIdsOfLastInputNsToDelete = Object.values(lastNitrogenInputDico)
			// On garde que les dernier apports non archivé et qui ont été sélectionné
			.filter(lni => lni && parcelIdsOfLastInputNsSelected.includes(lni.parcelId) && !lni.isArchived)
			// puis on récupère que leur identifiant de parcelle
			.map(lni => lni.parcelId);

		if (parcelIdsOfLastInputNsToDelete && parcelIdsOfLastInputNsToDelete.length > 0)
			deleteLastNitrogenInput(parcelIdsOfLastInputNsToDelete);
		
		this.setState({
			openConfirmDeleteLastInputNDialog: false,
		});
	}
	// #endregion

	/* Ferme le dialog des messages d'erreur */
	closeDialogOfErrors = () => {
		const { clearLastNitrogenInputError } = this.props;

		this.setState({
			openDialogOfErrors: false,
            showUseComputerForDownload: false,
		});

		clearLastNitrogenInputError();
	}

	onStartModulationLastInputN = (parcelId) => {
		const { goToSelectImage } = this.props;

		goToSelectImage(parcelId);

	}

	//Méthode pour modifier une modulation de conseil dernier apport en arrivant directement à l'écran des résultats sans passer par la carte.
	onModifyModulationLastInputN = (parcelId) =>{
		const { goToModifyModulationForLastInputN } = this.props
		
		goToModifyModulationForLastInputN(parcelId);
	}

	//Toolbar customisé du tableau comprenant :
    // - les boutons d'action suivants : Recalcul | @@
    // - le bouton d'aide
    customToolbar(props) {
		const { lastInputNsSelected, buildingState, downloadingState, deletingState, deleteLastInputN, totalDoseToExpand, bothSupplyType, totalDoseSupplyType,
			handleChangeCropYear, cropYears, cropYearsSelected, currentCropYear, onlyLastInputArchivedSelected, authorizeFertilizer,
		 	} = props;

		const doingSomething = ((deletingState === true) || (buildingState === true) || (downloadingState === true));
		const actionStarting = (((!lastInputNsSelected) || (lastInputNsSelected.length <= 0)) || doingSomething);

		const unity = (totalDoseSupplyType === SupplyType.Solid) ? "Kg" : "L";

        return (
            <Grid container spacing={2} >
                {/* Zone d'entête de tableau' */}
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {/* Pour zone de recherche (au besoin futur) */}
                        <Grid item xs={8} sm={8} md={4} lg={4}>
                            <Box sx={{ display: { md: 'block', xs: 'none' }, '& button': { m: 1 } }} style={{ textAlign: 'start' }} >
								{/* recalcul de l'ajustement de(s) PPF */}
								<Button
									variant="contained" color="primary" style={{ textAlign: 'start' }} 
									disabled={(!authorizeFertilizer) || actionStarting || onlyLastInputArchivedSelected}
									onClick={(evt, data) => props.buildLastInputNs(evt, lastInputNsSelected)}
									startIcon={(buildingState === true) ? <CircularProgress size={20} color="inherit" /> : <Calculate />}
								>
									{StringTranslate.rebuildLastInputNs}
								</Button>
							</Box>
							{/* Boutons en écran Mobile (< md) */}
                            <Stack 
                                sx={{ display: { md: 'none', xs: 'block' }, '& button': { m: 1 }, pt:1 }} 
                                style={{ textAlign: 'start' }} 
                                direction="row" spacing={1}
                            >
                                <Button
                                    disabled={actionStarting}
                                    variant="contained" color="primary"
                                    size="medium"
                                    aria-label="build Last input(s)"
                                    component="span"
                                    onClick={(evt, data) => props.buildLastInputNs(evt, lastInputNsSelected)}
									startIcon={(buildingState === true) ? <CircularProgress size={20} color="inherit" /> : <Calculate />}
                                >
									{StringTranslate.restartCalculation}
                                </Button>
                            </Stack>
                        </Grid>

                        {/* Boutons d'action */}
                        <Grid item xs={4} sm={4} md={8}>
                            {/* Boutons en écran PC (>= md) */}
                            <Box sx={{ display: { md: 'block', xs: 'none' }, '& button': { m: 1 } }} style={{ textAlign: 'end' }} >
                                <Button
                                    variant="text" color="error"
                                    disabled={actionStarting}
									onClick={() => deleteLastInputN()}
									startIcon={((deletingState === true)) && <CircularProgress size={20} color="inherit" />}
                                >
                                    {StringTranslate.lastInputNDeleteBtn}
                                </Button>

								<Button
                                    variant="contained" color="primary"
                                    disabled={actionStarting || onlyLastInputArchivedSelected}
                                    onClick={(evt, data) => props.downLoadLastInputNModulations(evt, lastInputNsSelected)}
									startIcon={((downloadingState === true)) && <CircularProgress size={20} color="inherit" />}
                                >
									{((downloadingState === true) ? StringTranslate.lastInputNsDownLoading : StringTranslate.downloadLastInputNs)}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(evt, data) => props.generateResumePdfLastNitrogen(evt, data)}
                                >
                                    {props.lastInputNsSelected.length <= 0 ? StringTranslate.generateResumePdfWeightingAllLastNitrogenInput : props.formatStringButton(StringTranslate.generateResumePdfWeightingLastNitrogenInput, StringTranslate.generateResumePdfOneWeightingLastNitrogenInput, props.lastInputNsSelected)}
                                </Button>
                            </Box>
                            {/* Boutons en écran Mobile (< md) */}
                            <Stack 
                                sx={{ display: { md: 'none', xs: 'block' }, '& button': { m: 1 }, pt:1 }} 
                                style={{ textAlign: 'center' }} 
                                direction="row" spacing={1}
                            >
                                <IconButton
                                    disabled={actionStarting}
                                    color="error"
                                    size="large"
                                    aria-label="delete Last input(s)"
                                    component="span"
                                    onClick={() => deleteLastInputN()}
                                    sx={{p:0}}
                                >
                                    {(deletingState === true) ? (<CircularProgress size={20} color="inherit" />) : (<DeleteForeverSharpIcon />)}
                                </IconButton>

								<IconButton
                                    disabled={doingSomething}
                                    color="primary"
                                    size="large"
                                    aria-label="rebuild Last input(s)"
                                    component="span"
                                    onClick={(evt, data) => props.generateResumePdfLastNitrogen(evt, lastInputNsSelected)}
                                    sx={{p:0}}
                                >
                                    {(downloadingState === true) ? (<CircularProgress size={20} color="inherit" />) : (<IconPdf />)}
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>

				{/* historique actuelle ou antérieure */}
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item>
							{/* Zone de recherche sur l'année de campagne' */}
							<FormControl sx={{ minWidth: 200 }} size="small">

								<InputLabel id="grouped-select-crop-year-lbl">{StringTranslate.cropYear}</InputLabel>
								<Select
									labelId="grouped-select-crop-year-lbl"
									id="grouped-select-crop-year"
									multiple
									value={cropYearsSelected}
									onChange={handleChangeCropYear}
									input={<OutlinedInput label={StringTranslate.cropYear} sx={{ textAlign: 'start' }} />}
									renderValue={(selected) => selected.join(', ')}
								>
									{/* La campagne en cours sera toujours affichée */}
									<ListSubheader>{StringTranslate.currentCropYear}</ListSubheader>
									<MenuItem value={currentCropYear}>
										<Checkbox checked={cropYearsSelected.includes(currentCropYear)} />
										<ListItemText primary={currentCropYear} />
									</MenuItem>

									{/* CropYears : Liste des campagnes présentes en base de données */}
									<ListSubheader>{StringTranslate.archivedCropYears}</ListSubheader>
									{cropYears.map((cropYear) => (
										(cropYear !== currentCropYear) && (<MenuItem key={cropYear} value={cropYear}>
											<Checkbox checked={cropYearsSelected.includes(cropYear)} />
											<ListItemText primary={cropYear} />
										</MenuItem>)))
									}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
				
                {/* Bouton d'aide */}
                <Grid item xs={6} sm={4 } style={{ textAlign: 'left' }}>
                    <Button color="secondary" variant="text" onClick={() => props.showProfilMenuDialog()}>
                        {StringTranslate.helpAsk}
                    </Button>
                </Grid>
				<Grid item xs={6} sm={8} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center'}}>
					{(bothSupplyType === true) ? (
                        <><ReportProblemRoundedIcon color="error" /><Typography id="totalDoseToExpand">{` ${StringTranslate.totalDoseToExpandAlert}`}</Typography></>
                    ) : (
                        <Typography id="totalDoseToExpand">{(totalDoseToExpand > 0) ? `${StringTranslate.totalDoseToExpand}${numberHelper.fixeDecimal(totalDoseToExpand)} (${unity})`: `${StringTranslate.totalDoseToExpand} --`}</Typography>
                    )}                
				</Grid>
            </Grid>
        );
    }
	
	/* Lance la demande de (RE)calcul pour une à plusieurs parcelles, via les items de derniers apports fournis. */
	onBuildLastInputNs(event, selectedLastInputNs) { //A voir ce que contient 'selectedLastInputNs' (les IDs ???) !
        const { lastNitrogenInputDico, buildLastNitrogenInputs } = this.props;
		const { parcelIdsOfLastInputNsSelected } = this.state;

        let lastInputNsToBuild = [];
        let lastInputNsToBuildCount = 0;
        parcelIdsOfLastInputNsSelected.forEach(itemParcelId => {
            let itemSelected = lodashGet(lastNitrogenInputDico, `[${itemParcelId}]`, undefined);
            if (itemSelected && !itemSelected.isArchived) {
                lastInputNsToBuild.push(itemSelected);
				lastInputNsToBuildCount += 1;
            }
        });

        if (buildLastNitrogenInputs && lastInputNsToBuild && (lastInputNsToBuildCount > 0)) {
			buildLastNitrogenInputs(lastInputNsToBuild);
		}
    }
	
	/* Lance la demande de téléchargement de la prescription des items de derniers apports fournis. */
	onDownLoadLastInputNModulations(event, selectedLastInputNs) { //A voir ce que contient 'selectedLastInputNs' (les IDs ???) !
        const { downloading, lastNitrogenInputDico, /*downloadLastNitrogenInputs, */ authorizeModulation } = this.props;
		const { parcelIdsOfLastInputNsSelected } = this.state;

		// Si le client ne dispose pas des droits de modulation, on l'incite à contacté le commerce
		if (authorizeModulation !== true) {
			this.setState({
				showIncitationModulFumure: true,
			});
			return;
		}

		//On vérifie si on est dans l'appli mobile. Si oui, on interdit le téléchargement mais en indiquant comment faire (utiliser l'appli web sur PC).
        if ((downloading === true) || (IsNativeHoster() === true)) {
            this.setState({ showUseComputerForDownload: true, });
            return;
        }
        //else //sinon, on autorise le téléchargement !

		//Constitue la liste des identifiants visés... 
		// et vérifie (au passage) si le client mélange des modulations de type 'liquide' et 'solide' sur le même export:
        let idsOflastInputNToDownload = [];
		let liquidCounter = 0;
		let solidCounter = 0;
		let excludeIdCounter = 0;
		//let excludeArchivedCounter = 0;
        parcelIdsOfLastInputNsSelected.forEach(itemParcelId => {
            let itemSelected = lodashGet(lastNitrogenInputDico, `[${itemParcelId}]`, undefined);
            if ((itemSelected) && (itemSelected.id > 0)) {

				//comptabilise les modulations de type 'liquide' et les autres ('solide'):
				if ((itemSelected.supplyType === SupplyType.Liquid) || (itemSelected.supplyType === true)) {//TODO: on pourra simlplifié après W de TPi !
					liquidCounter += 1;
				} else {
					solidCounter += 1;
				}

				//comptabilise les derniers apports archivés
				if (itemSelected.isArchived){
					//excludeArchivedCounter += 1;
				}
				//comptabilise les derniers apports sans données de modulation:
				else if ((!itemSelected.hasModulation)){
					excludeIdCounter += 1;
				}
				//Si on a pas de modulation, on ne veut pas les télécharger
				else {
					idsOflastInputNToDownload.push(itemSelected.id);
				}
            }
        });

		//on ne lance pas la rescription direct ! On ouvre l'écran de choix du format :
        /*if (downloadLastNitrogenInputs && idsOflastInputNToDownload) {
			downloadLastNitrogenInputs(idsOflastInputNToDownload);
		}*/
        const withWarning = ((liquidCounter > 0) && (solidCounter > 0)) ? true : false;
        const withExcluding = (excludeIdCounter > 0) ? true : false;
        this.setState({
            openConfirmDownloadModulationDialog: true,
            showFirstStepForDownloadingModulations: withWarning,
            showFirstStepExcludeThemWithoutModulations: withExcluding,
            idsToDownload: idsOflastInputNToDownload, //info de l'avertissement.
        });
    }

    /* Désaffiche la boite de dialog pour le téléchargement des modulations */
    handleCloseConfirmDialogForDownloading = () => {
        this.setState({
            openConfirmDownloadModulationDialog: false,
            showFirstStepForDownloadingModulations: false,
            showFirstStepExcludeThemWithoutModulations: false,
        });
    }

    /* ↓↓↓ Téléchargement des modulation ↓↓↓ */
    /* Etape 1 qui confirme que le client est d'accord avec le fait 
    qu'il téléchargera un zip avec 2 types d'apport différents.
    Etape 2 : affichage du choix du type de téléchargement (SHP ou ISO XML) */
    onGoToNextStepToDownload = () => {
        this.setState({
            showFirstStepForDownloadingModulations: false,
            showFirstStepExcludeThemWithoutModulations: false,
        })
    }

    /* Fonction de confirmation de téléchargement de modulations */
    onConfirmDownloadModulations = () => {
        const { idsToDownload, typeDownloadChoice } = this.state;
        const { downloadLastNitrogenInputs } = this.props;

        //Lance le téléchargement des modulations sélectionées :
        let formatToExport = TypeDownloadChoice.shp;
        switch (typeDownloadChoice) {
            case TypeDownloadChoice.isoXml: {
                formatToExport = 2;
                break;
            }

            case TypeDownloadChoice.rds: {
                formatToExport = 3;
                break;
            }

            default: { // == TypeDownloadChoice.shp
                formatToExport = 1;
                break;
            }
        }
		if (downloadLastNitrogenInputs && idsToDownload && idsToDownload.length > 0) {
			downloadLastNitrogenInputs(idsToDownload, formatToExport);
		}

        this.setState({
            openConfirmDownloadModulationDialog: false,
            showFirstStepForDownloadingModulations: false,
            showFirstStepExcludeThemWithoutModulations: false,
            idsToDownload: [],
        });
    }

    /* Fonction appelée lors du choix du type de téléchargement : SHP ou ISOXML */
    handleChangeTypeDownloadChoice = (event) => {
        this.setState({
            typeDownloadChoice: event.target.value
        });
    }

	handleCellClick = (params) => {
		// Vérifie si on a clicker sur une case pour laquelle on ne veut pas afficher la modulation
		// Et si on a une modulation
		if ((params) && (params.field !== 'adjustmentButton') && (params.field !== '__check__') && (params.field !== 'pdf') && (params.row) && (params.row.hasModulation)) {
			this.onOpenLastNitrogenInputResultDialog(params.row);
		}
	}

	onOpenLastNitrogenInputResultDialog = (row) => {
		const { selectLastNitrogenInput } = this.props;

		this.thumbnailInfosSelectedToShowModulation = row.thumbnailInfos;
		this.parcelIdSelectedToShowModulation = row.parcelId;
		selectLastNitrogenInput(row.parcelId);
		this.setState({
			openLastNitrogenInputResultDialog: true,
		});
	}

	onCloseLastNitrogenInputResultDialog = () => {
		const { unselectLastNitrogenInput } = this.props;

		this.thumbnailInfosSelectedToShowModulation = undefined;
		this.parcelIdSelectedToShowModulation = undefined;
		unselectLastNitrogenInput();
		this.setState({
			openLastNitrogenInputResultDialog: false,
		});
	}

	// Permet la génération du pdf
	onGenerateLastNitrogenFileClick(event, parcelId) {
        if(event) {
            event.preventDefault();
            event.stopPropagation();
        }
        const { lastNitrogenInputDico, generateLastNitrogenInputFile } = this.props;

        try {
            const lastInputNSelected = lastNitrogenInputDico[parcelId];
            generateLastNitrogenInputFile(lastInputNSelected);
        }
        catch (err) { }
    }

    onGenerateResumePdfLastNitrogen(event, lastNitrogen) {
        const { lastNitrogenInputDico, generateResumeLastNitrogenFile } = this.props;
        const { currentRowsOfTable, parcelIdsOfLastInputNsSelected } = this.state;

        let lastNitrogenInputParcelIdsSelected = [];

		if(parcelIdsOfLastInputNsSelected.length > 0) { 
			parcelIdsOfLastInputNsSelected.forEach(parcelId => {
				lastNitrogenInputParcelIdsSelected.push(lastNitrogenInputDico[parcelId].parcelId);
			})
		} else {
			//On récupère les données presente dans le dataGrid et non dans le dico via les idsParcel
			currentRowsOfTable.forEach(row => {
				lastNitrogenInputParcelIdsSelected.push(lastNitrogenInputDico[row.parcelId].parcelId);
			})
		}
        generateResumeLastNitrogenFile(lastNitrogenInputParcelIdsSelected);
    }

    formatStringButton = (textValue, textForOnlyOneValue, datasSelection) => {
        if ((!datasSelection) || (!Array.isArray(datasSelection)) || (textValue.indexOf('{0}') < 0)) {
            if ((textValue) && (textValue !== '')) {
                return textValue;
            } else {
                return textForOnlyOneValue;
            }
        } else if ((textForOnlyOneValue) && (textForOnlyOneValue !== '') && (datasSelection.length === 1)) {
            return textForOnlyOneValue;
        } else {
            return StringTranslate.formatString(textValue, datasSelection.length);
        }
    }

	handleCloseIncitationDialog = () => {
		this.setState({
			showIncitationModulFumure: false,
		});
	}

	render() {
		const { rowsOfTable, currentRowsOfTable, parcelIdsOfLastInputNsSelected, typeDownloadChoice, 
			showUseComputerForDownload, openConfirmDownloadModulationDialog, showFirstStepForDownloadingModulations, showFirstStepExcludeThemWithoutModulations, 
			openConfirmDeleteLastInputNDialog, openDialogOfErrors, openLastNitrogenInputResultDialog, totalDoseToExpand, bothSupplyType, totalDoseSupplyType,
			cropYearsSelected, cropYears, onlyLastInputArchivedSelected, hasLastInputArchievedSelected, showIncitationModulFumure } = this.state;
		const { pageSize, building, downloading, deleting, showProfilMenuDialog, authorizeFertilizer } = this.props;

		return(
			<>
				{/* ↓↓ Partie visuel - affichage Pop d'une erreur ↓↓ */}
				{(openDialogOfErrors === true) && (
					<AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
				)}
                {/* Dialog d'incitation à utiliser le PC et l'appli web pour le téléchargement de modulation(s) */}
                {(showUseComputerForDownload === true) && (
                    <AlertDialog popup={this.popupUseComputerForDownloadDialog} handleChangePopup={this.closeDialogOfErrors} />
                )}

				{/* ↓↓ Partie visuel - affichage Popup pour la lecture des données de fumure ↓↓ */}
				{(openLastNitrogenInputResultDialog) && (
					<Dialog
						sx={{ '& .MuiDialog-paper': { maxHeight: { xs: "100%", md: "90%", lg: "90%" } } }}
						fullWidth={true}
						maxWidth={false}
						fullScreen={this.fullScreen}
						open={openLastNitrogenInputResultDialog}
						onClose={this.onCloseLastNitrogenInputResultDialog}
					>
						<DialogTitle
							sx={{
								m: 0,
								p: 2,
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'center'
							}}
						>
							{(this.thumbnailInfosSelectedToShowModulation && (
								<>
									<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${this.parcelIdSelectedToShowModulation}`} {...this.thumbnailInfosSelectedToShowModulation} />
									<Typography sx={{ fontSize: '1.25rem' }}>
										{this.thumbnailInfosSelectedToShowModulation.parcelName}
									</Typography>
								</>
							))}

							<IconButton
								aria-label="close"
								onClick={this.onCloseLastNitrogenInputResultDialog}
								sx={{
									position: 'absolute',
									right: 8,
									top: 8,
									color: (theme) => theme.palette.grey[500],
								}}
							>
								<CloseIcon />
							</IconButton>
						</DialogTitle>

						<DialogContent dividers sx={{ paddingTop: "20px !important" }}>
							<LastNitrogenInputResult readableMode />
						</DialogContent>

						<DialogActions>
							<Button onClick={this.onCloseLastNitrogenInputResultDialog} variant="text" color="primary">{StringTranslate.close}</Button>
						</DialogActions>
					</Dialog>
				)}

				{/* ↓↓ Partie visuel - affichage Popup pour la suppression des derniers apports ↓↓ */}
				<Dialog open={openConfirmDeleteLastInputNDialog}
					onClose={this.handleCloseConfirmDialogForDeleting}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{StringTranslate.lastInputNDeleteDialogTitle}</DialogTitle>
	
					<DialogContent>
						<DialogContentText>{StringTranslate.lastInputNDeleteDialogContent}</DialogContentText>
						{(hasLastInputArchievedSelected) && <DialogContentText>{StringTranslate.lastInputDeleteDialogContentWarningArchived}</DialogContentText>}
					</DialogContent>
	
					<DialogActions>
						<Button onClick={this.handleCloseConfirmDialogForDeleting} variant="text" color="error" autoFocus>
							{StringTranslate.cancelDeleteAction}
						</Button>
						<Button onClick={this.onConfirmDeleteLastInputN} variant="contained" color="primary">
							{StringTranslate.confirmDeleteAction}
						</Button>
					</DialogActions>
				</Dialog>

				{/* ↓↓ Partie visuel - affichage Popup pour le téléchargement des modulations ↓↓ */}
				<Dialog open={openConfirmDownloadModulationDialog}
					onClose={this.handleCloseConfirmDialogForDownloading}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{StringTranslate.ColumnMdlToDownLoad}</DialogTitle>
					{((showFirstStepForDownloadingModulations === true) || (showFirstStepExcludeThemWithoutModulations === true)) ?
						<>
							<DialogContent>
								{/* Etape 1 : Attention, 2 types d'apports différents, on affiche l'étape suivante qui est le choix entre l'IsoXml et le shp */}
								<DialogContentText>{(showFirstStepForDownloadingModulations === true) ? StringTranslate.twoSupplyTypesSelected : StringTranslate.someoneWithoutModulSelected}</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleCloseConfirmDialogForDownloading} variant="text" color="error" autoFocus>
									{StringTranslate.goBackToModulationTable}
								</Button>
								<Button onClick={this.onGoToNextStepToDownload} variant="contained" color="primary">
									{StringTranslate.continueStep1}
								</Button>
							</DialogActions>
						</> :
						<>
							<DialogContent>
								{/* Etape 2 : Choix entre l'IsoXml et le shp */}
								<Grid container>
									<Grid item xs={12}>
									<FormControl component="fieldset">
									<FormLabel component="legend">{StringTranslate.dialogSubtitleTypeShape1}</FormLabel>
									<RadioGroup aria-label="downloadChoice" name="downloadChoice" value={typeDownloadChoice} onChange={this.handleChangeTypeDownloadChoice}>
										<FormControlLabel value={TypeDownloadChoice.shp} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceShp} />
										<FormControlLabel value={TypeDownloadChoice.isoXml} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceIsoXml} />
										<FormControlLabel value={TypeDownloadChoice.rds} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceRds} />
									</RadioGroup>
								</FormControl>
									</Grid>
									<Grid item xs={12}>
										<Button 
											color="secondary" variant="text" size="small" 
											onClick={() => showProfilMenuDialog(ProfilIndex.aide_ModulationFormatFichier)}
											sx={{mb:2}}
										>
											{StringTranslate.helpAskModulation}
										</Button>
									</Grid>
								</Grid> 
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleCloseConfirmDialogForDownloading} variant="text" color="error" autoFocus>
									{StringTranslate.cancelDeleteAction}
								</Button>

								<Button onClick={this.onConfirmDownloadModulations} variant="contained" color="primary">
									{StringTranslate.confirmDeleteAction}
								</Button>

							</DialogActions>
						</>
					}
				</Dialog>

				{/* Dialog d'incitation à passer à la typo suppérieure pour le téléchargement de modulation(s) */}
				{(showIncitationModulFumure === true) && (
					<AlertDialog popup={this.popupIncitationModulEtFumureDialog} handleChangePopup={this.handleCloseIncitationDialog} />
				)}
				
				<TableContainer>
					
					<Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Card>
                                <Alert
                                    severity="info"
                                    icon={<InfoOutlined />}
                                >
                                    <Typography>{StringTranslate.lastInputNGenerationInfo}</Typography>
                                </Alert>
                            </Card>
                        </Grid>
                    </Grid>
					<Grid style={{ width: '100%', textAlign: "center" }} item xs={12}
						sx={{
							'& .coloredCell': {
								backgroundColor: 'grey.100',
							}
						}}
					>
						{/* partie tableau */}
						<CustomDataGrid
							tableName={TableType.lastNitrogenInput}
							pageSize={pageSize}

							Toolbar={this.customToolbar}
							toolbar={{
                                lastInputNsSelected: parcelIdsOfLastInputNsSelected,
								onlyLastInputArchivedSelected: onlyLastInputArchivedSelected,
								buildingState: building,
								downloadingState: downloading,
								deletingState: deleting,
								totalDoseToExpand: totalDoseToExpand,
								totalDoseSupplyType: totalDoseSupplyType,
                                bothSupplyType: bothSupplyType,
                                formatStringButton: (generalText, textForOneItem, data) => this.formatStringButton(generalText, textForOneItem, data),
								buildLastInputNs: (evt, listItems) => this.onBuildLastInputNs(evt, listItems),
								downLoadLastInputNModulations: (evt, listItems) => this.onDownLoadLastInputNModulations(evt, listItems),
                                deleteLastInputN: () => this.onDeleteLastInputN(),
                                generateResumePdfLastNitrogen: (evt, data) => this.onGenerateResumePdfLastNitrogen(evt, data),

								authorizeFertilizer: authorizeFertilizer,
								
								currentCropYear: this.currentCropYear, //année de campagne en cours
								cropYears: cropYears, //Années de campagne présentes dans les fumures actuelles et archivées
								cropYearsSelected: cropYearsSelected, //Années de campagne sélectionnées dans la zone de filtre
								handleChangeCropYear: (event) => this.requestSearch(event.target.value, rowsOfTable),
							}}
							
							columns={this.columnsTableDefinition}
							rows={currentRowsOfTable}

							checkBoxActive={true}
							disableSelectionOnClick={true}
							selectionModel={parcelIdsOfLastInputNsSelected}
                            onSelectionModelChange={(newParcelIdsOfLastInputNsSelected) => { //Sélection des biomasses par clic checkbox
                                this.setLastInputNsSelected(newParcelIdsOfLastInputNsSelected);
                            }}

							onCellClick={this.handleCellClick}
						/>
					</Grid>
				</TableContainer>
		</>);
	}
}


const mapStateToProps = state => ({
	lastNitrogenInputDico: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDico', {}),
	lastNitrogenInputDicoCounter: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDicoCounter', 0),
	lastNitrogenInputDicoIsLoaded: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDicoIsLoaded', false),
	
    culturesForFertilizing: lodashGet(state, 'fertilizerData.culturesForFertilizing', []), 
	closingDateAzofert: lodashGet(state, 'fertilizerData.closingDateAzofert', ConstantsFertilizer.DefaultClosingDateAzofert),
    openingDateAzofert: lodashGet(state, 'fertilizerData.openingDateAzofert', ConstantsFertilizer.DefaultOpeningDateAzofert),

	errorMessage: lodashGet(state, 'lastNitrogenInputData.errorMessage', undefined),
	building: lodashGet(state, 'lastNitrogenInputData.building', false),
	downloading: lodashGet(state, 'lastNitrogenInputData.downloading', false),
	deleting: lodashGet(state, 'lastNitrogenInputData.deleting', false),

	pageSize: lodashGet(state, 'settingsData.settings.rowsPerPageForTableParcels', 20),
	
    thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),

    authorizeFertilizer: lodashGet(state, 'clientUserData.clientDatas.authorizeFertilizer', false),
    authorizeModulation: lodashGet(state, 'clientUserData.clientDatas.authorizeModulation', false),
}); 

const mapDispatchToProps = dispatch => ({
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),

	getAllLastNitrogenInputs: (clientId) => dispatch(ActionGetAllLastNitrogenInputs(clientId)),

	deleteLastNitrogenInput: (parcelIdsToDelete) => dispatch(ActionDeleteLastNitrogenInput(parcelIdsToDelete)),

	clearLastNitrogenInputError: () => dispatch(ActionClearLastNitrogenInputError()),

	buildLastNitrogenInputs: (selectedLastInputNs) => dispatch( ActionBuildLastNitrogenInputs(selectedLastInputNs) ),

	downloadLastNitrogenInputs: (selectedLniIds, formatToExport) => dispatch( ActionBuildLastNitrogenInputListPrescriptions(selectedLniIds, formatToExport) ),

	goToSelectImage: (parcelId) => dispatch(ActionGoToSelectImage(parcelId)),

	selectLastNitrogenInput: (parcelId) => dispatch(ActionSelectLastNitrogenInput(parcelId)),
	unselectLastNitrogenInput: () => dispatch(ActionUnselectLastNitrogenInput()),
	goToModifyModulationForLastInputN: (parcelId) => dispatch(ActionGoToModifyModulation(parcelId)),
    generateLastNitrogenInputFile: (lastInputNSelected) => dispatch(ActionGenerateFileOfThisLastNitrogen(lastInputNSelected)),
    generateResumeLastNitrogenFile: (lastNitrogenInputParcelIdsSelected) => dispatch(ActionGenerateFileOfResumeLastNitrogen(lastNitrogenInputParcelIdsSelected)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LastNitrogenInputsList);
