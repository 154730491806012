import { get as lodashGet } from 'lodash';
import { ActionSelectParcel, ActionGoToBiomassFromMap } from '../actions/contextApp';
import { ActionAddTheLastSelectedSatImagesToSetOnMap, ActionGetRangeAndSelectParcel } from '../actions/satImage.js';
import { biomassWebApiProvider } from '../../utils/webApiProvider.js';
import ConstantsLayers from '../../utils/constantsOfLayers.js';
import { 
    ActionSelectLayerOfParcelForBiomass, 
    ActionUpdateParcelsAfterBiomassesDeleted,
    ActionUpdateParcelAfterBiomassAdded
 } from '../actions/parcels';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';// et utilisé comme ceci: ${StringTranslate.xxx}
import { IsNativeHoster } from '../../utils/platformHelper';


export const GO_TO_TAB_OF_BIOMASS = 'GO_TO_TAB_OF_BIOMASS';
export const CLEAR_NEW_BIOMASS = 'CLEAR_NEW_BIOMASS';
export const GO_TO_STEP_OF_BIOMASS = 'GO_TO_STEP_OF_BIOMASS';
export const SELECT_PARCELS_FOR_BIOMASS = 'SELECT_PARCELS_FOR_BIOMASS';
export const SELECT_BIOMASS = 'SELECT_BIOMASS';
export const ERROR_LOAD_BIOMASSES = 'ERROR_LOAD_BIOMASSES';
export const LOAD_BIOMASSES = 'LOAD_BIOMASSES';
export const LOADING_BIOMASSES = 'LOADING_BIOMASSES';
export const DELETING_BIOMASS = 'DELETING_BIOMASS';
export const DELETING_BIOMASSES = 'DELETING_BIOMASSES';
export const DELETE_BIOMASS = 'DELETE_BIOMASS';
export const DELETE_BIOMASSES = 'DELETE_BIOMASSES';
export const SELECT_LAYER_FOR_BIOMASS = 'SELECT_LAYER_FOR_BIOMASS';
export const CLEAR_BIOMASS_VALUE = 'CLEAR_BIOMASS_VALUE';
export const START_BUILD_BIOMASS = 'START_BUILD_BIOMASS';
export const END_BUILD_BIOMASS = 'END_BUILD_BIOMASS';
export const ERROR_BUILD_BIOMASS = 'ERROR_BUILD_BIOMASS';
export const UP_NEW_BIOMASS = 'UP_NEW_BIOMASS';
export const ASK_FOR_ENDING_BIOMASS = 'ASK_FOR_ENDING_BIOMASS';
export const GENERATING_BIOMASS_FILE = 'GENERATING_BIOMASS_FILE';
export const BIOMASS_FILE_GENERATED = 'BIOMASS_FILE_GENERATED';
export const CLEAN_ERROR = 'CLEAN_ERROR';

export const ActionTypeBiomass = {
    CLEAR_NEW_BIOMASS: CLEAR_NEW_BIOMASS,
    CLEAR_BIOMASS_VALUE: CLEAR_BIOMASS_VALUE,

    GO_TO_TAB_OF_BIOMASS: GO_TO_TAB_OF_BIOMASS,
    GO_TO_STEP_OF_BIOMASS: GO_TO_STEP_OF_BIOMASS,
    
    SELECT_PARCELS_FOR_BIOMASS: SELECT_PARCELS_FOR_BIOMASS,
    SELECT_BIOMASS: SELECT_BIOMASS,
    SELECT_LAYER_FOR_BIOMASS: SELECT_LAYER_FOR_BIOMASS,

    ERROR_LOAD_BIOMASSES: ERROR_LOAD_BIOMASSES,
    ERROR_BUILD_BIOMASS: ERROR_BUILD_BIOMASS,

    LOAD_BIOMASSES: LOAD_BIOMASSES,
    LOADING_BIOMASSES: LOADING_BIOMASSES,
    
    START_BUILD_BIOMASS: START_BUILD_BIOMASS,
    END_BUILD_BIOMASS: END_BUILD_BIOMASS,
    UP_NEW_BIOMASS: UP_NEW_BIOMASS,
    ASK_FOR_ENDING_BIOMASS: ASK_FOR_ENDING_BIOMASS,

    DELETING_BIOMASS: DELETING_BIOMASS,
    DELETING_BIOMASSES: DELETING_BIOMASSES,
    DELETE_BIOMASS: DELETE_BIOMASS,
    DELETE_BIOMASSES: DELETE_BIOMASSES,

    GENERATING_BIOMASS_FILE: GENERATING_BIOMASS_FILE,
    BIOMASS_FILE_GENERATED: BIOMASS_FILE_GENERATED,
    
    CLEAN_ERROR: CLEAN_ERROR,
}

/**
 * Les différents Tabs de la Biomass
 */
export const BiomassTab = {
    NewBiomass: 0,
    MyBiomass: 1,
}

/* Les différentes étapes de la pesée de biomasse */
export const BiomassStep = {
    CHOIX_PARCELS: 'CHOIX_PARCELS',
    MAP: 'MAP',
    WEIGHTING: 'WEIGHTING',
}

export const ActionGoToTabOfBiomass = (biomassTab) => ({
    type: GO_TO_TAB_OF_BIOMASS,
    biomassTab: biomassTab,
})

export const ActionCleanErrorBiomass = () => ({
    type: CLEAN_ERROR,
})

export function ActionAskForEndingBiomass(hasAskedForEndingWinterValue) {
    return {
        type: ASK_FOR_ENDING_BIOMASS,
        hasAskedForEndingBiomass: hasAskedForEndingWinterValue
    }
}

///////////////////////////////////////////////////////////////// ↓↓ CLEAR ↓↓ 
export function ActionClearNewBiomassCounter() {
    return {
        type: CLEAR_NEW_BIOMASS,
    }
}

export function ActionClearBiomassValue() {
    return {
        type: CLEAR_BIOMASS_VALUE
    }
};
///////////////////////////////////////////////////////////////// ↑↑ CLEAR ↑↑

/**
 * Action qui permet le chargement des bons mois de l'année dans le cas d'une pesée de colza
 * @param {Number} parcelId id de la parcelle 
 */
export function ActionLoadImagesForBiomass(parcelId) {
    return function (dispatch, getState) {
        const currentStoreState = getState();
        const hasAskedForEndingBiomass = lodashGet(currentStoreState,"biomassData.hasAskedForEndingBiomass");

        dispatch(
            ActionGetRangeAndSelectParcel(hasAskedForEndingBiomass, parcelId, (currentStoreState, parcelId) => {
                //Plus besoin d'aller la chercher dans le biomassDico car on la récupère dans l'entité de parcelle plus tôt (sauf si elle est null dans l'entité parcelle)
                // On va chercher la date de l'image d'entree d'hiver pour la parcelle...
                const biomassDico = lodashGet(currentStoreState, 'biomassData.biomassDico');
                let beginWinterImgDate = undefined;

                for (const biomass of Object.values(biomassDico)) {
                    if (biomass.parameter) {
                        if ((biomass.parameter.parcelId === parcelId) && (biomass.parameter.isBeginningWinter === true)) {
                            beginWinterImgDate = biomass.parameter.dateImagSource;
                        }
                    }
                }

                return beginWinterImgDate;
            })
        );        
    }
}

export function ActionGoToNextStepOfBiomass(nextStepValue, parcelIdsSelectedForBiomassValue, readableModeValue, biomassSelectedItemValue = undefined) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        dispatch(ActionSelectParcelsForBiomass(parcelIdsSelectedForBiomassValue));

        dispatch(ActionGoToStepOfWeightingBiomass(nextStepValue, readableModeValue)); //@@faudrait fusionner les deux appels car je sent le bogue planner !

        if (nextStepValue === BiomassStep.MAP) {

            dispatch(ActionSelectParcel(parcelIdsSelectedForBiomassValue[0]));

            dispatch(ActionLoadImagesForBiomass(parcelIdsSelectedForBiomassValue[0]));
            

            //Mémoriser les dates des images courantes des parcelles sur la carte pour pouvoir les remettre à la fin de la fumure, biomasse ou modulation
            const currentStoreState = getState();
            parcelIdsSelectedForBiomassValue.forEach(parcelId => {
                const satimageId = lodashGet(currentStoreState, `parcelsData.parcelDico[${parcelId}].currentSatimageId`, null);
                const satImageDate = lodashGet(currentStoreState, `parcelsData.parcelDico[${parcelId}].currentSatimageDate`, null);
                
                if ((satimageId !== null) && (satImageDate !== null)) {
                    dispatch( ActionAddTheLastSelectedSatImagesToSetOnMap(parcelId, satimageId, satImageDate) );
                }
            });
        } else if (biomassSelectedItemValue) {
            //Ce cas de figure est proche de 'ActionGoToShowThisWeightingBiomass' (cas de consultation d'une pesée de biomasse de colza), 
            // mais où l'on souhaite (possibilité) pouvoir refaire /modifier la pesée de biomasse colza !

            dispatch(ActionSelectParcel(biomassSelectedItemValue.parcelId));

            //Si on n'a pas l'image, on l'obtient:
            if ((!biomassSelectedItemValue.dataImg) && (!biomassSelectedItemValue.azureImagURL)) {
                biomassWebApiProvider.loadImagWeightingBiomass(dispatch, getState, biomassSelectedItemValue)
                    .then((newBiomass) => {
                        //Affiche l'entité modulation actualisée:                       
                        dispatch(ActionSelectBiomass(newBiomass, readableModeValue));
                    },
                    (error) => {
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorLoadWeightingBiomasses(error));

                        //Affiche l'entité peséze de biomasse colza (sans son image):
                        dispatch(ActionSelectBiomass(biomassSelectedItemValue, readableModeValue));
                    });
            } else {
                dispatch(ActionSelectBiomass(biomassSelectedItemValue, readableModeValue));
            }
        }
    }
}

export function ActionGoToShowThisBiomass(biomassSelectedItem, isAskedFromMap = false) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if (biomassSelectedItem) {
            const readableMode = true;

            dispatch(ActionSelectParcelsForBiomass([biomassSelectedItem.parameter.parcelId]));

            dispatch(ActionSelectParcel(biomassSelectedItem.parameter.parcelId));

            dispatch(ActionNewBiomassBuilding());

            if (isAskedFromMap === true) {
                dispatch(ActionGoToBiomassFromMap());
            }
            else {
                dispatch(ActionGoToStepOfWeightingBiomass(BiomassStep.WEIGHTING, readableMode));
            }
            
            //Si on n'a pas l'image, on l'obtient:
            if ((!biomassSelectedItem.dataImg) && (!biomassSelectedItem.azureImagURL)) {
                biomassWebApiProvider.loadImagBiomass(dispatch, getState, biomassSelectedItem)
                    .then((newBiomass) => {
                        //Affiche l'entité pesée de biomasse colza actualisée:                    
                        dispatch(ActionSelectBiomass(newBiomass, readableMode));
                    },
                    (error) => {
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorLoadWeightingBiomasses(error));

                        //Affiche l'entité pesée de biomasse colza (sans son image):
                        dispatch(ActionSelectBiomass(biomassSelectedItem, readableMode));
                    });
            } else {
                dispatch(ActionSelectBiomass(biomassSelectedItem, readableMode));

            }
            dispatch(ActionEndBiomassBuilding());
            
        } //else //ne fait rien !
    }
}

/*export*/ const ActionGeneratingBiomassFile = () => ({
    type: GENERATING_BIOMASS_FILE,
})

/*export*/ const ActionBiomassFileGenerated = () => ({
    type: BIOMASS_FILE_GENERATED,
})

export function ActionGenerateFileOfThisBiomass(biomassSelectedItem) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if (biomassSelectedItem) {
            //Affiche l'attente:
            dispatch( ActionGeneratingBiomassFile() );

            //Génère le pdf de la ou les pesées de cette parcelle:
            //biomassWebApiProvider.GenerateBiomassPdf(dispatch, getState, biomassSelectedItem, false, true) //demande le stockage du fichier Pdf !
            biomassWebApiProvider.GenerateBiomassPdf(dispatch, getState, biomassSelectedItem, false, false) //demande le téléchargement du fichier Pdf !
                .then((respDatas) => {
                    //Supprime l'attente:
                    dispatch( ActionBiomassFileGenerated() );
                    
                    //A CE JOUR : on reçoit : 
                    // - Rien, si on est sur l'appli Web;
                    // - l'URL du fichier stocké sur le compte Azure, si on est sur l'appli mobile.
                    //Dans ce second cas, il faut transmettre à l'appli native cette url pour lancer son affichage !
                    if ((IsNativeHoster() === true) && (respDatas !== '') && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp) {
                        userAgentAuthent && userAgentAuthent.commProviderWithNativeApp.askShowUrl(respDatas); //'respDatas' est une URL !
                    }
                },
                (error) => {
                    //Supprime l'attente:
                    dispatch( ActionBiomassFileGenerated() );
                    
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorBuildBiomass(error));
                });
        } //else //ne fait rien !
    }
}

export function ActionGenerateFileOfResumeBiomass(parcelSelectedItems) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        //Affiche l'attente:
        dispatch( ActionGeneratingBiomassFile() );

        // Attention 'parcelSelectedItems' est un tableau d'identifiants
        // ET peut être vide !!! => tout générer !
        let idsOfParcelBiomass = [];
        if (parcelSelectedItems && Array.isArray(parcelSelectedItems) && (parcelSelectedItems.length > 0)) { 
            idsOfParcelBiomass = parcelSelectedItems;
        } //else //le tableau sera transmis vide pour générer toutes les pesées existantes en BdD pour ce client !
        
        //Génère le pdf de la ou les pesées de cette parcelle:
        //biomassWebApiProvider.GenerateBiomassPdfResume(dispatch, getState, idsOfParcelBiomass, false, true) //demande le stockage du fichier Pdf !
        biomassWebApiProvider.GenerateBiomassPdfResume(dispatch, getState, idsOfParcelBiomass, false, false) //demande le téléchargement du fichier Pdf !
            .then((respDatas) => {
                //Supprime l'attente:
                dispatch( ActionBiomassFileGenerated() );
                
                //A CE JOUR : on reçoit : 
                // - Rien, si on est sur l'appli Web;
                // - l'URL du fichier stocké sur le compte Azure, si on est sur l'appli mobile.
                //Dans ce second cas, il faut transmettre à l'appli native cette url pour lancer son affichage !
                if ((IsNativeHoster() === true) && (respDatas !== '') && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp) {
                    userAgentAuthent && userAgentAuthent.commProviderWithNativeApp.askShowUrl(respDatas); //'respDatas' est une URL !
                }
            },
            (error) => {
                //Supprime l'attente:
                dispatch( ActionBiomassFileGenerated() );
                
                //Avertit du mauvais retour:
                dispatch(ActionErrorBuildBiomass(error));
            });
    }
}

export function ActionGoToStepOfWeightingBiomass(nextStepValue, readableModeValue, openFirstPanelValue = false) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        let newOpenFirstPanelValue = openFirstPanelValue;
        
        //Signale le changement d'étape (avec le mode lecture seule activable sur l'étape de 'carto'):
        dispatch({
            type: GO_TO_STEP_OF_BIOMASS,
            biomassStep: nextStepValue,
            readableMode: (nextStepValue === BiomassStep.MAP) ? false : ((readableModeValue !== undefined) ? readableModeValue : true),
            openFirstPanel: newOpenFirstPanelValue,
        });
    };
}

export const ActionSelectParcelsForBiomass = (parcelIdsSelectedForBiomassValue) => ({
    type: SELECT_PARCELS_FOR_BIOMASS,
    parcelIdsSelected: parcelIdsSelectedForBiomassValue,
});

export function ActionSelectBiomass(biomassSelectedValue, readableModeValue = false) {
    return {
        type: SELECT_BIOMASS,
        biomassSelected: biomassSelectedValue,
        readableMode: readableModeValue,
    }
};

/*export*/ function ActionErrorBuildBiomass(errorValue) {
    return {
        type: ERROR_BUILD_BIOMASS,
        errorMessage: errorValue,
    };
}

/*export*/ function ActionUpNewBiomassCounter(biomassValue) {
    return {
        type: UP_NEW_BIOMASS,
        newBiomass: biomassValue
    };
}

export function ActionErrorLoadWeightingBiomasses(errorValue) {
    return {
        type: ERROR_LOAD_BIOMASSES,
        errorMessage: errorValue,
    };
}

export function ActionGetAllWeightingBiomasses(clientId) {
    return function (dispatch, getState) {
        // 1- informe que l'on va faire la demande d'obtension des pesées de colza du client:
        dispatch(ActionLoadingBiomasses());

        // 2- récupération des pesées de biomasse colza
        biomassWebApiProvider.loadBiomasses(dispatch, getState, clientId)
            .then((biomasses) => {
                //Fournis les données des pesées de biomasse colza du client:
                dispatch(ActionInitBiomassDico(biomasses));

            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadWeightingBiomasses(error));
            });    
    }
}

/*export*/ function ActionInitBiomassDico(biomassDicoValue) {
    return {
        type: LOAD_BIOMASSES,
        biomassDico: biomassDicoValue
    };
}

/*export*/ const ActionLoadingBiomasses = () => ({
    //return {
        type: LOADING_BIOMASSES,
        //};
    });

///////////////////////////////////////////////////////////////// ↓↓ SUPPRESSION ↓↓ 
/*export*/ const ActionDeletingBiomass = () => ({
    type: DELETING_BIOMASS,
})

/*export*/ const ActionDeletingBiomasses = () => ({
    type: DELETING_BIOMASSES,
})

/*export*/ const ActionBiomassDeleted = (biomassIdToDeleteValue) => ({
    type: DELETE_BIOMASS,
    biomassIdDeleted: biomassIdToDeleteValue
})

/*export*/ const ActionBiomassesDeleted = (biomassIdsToDeleteValue) => ({
    type: DELETE_BIOMASSES,
    biomassIdsDeleted: biomassIdsToDeleteValue
})

export function ActionDeleteBiomass(biomassIdToDelete) {
    return function (dispatch, getState) {
        dispatch(ActionDeletingBiomass());

        biomassWebApiProvider.deleteBiomass(dispatch, getState, biomassIdToDelete)
            .then((response) => {
                dispatch(ActionBiomassesDeleted(biomassIdToDelete));
            },
                (error) => {
                    //Avertit du mauvais retour: non !
                    dispatch(ActionBiomassDeleted(undefined));
                });
    }
}

export function ActionDeleteBiomasses(biomassesToDelete) {
    return function (dispatch, getState) {
        dispatch(ActionDeletingBiomasses());

        let biomassIdsToDelete = [];
        biomassesToDelete.forEach(item => {
            biomassIdsToDelete.push(item.id);
        });

        return biomassWebApiProvider.deleteBiomasses(dispatch, getState, biomassIdsToDelete)
            .then((response) => {
                dispatch(ActionBiomassesDeleted(biomassIdsToDelete));
                // Répercuter les changements de suppression au niveau des parcelles
                dispatch(ActionUpdateParcelsAfterBiomassesDeleted(biomassesToDelete));
                return biomassesToDelete;
            },
            (error) => {
                //Avertit du mauvais retour: non !
                dispatch(ActionBiomassesDeleted(undefined));
            });
    }
}
///////////////////////////////////////////////////////////////// ↑↑ SUPPRESSION ↑↑

///////////////////////////////////////////////////////////////// ↓↓ CHOIX DES COUCHES ↓↓
export function ActionSelectLayerForBiomass(baseLayertypeValue, forewardLayertypeValue) {
    return function (dispatch, getState) {
        //Besoin d'actualiser le choix des couches si une parcelle est sélectionnée et corresponds à un type spécifique 'Parcelle':
        if (forewardLayertypeValue && (
            (forewardLayertypeValue === ConstantsLayers.VisibleParcelLayerName) || (forewardLayertypeValue === ConstantsLayers.NdviParcelLayerName))) {
            const currentStoreState = getState();
            // 1- récupération de la parcelle sélectionnée
            const currentParcelIdSelected = lodashGet(currentStoreState, 'contextAppData.parcelIdSelected', -1);
            if (currentParcelIdSelected > 0)
                dispatch(ActionSelectLayerOfParcelForBiomass(forewardLayertypeValue, currentParcelIdSelected));
        }

        // Valeurs possibles pour 'baseLayertypeValue' : ConstantsLayers.VisibleBaseLayerName ou ConstantsLayers.RoadBaseLayerName !
        // Valeurs possibles pour 'forewardLayertypeValue' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
        return dispatch({
            type: SELECT_LAYER_FOR_BIOMASS,
            baseLayertypeForBiomass: baseLayertypeValue,
            forewardLayertypeForBiomass: forewardLayertypeValue,
        });
    }
}
///////////////////////////////////////////////////////////////// ↑↑ CHOIX DES COUCHES ↑↑

///////////////////////////////////////////////////////////////// ↓↓ BUILDING BIOMASS ↓↓
/*export*/ function ActionNewBiomassBuilding() {
    return {
        type: START_BUILD_BIOMASS,
    };
}

/*export*/ function ActionEndBiomassBuilding() {
    return {
        type: END_BUILD_BIOMASS,
    };
}
///////////////////////////////////////////////////////////////// ↑↑ BUILDING BIOMASS ↑↑

export function buildBiomassSampleAndQuantities(idClient, biomassParameter) {
    return function (dispatch, getState) {
        const currentStoreState = getState();
        const canBuildFromTypo = lodashGet(currentStoreState, 'clientUserData.clientDatas.authorizeBiomass', false);

        if (canBuildFromTypo === true) { //Droit accordé !
            //Lance l'attente de génération:
            dispatch(ActionNewBiomassBuilding());

            biomassWebApiProvider.buildBiomassSampleAndQuantities(dispatch, getState, idClient, biomassParameter)
                .then((biomass) => {
                    //Si le retour est positif mais qu'il indique une 'invalidité' du nombre de zones, on avertit l'utilisateur:
                    if (biomass && (biomass.isValidZones === false)) {
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorBuildBiomass(
                            StringTranslate.formatString(StringTranslate.unvalidZonesFromBuild, (biomassParameter.zonesCounter - 1))
                        ));
                    } else { //on est ok, on affiche le résultat !
                        //RQ: Appelé sans fournir la teneur (en principe) donc pas besoin de faire appel à 'clearValuesIfContentDefined' !
                        // Assigne les nouvelles données générées:
                        dispatch(ActionSelectBiomass(biomass));

                        //Si on était sur l'étape de choix de la date de cliché, on passe sur l'étape de choix des pesées:
                        const currentStep = lodashGet(currentStoreState, 'biomassData.biomassStep', BiomassStep.MAP);
                        if (currentStep === BiomassStep.MAP) {
                            dispatch(ActionGoToStepOfWeightingBiomass(BiomassStep.WEIGHTING, false));
                            dispatch(ActionUpNewBiomassCounter(biomass));
                            dispatch(ActionUpdateParcelAfterBiomassAdded(biomass));
                        } //else //on est déjà dessus en principe !
                    }
                },
                    (error) => {
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorBuildBiomass(error));
                    }
            );

        }
        //else //Client pas autorisé à générer !
    }
}
