import React from 'react';
import { connect } from 'react-redux';

import lodashGet from 'lodash/get';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import dicoFunction from '../../../datas/dicoDetails.js';

import { ParcelsHelper } from '../../../utils/parcelsHelper.js';
import DateHelper from '../../../utils/dateHelper.js';

import { Typography } from '@mui/material';
import TemplateWidget from './templateWidget.jsx';

///////////////////////////////////////////////////////////////////////////
// fonction de rendu visuel pour la fonctionnalité widget d'une parcelle
///////////////////////////////////////////////////////////////////////////
class WidgetParcel extends React.Component {

    getContentParcelSelected() {
        const { parcelDico, parcelIdSelected } = this.props;
        if (parcelIdSelected <= 0) return;

        // //↓ données parcels (conséquence => nouveau rendu) ↓
        let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdSelected);
        if (!parcel) return;

        //↓↓ Récupération des propriétés/détails de la parcelle ↓↓
        let properties = (parcel.properties) ? ( (parcel.properties instanceof String) ? JSON.parse(parcel.properties) : parcel.properties ) : null;
        let details = parcel.details || null;

        //↓↓ Création des données de colonnes ↓↓
        const dico = dicoFunction();
        let columnsName = [];
        if (details) {
            for (let detail in details) {
                dico.forEach(column => {
                    if (detail === column.name) {
                        columnsName.push(column.libeleColumn);
                    }
                });
            }
        } else if (properties) {
            properties.forEach(property => {
                columnsName.push(property.key);
            });
        }

        let titleCardHeader = "Parcelle : " + parcel.name;

        let headTable = (
            <TableRow>
                {/* ↓ informations restantes ↓ */}
                {columnsName.map((key, index) => { return <TableCell key={index}><Typography fontWeight='bold'>{key}</Typography></TableCell> })}
            </TableRow>
        );

        let bodyTable = () => (
            <TableRow>
                {/* ↓ informations restantes ↓ */}
                {Object.keys(details ? details : properties)
                    // .filter(key => !key.includes('pz_')) // on retire les éléments pz_ de la liste (pour l'instant)
                    .map((key, index) => {
                        let propValue = details[key];
                        let value = (propValue) ? propValue : '';
                        if (key.toLowerCase() === 'datesemi') {
                            if (propValue && (propValue instanceof (Date))) {
                                value = DateHelper.formati18n(propValue, 'P');
                            }
                            else if (propValue && (DateHelper.getDateFromString(propValue) instanceof Date)) {
                                value = DateHelper.formati18n(new Date(propValue), 'P');
                            }
                        }
                        else if (key.toLowerCase() === 'surface') {
                            if(parcel.area && (parcel.area > 0)) {
                                value = parcel.area;
                            }
                        }
                        return <TableCell key={index}>{value}</TableCell>
                    })}
            </TableRow>
        );

        return (
            <TemplateWidget 
                titleCardHeader={titleCardHeader}
                headTable={headTable}
                bodyTable={bodyTable}
                isTable={true}
                dataIsLoaded={true} // on considère que les données ont été chargées pour simplifier le code (sinon, on devrait afficher un message d'attente)

            />
        )
    }

    render() {
        return (
            this.getContentParcelSelected()
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        parcelIdSelected: (state && state.contextAppData) ? state.contextAppData.parcelIdSelected : -1,
        parcelDico: (state && state.parcelsData) ? state.parcelsData.parcelDico : {},
        settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client
    };
}

export default connect(mapStateToProps, null)(WidgetParcel);