import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';

import { ParcelsHelper } from '../../../utils/parcelsHelper.js';
import DateHelper from '../../../utils/dateHelper.js';

import { ActionGetAllHarvests, ActionGoToTab, HarvestTab, HarvestStep, ActionGoToStepOfHarvest, ActionSelectIdsParcelsToStartManagement } from '../../../redux/actions/harvest';
import { ActionGoToHarvest } from '../../../redux/actions/contextApp.js';

import lodashGet from 'lodash/get';

/* Theme Berry */
import TemplateWidget from './templateWidget';


function WidgetHarvest(props) {
    const { clientId, getAllHarvest } = props;

    useEffect(() => {
        if (getAllHarvest && (clientId > 0)) {
            getAllHarvest(clientId);
        }
    }, [clientId, getAllHarvest]);

    const handleCreateHarvestClick = (event, data) => {
        const { goToStep, setTab, goToHarvest, selectIdParcelsToStartManagement } = props;
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'harvestPage' });

        setTab(HarvestTab.Management);

        let newParcelIdsSelected = [data];

        selectIdParcelsToStartManagement(newParcelIdsSelected);
        if (goToHarvest) {
            goToHarvest(newParcelIdsSelected);
            goToStep(HarvestStep.MANAGEMENT);
        }
    }

    const handleShowHarvest = (event, data) => {
        const { goToHarvest, setTab } = props;
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'harvestPage' });

        let newParcelIdsSelected = [data.parcelId];

        if (goToHarvest) {
            goToHarvest(newParcelIdsSelected);
            setTab(HarvestTab.Summary);
        }
    }

    const getTemplate = () => {
        const { parcelDico, parcelIdSelected, harvestDico, harvestDicoLoading } = props;

        if (parcelIdSelected <= 0) return;

        let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdSelected);
        if (!parcel) return;

        let harvests = undefined;
        let dataIsLoaded = null;

        if (harvestDico !== undefined) {
            for (const key in harvestDico) {
                const harvestItem = harvestDico[key];
                if (harvestItem && (harvestItem.parcelId === parcelIdSelected)) {
                    if (harvests === undefined) {
                        harvests = [];
                    }
                    harvests.push(harvestItem);
                }
            }
        }
        if (harvests !== undefined) {
           dataIsLoaded = true;
        } else if (harvestDicoLoading === true) {
            dataIsLoaded = null;
        } else {
            dataIsLoaded = false;
        }

        let labelButton = StringTranslate.newDateHarvestTitle;
        let labelLoading = StringTranslate.loadingHarvestRecords;
        let titleCardHeader = StringTranslate.harvestsExistsTitle;
        let generateData = (event) => {handleCreateHarvestClick(event, parcelIdSelected)};

        // Création de l'entête du tableau
        let headTable = (
            <TableRow key="rowHead" className="widget-color">
                <TableCell><Typography fontWeight='bold'>{StringTranslate.percentage}</Typography></TableCell>
                <TableCell><Typography fontWeight='bold'>{StringTranslate.harvestSowingdate}</Typography></TableCell>
                <TableCell><Typography fontWeight='bold'>{StringTranslate.harvestFloweringDate}</Typography></TableCell>
                <TableCell><Typography fontWeight='bold'>{StringTranslate.harvestEstimatedDate}</Typography></TableCell>
            </TableRow>
        );
        // Création du corps du tableau
        let bodyTable = () => (
            harvests.map(harvest => (       
                <TableRow key={`row[${harvest.id}]`} onClick={(event) => handleShowHarvest(event, harvest)} className="widget-color" hover>
                    <TableCell>{harvest.dryOrHumidityThreshold + "% H"}</TableCell>
                    <TableCell>{DateHelper.formati18n(new Date(harvest.sowingDate), 'P')}</TableCell>
                    <TableCell>{(harvest.floweringDate === null) ? '' : DateHelper.formati18n(new Date(harvest.floweringDate), 'P')}</TableCell>
                    <TableCell>{DateHelper.formati18n(new Date(harvest.estimatedDate), 'P')}</TableCell>                 
                </TableRow>
            ))
        );

        return (
            <TemplateWidget 
                labelButton={labelButton}
                labelLoading={labelLoading}
                titleCardHeader={titleCardHeader}
                dataIsLoaded={dataIsLoaded}
                headTable={headTable}
                bodyTable={bodyTable}
                generateData={generateData}
                isTable={true}
            />
        )
    };

    return (
        getTemplate()
    );
}

const mapStateToProps = (state) => {
    return {
        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
        parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
        harvestDicoLoading: lodashGet(state, 'harvestData.loadingHarvests', false),
        harvestDico: lodashGet(state, 'harvestData.harvestDico', {}),
        harvestDicoCounter: lodashGet(state, 'harvestData.harvestDicoCounter', 0),
        clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
        settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client       
    };
}

const mapDispatchToProps = dispatch => ({
    getAllHarvest: (clientId) => dispatch(ActionGetAllHarvests(clientId)),
    goToHarvest: () => dispatch(ActionGoToHarvest()),
    setTab: (tab) => dispatch(ActionGoToTab(tab)),
    goToStep: (nextStep) => dispatch(ActionGoToStepOfHarvest(nextStep)),
    selectIdParcelsToStartManagement: (newParcelIdsSelected) => dispatch(ActionSelectIdsParcelsToStartManagement(newParcelIdsSelected)),
    
})

export default connect(mapStateToProps, mapDispatchToProps)(WidgetHarvest);