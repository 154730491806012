import lodashGet from 'lodash/get';
import lodashSome from 'lodash/some';

import { CornType, Crop } from '../redux/actions/harvest';

export const DefaultHarvestTarget = 32;
export const DefaultHarvestTargets = {
    Grain: [ 15, 30, DefaultHarvestTarget, 34, 35 ],
    Silage: [ 30, DefaultHarvestTarget, 35, 36 ]
};

export const HarvestHelper = {
    /**
     *  fonction permettant de calculer le nombre total de récoltes se trouvant dans le dico
     */
    count(harvestDico) {
        if (!harvestDico) return 0;
        return Object.entries(harvestDico).length;
    },

    /* fonction permettant d'adapter l'item de récolte reçu - le concept de normalisation des données est ici utilisé */
    convertItem(harvestItem) {
        if (!harvestItem) {
            return {};
        }

        //transforme la date (caîne en entité 'Date'):
        harvestItem.sowingDate = (harvestItem.sowingDate && (!isNaN(Date.parse(harvestItem.sowingDate)))) ?
            new Date(harvestItem.sowingDate) : new Date();
        harvestItem.floweringDate = (harvestItem.floweringDate && (!isNaN(Date.parse(harvestItem.floweringDate)))) ?
            new Date(harvestItem.floweringDate) : null;
        harvestItem.generationDate = (harvestItem.generationDate && (!isNaN(Date.parse(harvestItem.generationDate)))) ?
            new Date(harvestItem.generationDate) : new Date();
        harvestItem.estimatedDate = (harvestItem.estimatedDate && (!isNaN(Date.parse(harvestItem.estimatedDate)))) ?
            new Date(harvestItem.estimatedDate) : null;

        return harvestItem;
    },

    /* fonction permettant de passer d'une liste à un dico - le concept de normalisation des données est ici utilisé */
    convertToDico(harvestList) {
        if ((!harvestList) || (!Array.isArray(harvestList))) {
            return {};
        }

        let dicoHarvest = {};
        harvestList.forEach(harvestItem => {
            dicoHarvest[harvestItem.parcelId] = HarvestHelper.convertItem(harvestItem);
        });

        return dicoHarvest;
    },

    /**
     *  fonction permettant de récupérer une entité harvest
     */
    selectHarvestFromDicoByParcelId(harvestDico, parcelId) {
        if ((!harvestDico) || (!parcelId) || (!Number.isInteger(parcelId)) || (parcelId <= 0)) {
            return undefined;
        }

        let harvestFund = undefined;

        try {
            for (const key in harvestDico) {
                if (harvestDico[key].parcelId === parcelId) {
                    harvestFund = true;
                    return harvestDico[key];
                }
            }
        } catch (e) {
            //RAS, juste pour pouvoir s'arrêter, sauf si réelle erreur.
        }

        return harvestFund;
    },

    /**
     * 
     * @param {*} cropValue
     * @returns le type de maïs
     */
    getCornTypeByCrop(cropValue) {
        const crop = cropValue.toLowerCase();

        if ((crop !== undefined) && (crop !== null) && (crop.includes('mais') || crop.includes('maïs'))) {
            if (crop.includes("grain")) {
                return CornType.Grain;
            }
            else if (crop.includes("ensil")) {
                return CornType.Ensilage;
            }
        }

        return CornType.None;
    },

    /**
     * Fonction permettant de savoir si des dates de calendrier de semis doivent être grisées
     * @param {*} date 
     * @returns 
     */
    shouldDisableSowingDates(date) {
        return (date.getTime() < new Date(new Date().getFullYear(), 0, 1).getTime() ||
            date.getTime() > new Date(new Date().getFullYear() + 1, 0, 1).getTime());
    },

    /**
     * Fonction permettant de savoir si les données sont triées en fonction de la date estimée
     * @param {*} données 
     * @returns true si trié, false si non trié
     */
    isSortedByEstimatedDates(datas) {
        for (var i = 0; i < Object.entries(datas).length - 1; i++) {
            if (Object.entries(datas)[i][1].estimatedDate > Object.entries(datas)[i+1][1].estimatedDate) return false;
        }
        return true;
    },

    /**
     * Fonction permettant de savoir si les données sont identiques (comparasion)
     * Concerne l'onglet 1 étape 2  
     * @param {*} data1 (harvest 1) 
     * @param {*} data2 (harvest 2) 
     * @returns true identique, false si non identique
     */
    isSameHarvestData(data1, data2) {
        const enumCrop1 = lodashGet(data1, 'harvestCrop.enumCrop', Crop.None);
        const enumCrop2 = lodashGet(data2, 'harvestCrop.enumCrop', Crop.None);
        const dateSowingDate1 = lodashGet(data1, 'sowingDate', null);
        const dateSowingDate2 = lodashGet(data2, 'sowingDate', null);
        const dateFloweringDate1 = lodashGet(data1, 'floweringDate', null);
        const dateFloweringDate2 = lodashGet(data2, 'floweringDate', null);

        if ((data1.clientId === data2.clientId) &&
            (data1.parcelId === data2.parcelId) &&
            (data1.isGrain === data2.isGrain) &&
            (data1.dryOrHumidityThreshold === data2.dryOrHumidityThreshold) &&
            (data1.harvestVarietyId === data2.harvestVarietyId) &&
            (data1.harvestVarietyInfoId === data2.harvestVarietyInfoId) &&
            (enumCrop1 === enumCrop2) &&
            (new Date(dateSowingDate1).getTime() === new Date(dateSowingDate2).getTime()) &&
            (new Date(dateFloweringDate1).getTime() === new Date(dateFloweringDate2).getTime())) {
            //Les données sont identiques
            return true;
        }
        else {
            //Les donénes ne sont pas identiques
            return false;
        }
    },

    /**
     * Fonction permettant d'obtenir le type de maïs en fonction de la varieté
     * @param {Object} variety
     * @param {*} parcelCrop
     * @returns {CornType} le type de maïs
     */
    getCornTypeByVariety(variety, parcelCrop) {
        let result = CornType.None;

        // Sans info de variété, on ne peut rien faire
        if (!variety) {
            return result;
        }

        const parcelCornType = this.getCornTypeByCrop(parcelCrop);

        // Si on a trouvé un type de mais en fonction de la culture de la parcelle, on vérifie la correspondance avec celui de variété trouvée
        if (parcelCornType !== CornType.None) {

            const hasSameTypeAsParcel = lodashSome(variety.varietyInfos, vi => vi.harvestType === parcelCornType);
            // Si la variété possède le même type de récolte que la parcelle, alors on prend celui-là
            if (hasSameTypeAsParcel === true)
                return parcelCornType;
            // Sinon il y a une incohérence entre le type de maïs de la varieté et le type de maïs de la parcelle
            // on prefererra retourner CornType.None et laisser le client choisir
            else 
                return CornType.None;
        }
        // else // si on est passé dans le if, on est déjà sorti donc pas besoin de le précisé

        // On regarde si on a des infos par type de récolte
        const hasInfoForSilage = lodashSome(variety.varietyInfos, vi => vi.harvestType === CornType.Ensilage);
        const hasInfoForGrain = lodashSome(variety.varietyInfos, vi => vi.harvestType === CornType.Grain);

        // Si on en a pour du grain
        if (hasInfoForGrain === true) {
            // Par defaut on considère que si l'on a une varieté mixte, on prendra maïs ensilage
            result = hasInfoForSilage ? CornType.Ensilage : CornType.Grain;
        } 
        else if (hasInfoForSilage === true) {
            result = CornType.Ensilage;
        }
        // cas impossible normalement, mais si on a aucune info de variété alors on retourne 'CornType.None'
        else {
            result = CornType.None;
        }

        return result;
    },

    /**
     * Fonction permettant de savoir si les infos de variétés sont uniques en fonction du type de récolte et du stade
     * @param {Object} varietyInfosList
     * @returns {boolean} areVarietiesUniq
     */
    areVarietiesUniq(varietyInfosList) {
        for (let i = 0; i < varietyInfosList.length; i ++) {
            for (let j = 0; j < varietyInfosList.length; j ++) {
                if (j !== i) {
                    if ((varietyInfosList[i].harvestType > 0) && 
                        (varietyInfosList[i].harvestType === varietyInfosList[j].harvestType) &&
                        (parseInt(varietyInfosList[i].percentageHarvestTarget) > 0) &&
                        (parseInt(varietyInfosList[i].percentageHarvestTarget) === parseInt(varietyInfosList[j].percentageHarvestTarget))) {
                        return false;
                    }
                }
            }
        }
        return true;
    } 
}