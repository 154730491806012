import { globalWebApiProvider } from '../utils/webApiProvider.js';
import dateHelper from '../utils/dateHelper.js';
import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import StoreFunctions from '../utils/storeFunctions.js';
import URLHelper from '../utils/urlHelper.js';
import { isAuthorityForResetPwd, isAuthorityForSignup } from '../utils/authentUtils.js';
import { ConfigAuthent } from "../utils/configAuthent.js";
import CryptoHelper from "../utils/crypto.js";
import AuthentErrorCode from './specificErrorAuthent.js';
import StoreManager from '../redux/store/configureStore.js';
import { StatesConnection, ActionUpdateTokens } from '../redux/actions/connection.js';
import { ActionResetAppDatas } from '../redux/actions/contextApp.js';
import { ActionGoToPage, ActionGoToTabOfPage } from '../redux/actions/contextApp.js';

/*
    Objet permettant de gérer l'authentification, de façon interne; Grace, notamment, à l'application native!
*/
export class InternalAgentApplication { //sémantique inspiré de l'objet de MSal...
    constructor(options) { //il aura la propriété 'auth'
        this.configAuth = options.auth;

        this.commProviderWithNativeApp = undefined;
        
        this.tokenReceivedCallback = undefined; //deprecated
        this.errorReceivedCallback = undefined; //deprecated
        this.authResponseCallback = undefined;

        //version de l'application native héberguant l'application web:
        this.nativeAppVMaj = undefined;
        this.nativeAppVMin = undefined;
        this.nativeAppVRel = undefined; //release. pour info !
        
        //contient la version de l'application web et la version minimale compatible de l'application native avec l'application web:
        this.infosVersion = undefined;

        this.accountFromNativeApp = undefined;
        this.needUpdateNativeApp = undefined; //false ou true !
        this.authentStarting = undefined; //false ou true ! Pour éviter plusieurs appels (et donc tentatives) à l'authent.
        this.datasOfAccount = undefined; //objet passé en retour de la méthode 'getAccount'
        this.datasOfToken = undefined; //objet passé à la méthode de callback 'CallbackAuthenticated' si tout est ok!

        //Propriétés dont l'application web peut/fait (déjà) appel (comme si c'était MSal) !
        this.baseAuthority = undefined;
        this.authority = undefined;
        this.policy = ConfigAuthent.ConstAndDefault.SignInPolicyROPC;
        if (this.configAuth && this.configAuth.instance && this.configAuth.tenant) { 
            this.baseAuthority = `${ConfigAuthent.instance}${ConfigAuthent.tenant}`;
            this.authority = `${ConfigAuthent.instance}${ConfigAuthent.tenant}/${this.policy}`;
        }
        
        //modifié par l'action de redux demandant la fermeture de l'appli (en plus d'une déconnexion) lors d'un fonctionnement via l'appli native.
        this.closeApp = false; 
        this.notifyNativeApp = true; //seule exception, la demande de suppression du compte et données.
    }

    handleRedirectCallback(authOrTokenCallback, errorReceivedCallback) {
        if (!authOrTokenCallback) {
            this.logger.warning("Please get 'authOrTokenCallback' on 'handleRedirectCallback' !");
            return;
        }

        // Set callbacks
        if (errorReceivedCallback) {
            this.tokenReceivedCallback = authOrTokenCallback;
            this.errorReceivedCallback = errorReceivedCallback;
            this.logger.warning("This overload for callback is deprecated - please change the format of the callbacks to a single callback as shown: (err: AuthError, response: AuthResponse).");
        }
        else {
            this.authResponseCallback = authOrTokenCallback;
        }
    }

    CallbackAuthenticated(datas) {
        if (this.authResponseCallback) {
            this.authResponseCallback(null, datas);
        } else if (this.tokenReceivedCallback) {
            this.tokenReceivedCallback(datas);
        }
        //else //tampis !!! On ne prévient personne...
    }

    CallbackErrorOnAutent(error) {
        if (this.authResponseCallback) {
            this.authResponseCallback(error, null); //doit disposer des propriétés suivantes : name, errorCode et errorMessage et.... code !
        } else if (this.errorReceivedCallback) {
            this.errorReceivedCallback(error); 
        }
        //else //tampis !!! On ne prévient personne...
    }

    ///A la réception du message provenant de la notification Onesignal, on redirige 
    //à la bonne fonctionnalité, au bon onglet.
    redirectToPage(messageData) {
        if ((!messageData) || (messageData.goToPage === "") || (messageData.goToPage === undefined) || (messageData.goToPage === null)) {
            return;
        }
        else {
            StoreManager.store.dispatch( ActionGoToPage(messageData.goToPage) ); 
        }

        if ((messageData.goToTab !== undefined) && (messageData.goToTab !== null) && (messageData.goToTab !== '')) {
            StoreManager.store.dispatch( ActionGoToTabOfPage(messageData.goToTab, messageData.goToPage) );
        } 
    }

    initAuthentProcess(messageData) {
        /*//Pour test / débogage:
        //alert(`initAuthentProcess(${JSON.stringify(messageData)})`);
        if (this.commProviderWithNativeApp) 
            this.commProviderWithNativeApp.sendAlertToNativeApp(`initAuthentProcess(${JSON.stringify(messageData)})`);
        */

        this.authentStarting = false; //false ou true !

        //'messageData' reçoit les infos détaillant l'application native dans laquelle elle vient d'être chargé.
        if (((this.nativeAppVMaj === undefined) || (this.nativeAppVMaj <= 0) || (this.nativeAppVMin === undefined) || (this.nativeAppVMin < 0)) && //on exploitera ce qui a déjà été reçu (en cas de relance de l'authent)
            ((!messageData) || (messageData.versionMaj === undefined) || (messageData.versionMaj <= 0) || 
            (messageData.versionMin === undefined) || (messageData.versionMin < 0)) ) { //versionRelease non-obligatoire !
            this.continueWithoutControlNativeAppVersion(); //on considère que l'appli va fonctionner! Tampis, si elle plante après.
            return;
        } else if ((messageData) && ((this.nativeAppVMaj === undefined) || (this.nativeAppVMaj <= 0) || (this.nativeAppVMin === undefined) || (this.nativeAppVMin < 0))) {
            this.nativeAppVMaj = messageData.versionMaj;
            this.nativeAppVMin = messageData.versionMin;
        }

        //Demande la lecture en BdD, de la version minimale de l'application native compatible (et du même coup, la version actuelle de l'appli Web+API):        
        if (!this.infosVersion) { //si pas déjà demandé!
            globalWebApiProvider.getVersioning()
                //En cas de retour positif, on stocke puis compare les versions. 
                .then( (infosVersion) => { 
                    this.infosVersion = infosVersion;

                    this.controlNativeAppVersion();
                    this.updateOrAuthenticate(messageData);
                })
                //En cas de retour négatif, on considère que l'appli va fonctionner! Tampis, si elle plante après.
                .catch( (error) => {
                    /*let errorMsg = (error && error.response && error.response.payload) ? 
                        error.response.payload : (error && error.message) ? error.message : error;
                    console.log(errorMsg);*/

                    this.controlNativeAppVersion();
                    this.updateOrAuthenticate(messageData);
                });
        } else {
            this.controlNativeAppVersion();
            this.updateOrAuthenticate(messageData);
        }
    }

    controlNativeAppVersion() {
        if (!this.commProviderWithNativeApp) return;

        //Compare que les numéros de version majeure et mineure:
        if (!this.infosVersion) {//si on n'a pas les données, on considère les applis compatible. Tampis si cela ne fonctionne pas à l'utilisation!
            this.needUpdateNativeApp = false;
        } else if (this.nativeAppVMaj && (this.nativeAppVMaj > 0) && (this.nativeAppVMin !== undefined) && (this.nativeAppVMin >= 0) && 
            this.infosVersion.mincompNativMaj && (this.infosVersion.mincompNativMaj > 0) && 
            (this.infosVersion.mincompNativMin !== undefined) && (this.infosVersion.mincompNativMin >= 0)) {
            this.needUpdateNativeApp = (this.nativeAppVMaj < this.infosVersion.mincompNativMaj) || 
                ((this.nativeAppVMaj === this.infosVersion.mincompNativMaj) && (this.nativeAppVMin < this.infosVersion.mincompNativMin));
        } else {//si il nous manque une ou des données, on considère les applis compatible. Tampis si cela ne fonctionne pas à l'utilisation!
            this.needUpdateNativeApp = false;
        }
    }

    updateOrAuthenticate(messageData = undefined) { // 'messageData' n'est pas obligatoire ! Permettra juste de passer à la phase d'authent si elle contient les données nécessaires.
        if (!this.commProviderWithNativeApp) return;

        // ->Si ok, on demandera les infos d'authentification;
        // ->Si KO, on signalera à l'application native la nécessité de mettre à jour l'application !
        if (this.needUpdateNativeApp === true) {
            //On notifie l'appli native pour qu'elle se mette à jour!
            this.commProviderWithNativeApp.notifyNeedUpdateNativeApp(this.infosVersion);
        } else if ((messageData) && (messageData.login) && (messageData.pwdCrypted)) { //@@prendre en compte la langue fournie par l'application native !'
            //L'appli native nous a fournis en même temps que sa version, les infos d'authent qu'elle possédait déjà. On peut lancer l'authent directement!
            this.startAuthentProcess(messageData);
        } else {
            //@@Ici aussi, il faut prendre en compte la langue fournie par l'application native !
            //On fait appel à l'appli native pour nous fournir les infos d'authent:
            this.commProviderWithNativeApp.askDatasAuthent(); //=> le retour d'information se fera par l'appel à la callback 'startAuthentProcess'
        }
    }

    continueWithoutControlNativeAppVersion() {
        if (!this.commProviderWithNativeApp) return;

        //On fait appel à l'appli native pour nous fournir les infos d'authent:
        this.commProviderWithNativeApp.askDatasAuthent(); //=> le retour d'information se fera par l'appel à la callback 'startAuthentProcess'
    }

    startAuthentProcess(messageData) { //Appelé lorsque l'appli native fournis à l'appli web les infos pour s'authentifier
        if (!this.commProviderWithNativeApp) return;

        /*//Pour test / débogage:
        //alert(`startAuthentProcess(${JSON.stringify(messageData)})`);
        //alert(`startAuthentProcess2(${JSON.stringify(messageData)})`);
        if (this.commProviderWithNativeApp) {
            this.commProviderWithNativeApp.sendAlertToNativeApp(`startAuthentProcess(${JSON.stringify(messageData)})`);
            this.commProviderWithNativeApp.sendAlertToNativeApp(`startAuthentProcess2(${JSON.stringify(messageData)})`);
        }*/

        //si un appel est déjà en cours, on n'en fait pas un second:
        if (this.authentStarting === true) return;
        /*//Pour test / débogage:
        //alert(`startAuthentProcess3(${JSON.stringify(messageData)})`);
        if (this.commProviderWithNativeApp) 
            this.commProviderWithNativeApp.sendAlertToNativeApp(`startAuthentProcess3(${JSON.stringify(messageData)})`);
        */

        if ((!messageData) || (!messageData.login) || (!messageData.pwdCrypted)) { //cas d'erreur gérée et signalé à l'appli native afin de relancer l'authent !
            this.commProviderWithNativeApp.notifyAuthentVoidData(StringTranslate.noDatasAuthentFromNativeApp);
            return;
        }
        /*//Pour test / débogage:
        //alert(`startAuthentProcess4(${JSON.stringify(messageData)})`);
        if (this.commProviderWithNativeApp) 
            this.commProviderWithNativeApp.sendAlertToNativeApp(`startAuthentProcess4(${JSON.stringify(messageData)})`);
        */

        this.authentStarting = true;
        this.accountFromNativeApp = { //RQ: Correspond à l'objet C# dérivant de 'Account' !
            email: messageData.login, 
            pwd: messageData.pwdCrypted,
            shaOne: messageData.shaOnePwd,
            //si c'est un cas de création, on aura aussi des infos supplémentaires:
            createClientBefore: messageData.forCreation,
            phone: messageData.phone,
            country: messageData.country,
            givenName: messageData.givenName,
            name: messageData.name,
        };
        
        let needUpdatePasswordBefore = false;
        if (messageData.pwdChanged === true) { //pour la gestion de la modification du mot de passe (saisie depuis l'appli native)
            needUpdatePasswordBefore = true;

            this.accountFromNativeApp.oldPwd = messageData.oldPwdCrypted;
            this.accountFromNativeApp.oldShaOne = messageData.oldShaOnePwd;
        }

        //On demande l'authentification via le même mécanisme que pour la liaison Géofolia !
        globalWebApiProvider.authenticateFromNativeApp(this.accountFromNativeApp, needUpdatePasswordBefore)
            //En cas de retour positif, on stocke puis compare les versions. 
            .then( (accountAndToken) => { 
                this.authentStarting = undefined; //false ou true ! Appel terminé (que la suite soit ok ou pas)

                // ->Si ok, On prévient l'appli native que l'authent est validée et on poursuit dans l'appli avec le jeton d'accès reçu.
                this.commProviderWithNativeApp.notifyClientAuthenticated(accountAndToken.expiresOn);
                
                //Renseinge le nom de cet utilisateur:
                this.accountFromNativeApp.name = messageData.name;
                this.accountFromNativeApp.givenName = messageData.givenName;
                this.accountFromNativeApp.country = messageData.country;

                //Il faut décoder le jeton et contrôler le sha1:
                const bytesValue = CryptoHelper.FromHexString(accountAndToken.token);
                accountAndToken.token = CryptoHelper.DecodeBytesToString(bytesValue, accountAndToken.shaOneToken);
                if ((!accountAndToken.token) || (accountAndToken.token.length <= 0)) {
                    //On invalide ce qui a été reçu:
                    this.accountFromNativeApp = undefined;
                    this.datasOfToken = undefined;

                    const errorMsg = "decode access's token generate an error.";
                    //console.log(errorMsg); //RQ 'notifyAuthentFailed' fera un log dans azure !

                    // ->Si KO, on signalera à l'application native la nécessité de ré-initialiser les données d'authent !
                    // (L'appli native reproposera alors l'écran d'authent ; et on reprendra le scénario depuis le début)
                    this.commProviderWithNativeApp.notifyAuthentFailed(errorMsg, AuthentErrorCode.NoReturnToken);

                    this.CallbackErrorOnAutent({ message: errorMsg, });
                } else {
                    //Définit les données associées au jeton d'accès:
                    const lengthToken = (accountAndToken.token) ? accountAndToken.token.length : 0;
                    const lenIdToken = 50;
                    const dateOfExpiration = (!(accountAndToken.expiresOn instanceof Date)) ? new Date(accountAndToken.expiresOn) : accountAndToken.expiresOn;
                    this.datasOfToken = {
                        tokenType: 'access_token',
                        //account: ..., //sera renseigné après (car 'getAccount()' vérifie la présence de 'this.datasOfToken' !)
                        idToken: {
                            objectId: accountAndToken.id,
                            rawIdToken: accountAndToken.token.substring(0, (lengthToken > lenIdToken) ? lenIdToken : lengthToken), //fausse valeur car on ne transmet pas depuis la Web PAI cette valeur (volontairement) !
                            expiration: (dateOfExpiration.getTime() / 1000),
                            subject: accountAndToken.id,
                            issuer: this.baseAuthority, //@@CAn :: A vérifier si c'est bien la bonne info !
                        },
                        idTokenClaims: {
                            exp: (dateOfExpiration.getTime() / 1000),
                            tfp: this.policy, 
                            emails: [this.accountFromNativeApp.email],
                            idp: 'localAccount', //on n'aura jamais 'facebook.com' ou 'google.com' car l'authent ROPc n'est possible qu'avec un compte local (de l'AD B2C)
                            iss: this.baseAuthority, //@@CAn : A vérifier si c'est bien la bonne info !
                            family_name: this.accountFromNativeApp.name, //name correspond au nom
                            given_name: this.accountFromNativeApp.givenName //givenName correspond au prénom
                        },
                        accessToken: accountAndToken.token,
                        expiresOn: dateOfExpiration,
                    };
                    this.datasOfToken.account = this.getAccount();
                    this.datasOfToken.idToken.claims = this.datasOfToken.idTokenClaims;

                    this.CallbackAuthenticated(this.datasOfToken);
                }
            })
            //En cas de retour négatif, on considère que l'appli va fonctionner! Tampis, si elle plante après.
            .catch( (error) => {
                this.authentStarting = undefined; //false ou true ! Appel terminé (en erreur)
                this.datasOfToken = undefined;

                let errorMsg = (error && error.response && error.response.payload) ? 
                    error.response.payload : (error && error.message) ? error.message : error;
                //console.log(errorMsg); //RQ 'notifyAuthentFailed' fera un log dans azure !

                // Vérifie si on peut extraire un code d'erreur spécifique à l'appel de cette méthode de web API:
                let codeErrorSpecific = AuthentErrorCode.DefaultFailed;
                try
                {
                    if (errorMsg && (typeof(errorMsg) === 'string') && (errorMsg !== '')) {
                        const starterCode = '(SEC_';
                        const indexOfStarterCode = errorMsg.indexOf(starterCode);
                        if (indexOfStarterCode > 0) {
                            const messageWithoutCode = errorMsg.substring(0, indexOfStarterCode);
                            
                            const enderCode = ')';
                            const indexOfEndCode = errorMsg.indexOf(enderCode, indexOfStarterCode);
                            if (indexOfEndCode > indexOfStarterCode) {
                                const stringOfCode = errorMsg.substring(indexOfStarterCode + starterCode.length, indexOfEndCode);
                                try {
                                    const codeInt = parseInt(stringOfCode, 10);
                                    codeErrorSpecific = codeInt;
                                }
                                catch (errParse) {
                                    //'codeErrorSpecific' reste avec la valeur par défaut !
                                }
                            }

                            errorMsg = messageWithoutCode;
                        }
                    }
                }
                catch (errSubstrCode) {
                    errorMsg = (error && error.message) ? error.message : error;    
                }

                // ->Si KO, on signalera à l'application native la nécessité de ré-initialiser les données d'authent !
                // (L'appli native reproposera alors l'écran d'authent ; et on reprendra le scénario depuis le début)
                this.commProviderWithNativeApp.notifyAuthentFailed(errorMsg, codeErrorSpecific);

                this.CallbackErrorOnAutent(error);
            });
    }

    checkWebApplication(messageData) { //Appelé lorsque l'appli native veut un contrôle de l'appli Web !
        if (!this.commProviderWithNativeApp) return;

        //@@
    }

    useAuthentTokensProcess(messageData) { //Appelé lorsque l'appli native signale qu'elle a authentifié l'utilisateur du mobile (et nous fournis directement le jeton d'accès)
        if (!this.commProviderWithNativeApp) return;

        if ((!messageData) || (!messageData.login) || (!messageData.accessToken) || (!messageData.account)) return;

        //Renseinge l'utilisateur:
        this.accountFromNativeApp = { //RQ: Correspond à l'objet C# dérivant de 'Account' !
            email: messageData.login,
            givenName: messageData.givenName, //givenName correspond au prénom
            name: messageData.name, //name correspond au nom
            pwd: undefined,
            shaOne: undefined,
            //si c'est un cas de création, on aura aussi des infos supplémentaires:
            createClientBefore: messageData.forCreation,
            phone: messageData.phone,
            country: messageData.country,
        };

        //Définit les données associées au jeton d'accès: 
        const lengthToken = messageData.accessToken.length;
        const lenIdToken = 50;
        const dateOfExpiration = messageData.expiresOn;
        this.datasOfToken = {
            tokenType: 'access_token',
            account: messageData.account,
            idToken: {
                objectId: messageData.objectId,
                rawIdToken: messageData.accessToken.substring(0, (lengthToken > lenIdToken) ? lenIdToken : lengthToken), //fausse valeur car on ne transmet pas depuis la Web PAI cette valeur (volontairement) !
                expiration: messageData.expiresOn,
                subject: messageData.objectId,
                issuer: messageData.issuer, //@@CAn : A vérifier si c'est bien la bonne info  par rapport à 'baseAuthority' !
            },
            // idTokenClaims: {
            //     exp: (dateOfExpiration.getTime() / 1000),
            //     tfp: messageData.policy,
            //     emails: [messageData.login],
            //     idp: messageData.idp, //'facebook.com' ou 'google.com' ou 'apple.signin.com'
            //     iss: messageData.issuer, //@@CAn : A vérifier si c'est bien la bonne info  par rapport à 'baseAuthority' !
            // },
            idTokenClaims: { 
                ...messageData.account.claims
            },
            accessToken: messageData.accessToken,
            expiresOn: dateOfExpiration,
        };
        this.datasOfToken.idToken.claims = this.datasOfToken.idTokenClaims;

        // authentification via réseau social ? Le client web est il déjà connecté ? On met à jour les jetons d'accès
        const store = (StoreManager.store && StoreManager.store.getState) ? StoreManager.store.getState() : null;
        const stateConnexion = (store && store.connectionData) ? store.connectionData.stateConnexion : null;
        const current_idp = (store && store.connectionData && store.connectionData.account && store.connectionData.account.idToken && store.connectionData.account.idToken.claims) ? store.connectionData.account.idToken.claims.idp : null;
        const native_idp = (messageData && messageData.account && messageData.account.claims) ? messageData.account.claims.idp : null;
        if ((stateConnexion === StatesConnection.connected) && (current_idp === native_idp)) {
            try {
                if (this.datasOfToken.idToken && this.datasOfToken.accessToken && this.datasOfToken.expiresOn)
                    StoreManager.store.dispatch( ActionUpdateTokens(this.datasOfToken) ); 
            } catch (error) {}
            return;
        }

        //Notifie que l'authent est accordée (ce qui sauvegardera l'email et le jeton d'accès afin de l'exploiter lors des appels vers la Web Api): 
        this.CallbackAuthenticated(this.datasOfToken);
    }
    
    controlWebAppVersion(messageData, oldInfosVersion = undefined) {
        if (!this.commProviderWithNativeApp) return;

        //Vérifie en premier lieu que l'appli native est toujours ok avec la version publiée de l'appli Web:
        this.controlNativeAppVersion();
        // ->Si ok, passe à la vérification suivante;
        // ->Si KO, on signalera à l'application native la nécessité de mettre à jour l'application !
        if (this.needUpdateNativeApp === true) {
            //On notifie l'appli native pour qu'elle se mette à jour!
            this.commProviderWithNativeApp.notifyNeedUpdateNativeApp(this.infosVersion);
            return;
        } //else //ok!

        //Vérifie alors si l'appli web chargée est la même que celle publiée:
        if (oldInfosVersion) {
            //Compare que les numéros de version majeure et mineure:
            let needRefreshWebApp = false;
            if (!this.infosVersion) {//si on n'a pas les données, on considère les applis compatible. Tampis si cela ne fonctionne pas à l'utilisation!
                needRefreshWebApp = false;
            } else if (oldInfosVersion.mincompNativMaj && (oldInfosVersion.mincompNativMaj > 0) && 
                (oldInfosVersion.mincompNativMin !== undefined) && (oldInfosVersion.mincompNativMin >= 0) && 
                this.infosVersion.mincompNativMaj && (this.infosVersion.mincompNativMaj > 0) && 
                (this.infosVersion.mincompNativMin !== undefined) && (this.infosVersion.mincompNativMin >= 0)) {
                    needRefreshWebApp = (oldInfosVersion.mincompNativMaj < this.infosVersion.mincompNativMaj) || 
                    ((oldInfosVersion.mincompNativMaj === this.infosVersion.mincompNativMaj) && (oldInfosVersion.mincompNativMin < this.infosVersion.mincompNativMin));
            } else {//si il nous manque une ou des données, on considère l'appli Web n'a pas évolué. Tampis si cela ne fonctionne pas à l'utilisation!
                needRefreshWebApp = false;
            }

            // ->Si ok, passe à la vérification suivante;
            // ->Si KO, on recharge l'application web (et donc les données aussi):
            // pour cela, on le demande à l'appli native...
            if (needRefreshWebApp === true) {
                this.commProviderWithNativeApp.askRefreshWebView();
                return;
            } //else //ok!
        } else if (!this.accountFromNativeApp) { //si on n'a pas les données d'authent (passé), on relance l'appli aussi !
            this.commProviderWithNativeApp.askRefreshWebView();
            return;
        }

        if ((messageData) && (messageData.dateStandBy)) {
            //Vérifie alors si cela fait plus de 48h que l'application a été mise en veille 
            // (ou que les précéedentes sorties de mise en veille n'ont pas provoqué d'actualisation de données):
            let time48hAgo = new Date();
            time48hAgo.setHours(time48hAgo.getHours() - 48);
            const needUpdateDatas = dateHelper.Compare(messageData.dateStandBy, time48hAgo);
            if (needUpdateDatas <= 0) { //la date de mise en veille est antérieure à 48h! => on force le rafraichissement des données !
                this.commProviderWithNativeApp.askClearWakeUpDatas();

                //On simule une authentification qui vient d'être effectué (ainsi, on redemandera alors la lecture des données de l'utilisateur en BdD):
                this.CallbackAuthenticated(this.datasOfToken);
                return;
            }
        }
        //else //considère qu'il n'y a pas besoin d'actualiser les données

        //Vérifie alors si le jeton est toujours 'valide':
        let needUpdateToken = false;
        const dateOfExpir = dateHelper.getDateFromString(this.datasOfToken.expiresOn);
        let time10MinAgo = new Date();
        time10MinAgo.setMinutes(time10MinAgo.getMinutes() - 10);
        needUpdateToken = dateHelper.Compare(dateOfExpir, time10MinAgo);
        // ->Si ok, tout est bon;
        // ->Si KO, on relance l'authent:
        if (needUpdateToken === true) {
            this.datasOfToken = undefined;
            
            const messageData = {
                login: this.accountFromNativeApp.email,
                pwdCrypted: this.accountFromNativeApp.pwd,
                shaOnePwd: this.accountFromNativeApp.shaOne,
                //on ne considère pas le client comme tout juste inscrit !
                forCreation: false,
                //phone: undefined,
                //country: undefined,
            };
            this.startAuthentProcess(messageData);
        }
    }

    //Méthodes  dont l'application web peut/fait (déjà) appel (comme si c'était MSal) !
    getAccount() {
        if (this.accountFromNativeApp && this.datasOfToken && (!this.datasOfAccount)) {
            let phoneObject = this.accountFromNativeApp.phone;

            let countryObject = { name: '', codeCountry: '', };
            if (this.accountFromNativeApp.country && this.accountFromNativeApp.country.name && this.accountFromNativeApp.country.codeCountry) {
                countryObject = this.accountFromNativeApp.country;
            }
            else if (typeof (this.accountFromNativeApp.country) === 'string') {
                //tente la désérialisation :
                try {
                    const countryJson = JSON.parse(this.accountFromNativeApp.country);
                    if (countryJson && countryJson.name && countryJson.codeCountry) {
                        countryObject = countryJson;
                    } else {
                        countryObject.name = this.accountFromNativeApp.country;
                    }
                } 
                catch (error) { 
                    countryObject.name = this.accountFromNativeApp.country;
                }
            }

            this.datasOfAccount = {
                accountIdentifier: (this.datasOfToken.idToken) ? this.datasOfToken.idToken.objectId : undefined,
                homeAccountIdentifier: 'N/A', //CAn : Je ne le renseigne pas car je ne connais pas ca valeur
                userName: this.accountFromNativeApp.name,
                username: this.accountFromNativeApp.name,
                idToken: this.datasOfToken.idToken,
                idTokenClaims: {
                    exp: (this.datasOfToken.idToken) ? this.datasOfToken.idToken.expiration : undefined,
                    tfp: this.policy, 
                    emails: [this.accountFromNativeApp.email],
                    idp: (this.datasOfToken.idToken && this.datasOfToken.idToken.claims && ['facebook.com','google.com','apple.signin.com'].includes(this.datasOfToken.idToken.claims.idp)) ? this.datasOfToken.idToken.claims.idp : 'localAccount',
                    country: countryObject, //Nécessaire pour la création de compte dans webApiProvider.searchOrCreateClientDatasConnected !
                    extension_Telephone: phoneObject,
                    family_name: this.accountFromNativeApp.name, //name correspond au nom
                    given_name: this.accountFromNativeApp.givenName //givenName correspon au prénom
                },
                sid: undefined, //CAn : Info non divulgué via MSAl.. donc idem !
                environment: this.baseAuthority, //@@CAn : A vérifier si c'est bien la bonne info !
            };
        } //else 'null' ou l'objet déjà instancié !

        return this.datasOfAccount;
    }

    loginRedirect(userRequest) { 
        if (!this.commProviderWithNativeApp) { //RQ: je ne peux donc pas appeler (pour tracer) : sendAlertToNativeApp('loginRedirect - this.commProviderWithNativeApp is null !');
            //alert('loginRedirect - this.commProviderWithNativeApp is null !');
            //console.log('loginRedirect - this.commProviderWithNativeApp is null !');
            return;
        }

        //Attention! Si on est configuré pour appeler la ré-initialisation de mot de passe:
        if (isAuthorityForResetPwd(this) === true) {
            const authorityReset = this.authority;

            //Remets l'autorité 'valide':
            this.authority = `${ConfigAuthent.instance}${ConfigAuthent.tenant}/${this.policy}`;

            this.commProviderWithNativeApp.notifyReinitDatasAuthent(authorityReset);
            return;
        } 
        else if (isAuthorityForSignup(this) === true) {
            //Remets l'autorité 'valide':
            this.authority = `${ConfigAuthent.instance}${ConfigAuthent.tenant}/${this.policy}`;
            
            //@@A voir pour gérer le cas d'une demande de passage en mode inscription pour que la page d'authent du nativ passe dans ce mode directement !
        }
        
        //Vérification avant de lancer quoique ce soit:
        //Vérifie que l'on a le minimum d'info:
        // ->Si KO, on demande la relance de l'appli web dans la webView:
        if (!this.accountFromNativeApp) { //si on n'a pas les données d'authent (passé), on relance l'appli aussi !
            //@@faut peut-être regarder avant si on n'a pas les infos provenant de l'authent Google/Facebook!

            this.commProviderWithNativeApp.askRefreshWebView();
            return;
        }

        //Vérifie alors que l'on a les données de la précédente authentification:
        // ->Si KO, on relance l'authent sachant que l'on a les infos du compte ('this.accountFromNativeApp'):
        if (!this.datasOfToken) {
            const messageData = {
                login: this.accountFromNativeApp.email,
                pwdCrypted: this.accountFromNativeApp.pwd,
                shaOnePwd: this.accountFromNativeApp.shaOne,
                //on ne considère pas le client comme tout juste inscrit !
                forCreation: false,
                //phone: undefined,
                //country: undefined,
            };
            this.startAuthentProcess(messageData);
            return;
        }

        //Vérifie alors si le jeton est toujours 'valide':
        let needUpdateToken = false;
        const dateOfExpir = dateHelper.getDateFromString(this.datasOfToken.expiresOn);
        let time10MinAgo = new Date();
        time10MinAgo.setMinutes(time10MinAgo.getMinutes() - 10);
        needUpdateToken = dateHelper.Compare(dateOfExpir, time10MinAgo);
        // ->Si ok, tout est bon;
        // ->Si KO, on relance l'authent:
        if (needUpdateToken === true) {
            this.datasOfToken = undefined;
            
            const messageData = {
                login: this.accountFromNativeApp.email,
                pwdCrypted: this.accountFromNativeApp.pwd,
                shaOnePwd: this.accountFromNativeApp.shaOne,
                //on ne considère pas le client comme tout juste inscrit !
                forCreation: false,
                //phone: undefined,
                //country: undefined,
            };
            this.startAuthentProcess(messageData);
            return;
        }

        // ->Si ok, On prévient l'appli native que l'authent est validée et on poursuit dans l'appli avec le jeton d'accès reçu.
        this.commProviderWithNativeApp.notifyClientAuthenticated(this.datasOfToken.expiresOn);        
        this.CallbackAuthenticated(this.datasOfToken);
    }

    acquireTokenRedirect(userRequest) { //RQ importante: je ne fais que vérifier le besoin de rafraichir le jeton!
        if (!this.commProviderWithNativeApp) return;
        
        //Vérifie juste que l'on est dans l'état attendu pour rafraichir le jeton:
        if ((!this.accountFromNativeApp) || (!this.datasOfToken)) { //sinon, on demande le rafraichissement de l'appli web dans la webView
            this.commProviderWithNativeApp.askRefreshWebView();
            return;
        }

        //Vérifie alors si le jeton est toujours 'valide':
        let needUpdateToken = false;
        const dateOfExpir = dateHelper.getDateFromString(this.datasOfToken.expiresOn);
        let time10MinAgo = new Date();
        time10MinAgo.setMinutes(time10MinAgo.getMinutes() - 10);
        needUpdateToken = dateHelper.Compare(dateOfExpir, time10MinAgo);
        // ->Si ok, tout est bon;
        // ->Si KO, on relance l'authent:
        if (needUpdateToken === true) {
            this.datasOfToken = undefined;
            
            const messageData = {
                login: this.accountFromNativeApp.email,
                pwdCrypted: this.accountFromNativeApp.pwd,
                shaOnePwd: this.accountFromNativeApp.shaOne,
                //on ne considère pas le client comme tout juste inscrit !
                forCreation: false,
                //phone: undefined,
                //country: undefined,
            };
            this.startAuthentProcess(messageData);
            return;
        }

        // ->Si ok, On prévient l'appli native que l'authent est validée et on poursuit dans l'appli avec le jeton d'accès reçu.
        this.commProviderWithNativeApp.notifyClientAuthenticated(this.datasOfToken.expiresOn);        
        this.CallbackAuthenticated(this.datasOfToken);
    }

    //ATTENTION : retourne une 'promesse' !
    acquireTokenSilent(userRequest) { //RQ importante: je ne fais que vérifier le besoin de rafraichir le jeton!
        if (!this.commProviderWithNativeApp) return;
        
        return new Promise(function (resolve, reject) {
            //Vérifie juste que l'on est dans l'état attendu pour rafraichir le jeton:
            if ((!this.accountFromNativeApp) || (!this.datasOfToken)) { //sinon, on demande le rafraichissement de l'appli web dans la webView
                this.commProviderWithNativeApp.askRefreshWebView();
                reject(undefined); //on dire KO, mais c'est sans valeur car l'appli native va relancer l'appli web !
                return;
            }
    
            //Vérifie alors si le jeton est toujours 'valide':
            let needUpdateToken = false;
            const dateOfExpir = dateHelper.getDateFromString(this.datasOfToken.expiresOn);
            let time10MinAgo = new Date();
            time10MinAgo.setMinutes(time10MinAgo.getMinutes() - 10);
            needUpdateToken = dateHelper.Compare(dateOfExpir, time10MinAgo);
            // ->Si ok, tout est bon;
            // ->Si KO, on relance l'authent:
            if (needUpdateToken === true) {
                this.datasOfToken = undefined;
                
                const messageData = {
                    login: this.accountFromNativeApp.email,
                    pwdCrypted: this.accountFromNativeApp.pwd,
                    shaOnePwd: this.accountFromNativeApp.shaOne,
                    //on ne considère pas le client comme tout juste inscrit !
                    forCreation: false,
                    //phone: undefined,
                    //country: undefined,
                };
                this.startAuthentProcess(messageData);
                reject(undefined); //on dire KO, mais c'est sans valeur car on va relancer l'authentification !
                return;
            }
    
            // ->Si ok, On prévient l'appli native que l'authent est validée et on poursuit dans l'appli avec le jeton d'accès reçu.
            this.commProviderWithNativeApp.notifyClientAuthenticated(this.datasOfToken.expiresOn);        
            this.CallbackAuthenticated(this.datasOfToken);
            resolve(this.datasOfToken);
        });
    }

    logout() { //En liaison avec l'application native, la déconnexion est plus une fermeture de l'appli native ! 
        //En cas de déconnexion, on commence par vider les données actuelles:
        this.accountFromNativeApp = undefined;
        this.needUpdateNativeApp = undefined; //false ou true !
        this.authentStarting = undefined; //false ou true !
        this.datasOfAccount = undefined;
        this.datasOfToken = undefined; //objet passé à la méthode de callback 'CallbackAuthenticated' si tout est ok!

        StoreManager.store.dispatch( ActionResetAppDatas() ); // reset de toutes les données dans le store (chaque reducer reprenant intialState comme défaut par défaut)

        if (!this.commProviderWithNativeApp) return;
        
        //Et on prévient l'application native:
        if (this.closeApp === true) {
            this.commProviderWithNativeApp.notifyCloseApp();
            this.closeApp = false;
        } else if (this.notifyNativeApp === true) {
            this.commProviderWithNativeApp.notifyDisconnection();
        } else {
            this.notifyNativeApp = true; //force à ce que la prochaine fois, on notifie l'appli native !
        }
    }

    //RQ: Pas de méthode spécifique pour gérer la demande de changement de mot de passe!
    //La mécanique de MSal (l'officiel) c'est de changer l'autorité puis de refaire appel à l'authent via la méthode '' !
    
    setAuthorityCache(state, authority) {
        const canonAuthority = URLHelper.CanonicalizeUri(authority);
        StoreFunctions.saveNativeAuthority(canonAuthority);
    }
}

export default InternalAgentApplication;
