let stringLanguage_esES = {
    //Login    
    relancerAuthent:"Reiniciar autenticación",
    //Sidebar
    carte:"Plano",
    btnHarvestOnSidebar:"Cosecha maiz",
    btnModulationOnSidebar:"Modulaciones",
    btnBiomassOnSidebar:"Pesadas colza",
    btnFertilizerOnSidebar:"Estiércoles",
    parcellesListBtnOnSidebar:"Parcelas",
    parcellesImportBtnOnSidebar:"Importar mis parcelas",
    observationBtnOnSidebar:"Observaciones",
    aide:"Ayuda",
    allHelps:"Ayudas útiles",
    termsAndConditionsSpotifarm:"Ver los términos y condiciones de Spotifarm ?",
    dependenciesApi:"Vea a continuación todas las dependencias y API utilizadas en nuestro producto",
    indicevege:"Entiende el índice de vegetación",
    language:"Idioma",
    //importer mes parcelles
    importparcelles:"Introduzca el nombre de su explotación",
    provfichier:"Seleccione el origen de sus archivos",
    aideimport:"¿Cómo importar mis parcelas?",
    aideimportParcelles:"¿Cómo recuperar mis parcelas?",
    autrefichier:"Otros",
    selectionnerfichier:"Seleccione su archivo",
    enregistrerparcelles:"Guardar mis parcelas",
    annulerimport:"Anular",
    aideimportredirect:"Si no tiene sus parcelas en un archivo puede dibujarlas directamente sobre el plano",
    importFichiersProvenance:"Origen de los archivos",
    importFichiersType:"Tipo de archivo",
    importFichiersTypeZip:"Archivo zip",
    importFichiersTypeIndividuels:"Archivos individuales",
    importParcellesGif:"Gif de ayuda de importación de archivos",
    // Header
    seconnecter:"Conectarse",
    creationcompte:"Crear una cuenta",
    changePassword:"Cambiar mi contraseña",
    disconnect:"Desconectarse",
    confirmDisconnection:"¿Estás seguro de que quieres desconectarte?",
    confirmDeleteAccount:"¿Está seguro de que desea eliminar su cuenta?",
    confirmChangePassword:"¿Estás seguro de que quieres cambiar tu contraseña?",
    confirmChangePwd:"Cambiar",
    parametres:"Parámetros",
    notifications:"Notificaciones",
    historique:"Histórico",
    parametrage:"Parametraje",
    compte:"Cuenta",
    plus:"Más",
    monprofil:"Mi perfil",
    calendrier:"Calendario",
    tooltipDisableFilter:"activar (o no) el filtro",
    farmsLabel:"Selección de la explotación",
    cropsLabel:"Selección del cultivo",
    nameLabel:"Selección por nombre",
    allFarmFilter:"Todas",
    allCropFilter:"Todas",
    noFarmNameFilter:"<sin nombre>",
    noCropNameFilter:"<sin nombre>",
    selectedFarmsCounter:" explotaciones filtradas",
    selectedCropsCounter:" cultivos filtrados",
    filterAreaHelper:"Introduzca un área para aplicar el filtro",
    selectionnerhistorique:"Pulse en una parcela para ver el historial",
    news:"Noticias",
    tutoVideos:"Tutoriales en vídeo",
    contactSupport:"Contactar con el servicio de atención al cliente",
    shareApp:"Compartir la aplicación",
    joinWhatsappGroup:"Únete al grupo de whatsapp",
    rateUs:"Tenga una notacion a la aplicación",
    aboutUs:"A proposito​",
    removeClientFromNativeApp:"Cancelar mi cuenta y mis datos",
    titleConfirmRemoveAccount:"Confirmación antes de la eliminación total",
    askConfirmRemoveAccount:"De verdad quieres que eliminemos tu cuenta y todos tus datos?",
    cancelRemoveAccount:"Anular",
    validRemoveAccount:"Confirmar",
    //Notifications:
    conseil:"Recomendación:",
    incitationWellcomeTitle:"Bienvenido a tu cuenta de Spotifarm",
    incitationWellcomeTextWithDiscountPV:"Utilizar el código promocional : '#LECODEPROMO#' para beneficiarse de 2 meses gratuitos.",
    incitationWellcomeTextWithDiscount:"Utilizar el código promocional : '#LECODEPROMO#' para beneficiarse de un descuento en la suscripción.",
    incitationWellcomeText:"Existe una versión PRO...",
    incitationTitle:"¿ Ganas de nuevas imágenes 🛰 ?",
    invitationPremium:"Funcionalidad no disponible con su suscripción actual...",
    invitationPremiumLayersGlobal:"Las capas globales están reservadas para la versión PRO.",
    invitationPremiumLayersGlobalForMobile:"Estas funcionalidades están reservadas para los usuarios que se han suscrito a la versión completa de Spotifarm.",
    invitationPremiumNoLimitParcels:"Usted está limitado a 3 parcelas...",
    invitationPremiumNoLimitHitoricParcels:"No puede generar el historial de parcelas...",
    invitationPremiumSolution:"¡Descubre la versión PRO de Spotifarm y también activar todas las funcionalidades!",
    invitationPremiumAction:"Probar la versión PRO >>",
    incitationMoreInfos:"Para más información, visite nuestra web: ",
    invitationPremiumPlusSolution:"¡Descubre la versión PRO de Spotifarm y active todas las funcionalidades incluyendo al modulación!",
    invitationPremiumPlusSolutionBiomass:"¡Descubre la versión PRO de Spotifarm y active todas las funcionalidades incluyendo el pesaje de colza!",
    invitationPremiumPlusSolutionFertilizer:"¡Descubre la versión PRO de Spotifarm y active todas las funcionalidades incluyendo los abonos!",
    //settings
    monparametrage:"Mi configuración",
    nommax20:"El nombre no debe exceder más de 20 caracteres",
    valeurtolere:"valor tolerado entre 10 y 80",
    modifier:"Modificar",
    annuler:"Anular",
    enregistrer:"Guardar",
    suppParcel:"Eliminar mi parcelación",
    monParcellaire:"Mi parcelación",
    confirmSuppParcels:"Esta importación conlleva la eliminación de la parcelación actual (así como las modulaciones y pesajes asociados), ¿ Desea continuar ?",
    looseActualImages:"La eliminación de la parcelación conlleva suprimir las imágenes de los satélites asociadas. Para cargar nuevas imágenes debe suscribirse a Spotifarm.",
    importparcel:"Importar mis parcelas",
    nomexploit:"Nombre de la explotación",
    tauxnuage:"% de nubosidad",
    legendAndGraph:"Leyenda e histograma",
    myaccount:"Mi cuenta",
    myexploitation:"Mi cartografía",
    mymodulation:"Modulación",
    mybiomasses:"Pesajes de colza",
    qualityImagModul:"Ajuste de la resolución de los mapas de modulación",
    IsoXmlFileVersion:"Versión de archivos IsoXml",
    ShpDoseLabel:"Identificador de dosis en recetas SHP",
    ShpDoseLabelNotBeVoid:"El identificador no puede estar vacío", 
    ShpDoseLabelWithInvalidChar:"Su identificador contiene al menos un carácter no admitido", 
    maxModulationQualityLabel:"muy alta",
    mediumModulationQualityLabel:"Alta",
    defaultModulationQualityLabel:"media",
    minModulationQualityLabel:"baja",
    maxModulationQualityDetail:"Resolución máxima, que a veces limita la lectura de algunas consolas",
    mediumModulationQualityDetail:"Resolución alta",
    defaultModulationQualityDetail:"Resolución medía",
    minModulationQualityDetail:"Disminuir al máximo la resolución del plano para facilitar la lectura por parte de algunas consolas",
    noRows:"No hay información para mostrar.",
    displayLandsat8:"Imágenes de landsat8",
    //parcelles
    importparcelles2:"Para importar una nueva parcelación",
    ListparcelsModulation:"Nuevo plano de modulación",
    ListparcelsModulationMobile:"Nuevo plano",
    listparcels:"Lista de mis parcelas",
    nomcolumn:"Nombre",
    idcolumn:"Id",
    searchPlaceholder:"Búsqueda",
    search:"Búsqueda",
    filterall:"Todo",
    filtertitle:"Filtros",
    filterreset:"Reinicializar",
    viewcolumntitle:"Columnas a seleccionar",
    viewcolumntitlearia:"Mostrar/ocultar Columnas de la tabla",
    desonoinfo:"Lo sentimos, no se ha encontrado información",
    sort:"Destino",
    selectedParcelRowText:"parcela(s) seleccionada(s)",
    errorGenerateParcelDrawed:"La parcela dibujada no se ha podido guardar.",
    askForDeletingParcel:"¿Desea suprimir esta parcela?",
    askForDeletingParcels:"¿ Está seguro que desea eliminar las parcelas seleccionadas ?",
    deleteParcel:"Suprimir",
    deleteParcels:"Suprimir las parcelas seleccionadas",
    alertDeleteModulation:"Las modulaciones ligadas a estas parcelas también se eliminarán",
    // DicoDetails.json
    libelecolumncampagne:"Campaña",
    libelecolumnculture:"Cultivo",
    libelecolumnvariete:"Variedad",
    libelecolumnsemis:"Fecha de siembra",
    libelecolumnrecolte:"Fecha de cosecha",
    libelecolumnfloraison:"fecha de floración",
    libelecolumnrecolteestime:"fecha estimada de cosecha",
    libelecolumndegrecertitude:"Grado de certidumbre",
    libelecolumncultureprece:"Cultivo anterior",
    libelecolumnsurface:"Superf. (Ha)",
    libelecolumnexploitation:"Explotación",
    libelecolumnmodel:"Modelo de cultivo",
    // choix listes déroulante pour culturesn exploitations, variétés
    chooseCulture:"- Seleccione  un cultivo -",
    chooseExploitation:"- Seleccione una explotación -",
    chooseVariety:"- Seleccione una variedad -",
    // DicoCulture
    libelleBetterave:"Remolacha",
    libelleBleDur:"Trigo duro",
    libelleBleTendre:"Trigo blando",
    libelleColza:"Colza",
    libelleLin:"Lino",
    libelleLuzerne:"Alfalfa",
    libelleMaisEnsilage:"Maíz ensilado",
    libelleMaisGrain:"Maíz para grano",
    libelleOrgeDePrintemps:"Cebada de primavera",
    libelleOrgeDHiver:"Cebada de invierno",
    libellePoisFourrager:"Guisante forrajero",
    libellePoisProteagineux:"Guisantes proteaginosos",
    libellePrairiePermanente:"Pradera permanente",
    libellePrairieTemporaire:"Pradera temporal",
    libellePommeDeTerre:"Patata",
    libelleSoja:"Soja",
    libelleTournesol:"Girasol",
    libelleAutre:"Otro",
    // error
    erroroups:"Oups",
    errorsuite:", se ha producido un error en la aplicación",
    error:"El error se ha enviado y será corregido próximamente",
    // StripeInfoCard(anciennement 'StripeInformationDetails')
    infofact:" Mis datos de facturación",
    infofactForMobile:" Mi información personal",
    mesabos:"Mis suscripciones",
    abonner:"Suscripción",
    label:"dirección:",
    postalcode:"Cod. postal: ",
    city:"municipio: ",
    country:"país: ",
    firstname:"Apellido: ",
    lastname:"Nombre: ",
    company:"Empresa: ",
    usertext:"denominación: ",
    phone:"Teléfono: ",
    id:"identificante: ",
    subscriptiontext:"suscripción: ",
    subscriptionpaidstate:"cuenta de pago",
    subscriptionfreestate:"cuenta gratuita",
    description:"descripción: ",
    card:"su tarjeta",
    expiry:"caducidad",
    askForSubscription:"Solicitud de cambio de suscripción",
    contactForSubscription:"Nuestro equipo se comunicará con usted lo antes posible.",
    // StripePlanCard
    jour:" Sin iva / Día",
    semaine:" Sin iva / Semana",
    mois:" Sin iva / Mes",
    annee:" Sin iva / Año",
    jourS:" Sin iva, cada X días",
    semaineS:" cada X semanas (ht)",
    moisS:" Sin iva, cada X meses",
    anneeS:" cada X años (ht)",
    selectbutton:"Iniciar",
    unsuscribebutton:"darse de baja",
    suscribetext:"Subscrito",
    buyByStore:"a través de la tienda",
    comingsoontext:"próximamente",
    promocodeedit:"Código Promocional",
    promocodeverify:"confirmar",
    promocodecancel:"anular",
    promocodenotvalid:"código inválido",
    // StripeCreditCardView
    cbName:"NOMBRE DEL TITULAR",
    numberelement:"Número de tarjeta",
    expiryelement:"Fecha caducidad",
    cvcelement:"Cód. Verificación",
    errorSaveCBOfCustomer:"Al guardar sus datos bancarios se ha provocado un error.",
    errorSaveCustomer:"La creación de sus datos no tuvo éxito.",
    confirmerpaiement:"Confirmar pago",
    revenir:"Volver",
    endcard:"su tarjeta termina con: ",
    expiration:"caducidad: ",
    cardexpiry:"su tarjeta de crédito ha caducado; por favor debe actualizarla",
    confirmersub:"Confirmar la nueva suscripción",
    cartenonvalide:"su tarjeta no es la correcta",
    changeOfCard:"Cambiar tarjeta",
    titlecard:"Mi tarjeta bancaria",
    cardNewInput:"Rellene los datos de su tarjeta de crédito",
    subheader:"datos proporcionados por el servicio bancario",
    cgvtext:"Marcando esta casilla, confirma que ha leído y acepta las ",
    cgvlink:"Condiciones generales de venta.",
    pgdlink:"Política de confidencialidad.",
    // StripeInvoice
    facture:"Número",
    descriptionfac:"Descripción",
    prix:"Precio (impuesto incluido)",
    statut:"Estado",
    pdf:"PDF",
    factures:"facturas",
    aucunefac:"No hay facturas en curso",
    facturePayee:"Pagado",
    factureEnImpayee:"En espera",
    viewYourBillsTitle:"Ver tus facturas",
    viewYourBillsText:"Haga clic aquí para acceder al panel de facturas",
    // StripePlanView
    desabo:"Para darse de baja, póngase en contacto con Administración vía e-mail: isagri@isagri.es",
    selectPlanMessageText:"Se requiere que seleccione una suscripción para pasar al siguiente paso",
    autounsubcribePlanMessageText:"Si ya dispone de una suscripción, la elección de una nueva suscripción conlleva de manera automática suspender la suscripción actual. Solo se le realizará un cobro periódico.",
    // StripeSuscribeProcess
    selectionabo:"Seleccione su suscripción",
    infofac:"Introduzca sus datos de facturación",
    infobanc:"Introduzca sus datos bancarios",
    errorpay:"se ha producido un error durante el pago (cliente). Debe contactar con Administración",
    nonvalide:"los datos de entrada no son válidos",
    errorsous:"se ha producido un error durante el pago (suscripción). Debe contactar con Administración",
    felicitation:"¡ Felicidades !",
    aboValide:"Acabamos de confirmar su subscripción a Spotifarm 🙂.\nSolo falta un último paso que es la confirmación del pago con su banco. Esto no nos llevará más de un día y a partir de la confirmación, ya podrá disfrutar plenamente de Spotifarm como si nada.\nConfiaste en nosotros por lo que nosotros confiamos en usted también 🙂.\nSi hay algún problema con el pago contactaremos con usted, si no contactamos con usted todo estará en orden.",
    aboValideMobile:"Acabamos de confirmar su subscripción a Spotifarm 🙂.\nPuedes aprovechar al máximo Spotifarm ahora 🙂!\nPuedes encontrar tu compra directamente en el espacio de tu Tienda en tu móvil",
    continuer:"Continuar",
    reloadingClientDatas:"recargamos tus datos... Por favor espera.",
    oups:"Oups...",
    abofail:"El pago no ha sido aceptado. debe ponerse en contacto con Administración",
    abofailDetails:"Detalles:",
    errorCreateCBToken:"El registro de tus datos bancarios ha provocado un error.",
    errorCreateCustomerTokenVoid:"El registro de sus datos bancarios parece haber fallado.",
    errorCreateCustomerTokenIdVoid:"El resultado del registro de sus datos bancarios parece haber fallado.",
    errorGetClientBilling:"La carga de su información ha fallado.",
    errorUpdateClientBilling:"No se ha podido guardar la información.",
    errorStripeCreateCustomer:"La creación de sus datos (para configurar su suscripción) no tuvo éxito.",
    errorCreateSubcribePlanIdVoid:"Su suscripción parece haber fallado.",
    errorStripeSubscribePlan:"Su suscripción ha fallado.",
    UserDataInvoiceSaveError:"se ha producido un error mientras se hacia la copia de seguridad",
    UserDataInvoiceNotComplet:"Los datos parecen incompletos...",
    submitting:"Registro actual",
    // StripeUserFormView
    allemagne:"Alemania",
    belgique:"Bélgica",
    france:"Francia",
    luxembourg:"Luxemburgo",
    suisse:"Suiza",
    tvanonrecuperable:"atención, el IVA no será recuperable",
    renseignerprenom:"debe introducir un nombre",
    renseignernom:"debe introducir un apellido",
    renseignertelephone:"debe introducir un número de teléfono",
    renseigneradresse:"debe introducir una dirección",
    renseignercode:"debe introducir un código postal válido",
    renseignerville:"debe introducir un municipio",
    renseignerpays:"debe introducir un país",
    errorsave:"Se ha producido un error durante la copia de seguridad de sus datos\nInténtelo más tarde",
    labelPrenom:"Nombre",
    labelNom:"Nombre",
    labelTelephone:"Teléfono",
    labelSociete:"Empresa",
    labelAdresse:"Dirección",
    labelCp:"Cód. postal",
    labelVille:"Municipio",
    acprenom:"su nombre",
    acnom:"su apellido",
    actelephone:"su número de teléfono",
    acsociete:"su empresa",
    acadresse:"su dirección",
    acville:"municipio",
    valider:"Confirmar",
    other:"Otro",
    mandatoryField:"Este campo es obligatorio",
    AnyCityFromCurrentPostalcode:"El código postal actual no está asociado a ninguna ciudad",
    SomeDataRequired:"Falta un campo obligatorio", 
    // calendrier 
    revenirimage:"Volver a la última imagen",
    newerImage:"Está en la última imagen",
    legendForDelayedImage:"La imagen aún no está disponible",
    legendForCloudyImage:"Imagen nublada",
    buttonForMoreImage:"Obtener más imágenes",
    buttonGoBackLastImage:"Volver a la última imagen",
    imageNonObtenue:"La imagen no se puede descargar",
    noImageBefore:"¡No hay imágenes disponibles antes de esta fecha!",
    tauxennuagement:"{0} % de nubosidad",
    // errorBoundary
    relancerapp:"Relanzar la aplicación",
    //imageSwiper
    chargeimage:"cargango imágenes, espere por favor...",
    formatDate:"dd/MM/yyyy",
    //layerSelection
    fondcarte:"Fondo de plano",
    plan:"Plano",
    satellite:"Satélite",
    vueglobale:"En todo el plano",
    vueglobaleOnlyForPremium:"Opción desactivada para clientes gratuitos",
    visible:"Visible",
    indvege:"Índice de vegetación",
    vueparcelle:"En mis parcelas",
    carterout:"plano satélite",
    visustand:"visualización standart",
    photovis:"fotos visibles desde el satélite",
    anavege:"análisis de la vegetación",
    displayObservations:"Mostrar observaciones",
    //loginTest
    redirectauth:"Redirección a la página de autenticación.",
    redirectmdp:"redirección a la página de cambio de contraseña.",
    deco:"Desconexión en curso, espere por favor...",
    reco:"Conexión en curso, espere por favor...",
    reco2:"Iniciando el satélite, espere por favor...",
    //mapinfo
    recupimg:"Recuperando nuevas imágenes...",
    newimg:"Nuevas imágenes disponibles",
    noimg:"No hay imágenes nuevas",
    redirectimport:"Recuerde importar su parcelación",
    maxccupdate:"Imágenes actualizadas",
    selectionnerparcelle:"Pulse en una parcela para ver el calendario",
    clickToUnderstandImage:"Haga clic aquí para comprender las imágenes de Spotifarm",
    //parcelNameEdit
    enregistrer2:"guardar",
    annuler2:"anular",
    modifier2:"modificar",
    //parcelEdit
    enregistrer3:"guardar",
    annuler3:"anular",
    modifier3:"modificar",
    ajouter3:"Añadir",
    updateCrop:"Editar parcela",
    titreajouterinformation:"Añadir información",
    libelleajouterinformation:"Por favor, incluya una información inexistente en la lista de informaciones predefinidas.",
    libellecolumninformation:"Información",
    //searchBar
    recherche:"Nombre de parcela",
    filtre:"Filtros",
    surface:"Superf.",
    typecult:"Tipo de cultivo",
    reinitialiser:"Reinicializar",
    filtrer:"Filtrar",
    tous:"Todos",
    labelAreaCompareAll:"Toda",
    labelAreaCompareLower:"<",
    labelAreaCompareUpper:">",
    SampleParcelAreaValue:"ej: 1",
    // stateCalendar
    obtentionimg:"Obtención de imágenes en curso",
    obtentionNotAsk:"...",
    pourmois:"para este mes",
    noimgexpl:"No hay imágenes disponibles",
    errorimg:"Error al obtener las imágenes",
    reloadhisto:"Recargar",
    //importParcels
    mezip:"Sin el archivo zip, la importación no se puede hacer",
    mefichier:"El archivo de importación ha provocado un error",
    me4:"error: debe seleccionar : o los 4 archivos con las extensiones .shp .shx .dbf .prj, o un archivo .zip",
    me3:"error: debe seleccionar : o los 3 archivos con las extensiones .shp .shx .dbf, o un archivo .zip",
    me2Min:"Error: debe proporcionar: al menos los archivos. shp y. dbf (El archivo con extensión. PRJ es opcional, pero recomendable); o un archivo. zip",
    meZip2:"error: debe seleccionar un archivo .zip",
    exploitation:"Mi explotación",
    selectionfichier:"Seleccionar archivo",
    enregistrementparc:"Grabando la parcelación",
    geofolia:"Geofolia",
    telepac:"Telepac",
    mesparcelles:"Mis parcelas",
    autres:"Otros",
    vosparcelles:"Sus parcelas",
    selectionnerparcelles:"Seleccionar las parcelas",
    parcelDefaultNameFormat:"parcela ",
    telepacClusterAttribute: "Grupo",
    telepacParcelAttribute: "Parcela",
    // renderMaps
    noauth:"<strong>¡ Lo sentimos !</strong> no esta autorizado a dibujar este tipo de parcela",
    bravo:"Acaba de dibujar una parcela. Ponle un nombre y guárdela para continuar.Sino pulse en anular.",
    bravotitle:"¡ Bravo !",
    nomparcelle:"Nombre de la parcela",
    parcelledess:"Parcela dibujada",
    monexploitation:"mi explotación",
    drawn:"Dibujado",
    autre:"otro",
    chargevisi:"visible mientras se carga",
    chargeind:"índice de vegetación cargando",
    tooltipstartgeolocation:"Activar la geolocalización",
    tooltipstopgeolocation:"Desactivar la geolocalización",
    tooltipcouche:"Capas a aplicar",
    tooltipInfo:"Información parcela",
    tooltipcenter:"Centrarse en mi explotación",
    geoSearchAddress:"Introduce una direccion",
    zoomer:"Acercar",
    dezoomer:"Alejar",
    commune:"Buscar un municipio",
    visiblecharge:"visible mientras se carga",
    indvegecharge:"índice de vegetación cargando",
    geoloc:"La geolocalización no estaba permitida.",
    desopos:"Lo sentimos, su posición no se ha encontrado",
    noOpenGlobalLayerWithoutParcelSelection:"Debe seleccionar una parcela para mostrar una capa general.",
    //menuParcels
    shapefile:"Esta funcionalidad le permite importar sus datos de parcelas en forma de archivo shapefile",
    importnew:"Importar nuevas parcelas",
    functionnew:"esta funcionalidad le permite importar nuevas parcelas. Atención, sus datos antiguos se borrarán durante la importación",
    viewlist:"Ver la lista de mis parcelas",
    functionlist:"esta funcionalidad le permite consultar la información de sus parcelas",
    viewparcelles:"Ver mis parcelas en un plano",
    functionview:"Esta funcionalidad le permite ver sus parcelas en un plano",
    //abstractProfil
    infos:"Mi información",
    mesfactures:"Mis facturas",
    //stripeCustomPlan
    abogratuit:"Aproveche Spotifarm en 3 parcelas con :\nAcceso Web y Smartphone\n1 año de histórico de las parcelas\n1 explotación",
    abopremium:"Disfrute de todas las funcionalidades Spotifarm\n(hasta el 28/02/2020 gratuitamente)\n+\nTodas las parcelas\nReinstalación en el histórico ilimitado",
    abopremiummodul:"Todas las ventajas del modo pro + \nl'acceso a la generación del plano de modulación.",
    freemium:"Muestra",
    premium:"Pro (oferta de lanzamiento)",
    premiummodul:"Premium + Modulación",
    //calendrier
    //Attention, la propriété ci-après ('calendar') doit être au format "culture complète !"
    calendar:"es-ES",
    //leafletdraw
    dessinerparcelle:"Dibuja una parcela",
    terminer:"Terminar",
    supppoint:"Eliminar el último punto",
    reduire:"Ocultar",
    start:"Haga clic para comenzar a dibujar.",
    cont:"Haga clic para continuar dibujando.",
    end:"Haga clic en el último punto para terminar el dibujo.",
    placemarker:"Hacer clic para situar un punto de observación.",
    placeMarkerOnMap:"Haz clic en el mapa para colocar un punto de observación.",
    placeFirstPointForPolygonOnMap:"Haz clic en el mapa para empezar a dibujar.",
    //cardShowed
    valid:"caduca el",
    //stripeinfoadress
    adresseLabelCard:"Dirección de facturación",
    adresse:"Dirección",
    codepostal:"Cód. postal",
    ville:"Municipio",
    pays:"País",
    onerror:"se ha producido un error al guardar sus datos",
    errorlabel:"debe introducir una dirección",
    errorcodepostal:"debe introducir código postal",
    errorville:"debe introducir un municipio",
    errorpays:"debe introducir un país",
    //stripeInfoCard
    cartebanc:"Tarjeta de crédito",
    expiryError:"Su tarjeta está caducada o caduca muy pronto. Introduzca una nueva tarjeta de crédito para continuar aprovechando al máximo las funciones de Spotifarm",
    changecard:"Cambio tu tarjeta",
    savecard:"Agrega tu tarjeta",
    cbNamemaj:"NOMBRE DEL TITULAR",
    numcard:"número de tarjeta",
    dateexpiry:"fecha caducidad",
    errorcard:"No se puede guardar su tarjeta",
    cryptogramme:"criptograma",
    //StripeInfoNaming
    clientLabelCard:"Cliente",
    email:"E-mail",
    prenom:"Nombre",
    nom:"Apellido",
    societe:"Empresa",
    telephone:"Teléfono",
    errorprenom:"debe introducir un nombre",
    errornom:"debe introducir un apellido",
    errorphone:"debe introducir un número de teléfono",
    //StripeInformationsdetails
    avertissement:"Advertencia",
    modifdonnees:"algunos datos se están modificando y se perderán si cierra esta página. No olvide guardarlos.",
    button:"Estoy de acuerdo",
    //StripePayment
    puis:"Entonces",
    total:"Total",
    couponReduc:"Cupón de descuento",
    resumeCommande:"Resumen del pedido",
    infoUsers:"Información del usuario",
    //authentBase
    alertrs:"Si se ha conectado con su red social, el cambio de contraseña solamente se puede hacer desde el sitio web.",
    alertdeco:"Parece que estás desconectado. Vamos a reiniciar la autenticación ...",
    //actions, reducers, containers
    noLoadClientDatasOrId:"No se encontraron los datos del cliente conectado o el identificador es incorrecto.",
    noLoadDatasWithoutStoreAcces:"Imposible consultar la base de datos de Spotifarm, sin acceso a los datos de la aplicación.",
    noLoadClientDatasWithoutEmail:"No se detectó el correo electrónico del usuario. No se puede consultar la base de Spotifarm.",
    noLoadDatasWithoutToken:"No se detectó el jeton de acceso. No se puede consultar la base de Spotifarm.",
    noCreateClientDatas:"La inicialización de datos del usuario ha fallado.",
    badSearchClientDatas:"La búsqueda de datos del usuario ha fallado.",
    noLoadParcelDatasWithoutClientId:"Imposible solicitar la parcelación sin haber identificado al cliente.",
    noLoadModulationDatasWithoutClientId:"Imposible solicitar las modulaciones sin haber identificado al cliente.",
    noLoadFertilizerDataWithoutClientId:"Imposible iniciar un tratamiento sin haber identificado al cliente.",
    noLoadHarvestDataWithoutClientId:"Impossible de démarrer une récolte sans avoir identifié le client.",
    noSaveOrUpdateFertilizerDataWithoutClientId:"Imposible guardar o modificar los datos del estiércol sin haber identificado al cliente.",
    noLoadFertilizerCommonInfosByCultureWithoutClientId:"No se puede solicitar información de fertilizante común por cultura sin identificar al cliente.",
    noGeneratePdfBiomassWithoutClientId:"Imposible generar Pdf sin identificar al cliente.",
    badLoadParcelDatas:"La solicitud de la parcelación ha fallado.",
    badLoadModulationDatas:"La solicitud de las modulaciones ha fallado.",
    badLoadInvoiceDatas:"No se pudo acceder a la factura.",
    badLoadFertilizerDatas:"Se ha producido un error al cargar los datos para iniciar una fecundación.",
    badLoadLastNitrogenInputDatas:"Se ha producido un error al cargar los datos de las últimas entradas de nitrógeno.",
    badLoadHarvestDatas:"Se ha producido un error al cargar datos para iniciar una cosecha.",
    badinitModelisationDatas:"Se ha producido un error al iniciar los datos de modelado.",
    badLoadModelisationDatas:"Se produjo un error al cargar datos de modelado.",
    badLoadFertilizerCrops:"Se produjo un error al cargar cultivos para iniciar la fertilización.",
    badLoadFertilizerCommonInfosByCultureDatas:"Error al acceder a datos comunes por cultura",
    noLoadInvoiceDatasWithoutClientId:"Imposible solicitar el acceso a una factura sin haber identificado al cliente.",
    badShowInvoice:"No se pudo abrir su factura.",
    badSaveModulationDatas:"Ha habido un fallo al grabar la modulación.",
    badSaveCommonInfosManagementDatas:"Se produjo un error al guardar la entrada del grupo para el control de nitrógeno.",
    badSaveSpecificInfosManagementDatas:"Ocurrió un error al registrar la información de control para iniciar la fertilización.",
    badPartialBuildModulation:"La generación de una nueva modulación ha fallado.",
    badPartialBuildFertilizer:"La generación de una nueva estiércol ha fallado.",
    badCompletBuildModulation:"La generación completa de las prescripciones ha fallado.",
    badCompletBuildLniModulation:"La generación completa de las prescripciones ha fallado.",
    badBuildPpfFile:"No se pudo obtener el archivo PPF.",
    badGeneratePdfModulation:"La generación del archivo Pdf de la modulación falló.",
    badGeneratePdfFertilizer:"La generación del archivo Pdf del estiércol falló.",
    badShowPdfModulation:"No se pudo mostrar el archivo PDF de modulación.",
    badShowPdfFertilizer:"No se pudo mostrar el archivo PDF del estiércol.",
    badPartialBuildBiomass:"La generación completa de las prescripciones ha fallado.",
    badGeneratePdfBiomass:"La generación del archivo Pdf de pesaje falló.",
    badGeneratePdfBiomassResume:"La generación del archivo Pdf resumen de pesaje falló.",
    badShowPdfBiomass:"No se pudo mostrar el archivo Pdf de pesaje.",
    badShowPdfBiomassResume:"La visualización del archivo Pdf resumen de los pesajes ha fallado.",
    badGeneratePdfLastNitrogenInputPdfResume:"Error en la generación del archivo Pdf que resume los pesajes.",
    badShowPdfLastNitrogenInput:"Error en la visualización del archivo PDF de la última contribución.",
    badShowPdfLastNitrogenInputResume: "La visualización del resumen del archivo PDF de la última contribución.",
    noGenerateHistoDatasWithoutClientId:"Imposible solicitar la generación de imágenes para una parcela sin haber identificado al cliente o la parcela a la que se refiere.",
    noGenerateHistoDatasWithoutMonthYear:"No se puede solicitar la generación de una lista de imágenes sin un mes y un año definido",
    badGenerateHistoDatas:"La solicitud para generar un historial de imágenes ha fallado.",
    noUpdateDatasWithoutStoreAcces:"Imposible actualizar la base de datos de Spotifarm, sin acceso a los datos de la aplicación.",
    noUpdateDatasWithoutClientId:"No se puede actualizar la base de datos de Spotifarm, sin haber identificado al cliente.",
    noUpdateDatasWithoutToken:"No se detectó el jeton de acceso. No se puede actualizar la base de datos de Spotifarm.",
    noUpdateDatasWithoutDatas:"No se puede actualizar la base de datos de Spotifarm sin tener los datos.",
    badUpdateDatas:"La solicitud de actualización ha fallado.",
    badUpdateFertilizerDatas:"Se produjo un error al actualizar los datos de fertilizantes.",
    noCheckDatasWithoutStoreAcces:"No se puede iniciar la verificación sin acceso a los datos de la aplicación.",
    noCheckDatasWithoutClientId:"No se puede iniciar una verificación sin identificar al cliente.",
    noCheckDatasWithoutToken:"No se detectó el jeton de acceso. No se puede iniciar la verificación.",
    noCheckDatasWithoutDatas:"No se puede iniciar la verificación sin tener los datos.",
    badCheckPRJDatas:"La solicitud de verificación de la proyección ha fallado.",
    badCorrectZipOfShpDatas:"La solicitud de corrección de importación SHP ha fallado.",
    noDeleteDatasWithoutStoreAcces:"Imposible suprimir la base de datos de Spotifarm, sin acceso a los datos de la aplicación.",
    noDeleteDatasWithoutClientId:"No se puede suprimir la base de datos de Spotifarm, sin haber identificado al cliente.",
    noDeleteDatasWithoutToken:"No se detectó el jeton de acceso. No se puede suprimir la base de datos de Spotifarm.",
    noDeleteDatasWithoutDatas:"No se pueden eliminar datos de la base de datos de Spotifarm sin tener los datos a los que se refiere.",
    badDeleteParcelDatas:"La solicitud para eliminar la parcelación ha fallado.",
    badDeleteModulationDatas:"La solicitud para eliminar la modulación ha fallado.",
    badDeleteFertilizerDatas:"Se produjo un error al eliminar los datos de fertilizantes.",
    badDeleteHarvestVarietyDatas:"Se produjo un error al eliminar los datos de la variedad.",
    errorUpdateParcelName:"Se ha producido un error al guardar el nombre de la parcela",
    noGetAnalysDatasWithoutClientId:"No se puede obtener el análisis de la imagen de la base de datos Spotifarm sin identificar al cliente.",
    noGetAnalysDatasWithoutToken:"No se detectó el jeton de acceso. No se puede obtener el análisis de la imagen de la base de Spotifarm.",
    badGetAnalysDatas:"La solicitud de obtención del análisis ha fallado.",
    importPlotsByFarmNameEmptyResult:"Le respuesta de los datos de parcelas de una importación de GEOFOLIA ha sido de un objeto vacío",
    noRemoveClientWithoutStoreAccess:"No se puede eliminar la cuenta y los datos sin acceder a los datos de la aplicación.",
    noRemoveClientWithoutClientId:"No se pueden eliminar la cuenta y los datos sin identificar al cliente.",
    noRemoveClientWithoutToken:"No se detecta el token de acceso. No se puede eliminar la cuenta y los datos.",
    noLoadLastNitrogenInputDataWithoutClientId:"Imposible iniciar una aportación final sin haber identificado al cliente.",
    badLoadLastNitrogenInputsDatas:"Se produjo un error al cargar los últimos datos cargados.",
    noBuildLastNitrogenInputWithoutClientId:"Imposible calcular un aviso de 'último depósito' sin identificar al cliente.",
    noBuildLastNitrogenInputWithoutDatas:"Imposible proporcionar asesoramiento de 'aporte final' sin los datos necesarios",
    badBuildLastNitrogenInput:"Se produjo un error durante la elaboración del consejo para una ingesta final de nitrógeno.",
    noResultLastNitrogenInputBuild:"La producción del consejo de un último aporte de nitrógeno no arrojó ningún resultado.",
    noResultLastNitrogenInputBuildSampleAndDoses:"Se ha producido un error al generar la modualción de una última entrada.",
    noResultLastNitrogenInputSavedSampleAndDoses:"Se ha producido un error al guardar la modulación de una última entrada.",
    doseApporterError: "La dosis a aplicar debe ser superior a 0. Modifica la dosis ya aplicada en consecuencia",
    //ImportGeofoliaFAH
    geofoliaStep1:"Geofolia",
    textStep1:"Importar mis parcelas Geofolia",
    textStep1_onlyFarmName:"Importar mis parcelas Geofolia, asociadas a la explotación: ",
    textStep2:"Seleccione la explotación a importar:",
    authorizeStep1:"¡ Al continuar, acepto compartir mis parcelas de Geofolia con Spotifarm !",
    refusedPvFAHOnStep:"No proponer la importación",
    cancelStep1:"Anular",
    continueStep1:"Continuar",
    cancelStpe2:"Anular",
    importStep2:"Importar",
    errorStep1:"se ha producido un error al cargar la lista de explotaciones",
    errorStep2:"se ha producido un error durante la importación de parcelas desde Geofolia",
    //LanguageMenu
    itemMenuLangueFR:"Français (France)",
    itemMenuLangueBE:"Français (Belgique)",
    itemMenuLangueES:"Español (España)",
    itemMenuLangueUS:"English (US)",
    itemMenuLangueGB:"English (UK)",
    //NdviScale
    scalelow:"sin vegetación",
    scalehigh:"alta vegetación",
    scalekey:"Índice de vegetación",
    //NdviChart
    imageAnalysis:"Análisis de imagen",
    cloudswarning:"Presencia de nubes",
    verifClouds:"Comprobar si hay nubes",
    noVerifCloudForModelisation:"No se detectan nubes en las imágenes modeladas",
    noNdviOnAllMapForModelisation:"No hay índice de vegetación en todo el mapa para las imágenes modeladas",
    goBackToVegetationIndex:"Volver al índice de vegetación",
    //RenderObservations
    columnparcelname:"Parcela",
    columnculture:"Cultivo",
    columnobservationdate:"Fecha de observación",
    columnobservationtype:"Tipo de observación",
    columnsurperficie:"Área (Ha)",
    columnremarques:"Notas",
    noparcelname:"Otro",
    renderobservationstitle:"Lista de observaciones",
    toolbarsearch:"Búsqueda",
    toolbarsearchculture:"Buscar un cultivo",
    toolbarSearchNameCornTypeAndVariety:"Buscar parcela, tipo de cosecha, variedad",
    toolbardownloadCsv:"Descarga CSV",
    toolbarprint:"Imprimir",
    toolbarviewColumns:"Columnas",
    toolbarfilterTable:"Filtro",
    selectedRowText:"observación(es) seleccionada(s)",
    selectedRowTitle:"Suprimir",
    noobservation:"Sin observación.",
    newobservation:"Crear una nueva observación",
    point:"punto",
    zone:"zona",
    labelToday:"Hoy",
    labelYesterday:"Ayer",
    labelTheLast7days:"Los últimos 7 días",
    labelThisMonth:"Este mes",
    labelLastMonth:"El mes pasado",
    labelAllDates:"Todas las fechas",
    //PinDropControl
    createpindropmarker:"Crear un punto de observación",
    createpindroppolygon:"Crear una zona de observación",
    createpindropimage:"Hacer una foto",
    //PinDropFormPopup
    pinDropFormTitlePoint:"Observación",
    pinDropFormTitleZone:"Zona de observación",
    pindropmarkersuccess:"Acaba de crear un punto de observación. Guárdelo para continuar, de lo contrario haga clic en anular.",
    pindroppolygonsuccess:"Acaba de crear una zona de observación de {0} Ha. Guárdela para continuar, de lo contrario haga clic en anular.",
    pinDropFormImage:"Imagen",
    pinDropFormAddImage:"Añadir una foto",
    pinDropFormErrorShowImage:"Lo sentimos, no se ha podido cargar la imagen",
    supprimer:"Borrar",
    pindDropFormErrorSaveObservation:"No hemos podido guardar su comentario. Esto se debe probablemente a un problema de conexión. Por favor, inténtelo de nuevo pulsando el botón \"Guardar\".",
    pindDropFormErrorSaveImage:"No hemos podido guardar una imagen. Esto se debe probablemente a un problema de conexión. Por favor, inténtelo de nuevo pulsando el botón \"Guardar\".",
    deleteObservation:"Suprimir la observacione",
    deleteObservationConfirmation:"¿Está seguro que desea eliminar la observacione?",
    cancelObservation:"Cancelar los cambios",
    cancelObservationConfirmation:"Se han realizado cambios en la observación. ¿Estás seguro de que quieres cancelarlos?",
    pinDropFormIdentificationComment:"Reconocimiento de la planta:",
    //Observations
    deleteObservations:"Suprimir las observaciones seleccionadas",
    deleteObservationsConfirmation:"¿Está seguro que desea eliminar las observaciones seleccionadas?",
    periode:"Período",
    // Observations - Type d'observations - ⚠️ l'unité (_1,_2,...) correspond à l'observationTypeId en BDD
    observation_type_0:"Todas",
    observation_type_1:"Otro",
    observation_type_2:"Malas hierbas",
    observation_type_3:"Carencias",
    observation_type_4:"Daños por animales de caza",
    observation_type_5:"Enfermedad",
    observation_type_6:"Problema en la emergencia",
    observation_type_7:"Plagas",
    typesObservations:"Tipos de observación",
    // Didacticiel / Tutorial : FirstParcel
    howtoimportfirstslot:"Quiero añadir más parcelas",
    close:"Cerrar",
    welcomespotifarmertitle:"¡ Bienvenido !",
    welcomespotifarmercontent1:"Para comenzar su vuelta y desbloquear sus",
    welcomespotifarmercontent2:"30 días de prueba gratuita,",
    welcomespotifarmercontent3:"empiece dibujando su primera parcela",
    starttutorial:"Comenzar",
    /* Step choix */
    choixtitle:"Mi primera parcela en Spotifarm",
    choixsubtitle:"Selecciono el método que más me conviene :",
    choixdrawtitle:"Dibujar",
    choixdrawcontent:"Dibuje sus parcelas de nuestra herramienta para el plano",
    choiximporttitle:"Importar",
    choiximportcontent:"Importe sus parcelas con un archivo shp o .zip",
    choixgeofoliatitle:"Sincronizar",
    choixgeofoliacontent:"Sincronice su parcelación si usa Geofolia",
    /* Step Import */
    importmenusaisie:"Introducción",
    importmenuaide:"¿Necesita ayuda?",
    importhelp1title:"Necesito ayuda para importar mis archivos",
    importredirecttostepdraw:"Mi importación no funciona, prefiero dibujar mis parcelas",
    importhelptitlepasdefichier:"No tengo ningún archivo con mi parcelación",
    importhelppasdefichiercontent:"Seleccione su herramienta de gestión",
    importhelpsubtitlepasdefichier1:"Recuperar el archivo con Telepac",
    importhelpsubtitlepasdefichier2:"Recuperar el archivo con mis parcelas",
    importhelpsubtitlepasdefichier3:"Recuperar el archivo con Geofolia",
    importhelptitleimport:"Tengo un problema cuando importo el archivo",
    importhelptitleautreprobleme:"Tengo otro problema",
    importhelpsubtitleautreprobleme1:"Las imágenes de spotifarm",
    importhelpsubtitleautreprobleme2:"Contáctenos",
    importhelpenvoyeremail:"Enviar",
    /* Step indice */
    stepIndiceTitle:"Índice de vegetación",
    stepIndiceSubtitle:"¿A qué corresponde el índice de vegetación?",
    stepIndiceButton:"Estoy de acuerdo",
    /* Step succès */
    successtitle:"¡ Felicidades !",
    successsubtitle:"Ahora tiene 30 días para probar Spotifarm gratis",
    successcontent:"Recibirás una imagen satelital cada 3 - 5 días de forma gratuita durante 30 días para :",
    successsubcontent1:"Controlar la emergencia de sus parcelas",
    successsubcontent2:"Seguimiento del crecimiento del cultivo",
    successsubcontent3:"Localizar problemas (enfermedades, riego, plagas, animales de caza)",
    successsubcontent4:"Compruebe la eficacia de los aportes de fertilizantes",
    successsubcontent5:"Determine la fecha de cosecha óptima",
    successbtnvalidation:"Allá vamos",
    /* Step dessin */
        /* Sub step géolocalisation */
    dessinwaitgeolocalisation:"Geolocalización en curso, espere por favor",
        /* CardExplain Zoom map */
    dessinzoom:"Hago zoom en mi parcela con la rueda",
        /* CardExplain Move map */
    dessinmovemap:"Me muevo en el plano con el ratón",
        /* Button start draw */
    dessindrawparcel:"Empieza a dibujar",
    dessinzoominsidemap:"No encuentro mi municipio, prefiero hacer zoom sobre el plano para localizar mis parcelas",
    gobacktostepchoix:"Cambiar método",
        /* Place search */
    searchcity:"Buscando tu municipio",
    // spécifiques pour le cas de chargement depuis l'appli native
    noDatasAuthentFromNativeApp:"Los datos necesarios para la autenticación no se han transmitido. La aplicación no puede iniciar sesión.",
    disconnectNativeApp:"Cambiar de cuenta",
    closeNativeApp:"Cerrar la aplicación",
    commNativeWithoutWebView:"La inicialización del sistema de autenticación no debería haber explotado el modo ' nativo '.",
    /* Step succès */
    actualize:"He creado mi enlace Geofolia, actualizo",
    actualizeForSmallScreen:"He creado mi enlace",
    /* Incitation fin période d'essai avant fin */
    incitationendoftrialtitle:"Parece que te gusta la aplicación",
    incitationendoftrialcontent1:"Su prueba gratuita está llegando a su fin.",
    incitationendoftrialcontent2:"Conviértase en un verdadero Spotifarmer y continúe vigilando sus parcelas con acceso ilimitado a las imágenes satelitales.",
    incitationendoftrialcontent2ForMobile:"Para convertirse en un verdadero spotifarmer, inicie sesión en su ordenador y siga vigilando sus parcelas gracias al acceso ilimitado a las imágenes satelitales.",
    incitationendoftrialcontent3:"Sin permanencia",
    incitationendoftrialok:"Vale, allá vamos",
    incitationendoftrialnotnow:"Ahora no",
    /* Incitation fin période d'essai post fin */
    incitationaftertrialtitle:"Pruebe la versión PRO de Spotifarm",
    incitationaftertrialcontent1:"¿ Le gusta Spotifarm y quiere continuar siguiendo el crecimiento de sus cultivos ?",
    incitationaftertrialcontent2:"Puede suscribirse a nuestra oferta, sin permanencia.",
    incitationaftertrialcontent2ForMobile:"Estas funcionalidades están reservadas para los usuarios que se han suscrito a la versión completa de Spotifarm.",
    incitationaftertrialcontenthowtodo1ForMobile:"¿ Cómo hago eso ?",
    inctitaionaftertrialcontenthowtodo2ForMobile:"La suscripción no está disponible en la aplicación. Está hecha desde su ordenador.",
    incitationaftertrialcontentokForMobile:"De acuerdo, lo he comprendido.",
    incitationaftertrialsupport:"¿ Tiene alguna pregunta ?",
    incitationaftertrialcontactsupport:"Contacte con nuestro equipo",
    incitationaftertrialcontent2BeforeDiscount:"Puede suscribirse a nuestra oferta utilizando este descuento, sin permanencia.",
    incitationaftertrialcontent2BeforeDiscountForMobile:"Puede suscribirse a nuestra oferta utilizando este descuento, sin permanencia.",
    /* Incitation freemium ayant déjà un shp */
    incitationfreemiumwithshptitle:"El mundo cambia. Nosotros también.",
    incitationfreemiumwithshpcontent1:"A partir de ahora, podrá acceder a la aplicación sin límite de parcelas. Pero la descarga de nuevas imágenes se reservará para los suscriptores.",
    incitationfreemiumwithshpcontent2:"Para recordarle lo interesante de Spotifarm,le desbloqueamos todo durante un mes de manera gratuita.",
    incitationfreemiumwithshpsignature:"El equipo de Spotifarm",
    /* incitation footer */
    incitationtrialfooter:"Si tiene una pregunta o necesita ayuda, no dude en ponerse en contacto con nuestro soporte",
    /* Incitation depuis la fumure */
    incitationDownloadFertiPrescriptions:"Tiene los derechos para generar un plan de estiércol, pero la descarga de los mapas de modulación requiere una suscripción a Spotifarm - modulación.",
    /* formulaire d'inscription via réseaux sociaux */
    socialformtitle:"Un último paso antes de comenzar una vuelta por sus parcelas",
    champobligatoire:"Campo obligatorio",
    /* Modulation : Aides */
    helpForModulation:"¿ Necesita ayuda con la modulación ?",
    helpForDownloadModulation:"¿ Necesita ayuda con la descarga ?",
    helpForFilesModulation:"¿ Qué formato de archivo para mi consola ?",
    helpForDownloadModulationOnDesktop:"Ordenador",
    helpForResolutionModulation:"¿ Necesita ayuda en cuanto a la resolución de los planos de modulación ?",
    helpAsk:"¿Necesitas ayuda?",
    helpAskModulation:"¿Qué formato de archivo debo usar para mi consola?",
    helpContactUs:"Tengo otra pregunta",
    /*  Pesée de colza aide */
    aidePeseeColza:"Not for aliens",
    /* Ecrans de modulation */
    totalDoseToExpand:"Total : ",
    totalDoseToExpandAlert:"Tenga en cuenta que ha seleccionado modulaciones en la ingesta de sólidos Y líquidos.",
    parcelModulated:"Está modulado",
    downloadNotAvailable:"Disponible en el ordenador",
    noRecordsToDisplay:"No hay modulación grabada para mostrar",
    loadingModulsRecords:"Carga de modulación ... ",
    deleteModulations:"Suprimir la(s) modulación(es)",
    deleteSelectedModulations:"Suprimir la(s) modulación(es) seleccionada(s)",
    confirmDeleteModulations:"¿ Desea suprimir la(s) modulación(es) seleccionada(s) ?",
    startModulation:"Comenzar",
    showOnlyParcelWithoutPrescription:"Mostrar solo parcelas no moduladas",
    ModulationsExistsTitle:"Mis planos de modulación",
    ModulationsExistsTitleMobile:"Mis planos",
    ColumnPclName:"Nombre",
    ColumnMdlDate:"Fecha de creación",
    ColumnMdlMediumDose:"Dosis media",
    ColumnContentRate:"Contenido %",
    ColumnMdlToDownLoad:"Descargar el plano",
    ColumnMdlToDownLoading:"Descarga el plano",
    downloadLastInputNs:"Descargar el plano",
    lastInputNsDownLoading:"Descarga el plano",
    badDownloadModulationFiles:"La descarga de los archivos asociados a una modulación de la parcela ha fallado. Contacte con nosotros: contacto@spotifarm.es",
    badContentDownloadModulationFiles:"El contenido de los archivos descargados, asociados a una modulación de parcela ha fallado. Contacte con nosotros: contacto@spotifarm.es",
    ObserveVisibleLayer:"Seleccione una imagen que tenga el menor número de nubes posible. En esta vista las nubes se distinguen por su color blanco y sus sombras por el oscurecimiento de la imagen. Ponemos la dosis media global en las zonas detectadas con nubes.",
    ObserveCloudLayer:"Es posible que las nubes hayan pasado nuestro filtro, pero hayan perturbado el índice de vegetación en algunas imágenes. Para detectarlas se puede observar la cobertura de nubes en el momento de tomar la foto.",
    ComeBackToIndicevege:"Volver al índice de vegetación",
    DetectClouds:"Detectar nubes",
    ConfirmSelectedImage:"Validar la imagen seleccionada",
    titleDeleteModulations:"Supresión de la(s) modulación(es)",
    confirmDeleteModulationsSelected:"¿ Desea suprimir las modulaciones seleccionadas ?",
    confirmDeleteAction:"Confirmar",
    cancelDeleteAction:"Anular",
    dialogSubtitleTypeShape1:"Selecciona el formato de exportación de los planos de modulación:",
    dialogSubtitleTypeShape11:"Selecciona el formato de exportación de los planos de modulación:",
    dialogSubtitleTypeShape12:"Selecciona el formato PDF:",
    dialogSubtitleTypeShape2:"Si tiene dudas sobre los formatos que acepta su consola, consulte con su proveedor.",
    startModulationImageChoice:"Elección de imagen",
    twoSupplyTypesSelected:"Atención, tenga en cuenta que su selección incluye planos con diferentes tipos de aporte (líquidos y sólidos). Si es su selección puedes continuar, si no es su selección le recomendamos separar los archivos por tipo de aporte para simplificar el uso en su consola.",
    someoneWithoutModulSelected:"Tenga en cuenta que su selección incluye consejos de las últimas contribuciones que no tienen la modulación configurada. Si continúa, el asesoramiento incompleto será excluido de la prescripción elaborada.",
    goBackToModulationTable:"Volver a mi seleccion",
    biomassRangeMobileHelper:"Fechas",
    newModulationWidget:"Crear un mapa de modulación",
    /* Ecrans de modulation : doses de modulation */
    rememberModulationSettings:"Recordar la dosis media, el contenido y el parametraje para las próximas modulaciones.",
    subTitleDefinitionZones:"Definir las zonas", 
    subTitleDoses:"Reparto de las dosis",
    subTitleSettings:"Parametraje",
    mediumDose:"Dosis media",
    content:"Contenido",
    nbZones:"Número de zonas",
    ratio:"Proporción",
    doseInputs:"Dosis de materia prima",
    area:"Superficie",
    distributionType:"Tipo de reparto",
    doseChange:"Cálculo de dosis",
    compensation:"Compensación",
    optimization:"Optimización",
    manuelDoses:"Manual",
    automaticDoses:"Automático",
    helpContent:"Concentración del producto útil en el producto aplicado. Por ejemplo 39% para la solución de nitrógeno 39",
    helpCloudZones:"En las zonas donde hay nubes (las zonas rojas) aplicamos la dosis media",
    helpOptimization:"Para compensar, el algoritmo favorece las zonas menos desarrolladas con menos potencial aplicando más producto. En la optimización del algoritmo favorece las zonas más desarrolladas, con un alto potencial, aplicando más producto",
    helpDoseChange:"Si introduce las dosis manualmente por zona, la dosis media total puede cambiar. Atención, respetar el plan de abonado. Si deja el cálculo automático, se modificará las dosis de otras zonas cada vez que modifique la dosis en una zona, sin modificar la dosis media total",
    altImgModulation:"vista previa no disponible",
    quantityToExpand:"Cantidad total a aplicar",
    quantityToFertiExpand:"Dosis media a aplicar",
    quantityToFertiExpandShort:"Dosis media",
    badQuantityToFertiExpand:"Su capacidad de propagación está alcanzada !",
    badQuantityToFertiExpandDetails:"si es posible, reduzca su dosis de reserva para tener una tarjeta de modulación válida",
    goBackStepModulation:"Volver",
    saveModulation:"Guardar",
    saveModulationAndGoNext:"Validar y pasar al siguiente",
    cancelModulationAndGoNext:"Volver y pasar al siguiente",
    downloadModulation:"Descargar",
    downloadModulPdf:"Descargar PDF",
    downloadModulShp:"Descargar SHP",
    downloadModulIso:"Descargar ISOXml",
    downloadModulRds:"Descargar RDS",
    errorDownloadModulation:"Se ha producido un error durante la descarga. Contacte con nosotros a: contacto@spotifarm.es",
    msgUseComputerForDownloadModulation:"La descarga solo se puede realizar desde un ordenador.",
    downloadModulationAndGoNext:"Descargar y luego pasar a la siguiente parcela",
    unvalidZonesFromBuild:"Este plano no permite generar más de {0} zonas.",
    unvalidZonesFromBuildFerti:"Este plano no permite generar {0} zonas.",
    errorNoLoadDatesRange:"No se puede cargar el rango de apertura del servicio de estiércol.",
    detectException:"La actualización de los datos de modulación ha encontrado un problema inesperado.",
    badNumberOfAreas:"Imposible aumentar el número de zonas, la heterogeneidad de la parcela es insuficiente.",
    dateImag:"Fecha de la captura",
    supplyType:"Tipo de aporte",
    supplyTypeSolid:"Sólido",
    supplyTypeLiquid:"Líquido",
    doseNitrogen:"Dosis de nitrógeno",
    downloadChoiceShp:"SHP", 
    downloadChoiceIsoXml:"ISO XML", 
    downloadChoiceRds:"RDS",
    repartitionLabel:"Heterogeneidad de la distribución de dosis",
    repartitionTip:"A través del ajuste de la distribución de dosis, decides la diferencia entre tus dosis más altas y más bajas en tus gráficos de modulación. Cuanto mayor sea la heterogeneidad de tu parcela, más justifica una fuerte heterogeneidad en tu distribución.",
    lowerRepartitionLabel:"Baja",
    higherRepartitionLabel:"Fuerte",
    errorDoseNominalZero: "Haber ingresado al menos una dosis mayor que 0",
    /* Modulations : écran Félicitation */
    messageEndModulation:"Ha terminado la modulación para las parcelas seleccionadas.",
    downloadModulationInProgress:"Grabando",
    /* Calculette dose Azote */
    CalculDoseNitrogenTitle:"Calcular mi dosis óptima de nitrógeno",
    CalculDoseNitrogenTitleMobile:"Mi dosis óptima",
    calculDoseNitrogenTypeBle:"trigo",
    calculDoseNitrogenTypeColza:"colza",
    calculDoseNitrogenDesc:"Calcule aquí la dosis de nitrógeno correspondiente al óptimo técnico-económico según el precio de venta de su {0} y el precio de compra de su nitrógeno.", 
    calculDoseNitrogenSellPrice:"Estoy pensando en vender mi {0}",
    calculDoseNitrogenPurchaseCost:"Estoy pensando en comprar mi abono nitrogenado",
    calculDoseNitrogenConcentration:"Mi abonado nitrogenado contiene",
    calculDoseNitrogenConcentrationEnd:"de nitrógeno",
    calculDoseNitrogenRecommanded:"Su dosis de nitrógeno a aplicar, en función de su plan de abono es",
    calculDoseNitrogenOptimized:"Su dosis óptima de nitrógeno es",
    calculDoseNitrogenUnite:"unidades",
    calculDoseNitrogenInfo:"Este cálculo proviene de un estudio publicado por Arvalis sobre la dosis de nitrógeno correspondiente al óptimo económico en función del precio de venta del cultivo y del precio de compra del nitrógeno. El cálculo no requiere el rendimiento del cultivo, porque este rendimiento ya se tiene en cuenta en el cálculo de la dosis prevista proporcionada por el método de balance.",
    calculDoseNitrogenGoToArticle:"Para más detalles puedes leer",
    calculDoseNitrogenGoToArticleBetween:"para el trigo, y",
    calculDoseNitrogenGoToArticleEnd:"para la colza.",
    calculDoseNitrogenArticle:"este artículo",
    calculDoseNitrogenErrorData:"Los datos introducidos deben ser números enteros positivos",
    calculDoseNitrogenErrorPurchaseCost:"El precio de su abono nitrogenado, así como la concentración en nitrógeno dan un coste por unidad de nitrógeno {lowerOrHigherThan}. Nuestro algoritmo no permite un cálculo con estos precios.",
    lowerThan:"inferior a {0}",
    higherThan:"superior a {0}",
    calculDoseNitrogenErrorSellPrice:"El precio de venta de su {culture} debe estar comprendido entre {priceMin} y {priceMax}",
    calculDoseNitrogenErrorCulture:"Nuestro algoritmo no puede dar resultados para su cultivo",
    /* Biomasse */
    /* Biomasse : choix des parcelles */
    parcelHasBeginningWinter:"Entrada de invierno",
    parcelHasEndingWinter:"Salida de invierno",
    startBiomass:"Comenzar",
    startBiomassBeginningWinter:"Iniciar pesaje de entrada de invierno",
    startBiomassEndingWinter:"Iniciar pesaje de salida de invierno",
    selectBiomassBeginningWinter:"Elección de imagen: entrada invierno",
    selectBiomassEndingWinter:"Elección de imagen: salida de invierno",
    listparcelsBiomass:"Nuevo pesaje de colza",
    updateBiomassEndingWinter:"Actualización ponderando el final del invierno",
    next:"Próximo",
    biomassesCertifTerresInovia:"Nuestro modelo está validado y aprobado por Terres-inovia para que pueda utilizarlo en su plan de abono.",
    biomassesHowToSelectDates:"Para garantizar la coherencia de los datos, asegúrese de seleccionar las fechas de entrada y salida del invierno con los mismos criterios que utilizaría para un pesaje de campo.",
    /* Biomasse : tableau des biomasses */
    ColumnBioDateWinter:"Fecha de pesaje",
    ColumnBioBeginningWinter:"Entrada",
    ColumnBioEndingWinter:"Lanzamiento",
    ColumnBioWeighting:"Pesaje (Kg/m²)",
    deleteWeightingBiomasses:"Eliminar pesajes",
    generateResumePdfWeightingBiomasses:"Compilación de resumen {0} ponderaciones en PDF",
    generateResumePdfOneWeightingBiomass:"Compilación de resumen 1 ponderacion en PDF",
    generateResumePdfWeightingAllBiomasses:"Crear currículum en PDF de todas las ponderaciones",
    weightingBiomassesExistsTitle:"Mis pesajes de colza",
    titleDeleteBiomasses:"Eliminación de pesaje (s) de colza",
    confirmDeleteBiomassesSelected:"¿ Confirma la eliminación de los pesajes de colza seleccionados ?",
    noBiomassRecordsToDisplay:"No hay pesaje de colza para mostrar",
    loadingBiomassRecords:"Mis pesas de colza se están cargando...",
    downloadBiomasses:"Descarga los pesajes",
    linkResultsWeightings:"Para calcular la cantidad de nitrógeno que debe aplicar a sus cultivos de colza, puede utilizar la ",
    clicHere:"regla del nitrógeno de Terres inovia",
    newBiomassWidget:"Nuevo pesaje de colza",
    /* Biomasse : map */
    goBackStepBiomass:"Regreso",
    biomassRangeDates:"Desde {0} hasta {1}",
    biomassRangeText:"fechas recomendadas por Terres Inovia",
    biomassExtraRangeDates:"Desde {0} hasta {1} o desde {2} hasta {3}",
    biomassExtraRangeText:"fechas aceptables",
    biomassOutOfRangeText:"Fuera de período",
    /* Biomasse : résultat */
    subTitleMapZonesBiomass:"Tu tarjeta de pesaje",
    subTitleYourWeightingBiomass:"Tus cantidades",
    subTitleResultWeightingBiomass:"Su resultado de pesaje",
    quantity:"Cantidad",
    download:"Descargar",
    downloadWeightingPdf:"Descargar PDF",
    goToNextFieldSkipTitle:"Pasar al siguiente parcela",
    goToNextFieldSkipContent:"¿Desea cancelar y pasar al siguiente parcela?",
    averageQuantityResult_1:"Tienes en promedio ",
    averageQuantityResult_2:" kg de material de colza fresca por m² en esta parcela.",
    totalArea:"Área total :",
    weightingSuccess:"Su peso ha sido guardado.",
    parcelleSuivanteDesktop:"Ignorar la parcela",
    parcelleSuivanteMobile:"Ignorar",
    ignorerEtTerminer:"Ignorar et Terminar",
    biomassesCheckBy:"Modelo validado por",
    /* Widget Analyse image */
    lifting:"Levantamiento",
    growth:"Crecimiento",
    maturity:"Madurez",
    cloud:"Nubes",
    /* Identification */
    identificationTitle:"Identificación",
    /* Identification: choix d'image */
    identificationChooseImageSubtitle:"Selección de imágenes",
    identificationChooseImageTips:"Para obtener los mejores resultados, seleccione un órgano de la planta en particular y añada una foto de ese órgano:",
    identificationChooseImageOrganLeaf:"Hoja",
    identificationChooseImageOrganFlower:"Flor",
    identificationChooseImageOrganFruit:"Fruta",
    identificationChooseImageOrganBark:"Tallo",
    identificationChooseImageSearch:"Buscar",
    identificationChooseImageMsgError:"No pudimos identificarlo. Esto se debe probablemente a un problema de conexión. Por favor, inténtelo de nuevo pulsando el botón \"Buscar\".",
    identificationSelectImage:"Selecciona una imagen para identificar la planta",
    /* Identification: résultat */
    identificationResultSubtitle:"Resultado",
    identificationResultEmpty:"No hay resultados para su búsqueda. Para una búsqueda más precisa, puede tomar fotos de los diferentes órganos de la planta.",
    identificationResultNotFound:"No encuentro mi planta",
    identificationResultPoweredByPlantnet:"Nuestro servicio de fotoidentificación de plantas utiliza la API Pl@ntnet, que se actualiza periódicamente y es accesible en el sitio web ",
    /* Identification: résultat item */
    identificationResultComponentConfidence:"Índice de confianza: {0}%",
    /* Achat In-app Mobile */
    fromStore:"(a través de la tienda móvil)",
    pleaseWaitBuyByStore:"Espere por favor. el proceso de compra comenzará en el dispositivo",
    buyByStoreRequireTime:"Comprar en la Tienda parece tardar más de lo esperado. Espere O regrese (Le notificaremos tan pronto como finalice).",
    errorPurchasePlanNotDetected:"Se ha validado una compra en la Tienda. sin embargo, la Tienda no nos envió la oferta suscrita. Póngase en contacto con nosotros para activar su acceso.", 
    errorUpgradeClientRulesAfterPurchase:"La activación de su acceso, luego de su compra en la Tienda, encontró una dificultad. Por favor contáctenos.", 
    errorNoPurchaseWithoutSelection:"Una suscripción a través de una compra en la Tienda requiere seleccionar la oferta deseada. Inténtalo de nuevo.", 
    errorNoPurchaseWithoutIdStorePlan:"La compra de la Tienda se cancela porque la Tienda no puede encontrar su selección.", 
    errorPurchaseMessageFromNativeApp:"La compra en la tienda devolvió un error ({0}). Por favor contáctenos.",
    errorUpdateClientAfterPurchasedFromNativeAppMessage:"La activación de sus derechos de acceso falló. Póngase en contacto con nosotros para que activemos completamente sus accesos.", 
    purchaseResultWithoutInfosFromNativeApp:"La compra en la Tienda proporcionó resultado. Por favor contáctenos.",
    appelStoreStep:"Conectando a la tienda...",
    explainBuyByStore:"Lanzaremos la compra por ti desde tu Tienda Móvil. Tras la validación del pago, se desbloqueará su acceso.",
    purchaseStore:"Comprar",
    goBackPurchaseStoreWaiting:"<< Volver atrás",
    dlgTitlePurchaseStoreResult:"Resultado de la compra en la tienda",
    dlgContentPurchaseStoreResult:"Tras la activación de la compra : ",
    messagePurchaseStoreGoodResult:"La compra ha sido validada. ¡Disfruta ya de tu acceso a Spotifarm!",
    btnClosePurchaseStoreResult:"Cerrar",
    /* Modélisation */
    modelisationTitle:"Modelización",
    modelisationCalendarDescription:"Imagen modelada",
    modelisationTutoStartTitle:"Disfrute de las imágenes cada semana sin interrupción",
    modelisationTutoStartExplanation:"Gracias a nuestra inteligencia artificial, ahora podemos modelar el crecimiento de las plantas y proporcionarle imágenes de sus cultivos a intervalos regulares, independientemente de las condiciones meteorológicas.",
    modelisationTutoStartIncitation:"Me aprovecho de esto",
    modelisationTutoStartHowTo:"Para disfrutar de estas nuevas imágenes tienes que validar tus cultivos para seguir 👉🏻",
    modelisationTutoStartButton:"Comienzo",
    modelisationTutoChooseModelTitle:"Selección de cultivos",
    modelisationTutoChooseModelExplanation:"Cada cultivo tiene sus propias especificidades y características de crecimiento. Hemos preparado 3 modelos de crecimiento para el trigo, la cebada y la colza.",
    modelisationTutoChooseModelSelect:"Seleccione los cultivos correspondientes para empezar a introducir los datos:",
    modelisationTutoChooseModelButton:"Validar",
    modelisationCropChoiceTitle:"Elección del cultivo a modelizar :",
    modelisationCropChoiceButton:"Validar la cultura",
    modelisationCropChoiceHelper:"Una vez elegido el cultivo que se va a modelizar, seleccione y valide las parcelas correspondientes.",
    modelisationAddParcelsSelectTitle:"Selección de parcelas",
    modelisationAddParcelsSelectAction:"Comience por seleccionar todas sus parcelas de {0}",
    modelisationAddParcelsFillInTitle:"Entrada de datos",
    modelisationAddParcelsFillInAction:"Comprueba y completa la información de cada parcela",
    modelisationDataGridButtonAdd:"Añadir parcelas a este modelo",
    modelisationDataGridButtonStop:"Detener la modelización de estas parcelas",
    modelisationDataGridButtonValidInformations:"Validar la información",
    modelisationDataGridButtonAddSelected:"Añade estos gráficos al modelo",
    modelisationDataGridPopupSaving:"Sus parcelas están listas. Comenzamos a procesar los datos para proporcionarle imágenes modeladas del siguiente período sin imágenes de satélite",
    modelisationDataGridPopupGoToNext:"Pasaremos automáticamente a la siguiente cosecha al final de la carga",
    modelisationMsgErrorReset:"Se ha producido un problema al dejar de modelar una de sus parcelas. Vuelve a intentarlo más tarde.",
    modelisationMsgErrorUpdate:"Se ha producido un problema al iniciar la modelización de una de sus parcelas. Vuelve a intentarlo más tarde.",
    loadingModelisation:"Modelado en progreso",
    modelisationIsLoading:"El modelado está en progreso.",
    modelisationResultPageInfo1:"Preparamos con todos los datos parcelarios, meteorológicos y de satélite el cálculo de las imágenes modeladas.",
    modelisationResultPageInfo2:"Aparecerán tan pronto como se produzca un período de nubosidad y, por lo tanto, las imágenes de satélite no estarán disponibles.",
    modelisationBackToExploit:"Vuelta a la operación",
    newModelisation:"Nuevo modelado",
    cropName:"Cultura :",
    modelisationSpecificInfo1:"Compruebe y confirme aquí sus fechas de siembra por parcelas.",
    modelisationSpecificInfo2:"Cuando todo esté correcto, inicie la generación de imágenes con el botón situado en la parte inferior derecha.",
    modelisationSpecificInfo3:"La generación de imágenes tendrá lugar en cuanto falten imágenes debido a las nubes.",
    modelisationCrop:"Modelando la cultura:",
    modelisationGenerateImages:"Lanzo la generación de imágenes",
    modelisationCommonInfosUpdateQuestion1:"Ya tenemos fechas de siembra para estas parcelas,",
    modelisationCommonInfosUpdateQuestion2:"quieres cambiarlos?",
    modelisationCommonInfosStart:"Para comenzar a modelar, complete la fecha de siembra de sus parcelas",
    modelisationMessageInstructionCrop:"El modelado de imágenes de Spotifarm te permitirá seguir tus cultivos durante todo el año, incluso en caso de tiempo nublado, proporcionándote imágenes constantes.",
    modelisationStep0Culture:"Elección del cultivo",
    modelisationStep1Parcelle:"Elección de parcelas",
    modelisationStep2SaisieTableauRecapitulatif:"Configuración",
    modelisationStep3Résultat:"Resultados",
    modelisationApplySowingDateToAllSlots:"Aplicar a todas las parcelas",
    modelisationApplySowingDateToSlotsWithoutDate:"Aplicar a parcelas sin fecha de siembra",
    modelisationSowingDateError:"Indica una fecha para la campaña {0}",
    modelisationRemoveParcelsSelected:"Eliminar las parcelas seleccionadas",
    modelisationDeleteSelected:"Borrar los modelos seleccionados",
    modelisationStatusTitle:"Modelización",
    modelisationStatusInitialisation:"Inicialización",
    modelisationStatusHarvested:"Cosechado",
    modelisationNbImageModelised:"Imágenes modeladas",
    modelisationStartNewModelisation:"Lanzamiento de nuevos modelos",
    deleteModelisationTitle:"Atención",
    deleteModelisationText:"Si elimina este paquete, volverá a la primera etapa del modelado.",
    backToModelisation:"Volver a modelar",
    keepModelisation:"Quédate con este paquete",
    //ProcessStatus
    processStatusWaiting:"En espera",
    processStatusInProgress:"En curso",
    processStatusSuccess:"Completado",
    processStatusError:"Varado",
    processStatusReady:"Activo",
    processStatusDiferred:"Aplazado",
    //commonStructures
    selectCrop:"Seleccione una cultura para comenzar",
    validSlots:"Validar parcelas",
    errorCommonStructures:"Se ha producido un error",
    errorCommonStructuresInfos:"Si el problema persiste,",
    //Fertilizer
    fertilizer:"Estiércol",
    fertilizerData:"Datos del estiércol",
    nitrogenManagement:"Control del nitrógeno",
    nitrogenManagementShort:"Control",
    goBack:"Atrás",
    validate:"Validar",
    doseLastInstake:"Dosis de la última toma",
    dosesProvided:"Dosis proporcionadas",
    intermediatecropFrequency:"Frecuencia de cultivo intermedia",
    plowing:"Arada:",
    cultivationHabitsPlot:"Hábitos de cultivo de la parcela.",
    yes:"Sí",
    no:"No",
    organicContribution:"Aporte orgánico",
    fertilization:"Fertilización",
    practicesFromLast10years:"Prácticas de los últimos 10 años.",
    productionTarget:"Objetivo de producción",
    agronomicInformation:"Información agronómica",
    commonInfosManagementInfos:"Puede ingresar tanta información como sea posible en esta pantalla que se aplicará a todas las parcelas de este cultivo. Si algunas parcelas se llevan a cabo de manera diferente, le aconsejo que aún ingrese la información que se aplica a la mayoría y podrá ingresar los detalles en la siguiente pantalla.",
    writeLater:"Lo conseguiré más tarde",
    selectCultureBeforeStarting:"Ingrese a una cultura antes de comenzar.",
    quit:"Dejar",
    associatedFrostCulture:"Si usted tiene un cultivo de Frost que se desarrolló bien antes de la helada, se recomienda reducir la dosis total de nitrógeno de la PPF en 30 Unidades.",
    quantityToKeep:"Cantidad de nitrógeno a reservar",
    quantityAlreadySupplied:"Cantidad de nitrógeno ya suministrada",
    nitrogenContent:"Contenido de nitrógeno del fertilizante.",
    nitrogenContentShort:"Contenido de nitrógeno",
    noAvailableChoice:"No hay opción disponible",
    typeToSpread:"Tipo de fertilizante a esparcir",
    removeHorizon:"Quitar un horizonte",
    addHorizon:"Añadir un horizonte",
    fertilizerQuantity:"Cantidad de nitrógeno",
    remainderDate:"Fecha del saldo *",
    relicTypeAnalysis:"Análisis",
    relicTypeRegionalValue:"Valor regional",
    relicTypeEstimatedValue:"Valor estimado",
    nitrateContent:"Contenido de nitrato",
    quantityWater:"Cantidad de agua",
    irrigation:"Irrigación",
    dateContribution:"Fecha de contribución",
    quantitySupplied:"Cantidad proporcionada",
    dateBurialResiduesN1:"Fecha de entierro de residuos N-1",
    becomingResiduesN1:"Destino de los residuos N-1",
    harvestDateN1:"Fecha de cosecha N-1*",
    sowingDateN1:"Fecha de siembra N-1*",
    nitrogenFertilizerN1:"Fertilización nitrogenada N-1",
    previousYield:"Rendimiento N-1",
    previousCrop:"Cultura N-1",
    loading:"Cargando...",
    plowingDepth:"Profundidad de arado",
    organicFertilizerFrequency:"Frecuencia de ingesta orgánica",
    organicFertilizerType:"Tipo de aporte orgánico",
    performanceObjective:"Objetivo de rendimiento",
    subTextPerformanceObjective:"(Promedio de los últimos 5 años excluyendo valores mínimos y máximos)",
    soilType:"Tipo de suelo",
    pebbleRate:"Tasa de cálculos",
    residueManagement:"Gestión de residuos",
    intermediateCultureType:"Tipo de cultivo intermedio",
    meadowTurning:"Girando la pradera",
    meadowTurningDate:"Fecha de girando la pradera",
    meadowDuration:"Duración de la pradera",
    errorDialogText1:"Compruebe los datos que ha introducido.",
    errorDialogText2:"Si el problema persiste, ",
    beginningWinterDate:"Fecha de entrada de invierno:",
    endingWinterDate:"Fecha de lanzamiento de invierno:",
    noFrequency:"Sin frecuencia",
    noMeadow:"Sin prado",
    buried:"Enterrado",
    reliquat30:"0 a 30",
    reliquat60:"30 a 60",
    reliquat90:"60 a 90",
    horizon:"horizonte",
    relicTypeEstimatedValueMsg:"Tenga en cuenta que estimaremos este valor utilizando un modelo de cálculo. En cuanto disponga de un valor regional, debería preferirlo al método estimado. No obstante, si su situación agraria no está cubierta por los datos de su región, puede conservar el valor estimado.",
    winterOutingShot:"Foto de entrada de invierno: ",
    winterEntranceShot:"Foto de lanzamiento de invierno: ",
    totalDose:"Dosis total",
    recommandedTotalDose:"Dosis total recomendada",
    doseAlreadyProvided:"Dosis ya proporcionada",
    reserveDose:"Dosis de reserva",
    registerAndContinue:"Guardar y continuar",
    downloadPPF:"Descarga el PPF",
    calculateAgain:"Recalcular",
    complete:"Completar",
    parcelHasLastInputN:"Ya ajustado",
    sowingDate:"Fecha de siembra",
    perfGoal:"Rendement potentiel PPF",
    newPerfGoal:"Rentabilidad ajustada",
    totalNdose:"Dosis N PPF",
    modulatedNdose:"Dosis modulada norte",
    downloadMaps:"Descargar mis tarjetas",
    modulation:"Modulación",
    quantityToSpread:"Cantidad a repartir",
    historized:"Historizado",
    actual:"Actual",
    popupIncitationFertilizer:" para más información.",
    cropYear:"Año de campaña",
    currentCropYear:"Campañas actuales",
    archivedCropYears:"Campañas archivadas",
    deleteFertilizers:"Eliminar consejo(s)",
    confirmFertilizersSelected:"¿Confirmar los fertilizantes seleccionados?",
    askDeleteFertilizers:"¿Quitar estiércol?",
    entryGrouped:"Entrada de grupo",
    updateLocalisationErrorMessage:"Se produjo un error al cambiar la ubicación de la parcela",
    newsNitrogenBoard:"Nuevo PPF",
    tipsNitrogen:"Mi PPF",
    titleInfoAlertFertilizers:"Información",
    textUnavailableAlertFertilizers:"La generación de tableros de nitrógeno no está disponible temporalmente.",
    textOpenDateAlertFertilizers:"La reapertura será el ",
    textOpenDateAlertFertilizersWithoutDate:"La reapertura se producirá a principios de enero.",
    textGetPastAlertFertilizers:"Todavía se puede acceder al abono pasado.",
    popupInfoCrop:"El cultivo de nitrógeno aconsejado es diferente al cultivo de la parcela. Será cambiado más tarde.",
    buildFertiNeedThisData:"Este dato es obligatorio para la generación de tu estiércol.",
    buildFertiNeedThisWhenAddOrganicInput:"Datos obligatorios si has realizado una aportación orgánicae",
    ppf:"PPF",
    fertilizerHavingLastNitrogenInputPopupTitle:"Ha seleccionado parcelas en las que ya ha ajustado la última entrada :",
    fertilizerHavingLastNitrogenInputPopupContent:"Si continúa, los ajustes de las últimas contribuciones se borrarán para las parcelas en cuestión.",
    deleteFertilizersWithLastInputN:"Si continúa con los ajustes, las últimas contribuciones también se eliminarán para las parcelas en cuestión.",
    parcelHasFertilizer:"Tiene un FPP",
    startPPF:"Crear un PPF",
    // Harvest
    ListparcelsHarvestMobile:"Nueva cosecha",
    ListparcelsHarvest:"Nueva cosecha de maíz",
    harvestsExistsTitleMobile:"mis cosechas",
    harvestsExistsTitle:"mis cosechas de maíz",
    askDeleteHarvestTitle:"Eliminar seguimiento de cosecha en parcelas seleccionadas",
    askDeleteHarvestVarietyTitle:"Eliminar variedades de maíz seleccionadas",
    askDeleteHarvests:"¿Está seguro de que desea eliminar el seguimiento de cosecha en sus parcelas ?",
    askDeleteHarvestVarieties:"¿Está seguro de que desea eliminar las variedades de maíz personalizadas?",
    deleteHarvests:"Quitar cultivos",
    harvestCampaign:"Campaña",
    harvestCulture:"Cultura",
    harvestVariety:"Variedad",
    harvestDegreeOfCertainty:"Grado de certidumbre",
    harvestSowingdate:"Fecha de siembra",
    harvestEstimatedDate:"Fecha estimada",
    harvestFloweringDate:"Fecha de floración",
    harvestGenerationDate:"Fecha de generacion",
    harvestAlreadyGenerated:"¿Ya generado?",
    accessToEstimationDates:"Acceso a fechas estimadas",
    loadingCropVarieties:"Cargando variedades de cultivos...",
    errorHarvestGenerate1:"Para obtener un resultado, debe introducir el objetivo de cosecha, la variedad, el tipo de cosecha y la fecha de siembra de cada parcela.",
    errorHarvestGenerate2:"Si ingresas la fecha de floración, esta debe ser mayor que la fecha de siembra.",
    cornType:"Tipo de cosecha",
    selectItem:"Seleccionar",
    grain:"Grain",
    ensilage:"Ensilaje",
    percentage:"Objectivo de cosecha",
    percentageForPopup:"Etapa",
    dryMaterial:"matieria seca",
    humidity:"humedad",
    sortBtn:"Actualizar las estimaciones",
    hightDegreeOfCertitude:"Nuestra estimación es demasiado incierta para recomendar una fecha en este momento. Vuelve en unos días.",
    helpHarvestTitle:"Cosecha de maíz",
    loadingHarvestData:"Cargando datos ...",
    dontSaveDatas:"No guardar",
    saveDatasBeforeGoingBackToFirstStep:"¿Quiere guardar los datos antes de volver a la elección de parcelas? Tenga en cuenta que los datos de cosecha enviados anteriormente serán reemplazados.",
    datesGenerated:"Las fechas estimadas de las parcelas con buenos datos están disponibles en \"Mis cosechas de maíz\".",
    myVarieties:"Mis variedades",
    deleteVarieties:"Borrar mis variedades",
    errorSaveHarvests:"Se ha producido un error al guardar los datos de la cosecha de maíz.",
    objective:"Objetivo n°",
    degreeDaysToFlowering:"°C día hasta la floración",
    manufacturer:"Fabricante",
    degreeDays:"°C día",
    needsInDegreeDays:"Necesidades en °C.j",
    dryMaterialInitials:"MS",
    addVariety:"Añade variedad",
    updateVariety:"Cambiar variedad",
    varietyLabelRequired:"Se requiere el nombre de la variedad",
    harvestTypeRequired:"Tipo de cosecha requerida",
    harvestObjectiveRequired:"Objetivo de cosecha requerido",
    harvestObjectiveGreaterThanZero:"El objetivo de cosecha debe ser mayor que 0",
    degreeDaysToFloweringGreaterThanZero:"Los °C días hasta la floración deben ser superiores a 0",
    degreeDaysGreaterThanZero:"Los °C días deben ser superiores a 0",
    degreeDaysRequired:"Los °C días son requeridos",
    fillAtLeastOneHarvestTarget:"Completa al menos un objetivo de cosecha",
    sameHarvestTargetData:"Has completado objetivos con el mismo tipo y la misma etapa",
    harvest:"Cosecha",
    errorHarvestUsedVariety:"La supresión de la variedad ha fracasado. Esto se debe probablemente a que se está utilizando actualmente en un cosecha. Compruebe los cosechas asociados antes de volver a intentarlo.",
    showAllVarieties: "Mostrar todas las variedades",
    newDateHarvestTitle: "Fecha estimada de cosecha de maíz",
    loadingHarvestRecords: "Cargando mis cultivos de maíz",
    deleteHarvestWithVariety:"Tenga en cuenta que también eliminará los datos de cosecha asociados con las variedades seleccionadas.",
    //LastNitrogenInput
    lastNitrogenInput:"Última contribución",
    newTotalNDose:"Dosis de N ajustada",
    lastNitrogenInputModulationButton:"Modulación",
    adjustment:"Diferencia entre FPP y ajuste (U/ha)",
    lastInputDate:"Fecha de ajuste",
    startModulationOfLastInputN:"Iniciar modulación",
    lastNitrogenInputModulated:"Editar",
    selectForLastInputN:"Último ajuste entrada trigo",
    selectForLastInputNMobile:"Ultima entrada",
    tipsLastInputN:"Mis ajustes",
    buildLastInputNs:"Calcular el ajuste de prescripción",
    rebuildLastInputNs:"Recalcular el ajuste de prescripción",
    lastInputNDeleteBtn:"Borrar ajuste(s)",
    lastInputNDeleteDialogTitle:"¿Suprimir las últimas aportaciones?",
    lastInputNDeleteDialogContent:"¿Estás seguro de que quieres prescindir de estas entradas?",
    lastInputDeleteDialogContentWarningArchived:"Algunas de las contribuciones más recientes quedan registradas en el historial y no se borrarán.",
    lastInputNDeleteError:"Se ha producido un error al borrar la última contribución.",
    changeImageForLastInputNModulation:"Cambiar imagen",
    saveLastInputNModulationAndGoNext:"Guardar y continuar",
    ppfDifference:"Desviación de la FPP :",
    doseToSpreadForLastInputN:"Dosis a esparcir para la última aplicación",
    finalTotalPlotDose:"Dosis total final de la parcela :",
    pdfDownloadNotAvaible:"Todavía estamos ultimando sus documentos de síntesis. Le avisaremos cuando estén disponibles.",
    modulationValuesAreDifferent:"La dosis total ha sido modificada. Recuerde actualizar la tarjeta de modulación.",
    adjusted:"Ajustada",
    modulatingDose:"Dosis modulante",
    lastNInputImageChoiceHelper:"Elija la imagen más reciente para reflejar mejor la heterogeneidad de la parcela al aplicar el abono. La elección de la imagen no afectará a la dosis total.",
    receipt:"Recibo",
    lastInputNDose:"Última dosis",
    lastInputNGenerationInfo:"Nuestros consejos de última aplicación se calculan a partir de los datos satelitales, meteorológicos y agronómicos de sus parcelas. No obstante, asegúrese de comprobar la normativa vigente en sus parcelas para asegurarse de que tiene derecho a utilizar herramientas para controlar la última aplicación de nitrógeno.",
    startCalculation:"Iniciar el cálculo",
    restartCalculation:"Reiniciar el cálculo",
    infosToStartLastInputN:"Para empezar a gestionar sus entradas finales, comience por completar sus PPF en las parcelas en cuestión.",
    generateResumePdfWeightingLastNitrogenInput:"Resumen en PDF de {0} última(s) contribución(es)",
    generateResumePdfOneWeightingLastNitrogenInput:"Resumen en PDF de esta última contribución",
    generateResumePdfWeightingAllLastNitrogenInput:"Resumen en PDF de todas las últimas contribuciones",
    lastInputCannotGenerateModulationForArchived: "Modulación no posible para una campaña histórica",
    incitationDownloadLastNInputPrescriptions:"Usted tiene los derechos para generar un ajuste de última entrada, pero para descargar los mapas de modulación necesita suscribirse a Spotifarm - modulación.",
    incitationDownloadLastNInputPrescriptionsPdf:" para más información. Para descargar el recibo de su última entrada, haga clic en el icono 'PDF'.",
    // message correspond aux codes d'erreur renvoyé par l'API
    msgErrorCodeFertiNPrescriptionIsNull:"Según los datos que ha introducido, la cantidad de insumos es superior a la necesaria. Por lo tanto, la dosis de nitrógeno que debe aplicarse es negativa.",
    msgErrorCodeEndWinterNAbsorbedOver:"Según las fotografías que has elegido, la cantidad de nitrógeno absorbida durante el invierno parece excesiva. Es de suponer que sobreestimamos este.",
    //autres
    formatDatePlaceHolder:"dd/mm/aaaa",
    beta:"beta",
    newFeaturesAlert:"nuevo",
    contactUs:"contacte-nous",
    //modal videos
    titleModalVideos:"Tutorial en vídeo",
}

export default stringLanguage_esES;