import { ParcelsHelper } from '../../utils/parcelsHelper.js';
import { ObservationsHelper } from '../../utils/observationsHelper.js';
import { ActionUpdateParcelIdFilteredList, ActionChangeSatimageIdToParcel, ActionSetParcelTab } from './parcels.js';
import { ModulationStep, ActionGoToStepOfModulation } from './modulations';
import { BiomassStep, ActionGoToStepOfWeightingBiomass } from './biomass';
import { ActionInitAzofertDatesRange, ActionSelectFertilizerTab, TabAbstractFertilizer } from './fertilizer';
import { ActionGoToTab, HarvestTab } from './harvest';
// import { ActionErrorLoadSatimagesOfParcel, ActionAddSatimage } from './satImage.js';//@@A renommer en satimage !
import { SatimageHelper } from '../../utils/satimageHelper.js';
import lodashGet from 'lodash/get';
import { clientWebApiProvider, settingsWebApiProvider } from '../../utils/webApiProvider.js';
import sendError from '../../utils/errorService.js';
import ConstantsLayers from '../../utils/constantsOfLayers.js';
import { ActionSelectLayer, ActionSaveSettingsAsk } from './settings.js';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import ConstantsTutorialHelps from '../../utils/constantsTutorialHelps.js';
import { SatImageSourceProvider } from '../../utils/constantsProvidersSatellite.js';
import { AreaComparatorAllCode } from '../../models/parcelFilterParams.js';


const SELECT_PARCEL = 'SELECT_PARCEL';
const UNSELECT_PARCELS = 'UNSELECT_PARCELS';
const DESELECT_PARCEL = 'DESELECT_PARCEL';
const INIT_FARMS_CULTURES_PARCEL = 'INIT_FARMS_CULTURES_PARCEL';

const SET_IS_FILTER_OPENED = 'SET_IS_FILTER_OPENED';
const UPDATE_FILTER_PARCELS = 'UPDATE_FILTER_PARCELS'; 
const UPDATE_CULTURES = "UPDATE_CULTURES";
const UPDATE_EXPLOITATIONS = "UPDATE_EXPLOITATIONS";

const MENU_BURGER_OPEN = 'MENU_BURGER_OPEN';
const MENU_BURGER_OPEN_DESKTOP = 'MENU_BURGER_OPEN_DESKTOP';
const MENU_BURGER_COLLAPSE = 'MENU_BURGER_COLLAPSE';

// ControlMaps
const PANEL_PARCELINFO_OPEN = 'PANEL_PARCELINFO_OPEN';
const PANEL_PARCELINFO_COLLAPSE = 'PANEL_PARCELINFO_COLLAPSE';
const MENU_LAYERS_OPEN = 'MENU_LAYERS_OPEN';
const MENU_LAYERS_CLOSE = 'MENU_LAYERS_CLOSE';
const PANEL_SWIPER_OPEN = 'PANEL_SWIPER_OPEN';
const SET_ANCHOR_LAYERS_PANEL = 'SET_ANCHOR_LAYERS_PANEL';
const SET_DISPLAY_SWIPEABLE_PANEL_FOR_MOBILE = 'SET_DISPLAY_SWIPEABLE_PANEL_FOR_MOBILE';  

const SELECT_DATE_IMG_PARCEL = 'SELECT_DATE_IMG_PARCEL';

const GO_TO_PAGE = 'GO_TO_PAGE';
const GO_TO_MAP_SELECT_PARCEL = 'GO_TO_MAP_SELECT_PARCEL';
const GO_TO_MAP_MODULATION_SELECT_PARCEL = 'GO_TO_MAP_MODULATION_SELECT_PARCEL';
const GO_TO_MAP_UNSELECT_PARCEL = 'GO_TO_MAP_UNSELECT_PARCEL';

const GO_TO_MAP_SELECT_OBSERVATION = 'GO_TO_MAP_SELECT_OBSERVATION';

const LOADING_WMSLAYER = 'LOADING_WMSLAYER';
const WMSLAYER_LOADED = 'WMSLAYER_LOADED';

const SET_URL_PARAMETERS = 'SET_URL_PARAMETERS';

const FIT_MAP_TO_BOUNDS = 'FIT_MAP_TO_BOUNDS';

const SHOWDLG_NOOPEN_GLOBALLAYER = "SHOWDLG_NOOPEN_GLOBALLAYER";
const CLOSEDLG_NOOPEN_GLOBALLAYER = "CLOSEDLG_NOOPEN_GLOBALLAYER";

const INCITATION_PREMIUM = "INCITATION_PREMIUM";
const CLOSE_INCITATION_PREMIUM = "CLOSE_INCITATION_PREMIUM";

const OPEN_PIN_DROP_FORM_DIALOG = 'OPEN_PIN_DROP_FORM_DIALOG';
const CLOSE_PIN_DROP_FORM_DIALOG = 'CLOSE_PIN_DROP_FORM_DIALOG';

const OPEN_IDENTIFICATION_DIALOG = 'OPEN_IDENTIFICATION_DIALOG';
const CLOSE_IDENTIFICATION_DIALOG = 'CLOSE_IDENTIFICATION_DIALOG';

const ENABLE_DRAW_MARKER_PINDROP = 'ENABLE_DRAW_MARKER_PINDROP';
const ENABLE_DRAW_AREA_PINDROP = 'ENABLE_DRAW_AREA_PINDROP';
const DISABLE_DRAW_MARKER_PINDROP = 'DISABLE_DRAW_MARKER_PINDROP';
const DISABLE_DRAW_AREA_PINDROP = 'DISABLE_DRAW_AREA_PINDROP';

const SET_VALUE_NEW_IMAGE_SHOWED_ON_MAP = 'SET_VALUE_NEW_IMAGE_SHOWED_ON_MAP';

const SET_CURRENT_PAGE_NAME = "SET_CURRENT_PAGE_NAME";

const SHOW_SOCIAL_ACCOUNT_SIGNUP_FORM = 'SHOW_SOCIAL_ACCOUNT_SIGNUP_FORM';

const RESET_APP_DATAS = 'RESET_APP_DATAS';

const SNACKBAR_NEWIMAGES_HAS_BEEN_SHOWN_NEWIMAGES = 'SNACKBAR_NEWIMAGES_HAS_BEEN_SHOWN_NEWIMAGES';
const SNACKBAR_HELP_NDVI_HAS_BEEN_SHOWN = 'SNACKBAR_HELP_NDVI_HAS_BEEN_SHOWN';

const SHOW_PROFIL_MENU = 'SHOW_PROFIL_MENU';
const CLOSE_PROFIL_MENU = 'CLOSE_PROFIL_MENU';

const SHOW_CALENDAR = 'SHOW_CALENDAR';
const CLOSE_CALENDAR = 'CLOSE_CALENDAR';

const OPEN_PARCEL_EDIT = "OPEN_PARCEL_EDIT";
const CLOSE_PARCEL_EDIT = "CLOSE_PARCEL_EDIT";

const SET_GEOLOCALISATION_CIRCLE_LAYERS = 'SET_GEOLOCALISATION_CIRCLE_LAYERS';

const SHOW_IMAGE_DIALOG = 'SHOW_IMAGE_DIALOG';
const CLOSE_IMAGE_DIALOG = 'CLOSE_IMAGE_DIALOG';

const SELECT_LAST_IMAGE_NOT_FROM_LANDSAT_TO_SHOW = 'SELECT_LAST_IMAGE_NOT_FROM_LANDSAT_TO_SHOW';

/* Enuméré des actions pour la gestion des parcelles: */
export const ActionTypeContextApp = {
    //Selection et filtre sur les parcelles:
    SelectParcel: SELECT_PARCEL,
    UnselectParcel: UNSELECT_PARCELS, //On aurai pû dire 'SelectAll'
    DeselectParcel: DESELECT_PARCEL,
    InitFarmsAndCulturesParcel: INIT_FARMS_CULTURES_PARCEL,

    setIsFilterOpened: SET_IS_FILTER_OPENED,
    UpdateFilterParcels: UPDATE_FILTER_PARCELS,
    updateCultures : UPDATE_CULTURES,
    updateExploitations :UPDATE_EXPLOITATIONS,

    //Gestion du bouton de menu burger mobile:
    BurgerMenuOpen: MENU_BURGER_OPEN,
    BurgerMenuClose: MENU_BURGER_COLLAPSE,

    //Gestion du bouton de menu burger desktop:
    BurgerMenuOpenDesktop: MENU_BURGER_OPEN_DESKTOP,

    //Gestion du calendrier:
    //@@

    //Gestion de l'histogramme:
    //@@

    //Gestion des différents panneaux visibles:
    ParcelInfoPanelOpen: PANEL_PARCELINFO_OPEN,
    ParcelInfoPanelClose: PANEL_PARCELINFO_COLLAPSE,
    LayersMenuOpen: MENU_LAYERS_OPEN,
    LayersMenuClose: MENU_LAYERS_CLOSE,
    SetAnchorLayersPanel: SET_ANCHOR_LAYERS_PANEL,
    SwiperPanelOpen: PANEL_SWIPER_OPEN,
    setDisplaySwipeablePanelForMobile: SET_DISPLAY_SWIPEABLE_PANEL_FOR_MOBILE,

    SelectDateImgOfParcel: SELECT_DATE_IMG_PARCEL, //Contexte pas global, mais à la parcelle ! Toutefois, cela permet de détecter rapidement un changement d'image, de parcelle !
    //    SelectLayerOfParcel: SELECT_LAYER_PARCEL, //définit dans l'action 'parcel' !

    // navigation
    goToPage: GO_TO_PAGE,
    goToMapSelectParcel: GO_TO_MAP_SELECT_PARCEL,
    goToMapModulationAndSelectParcel: GO_TO_MAP_MODULATION_SELECT_PARCEL,
    goToMapUnselectParcel: GO_TO_MAP_UNSELECT_PARCEL,

    goToMapSelectObservation: GO_TO_MAP_SELECT_OBSERVATION,

    LoadingGlobalLayer: LOADING_WMSLAYER,
    GlobalLayerLoaded: WMSLAYER_LOADED,

    // Gestion des paramètres URL (paramètres fournis lors de la connexion à l'application (ex: venant du site promotionnel))
    setURLParameters: SET_URL_PARAMETERS,

    // Recentrage de la carte sur une parcelle ou le parcellaire
    fitMapToBounds: FIT_MAP_TO_BOUNDS,

    // Affichage du message demandant la sélection d'une parcelle pour l'utilisation des couches globales:
    showDialogToSelectParcelForUseGlobalLayer: SHOWDLG_NOOPEN_GLOBALLAYER,
    closeDialogToSelectParcelForUseGlobalLayer: CLOSEDLG_NOOPEN_GLOBALLAYER,

    /*// en lien avec l'affichage du composant d'injection des parcelles GEOFOLIA (un seul affichage)
    setFirstDisplayGeofoliaFah: SET_FIRST_DISPLAY_GEOFOLIA_FAH,
    showDisplayGeofoliaFah: SHOW_DISPLAY_GEOFOLIA_FAH,*/ //plus utile depuis la mise en place de la liaison PV

    incitationPremium: INCITATION_PREMIUM,
    closeIncitationPremium: CLOSE_INCITATION_PREMIUM,

    // en lien avec la création d'observation 
    openPinDropFormDialog: OPEN_PIN_DROP_FORM_DIALOG,
    closePinDropFormDialog: CLOSE_PIN_DROP_FORM_DIALOG,

    //en lien avec l'identification
    openIdentificationDialog: OPEN_IDENTIFICATION_DIALOG,
    closeIdentificationDialog: CLOSE_IDENTIFICATION_DIALOG,

    // en lien avec l'activation automatique de dessin d'observation
    enableDrawMarkerPindrop: ENABLE_DRAW_MARKER_PINDROP,
    enableDrawAreaPindrop: ENABLE_DRAW_AREA_PINDROP,
    disableDrawMarkerPindrop: DISABLE_DRAW_MARKER_PINDROP,
    disableDrawAreaPindrop: DISABLE_DRAW_AREA_PINDROP,

    // en lien avec l'affichage d'informations de nouvelles images
    setValueNewImageShowedOnMap: SET_VALUE_NEW_IMAGE_SHOWED_ON_MAP,

    // permet de connaitre sur quelle page on se situe dans l'appli 
    setCurrentPageName: SET_CURRENT_PAGE_NAME,

    // permet de connaitre sur quelle page on se situe dans l'appli 
    showSocialAccountSignupForm: SHOW_SOCIAL_ACCOUNT_SIGNUP_FORM,

    // suppression de toutes les données - permet de revenir à l'état originel (tous les reducers auront leur initialState comme state par défaut)
    resetAppDatas: RESET_APP_DATAS,

    // Affichage ou non du snackbar des nouvelles images (affichage une fois au démarrage de l'appli)
    SnackbarNewImagesHasBeenNewImages: SNACKBAR_NEWIMAGES_HAS_BEEN_SHOWN_NEWIMAGES,
    snackbarHelpNdviHasBeenShown: SNACKBAR_HELP_NDVI_HAS_BEEN_SHOWN,

    // Affichage du menu de profil
    showProfilMenu: SHOW_PROFIL_MENU,
    closeProfilMenu: CLOSE_PROFIL_MENU,
    
    // Affichage du calendrier
    showCalendar: SHOW_CALENDAR,
    closeCalendar: CLOSE_CALENDAR,

    // Affichage et données pour l'édit d'une parcelle
    openDialogParcelEdit: OPEN_PARCEL_EDIT,
    closeDialogParcelEdit: CLOSE_PARCEL_EDIT,

    setGeolocationCircleLayers: SET_GEOLOCALISATION_CIRCLE_LAYERS,

    showImageDialog: SHOW_IMAGE_DIALOG,
    closeImageDialog: CLOSE_IMAGE_DIALOG,
}

//const NEW_CLIENT = "NEW_CLIENT"; //plus utile depuis la mise en service dudidacticiel "first parcel" !
const SHOW_PROMO = "SHOW_PROMO";
const LAYERS_GLOBAL = "LAYERS_GLOBAL";
const NOLIMIT_PARCELS = "NOLIMIT_PARCELS"; //plus utilisé depuis que la typo Explorer a été mis en place, mais pourrai revenir si on décidait de modifier les typos en BdD !
//const NOLIMIT_HISTORIC_PARCELS = "NOLIMIT_HISTORIC_PARCELS"; //pas utile !
const SPECIFIC_MSG_START_EXPLORER = "SPECIFIC_MSG_START_EXPLORER";
const SPECIFIC_MSG_NEAREND_EXPLORER = "SPECIFIC_MSG_NEAREND_EXPLORER";
const SPECIFIC_MSG_COMEBACK_FREEMIUM = "SPECIFIC_MSG_COMEBACK_FREEMIUM";

// TypoClient
const TYPO_FREEMIUM = 1;
const TYPO_TDP = 2;
const TYPO_EXPLORER = 3;
const TYPO_MODULATION = 4;
const TYPO_TDP_AND_FUMURE = 5;
const TYPO_MODULATION_AND_FUMURE = 6;

const MAP = "MAP";
const MODULATION = "MODULATION";
const IMPORT_SLOTS = "IMPORT_SLOTS";
const MY_OBSERVATIONS = "MY_OBSERVATIONS";
const MY_SETTINGS = "MY_SETTINGS";
const MY_PROFILE = "MY_PROFILE";
const CHANGE_PASSWORD = "CHANGE_PASSWORD";
const TUTORIAL_FIRSTPARCEL = "TUTORIAL_FIRSTPARCEL";
const BIOMASS_COLZA = "BIOMASS_COLZA";
const MODELISATION = "MODELISATION";
const FERTILIZER = "FERTILIZER";

/* Enuméré des raisons gérées pour présenter l'incitation au passage en Premium: 
    Attention ! Les valeurs n'ont pas forcément déclarées dans le même ordre, ni toutes présentes, côté Web API. 
    C'est pourquoi, 'ActionForceInviteToPremium' fait l'associtation de l'incitation forcée par la Web API en l'une des valeurs d'énuméré.
*/
export const ReasonInviteToPremium = {
    //NewClient: NEW_CLIENT,
    ShowPromo: SHOW_PROMO,
    LayersGlobal: LAYERS_GLOBAL,
    NoLimitParcels: NOLIMIT_PARCELS,
    //NoLimitHistoricParcels: NOLIMIT_HISTORIC_PARCELS,
    StartExplorer: SPECIFIC_MSG_START_EXPLORER,
    NearEndExplorer: SPECIFIC_MSG_NEAREND_EXPLORER,
    ComebackFreemium: SPECIFIC_MSG_COMEBACK_FREEMIUM,
}

export const TypoClientEnum = {
    Freemium: TYPO_FREEMIUM,
    TdP: TYPO_TDP,
    Explorer: TYPO_EXPLORER,
    Modulation: TYPO_MODULATION,
    TdPAndFumure: TYPO_TDP_AND_FUMURE,
    ModualtionAndFumure: TYPO_MODULATION_AND_FUMURE
}

export const PageName = {
    Map: MAP,
    Modulation: MODULATION,
    ImportSlots: IMPORT_SLOTS,
    MyObservations: MY_OBSERVATIONS,
    MySettings: MY_SETTINGS,
    MyProfile: MY_PROFILE,
    ChangePassword: CHANGE_PASSWORD,
    TutorialFirstParcel: TUTORIAL_FIRSTPARCEL,
    BiomassColza: BIOMASS_COLZA,
    Modelisation: MODELISATION,
    Fertilizer: FERTILIZER
}

export const ProfilIndex = {
    monExploitation: 100,
    parametres: 200,
    abonnements: 300,
    mesFacturations: 400,
    nouveautes: 500,

    // aide: 600,
    aide_IndiceVegetation : 610,
    aide_RecuperationParcelles : 620,   // Mes Parcelles
    aide_RecuperationParcellesGeofolia : 621,
    aide_RecuperationParcellesTelepac : 622,
    aide_ImporterParcelles : 630,
    aide_ModulationTéléchargementModulation : 640,  // PC
    aide_ModulationTéléchargementModulationAndroid : 641,
    aide_ModulationTéléchargementModulationIOS : 642,
    aide_ModulationFormatFichier : 650,
    aide_ModulationResolutionCarte : 660,
    aide_PeseeDeBiomasse : 670,
    aide_Fumure : 680,
    aide_LastNitrogenInput : 681,
    aide_Modelisation: 690,
    aide_HarvestCorn: 691,

    tutosVideo: 700,
    contacterLeServiceClient: 800,
    partagerLAppli: 900,
    whatsappCommu: 1000,
    rateApp: 1100,
    about: 1200,
    disconnect: 1300,
    contacterLeServiceClientPourAbonnement: 1400
}

function getProfilPageUrl(index, clientEmail) {
    let cultureValue = StringTranslate.getLanguage();

    let constUrlLangage = ConstantsTutorialHelps.SubUrlLangageFrFr;
    let constUrlLangageForModulation = ConstantsTutorialHelps.SubUrlLangageFrFr;
    let constSubUrlLangageEndForModulation = ConstantsTutorialHelps.SubUrlLanguageEndFr;

    if (cultureValue && (cultureValue === 'es-ES')) {
        constUrlLangage = ConstantsTutorialHelps.SubUrlLangageEsEs;
        constUrlLangageForModulation = ConstantsTutorialHelps.SubUrlLangageForModulationEsEs;
        constSubUrlLangageEndForModulation = ConstantsTutorialHelps.SubUrlLanguageEndEs;
    }
    else if (cultureValue && (cultureValue === 'en-GB')) {
        constUrlLangage = ConstantsTutorialHelps.SubUrlLangageEnGb;
        constUrlLangageForModulation = ConstantsTutorialHelps.SubUrlLangageEnGb;
        constSubUrlLangageEndForModulation = ConstantsTutorialHelps.SubUrlLanguageEndEn;
    }

    switch(index) {

        case ProfilIndex.aide_IndiceVegetation: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangage + ConstantsTutorialHelps.SubUrlHelpImagesSpotifarm;
        }

        case ProfilIndex.aide_RecuperationParcellesGeofolia: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangage + ConstantsTutorialHelps.SubUrlHelpExportGeofolia;
        }

        case ProfilIndex.aide_RecuperationParcelles: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangage + ConstantsTutorialHelps.SubUrlHelpExportMesparcelles;
        }

        case ProfilIndex.aide_RecuperationParcellesTelepac: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangage + ConstantsTutorialHelps.SubUrlHelpExportTelepac;
        }

        case ProfilIndex.aide_ImporterParcelles: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangage + ConstantsTutorialHelps.SubUrlHelpImport;;
        }

        case ProfilIndex.aide_ModulationTéléchargementModulation: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangageForModulation + ConstantsTutorialHelps.SubUrlHelpModulationTelechargementDesktop + constSubUrlLangageEndForModulation;
        }

        case ProfilIndex.aide_ModulationTéléchargementModulationAndroid: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangageForModulation + ConstantsTutorialHelps.SubUrlHelpModulationTelechargementAndroid + constSubUrlLangageEndForModulation;
        }

        case ProfilIndex.aide_ModulationTéléchargementModulationIOS: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangageForModulation + ConstantsTutorialHelps.SubUrlHelpModulationTelechargementApple + constSubUrlLangageEndForModulation;
        }

        case ProfilIndex.aide_ModulationFormatFichier: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangageForModulation + ConstantsTutorialHelps.SubUrlHelpModulationFichiersEtConsoles + constSubUrlLangageEndForModulation;
        }

        case ProfilIndex.aide_ModulationResolutionCarte: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangageForModulation + ConstantsTutorialHelps.SubUrlHelpResolutionMapModulation;
        }

        case ProfilIndex.aide_PeseeDeBiomasse: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangageForModulation + ConstantsTutorialHelps.SubUrlHelpPeseeDeColza;
        }

        case ProfilIndex.aide_Fumure: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangageForModulation + ConstantsTutorialHelps.SubUrlHelpFumure;
        }

        case ProfilIndex.aide_LastNitrogenInput: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangageForModulation + ConstantsTutorialHelps.SubUrlHelpLastInputN;
        }

        case ProfilIndex.aide_Modelisation: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangageForModulation + ConstantsTutorialHelps.SubUrlHelpModelisation;
        }

        case ProfilIndex.aide_HarvestCorn: {
            //L'aide pour le maïs est en français pour toutes les langues
            return ConstantsTutorialHelps.UrlSpotifarmBase + ConstantsTutorialHelps.SubUrlLangageFrFr + ConstantsTutorialHelps.SubUrlHelpHarvestCorn;
        }

        case ProfilIndex.contacterLeServiceClient: {
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangageForModulation + ConstantsTutorialHelps.SubUrlHelpContactForm + "#" + clientEmail;
        }

        case ProfilIndex.nouveautes: {
            return 'https://headwayapp.co/spotifarm-news';
        }

        case ProfilIndex.tutosVideo: {
            // return 'https://www.spotifarm.fr/top-secret-tutos-video';
            return ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangageForModulation + ConstantsTutorialHelps.SubUrlTutoVideo;
        }

        case ProfilIndex.rateApp: {
            return 'https://www.google.com/search?q=spotifarm&hl=en&source=hp&ei=WJc5YrnxM-OUlwTSw7ugCw&iflsig=AHkkrS4AAAAAYjmlaHKMs9dxPA9FWIsBugJ4JEkBKm3G&gs_ssp=eJzj4tVP1zc0zEmLNzIrMc8wYLRSNagwMU81NzA3SDJLNTE1tzBPszKoME4ySzNONDVLBpImycZGXpzFBfklmWmJRbkARtISxg&oq=spotifarm&gs_lcp=Cgdnd3Mtd2l6EAEYADILCC4QgAQQxwEQrwEyBAgAEB4yBggAEAUQHjoLCC4QgAQQxwEQ0QM6BQgAEIAEOgsILhCABBDHARCjAjoFCC4QgAQ6CAguEIAEENQCOgoILhDHARDRAxAKOgQIABAKOgQILhAKULQGWNwbYNMyaAFwAHgAgAFMiAHLBJIBATmYAQCgAQGwAQA&sclient=gws-wiz#lrd=0x47e7070b6e45787f:0x3b6f3a56cf3a4c32,1,,,';
        }

        case ProfilIndex.contacterLeServiceClientPourAbonnement: {
            return ConstantsTutorialHelps.UrlContactFormForSuscription;
        }


        default:
            return undefined;
    }

}

/* Actions: */
///////////////////////////////////////////////////////////////// ↓↓ Selection et filtre sur les parcelles ↓↓
export function ActionShowSnackbarNewImages(snackbarNewImagesHasBeenShownValue) {
    return {
        type: SNACKBAR_NEWIMAGES_HAS_BEEN_SHOWN_NEWIMAGES,
        snackbarNewImagesHasBeenShown: snackbarNewImagesHasBeenShownValue
    }
}

export function ActionShowSnackbarHelpNdvi(snackbarHelpNdviHasBeenShownValue) {
    return {
        type: SNACKBAR_HELP_NDVI_HAS_BEEN_SHOWN,
        snackbarHelpNdviHasBeenShown: snackbarHelpNdviHasBeenShownValue
    };
}

export function ActionShowProfilMenuDialog(index = ProfilIndex.monExploitation) {
    return function (dispatch, getState ) {
        
        const currentState = getState();
        const clientEmail = lodashGet(currentState, 'clientUserData.clientDatas.masterEmail', undefined);

        dispatch({
            type: SHOW_PROFIL_MENU,
            profilIndex: index,
            profilPageUrl: getProfilPageUrl(index, clientEmail),
        });
    };
}

export function ActionCloseProfilMenuDialog() {
    return {
        type: CLOSE_PROFIL_MENU,
    };
}

// CALENDAR
export function ActionShowCalendarDialog() {
    return {
        type: SHOW_CALENDAR,
    };
}

export function ActionCloseCalendarDialog() {
    return {
        type: CLOSE_CALENDAR,
    };
}

export function ActionOpenParcelEdit(parcel, thumbnailInfos) {
    return function (dispatch, getState) {
        dispatch({
            type: OPEN_PARCEL_EDIT,
            parcel: parcel,
            thumbnailInfos: thumbnailInfos
        })
    }
}

export function ActionCloseParcelEdit() {
    return {
        type: CLOSE_PARCEL_EDIT,
    }
}

export function ActionSelectParcel(parcelIdValue) {
    return function (dispatch, getState /*, { history, userAgentAuthent }*/) {
        // Recherche du nom et de la position dans la liste complète des parcelles, de la parcelle sélectionnée
        // (données redondantes mais permet d'éviter de faire ces recherches à chaque besoin d'affichage)
        let thisParcelName = undefined;
        let satimageIdOfThisParcel = -1;
        let satimageDateOfThisParcel = undefined;
        let mustShowCarrousel = true;
        let satimageSourceOfThisParcel = 0; //non-définis !

        if (getState) {

            const currentStoreState = getState();

            if (currentStoreState && currentStoreState.parcelsData) {
                const dicoOfParcels = currentStoreState.parcelsData.parcelDico;

                const thisParcel = ParcelsHelper.selectParcelFromDicoById(dicoOfParcels, parcelIdValue);

                if (thisParcel) {
                    thisParcelName = thisParcel.name;

                    satimageIdOfThisParcel = thisParcel.currentSatimageId;
                    satimageDateOfThisParcel = thisParcel.currentSatimageDate;
                }

                //Vérifie si le paramètre d'ouverture automatique du carrousel est actif:
                mustShowCarrousel = lodashGet(currentStoreState, 'settingsData.settings.showSwipper', true);
            }
            if (currentStoreState && currentStoreState.satimageData && (satimageIdOfThisParcel > 0)) {
                satimageSourceOfThisParcel = lodashGet(currentStoreState.satimageData, `satimagesByParcelDico[${parcelIdValue}].satimageDico[${satimageIdOfThisParcel}].sourceProvider`, 1);
            }

            // Si l'image sélectionnée est une image modélisée, on affiche le layer ndvi à la parcelle
            if (satimageSourceOfThisParcel === SatImageSourceProvider.Modelisation) {
                let settings = lodashGet(currentStoreState, 'settingsData.settings', {});
                let newSettingsToSave = {
                    ...settings,
                    forewardLayerSelected: ConstantsLayers.NdviParcelLayerName,
                    lastLayer: 4 //WMSLayersInfo-ndvi
                };
        
                dispatch(ActionAskToChangeLayer(undefined, ConstantsLayers.NdviParcelLayerName, newSettingsToSave));
            }
        }

        dispatch({
            type: SELECT_PARCEL,
            parcelId: parcelIdValue,
            parcelName: thisParcelName,
            dateImgId: satimageIdOfThisParcel,
            dateImgDate: satimageDateOfThisParcel,
            providerImagSource: satimageSourceOfThisParcel,
        });

        // Si le paramètre d'ouverture automatique du carrousel est actif, on l'ouvre !
        if (mustShowCarrousel === true) {
            dispatch(ActionSwiperPanelOpen());
        }
    };
}

export function ActionUnselectParcel() {
    return function (dispatch, getState /*, { history, userAgentAuthent }*/) {
        dispatch({
            type: UNSELECT_PARCELS,
        });

        // comme on désélectionne la parcelle, on n'a plus le droit d'être sur une couche globale... comme on n'a qu'une seule couche à la parcelle, on la sélectionne:
        dispatch( ActionSelectLayer(undefined, ConstantsLayers.NdviParcelLayerName) );
    }
}

export function ActionDeselectParcel() {
    return function (dispatch, getState) {
        dispatch({
            type: DESELECT_PARCEL,
        });
    }
}

export function ActionFitMapToBounds(parcelId = null) {
    return {
        type: FIT_MAP_TO_BOUNDS,
        parcelId: parcelId,
    }
}

export function ActionUpdateContextAfterParcelsDeleted() {
    return ActionUnselectParcel();
}

export function ActionInitFarmsAndCulturesOfParcels(parcelDicoValue) {
    return function (dispatch, getState /*, { history, userAgentAuthent }*/) {
        let parcelsDico = parcelDicoValue;
        if ((!parcelDicoValue) && (getState)) {
            const currentStoreState = getState();
            if (currentStoreState && currentStoreState.parcelsData) {
                parcelsDico = currentStoreState.parcelsData.parcelDico;
            }
        }
        if (!parcelsDico) return;

        const farmNameList = ParcelsHelper.searchFarms(parcelsDico);
        const cropNameList = ParcelsHelper.searchCultures(parcelsDico);
        dispatch({
            type: INIT_FARMS_CULTURES_PARCEL,
            farmNameList: farmNameList,
            cropNameList: cropNameList,
        });
    }
}

export function ActionSetIsFilterOpened(isOpen) {
    return {
        type: SET_IS_FILTER_OPENED,
        isOpen: isOpen,
    };
}

/* action creator  en lien avec la mise à jour du filtre */
function ActionUpdateFilter(paramsFilterParcelsValue) {
    return {
        type: UPDATE_FILTER_PARCELS,
        paramsFilterParcels: paramsFilterParcelsValue,
    };
}

/**
 * Action permettant de mettre à jour la parcelle actuellement sélectionnée ainsi que son image
 * @param {number} parcelId 
 * @param {string} parcelName 
 * @param {number} dateImgId 
 * @param {Date} dateImgDate 
 * @param {number} providerImagSource 
 */
export function ActionSelectDateImgParcel(parcelId, parcelName, dateImgId, dateImgDate, providerImagSource) {
    return {
        type: SELECT_DATE_IMG_PARCEL,
        parcelId: parcelId,
        parcelName: parcelName,
        dateImgId: dateImgId,
        dateImgDate: dateImgDate,
        providerImagSource: providerImagSource,
    };
}

const defaultFilterParcel = {
    isFilterApplied: false,
    filterFarmList: [],
    filterParcelName: "",
    filterCropList: [],
    filterAreaComparator: AreaComparatorAllCode,
    filterAreaValue: 0,
};

/**
 * fonction permettant d'appliquer le nouveau filtre mis à jour
 * @param {*} paramsFilterParcelsValue 
 */
export function ActionUpdateFilterAndApply(paramsFilterParcelsValue = undefined) { // définition du nouveau filtre (Exemple : Est-ce par rapport à la culture, à la supperficie, etc...)    
    return function (dispatch, getState) {

        let filter = Object.assign({}, defaultFilterParcel, paramsFilterParcelsValue);

        // Actualise le contenu du filtre:
        dispatch(ActionUpdateFilter(filter));

        settingsWebApiProvider.updateMapParcelFilter(dispatch, getState, filter);

        // récupération du dico de parcelle et des données de filtre
        const currentState = getState();
        const parcelDico = lodashGet(currentState, 'parcelsData.parcelDico', {});
        // Création de la liste filtrée des parcelles:
        if (parcelDico) {
            const filteredParcels = ParcelsHelper.filterParcelsFromParams(parcelDico, filter);
            const parcelIdFilteredList = ParcelsHelper.selectAllParcelIds(filteredParcels);

            // Appel pour mise à jour des parcelles filtrées:
            dispatch(ActionUpdateParcelIdFilteredList(parcelIdFilteredList));
        }
    }
}
/**
 * Action permettant de réinitialiser le filtre
 * (alias de ActionUpdateFilterAndApply)
 */
export function ActionResetAndApplyParcelFilter() {
    return ActionUpdateFilterAndApply();
}

/**
 * Update la liste des cultures de nos parcelles
 * @param {String[]} cropNameList liste des cultures
 */
export function ActionUpdateCultures(cropNameList) {
    return {
        type: UPDATE_CULTURES,
        cropNameList: cropNameList,
    }
}

/**
 * Update la liste des exploitations
 * @param {String[]} farmNameList liste des cultures
 */
export function ActionUpdateExploitations(farmNameList) {
    return {
        type: UPDATE_EXPLOITATIONS,
        farmNameList: farmNameList,
    }
}

///////////////////////////////////////////////////////////////// ↑↑ Selection et filtre sur les parcelles ↑↑


///////////////////////////////////////////////////////////////// ↓↓ bouton de menu burger ↓↓
export function ActionBurgerMenuOpen() {
    return {
        type: MENU_BURGER_OPEN,
    };
}

export function ActionBurgerMenuOpenDesktop() {
    return {
        type: MENU_BURGER_OPEN_DESKTOP,
    };
}

export function ActionBurgerMenuCollapse() {
    return {
        type: MENU_BURGER_COLLAPSE,
    };
}
///////////////////////////////////////////////////////////////// ↑↑ bouton de menu burger ↑↑

/* ↓ Action permettant d'ouvrir une fenêtre de dialogue (en smartphone) lors de la création d'une observation ↓ */
export function ActionOpenPinDropFormDialog(obj) {
    return {
        type: OPEN_PIN_DROP_FORM_DIALOG,
        datas: obj,
    };
}

/* ↓ Action permettant de fermer la fenêtre de dialogue (en smartphone) lors de la création d'une observation ↓ */
export function ActionClosePinDropFormDialog() {
    return {
        type: CLOSE_PIN_DROP_FORM_DIALOG,
    };
}

/* ↓ Action permettant d'ouvrir une fenêtre de dialogue (en smartphone) lors de l'identification ↓ */
export function ActionOpenIdentificationDialog() {
    return {
        type: OPEN_IDENTIFICATION_DIALOG,
    };
}

/* ↓ Action permettant de fermer la fenêtre de dialogue (en smartphone) lors de l'identification ↓ */
export function ActionCloseIdentificationDialog() {
    return {
        type: CLOSE_IDENTIFICATION_DIALOG,
    };
}

///////////////////////////////////////////////////////////////// ↓↓ activation:désactivation automatique de dessin d'observation ↓↓
export const ActionEnableDrawMarkerPindrop = () => ({ type: ENABLE_DRAW_MARKER_PINDROP });
export const ActionEnableDrawAreaPindrop = () => ({ type: ENABLE_DRAW_AREA_PINDROP });
export const ActionDisableDrawMarkerPindrop = () => ({ type: DISABLE_DRAW_MARKER_PINDROP });
export const ActionDisableDrawAreaPindrop = () => ({ type: DISABLE_DRAW_AREA_PINDROP });

// ↓↓ Mise à jour de la valeur exprimant le fait que de nouvelles images sont actuellement affichées sur la carte ↓↓
export const ActionSetValueNewImageShowedOnMap = (bool = false) => ({
    type: SET_VALUE_NEW_IMAGE_SHOWED_ON_MAP,
    bool: bool,
});

///////////////////////////////////////////////////////////////// ↓↓ différents panneaux visibles ↓↓
export const ActionParcelInfoPanelOpen = () => ({ type: PANEL_PARCELINFO_OPEN });
export const ActionParcelInfoPanelCollapse = () => ({ type: PANEL_PARCELINFO_COLLAPSE });
export const ActionLayersMenuOpen = () => ({ type: MENU_LAYERS_OPEN });
export const ActionLayersMenuClose = () => ({ type: MENU_LAYERS_CLOSE });
export const ActionSwiperPanelOpen = () => ({ type: PANEL_SWIPER_OPEN });
export const ActionSetAnchorLayersPanel = (target) => 
    ({ 
        anchorLayersPanelTarget: target,
        type: SET_ANCHOR_LAYERS_PANEL 
    });

export const setDisplaySwipeablePanelForMobile = (display, parcelIdZoomed) =>
({
    displaySwipeablePanelForMobile: display,
    parcelIdZoomed: parcelIdZoomed,
    type: SET_DISPLAY_SWIPEABLE_PANEL_FOR_MOBILE
});

///////////////////////////////////////////////////////////////// ↑↑ différents panneaux visibles ↑↑

// ↓↓ paramètres URL ↓↓
export function ActionSetURLParameters(array) {
    return {
        type: SET_URL_PARAMETERS,
        URLParameters: array,
    };
}

///////////////////////////////////////////////////////////////// ↓↓ CHOIX DE DATE ↓↓
export function ActionSelectDateImg(imgIdValue, imgDateValue = undefined, parcelIdValue = -1) {
    // Si 'parcelIdValue' vaut '-1', il faut retrouver la parcelle sélectionnée actuellement !
    return function (dispatch, getState) {
        if (!imgIdValue) return;

        const currentStoreState = getState();
        let currentParcelIdSelected = parcelIdValue;
        let thisParcelName = undefined;
        if ((!parcelIdValue) || (parcelIdValue <= 0)) {
            // 1- récupération de la parcelle sélectionnée
            currentParcelIdSelected = lodashGet(currentStoreState, 'contextAppData.parcelIdSelected', -1);

            const thisParcel = lodashGet(currentStoreState, `parcelsData.parcelDico[${currentParcelIdSelected}]`, undefined);
            if (thisParcel) {
                thisParcelName = thisParcel.name;
            }
        }

        if ((currentParcelIdSelected) && (currentParcelIdSelected > 0)) {
            // 1- récupération de la Satimage associée à cette parcelle, par son 'Id' d'image:
            //RQ: si on nous a fournis QUE la date, c'est une autre action qui fera le boulot de retrouver cette image par la date !
            const satimageFund = lodashGet(currentStoreState, `satimageData.satimagesByParcelDico[${currentParcelIdSelected}].satimageDico[${imgIdValue}]`, undefined);
            let dateInstance = (satimageFund) ? satimageFund.date : imgDateValue;
            let satimageSourceOfThisParcel = (satimageFund) ?  satimageFund.sourceProvider : 0; //non-définis !

            if ((dateInstance) && (!(dateInstance instanceof Date))) {
                dateInstance = new Date(dateInstance);
            }

            if (satimageFund && satimageFund.id && (satimageFund.id > 0)) {
                //prévient alors que le changement est validé (contextApp puis parcel):
                dispatch(ActionSelectDateImgParcel(currentParcelIdSelected, thisParcelName, imgIdValue, dateInstance, satimageSourceOfThisParcel));

                dispatch(ActionChangeSatimageIdToParcel(currentParcelIdSelected, imgIdValue, dateInstance));

                // Si l'image sélectionnée est une image modélisée, on affiche le layer ndvi à la parcelle
                if (satimageSourceOfThisParcel === SatImageSourceProvider.Modelisation) {
                    let settings = lodashGet(currentStoreState, 'settingsData.settings', {});
                    let newSettingsToSave = {
                        ...settings,
                        forewardLayerSelected: ConstantsLayers.NdviParcelLayerName,
                        lastLayer: 4 //WMSLayersInfo-ndvi
                    };
            
                    dispatch(ActionAskToChangeLayer(undefined, ConstantsLayers.NdviParcelLayerName, newSettingsToSave));
                }
            }
        }

        return;
    }
}

export function ActionSelectDateImgFromDate(dateImgDateValue, parcelIdValue = -1) {
    // Si 'parcelIdValue' vaut '-1', il faut retrouver la parcelle sélectionnée actuellement !
    return function (dispatch, getState) {
        if (!dateImgDateValue) return;

        let currentParcelIdSelected = parcelIdValue;
        let thisParcelName = undefined;
        if ((!parcelIdValue) || (parcelIdValue <= 0)) {
            const currentStoreState = getState();

            // 1- récupération de l'ID de la parcelle sélectionnée
            currentParcelIdSelected = lodashGet(currentStoreState, 'contextAppData.parcelIdSelected', -1);

            const thisParcel = lodashGet(currentStoreState, `parcelsData.parcelDico[${currentParcelIdSelected}]`, undefined);
            if (thisParcel) {
                thisParcelName = thisParcel.name;
            }
        }

        if (currentParcelIdSelected) {
            const currentStoreState = getState();

            let dateInstance = dateImgDateValue;
            if (!(dateImgDateValue instanceof Date)) {
                dateInstance = new Date(dateImgDateValue);
            }

            // 1- récupération de la liste des Satimage pour la parcelle 
            const satimageDicoOfParcel = lodashGet(currentStoreState, `satimageData.satimagesByParcelDico[${currentParcelIdSelected}].satimageDico`, undefined);
            // 2- Recherche de la Satimage d'après sa date, pour la parcelle visée:
            const satimageFund = SatimageHelper.getSatimageFromListByDate(satimageDicoOfParcel, dateInstance);

            if (satimageFund && satimageFund.id && (satimageFund.id > 0)) {
                let satimageSourceOfThisParcel = satimageFund.sourceProvider;

                //prévient alors que le changement est validé (contextApp puis parcel):
                dispatch(ActionSelectDateImgParcel(currentParcelIdSelected, thisParcelName, satimageFund.id, dateInstance, satimageSourceOfThisParcel));

                dispatch(ActionChangeSatimageIdToParcel(currentParcelIdSelected, satimageFund.id, dateInstance));
                
                // Si l'image sélectionnée est une image modélisée, on affiche le layer ndvi à la parcelle
                if (satimageSourceOfThisParcel === SatImageSourceProvider.Modelisation) {
                    let settings = lodashGet(currentStoreState, 'settingsData.settings', {});
                    let newSettingsToSave = {
                        ...settings,
                        forewardLayerSelected: ConstantsLayers.NdviParcelLayerName,
                        lastLayer: 4 //WMSLayersInfo-ndvi
                    };
            
                    dispatch(ActionAskToChangeLayer(undefined, ConstantsLayers.NdviParcelLayerName, newSettingsToSave));
                }
            }
        }

        return;
    }
}
///////////////////////////////////////////////////////////////// ↑↑ CHOIX DE DATE ↑↑


export const EnumGoToView = {
    map: 'map',
    modulation: 'modulation',
    biomass: 'biomass',
    fertilizer: 'fertilizer',
    parcels: 'parcels',
    settings: 'settings',
    profil: 'profil',
    observations: 'observations',
    modelisation: 'modelisation',
    didacticielfirstparcel: 'didacticiel/firstparcel',
    harvest: 'harvest',
}

/* Actions creator */
export const ActionGoToPage = (page) => (dispatch, getState, { history, userAgentAuthent }) => {
    let mapAndMustShowCarrousel = false;
    let parcelIdSelected = -1;
    if (page && ((page === EnumGoToView.map) || (page.startsWith(EnumGoToView.map)) || (page.startsWith(`/${EnumGoToView.map}`)))) {
        mapAndMustShowCarrousel = true;
        if (getState) {
            const currentStoreState = getState();
            if (currentStoreState) {
                //Vérifie si le paramètre d'ouverture automatique du carrousel est actif:
                mapAndMustShowCarrousel = lodashGet(currentStoreState, 'settingsData.settings.showSwipper', true);
                parcelIdSelected = lodashGet(currentStoreState, 'contextAppData.parcelIdSelected', -1);
            }
        }
    }

    if (history) history.push(page);

    dispatch({
        type: GO_TO_PAGE,
        currentPage: page
    });

    // Si on demande à passer sur la carto ET que le paramètre d'ouverture automatique du carrousel est actif, on l'ouvre !
    if ((mapAndMustShowCarrousel === true) && (parcelIdSelected > 0)) {
        dispatch(ActionSwiperPanelOpen());
    }
}

/* Action permettant d'être redirigé vers le bon onglet de la page passée en paramètre */
export const ActionGoToTabOfPage = (tab, page) => (dispatch, getSate, { history, userAgentAuthent }) => {
    if (((tab !== undefined) && (tab !== null) && (tab !== '')) || ((page !== undefined) && (page !== null) && (page !== ''))) {
        const goToTab = Number.parseInt(tab, 10);
        switch (page) {
            case EnumGoToView.harvest: //Récolte maïs
                dispatch( ActionGoToTab(((goToTab < 0) || Number.isNaN(goToTab)) ? HarvestTab.Management : goToTab) ); 
                break;
            case EnumGoToView.fertilizer: //Fumure
                dispatch( ActionSelectFertilizerTab(((goToTab < 0) || Number.isNaN(goToTab)) ? TabAbstractFertilizer.NITROGEN_MANAGEMENT : goToTab) ); 
                break;
            case EnumGoToView.parcels: 
                //@parcelles
                break;
            case EnumGoToView.modulation: 
                //@modulation
                break;
            case EnumGoToView.biomass: 
                //@biomasse
                break;
            default:
                break;
        }
    }
}

export function ActionGoToMapAndSelectParcel(parcelIdValue) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        // Recherche du nom et de la position dans la liste complète des parcelles, de la parcelle sélectionnée
        // (données redondantes mais permet d'éviter de faire ces recherches à chaque besoin d'affichage)
        let thisParcelName = undefined;
        let satimageIdOfThisParcel = -1;
        let satimageDateOfThisParcel = undefined;
        let mustShowCarrousel = true;
        let satimageSourceOfThisParcel = 0; //non-définis !
        if (getState) {
            const currentStoreState = getState();
            if (currentStoreState && currentStoreState.parcelsData) {
                const dicoOfParcels = currentStoreState.parcelsData.parcelDico;

                const thisParcel = ParcelsHelper.selectParcelFromDicoById(dicoOfParcels, parcelIdValue);
                if (thisParcel) {
                    thisParcelName = thisParcel.name;

                    satimageIdOfThisParcel = thisParcel.currentSatimageId;
                    satimageDateOfThisParcel = thisParcel.currentSatimageDate;
                }

                //Vérifie si le paramètre d'ouverture automatique du carrousel est actif:
                mustShowCarrousel = lodashGet(currentStoreState, 'settingsData.settings.showSwipper', true);
            }
            if (currentStoreState && currentStoreState.satimageData && (satimageIdOfThisParcel > 0)) {
                satimageSourceOfThisParcel = lodashGet(currentStoreState.satimageData, `satimagesByParcelDico[${parcelIdValue}].satimageDico[${satimageIdOfThisParcel}].sourceProvider`, 1);
            }

            
            if (history) {
                history.push(`/${EnumGoToView.map}/parcel/${parcelIdValue}`);
            }

            dispatch({
                type: GO_TO_MAP_SELECT_PARCEL,
                currentPage: EnumGoToView.map,
                parcelId: parcelIdValue,
                parcelName: thisParcelName,
                dateImgId: satimageIdOfThisParcel,
                dateImgDate: satimageDateOfThisParcel,
                providerImagSource: satimageSourceOfThisParcel,
            });

            // Si le paramètre d'ouverture automatique du carrousel est actif, on l'ouvre !
            if (mustShowCarrousel === true) {
                dispatch(ActionSwiperPanelOpen());
            }

            // Si l'image sélectionnée est une image modélisée, on affiche le layer ndvi à la parcelle
            if (satimageSourceOfThisParcel === SatImageSourceProvider.Modelisation) {
                let settings = lodashGet(currentStoreState, 'settingsData.settings', {});
                let newSettingsToSave = {
                    ...settings,
                    forewardLayerSelected: ConstantsLayers.NdviParcelLayerName,
                    lastLayer: 4 //WMSLayersInfo-ndvi
                };
        
                dispatch(ActionAskToChangeLayer(undefined, ConstantsLayers.NdviParcelLayerName, newSettingsToSave));
            }
        };
    }
}

export function ActionGoToMapAndSelectObservation(observationIdValue) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        // Recherche du nom et de la position dans la liste complète des observations, de l'observation sélectionnée
        // (données redondantes mais permet d'éviter de faire ces recherches à chaque besoin d'affichage)
        let thisObservationName = undefined;

        if (getState) {
            const currentStoreState = getState();
            if (currentStoreState && currentStoreState.observationsData) {
                const dicoOfObservations = currentStoreState.observationsData.observationDico;
                const thisObservation = ObservationsHelper.selectObservationFromDicoById(dicoOfObservations, observationIdValue);
                if (thisObservation) {
                    thisObservationName = thisObservation.name;
                }
            }
        }
        if (history) {
            history.push(`/${EnumGoToView.map}/observation/${observationIdValue}`);
        }

        dispatch({
            type: GO_TO_MAP_SELECT_OBSERVATION,
            currentPage: EnumGoToView.map,
            observationId: observationIdValue,
            observationName: thisObservationName,
        });

        // comme on désélectionne la parcelle, on n'a plus le droit d'être sur une couche globale... comme on n'a qu'une seule couche à la parcelle, on la sélectionne:
        dispatch( ActionSelectLayer(undefined, ConstantsLayers.NdviParcelLayerName) );
    };
}

export const ActionGoToMapAndUnselectParcel = () => (dispatch, getState, { history, userAgentAuthent }) => {
    let parcelCounter = -1;
    let thisParcel = undefined;
    if (getState) {
        const currentStoreState = getState();
        if (currentStoreState) {
            parcelCounter = lodashGet(currentStoreState, `parcelsData.parcelDicoCounter`, -2);

            const dicoOfParcels = lodashGet(currentStoreState, `parcelsData.parcelDico`, undefined);
            thisParcel = ParcelsHelper.selectFirstParcelFromDico(dicoOfParcels);
        }
    }

    if ((parcelCounter === 1) && thisParcel) {
        dispatch( ActionGoToMapAndSelectParcel(thisParcel.id) );
    } else {
        if (history) {
            history.push(`/${EnumGoToView.map}`);
        }

        dispatch({
            type: GO_TO_MAP_UNSELECT_PARCEL,
            currentPage: EnumGoToView.map,
        });

        // comme on désélectionne la parcelle, on n'a plus le droit d'être sur une couche globale... comme on n'a qu'une seule couche à la parcelle, on la sélectionne:
        dispatch( ActionSelectLayer(undefined, ConstantsLayers.NdviParcelLayerName) );
    }
}

export const ActionGoToMap = () => (dispatch, getState, { history, userAgentAuthent }) => {
    let currentPage = undefined;
    let parcelCounter = -1;
    let thisParcel = undefined;
    let mustShowCarrousel = true;
    let parcelIdSelected = -1;
    if (getState) {
        const currentStoreState = getState();
        if (currentStoreState) {
            currentPage = lodashGet(currentStoreState, `contextAppData.currentPage`, undefined);
            parcelCounter = lodashGet(currentStoreState, `parcelsData.parcelDicoCounter`, -2);
            parcelIdSelected = lodashGet(currentStoreState, 'contextAppData.parcelIdSelected', -1);


            const dicoOfParcels = lodashGet(currentStoreState, `parcelsData.parcelDico`, undefined);
            thisParcel = ParcelsHelper.selectFirstParcelFromDico(dicoOfParcels);

            //Vérifie si le paramètre d'ouverture automatique du carrousel est actif:
            mustShowCarrousel = lodashGet(currentStoreState, 'settingsData.settings.showSwipper', true);
        }
    }

    
    if ((parcelCounter === 1) && thisParcel) {
        dispatch( ActionGoToMapAndSelectParcel(thisParcel.id) );
    } else {
        if (history && ((!currentPage) || (currentPage !== EnumGoToView.map))) {
            history.push(`/${EnumGoToView.map}`);
        }

        dispatch({ type: GO_TO_PAGE, currentPage: EnumGoToView.map });

        // Si on demande à passer sur la carto ET que le paramètre d'ouverture automatique du carrousel est actif, on l'ouvre !
        if ((mustShowCarrousel === true) && (parcelIdSelected > 0)) {
            dispatch( ActionSwiperPanelOpen() );
        }
    }
}

export function ActionGoToModulation(isWidgetModulation = false) {
    return function(dispatch, getState, { history, userAgentAuthent }) {
        let currentPage = undefined;
        if (getState) {
            const currentStoreState = getState();
            if (currentStoreState && currentStoreState.contextAppData) {
                currentPage = currentStoreState.contextAppData.currentPage;
            }
        }
        if (history && ((!currentPage) || (currentPage !== EnumGoToView.modulation))) {
            history.push(`/${EnumGoToView.modulation}`);
        }

        /* Si "isWidgetModulation === true" alors on l'envoie vers la map sinon on laisse l'étape à laquelle il était */
        if (isWidgetModulation === true) {
            dispatch( ActionGoToStepOfModulation(ModulationStep.CHOIX_PARCELS) );
        }

        dispatch({ 
            type: GO_TO_PAGE, 
            currentPage: EnumGoToView.modulation,
            askToShowModulationFromMap: false, });
    };
}

export function ActionGoToModulationFromMap() {
    return function(dispatch, getState, { history, userAgentAuthent }) {
        let currentPage = undefined;
        if (getState) {
            const currentStoreState = getState();
            if (currentStoreState && currentStoreState.contextAppData) {
                currentPage = currentStoreState.contextAppData.currentPage;
            }
        }
        if (history && ((!currentPage) || (currentPage !== EnumGoToView.modulation))) {
            history.push(`/${EnumGoToView.modulation}`);
        }

        /* Direction vers l'étape de choix de parcelles */
        dispatch( ActionGoToStepOfModulation(ModulationStep.DOSES) );

        dispatch({ 
            type: GO_TO_PAGE, 
            currentPage: EnumGoToView.modulation,
            askToShowModulationFromMap: true, });
    };
}

export function ActionGoToBiomassFromMap() {
    return function(dispatch, getState, { history, userAgentAuthent }) {
        let currentPage = undefined;
        if (getState) {
            const currentStoreState = getState();
            if (currentStoreState && currentStoreState.contextAppData) {
                currentPage = currentStoreState.contextAppData.currentPage;
            }
        }
        if (history && ((!currentPage) || (currentPage !== EnumGoToView.biomass))) {
            history.push(`/${EnumGoToView.biomass}`);
        }

        /* Direction vers l'étape de choix de parcelles */
        dispatch( ActionGoToStepOfWeightingBiomass(BiomassStep.WEIGHTING) );

        dispatch({ 
            type: GO_TO_PAGE, 
            currentPage: EnumGoToView.biomass,
            askToShowBiomassFromMap: true,
        });
    };
}

export function ActionGoToBiomass(isWidgetBiomass = false) {
    return function(dispatch, getState, { history, userAgentAuthent }) {
        let currentPage = undefined;
        if (getState) {
            const currentStoreState = getState();
            if (currentStoreState && currentStoreState.contextAppData) {
                currentPage = currentStoreState.contextAppData.currentPage;
            }
        }
        if (history && ((!currentPage) || (currentPage !== EnumGoToView.biomass))) {
            history.push(`/${EnumGoToView.biomass}`);
        }

        /* Si "isWidgetBiomass === true" alors on l'envoie vers la map sinon on laisse l'étape à laquelle il était */
        if (isWidgetBiomass === true) {
            dispatch( ActionGoToStepOfWeightingBiomass(BiomassStep.CHOIX_PARCELS, false));
        }

        dispatch({ 
            type: GO_TO_PAGE, 
            currentPage: EnumGoToView.biomass,
            askToShowBiomassFromMap: false
        });
    };
}

export function ActionGoToFertilizer() {
    return function(dispatch, getState, { history, userAgentAuthent }) {
        let closingDateAzofert = undefined;
        let openingDateAzofert = undefined;

        let currentPage = undefined;

        if (getState) {
            const currentStoreState = getState();
            if (currentStoreState && currentStoreState.contextAppData) {
                currentPage = currentStoreState.contextAppData.currentPage;
            }

            if (currentStoreState && currentStoreState.fertilizerData) {
                closingDateAzofert = currentStoreState.fertilizerData.closingDateAzofert;
                openingDateAzofert = currentStoreState.fertilizerData.openingDateAzofert;
            } 
        }

        if (history && ((!currentPage) || (currentPage !== EnumGoToView.fertilizer))) {
            history.push(`/${EnumGoToView.fertilizer}`);
        }

        if (closingDateAzofert === undefined && openingDateAzofert === undefined) {
            /*  Demande de la plage de dates de clôture de Azofert */
            dispatch( ActionInitAzofertDatesRange() );
        }

        dispatch({ 
            type: GO_TO_PAGE, 
            currentPage: EnumGoToView.fertilizer,
            askToShowFertilizerFromMap: false
        });
    };
}

export function ActionGoToHarvest() {
    return function(dispatch, getState, { history, userAgentAuthent }) {
        let currentPage = undefined;
        if (getState) {
            const currentStoreState = getState();
            if (currentStoreState && currentStoreState.contextAppData) {
                currentPage = currentStoreState.contextAppData.currentPage;
            }
        }
        if (history && ((!currentPage) || (currentPage !== EnumGoToView.harvest))) {
            history.push(`/${EnumGoToView.harvest}`);
        }

        dispatch({ 
            type: GO_TO_PAGE, 
            currentPage: EnumGoToView.harvest,
        });
    };
}

export const ActionGoToParcels = () => (dispatch, getState, { history, userAgentAuthent }) => {
    let currentPage = undefined;
    if (getState) {
        const currentStoreState = getState();
        if (currentStoreState && currentStoreState.contextAppData) {
            currentPage = currentStoreState.contextAppData.currentPage;
        }
    }
    if (history && ((!currentPage) || (currentPage !== EnumGoToView.parcels))) {
        history.push(`/${EnumGoToView.parcels}`);
    }

    dispatch({ type: GO_TO_PAGE, currentPage: EnumGoToView.parcels });
}

export const ActionGoToSettings = () => (dispatch, getState, { history, userAgentAuthent }) => {
    let currentPage = undefined;
    if (getState) {
        const currentStoreState = getState();
        if (currentStoreState && currentStoreState.contextAppData) {
            currentPage = currentStoreState.contextAppData.currentPage;
        }
    }
    if (history && ((!currentPage) || (currentPage !== EnumGoToView.settings))) {
        history.push(`/${EnumGoToView.settings}`);
    }

    dispatch({ type: GO_TO_PAGE, currentPage: EnumGoToView.settings });
}

export const ActionGoToProfil = () => (dispatch, getState, { history, userAgentAuthent }) => {
    let currentPage = undefined;
    if (getState) {
        const currentStoreState = getState();
        if (currentStoreState && currentStoreState.contextAppData) {
            currentPage = currentStoreState.contextAppData.currentPage;
        }
    }
    if (history && ((!currentPage) || (currentPage !== EnumGoToView.profil))) {
        history.push(`/${EnumGoToView.profil}`);
    }

    dispatch({ type: GO_TO_PAGE, currentPage: EnumGoToView.profil });
}

export const ActionGoToObservations = () => (dispatch, getState, { history, userAgentAuthent }) => {
    let currentPage = undefined;
    if (getState) {
        const currentStoreState = getState();
        if (currentStoreState && currentStoreState.contextAppData) {
            currentPage = currentStoreState.contextAppData.currentPage;
        }
    }
    if (history && ((!currentPage) || (currentPage !== EnumGoToView.observations))) {
        history.push(`/${EnumGoToView.observations}`);
    }

    dispatch({ type: GO_TO_PAGE, currentPage: EnumGoToView.observations });
}

export const ActionGoToModelisation = () => (dispatch, getState, { history, userAgentAuthent }) => {
    let currentPage = undefined;
    if (getState) {
        const currentStoreState = getState();
        if (currentStoreState && currentStoreState.contextAppData) {
            currentPage = currentStoreState.contextAppData.currentPage;
        }
    }
    if (history && ((!currentPage) || (currentPage !== EnumGoToView.parcels))) {
        history.push(`/${EnumGoToView.parcels}`);
    }

    dispatch({ type: GO_TO_PAGE, currentPage: EnumGoToView.parcels });
    // Définie l'onglet sur lequel aller
    dispatch( ActionSetParcelTab(1) );
}

export const ActionGoToDidacticielFirstParcel = () => (dispatch, getState, { history }) => {
    history.push(`/${EnumGoToView.didacticielfirstparcel}`);
    dispatch({ type: GO_TO_PAGE, currentPage: EnumGoToView.didacticielfirstparcel });
}

///////////////////////////////////////////////////////////////// ↑↑ NAVIGATION ↑↑


///////////////////////////////////////////////////////////////// ↓↓ Choix des couches / chargement de celles-ci ↓↓
export function ActionLoadingGlobalLayer() {
    return {
        type: LOADING_WMSLAYER,
    };
}

export function ActionGlobalLayerLoaded() {
    return {
        type: WMSLAYER_LOADED,
    };
}
///////////////////////////////////////////////////////////////// ↑↑ Choix des couches / chargement de celles-ci ↑↑


///////////////////////////////////////////////////////////////// ↓↓ Fonctionnel de contrôle de l'utilisation des couches ↓↓
export function ActionAskToChangeLayer(baseLayertypeValue, forewardLayertypeValue, newSettingsValue = undefined) {
    return function (dispatch, getState) {
        //Détient tout les contrôles associés aux changement de couches !
        
        // Recherche de la parcelle sélectionnée actuellement (si il y en a une...):
        const currentStoreState = getState(); 
        const enumTypoClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.enumTypoClient', TypoClientEnum.Freemium);
        const showInviteToPremium = lodashGet(currentStoreState, 'contextAppData.showInviteToPremium', false);
        
        //Dans le cas de la sélection d'une couche globale :
        if (forewardLayertypeValue && (
            (forewardLayertypeValue === ConstantsLayers.VisibleGlobalLayerName) || (forewardLayertypeValue === ConstantsLayers.NdviGlobalLayerName))) {
            //Ctrl incitation premium: Si on est asujettis à l'incitation au passage en mode Premium:
            //RQ: plus tard, on gérera une info sur la typo indiquant l'autorisation ou non cet accès fonctionnel (afin de ne plus ce baser sur l'id de typo).
            if ((!enumTypoClient) || (enumTypoClient === TypoClientEnum.Freemium)) {
                // si l'écran d'incitation est déjà en cours de présentation:
                if (showInviteToPremium === true) {
                    return; // => on n'incite pas mais n'autorisera pas non-plus
                }
                else { // => sinon, on lance la demande de présentation de l'incitation !
                    dispatch( ActionShowInviteToPremium(ReasonInviteToPremium.LayersGlobal) );
                    return; // ET on ne fait rien d'autre...
                }
            }

            // Recherche de la parcelle sélectionnée actuellement (si il y en a une...):
            const parcelIdSelected = lodashGet(currentStoreState, 'contextAppData.parcelIdSelected', -1);

            //ICI, on peut tomber sur le cas où aucune parcelle n'est séletionnée !
            // Dans ce cas, on affiche une alerte demandant la sélection de l'une d'entre elle:
            if ((parcelIdSelected === undefined) || (parcelIdSelected === null) || (parcelIdSelected <= 0)) {
                dispatch( ActionOpenMessageOfNoOpenGlobalLayer() ); 
                dispatch( ActionSelectLayer(baseLayertypeValue, ConstantsLayers.NdviParcelLayerName) ); // = 'WMSLayersInfo-ndvi');
                
                return; //ne change pas de layer !
            }
        }

        //Réalise l'appel réel de changement de couche:
        dispatch( ActionSelectLayer(baseLayertypeValue, forewardLayertypeValue) );

        //Si on a fournit un nouveau paramétrage, on le sauvegarde en BdD:
        if (newSettingsValue !== undefined) {
            dispatch( ActionSaveSettingsAsk(newSettingsValue) );
        }
    }
}

/* Action permettant d'indiquer à l'utilisateur qu'il doit sélectionner une parcelle pour disposer des couches globales */
export function ActionOpenMessageOfNoOpenGlobalLayer() {
    return {
        type: SHOWDLG_NOOPEN_GLOBALLAYER,
    };
}

export function ActionCloseMessageOfNoOpenGlobalLayer() {
    return {
        type: CLOSEDLG_NOOPEN_GLOBALLAYER,
    };
}


///////////////////////////////////////////////////////////////// ↓↓ Fonctionnel de l'incitation au passage en Premium ↓↓
export function ActionShowInviteToPremium(reason) {
    return function (dispatch, getState) {
        // Recherche dans les paramètres du client, si le marketing lui avait affecté un code promo (OU si client en liaison Géofolia)
        const currentStoreState = getState();
        //utilise plutôt l'info mise sur le reducer ClientUser car présent en même temps que 'masterEmail et que finalement, c'est plus ca place...'
        //const discountCodeValue = lodashGet(currentStoreState, 'settingsData.settings.discountCode', undefined);
        const discountCodeValue = lodashGet(currentStoreState, 'clientUserData.discountCodeToDisplay', undefined);
        //const isClientPvLinkValue = lodashGet(currentStoreState, 'clientUserData.pvLinkActivated', false);

        dispatch({
            type: INCITATION_PREMIUM,
            discountCode: discountCodeValue,
            //isClientPvLink: isClientPvLinkValue, //on utilise en directe la valeur stockée dans 'clientUserData.pvLinkActivated' !
            reasonInvitation: reason,
        });
    }
}

//cas spécifique de l'incitation demandée par la Web API
export function ActionForceInviteToPremium(reason = undefined, clientDatas = undefined) {
    return function (dispatch, getState) {
        // Recherche dans les paramètres du client, si la web API nous en a fournis:
        const currentStoreState = getState();
        const discountCodeValue = lodashGet(currentStoreState, 'clientUserData.discountCodeToDisplay', undefined);
        //const isClientPvLinkValue = lodashGet(currentStoreState, 'clientUserData.pvLinkActivated', false);

        //Utilise la raison donnée à la connexion, si aucune n'a été fournis à l'appel de la méthode:
        let reasonFund = reason;
        if (!reason) {
            //RQ: 'clientDatas.forceIncitation' et 'clientDatas.specificTypeIncitation' reflète les valeurs des mêmes propriétés disponibles sur 'userDatas' !
            // Mais ce serai mieux d'utiliser que les unes ou les autres...
            let forceIncitationValue = (clientDatas) ? clientDatas.forceIncitation : lodashGet(currentStoreState, 'clientUserData.forceIncitation', false);
            const incitationTypeValue = (clientDatas) ? clientDatas.specificTypeIncitation : lodashGet(currentStoreState, 'clientUserData.incitationType', undefined);
            if (incitationTypeValue === 0) {
                forceIncitationValue = false;
            } else if (forceIncitationValue === true) {//pourrai être mis à 'false' !
                switch (incitationTypeValue) {
                    case 1: {
                        reasonFund = ReasonInviteToPremium.StartExplorer;
                        break;
                    }

                    case 2: {
                        reasonFund = ReasonInviteToPremium.NearEndExplorer;
                        break;
                    }

                    case 3: {
                        reasonFund = ReasonInviteToPremium.ComebackFreemium;
                        break;
                    }

                    case 4: {
                        reasonFund = ReasonInviteToPremium.ShowPromo;
                        break;
                    }

                    default: {
                        reasonFund = reason; //déjà le cas en principe !
                        break;
                    }
                }
            }
        } //else //on nous a fournis une raison !

        if (reasonFund) {
            dispatch({
                type: INCITATION_PREMIUM,
                discountCode: discountCodeValue,
                //isClientPvLink: isClientPvLinkValue, //on utilise en directe la valeur stockée dans 'clientUserData.pvLinkActivated' !
                reasonInvitation: reasonFund,
            });
        } //else pas de raison identifiée !
    }
}

export function ActionCloseInviteToPremium(userChoice) {
    return function (dispatch, getState) {
        // Obtient la raison actuelle de l'affichage de l'incitation:
        const currentStoreState = getState();
        // 'reasonValue' devrait être une des valeurs de l'énuméré 'ReasonInviteToPremium' !
        const reasonValue = lodashGet(currentStoreState, 'contextAppData.reasonInviteToPremium', 'RaisonInconnue');

        // Avant de fermer l'écran, on va demander l'enregistrement du mode de fermeture de l'incitation:
        clientWebApiProvider.saveResultIncitation(dispatch, getState, reasonValue, userChoice)
            .then((response) => { //ok ! sinon, on aurai eu une erreur....
                //RAS !
            })
            .catch((error) => {
                sendError('ActionCloseInviteToPremium - saveResultIncitation', error);
            });

        dispatch({
            type: CLOSE_INCITATION_PREMIUM,
        });
    }
}
///////////////////////////////////////////////////////////////// ↑↑ Fonctionnel de l'incitation au passage en Premium ↑↑

///////////////////////////////////////////////////////////////// ↓↓ Fonctionnel du nom de la page en cours ↓↓
export function ActionSetCurrentPageName(pageName, showTutorial) {
    return ({
        type: SET_CURRENT_PAGE_NAME,
        currentPageName: pageName,
        showTutorialFirstParcelAfterDeletingSlots: showTutorial
    });
}
///////////////////////////////////////////////////////////////// ↑↑ Fonctionnel du nom de la page en cours ↑↑

/* action permettant de présenter l'écran d'inscription dans le cadre d'une inscription via les réseaux sociaux */
export function ActionShowSocialAccountSignupForm() {
    return {
        type: SHOW_SOCIAL_ACCOUNT_SIGNUP_FORM,
    };
}

/* action permettant la suppression de toutes les données dans le store (chaque reducer reprenant son intialState comme valeur par défaut) */
export function ActionResetAppDatas() {
    return {
        type: ActionTypeContextApp.resetAppDatas
    }
}
/* action permettant de set le layerGroupe contenant la position de l'utilisateur */
export function ActionSetGeolocalisationCircleLayers(geolocationCircleLayers) {
    return {
        type: ActionTypeContextApp.setGeolocationCircleLayers,
        geolocationCircleLayers: geolocationCircleLayers,
    }
}

export const ActionShowImageDialog = (uri) => ({
    type: SHOW_IMAGE_DIALOG,
    uri: uri,
})

export const ActionCloseImageDialog = () => ({
    type: CLOSE_IMAGE_DIALOG,
})

/* action permettant de séecltionner la dernière image qui n'est pas une landsat */
export function ActionSelectLastImageNotLandsatToShow(parcelValue) {
    return {
        type: SELECT_LAST_IMAGE_NOT_FROM_LANDSAT_TO_SHOW,
        parcel: parcelValue,
    }
}

/**
 * Met à jour les paramètres de l'application concernant les parcelles, filters, etc...
 * @param {Object} parcelValue valeur de la nouvelle parcelles
 */
export function ActionUpdateParcelContext(parcelValue) {
    return function (dispatch, getState) {
        if ((!parcelValue) || (!parcelValue.details)) return;
        const currentStoreState = getState();
        const parcels = Object.values(lodashGet(currentStoreState, 'parcelsData.parcelDico', {}));

        // Mise à jour de la liste des cultures et/ou des cultures précedentes (pas des varietés, car pas référencés dans le filtre des parcelles)
        if ((parcelValue.details.culture !== undefined) || (parcelValue.details.culturePrecedente !== undefined)) {
            const cropNameList = lodashGet(currentStoreState, 'contextAppData.cropNameList', []);
            
            const newCropNameList = parcels.reduce((acc, parcel) => {
                if ((parcelValue.details.culture !== undefined) && (parcel.details.culture !== '') && (!acc.includes(parcel.details.culture))) {
                    acc.push(parcel.details.culture);
                }
    
                if ((parcelValue.details.culturePrecedente !== undefined) && (parcel.details.culturePrecedente !== '') && (!acc.includes(parcel.details.culturePrecedente))) {
                    acc.push(parcel.details.culturePrecedente);
                }

                return acc;
            }, []);

            //on vérifie que le résultat (nouvelle liste des cultures) est différent en sérialisant le tout:
            if (JSON.stringify(cropNameList) !== JSON.stringify(newCropNameList)) {
                dispatch(ActionUpdateCultures(newCropNameList));
            }
        }

        // Mise a jour de la liste des exploitations
        if ((parcelValue.details.exploitation !== undefined) && (parcelValue.details.exploitation !== '')) {
            const farmNameList = lodashGet(currentStoreState, 'contextAppData.farmNameList', []);
            
            const newFarmNameList = parcels.reduce((acc, parcel) => {
                if (!acc.includes(parcel.details.exploitation)) {
                    acc.push(parcel.details.exploitation);
                }

                return acc;
            }, []);

            //on vérifie que le résultat (nouvelle liste des exploit) est différent en sérialisant le tout:
            if (JSON.stringify(farmNameList) !== JSON.stringify(newFarmNameList)) {
                dispatch(ActionUpdateExploitations(newFarmNameList));
            }
        }
    }
}
