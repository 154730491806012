import React from 'react';
import { connect } from 'react-redux';

/* Components MUI */
import {
    Button, Typography, CircularProgress, Stack,
    Divider, Box, Grid, Card, CardContent, CardActions, CardHeader, Tooltip, Fab,
    IconButton, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText
} from '@mui/material';

import AlertDialog from '../../../components/alertDialog';
import ConstantsLayers from '../../../utils/constantsOfLayers.js';
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

/* Icones */
import CloudIcon from '@mui/icons-material/Cloud';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import AlertIcon from '@mui/icons-material/ErrorOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconeModulation from '../../../assets/images/modulation/iconeModulation.tsx';

/* Redux */
import { ModulationStep, ActionClearModulationValue, ActionBuildModulationSampleAndDoses, ActionGoToStepOfModulation,
    ActionGoToNextStepOfModulation, ActionCleanErrorModulation, ActionSelectLayerForModulation } from '../../../redux/actions/modulations';

/* css */
import '../../../assets/css/swipeablePanel.css';
import '../../../assets/css/modulation.css';

import getTheme from "../../../themes/index.js";


let theme = getTheme();

const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

/**
 * Composant représentant la couche de boutons sur la carte
 */
class MapButtonsPaneModulation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSlectedVisibleLayer: props.forewardLayerSelected === ConstantsLayers.VisibleGlobalLayerName ? true : false,
            openDialogOfErrors: (props.errorModulation !== '') ? true : false,
            openCancelParcel: false,
        };
        
        this.popupErrorDialog = {
            getTitle: () => { return (<AlertIcon />); },
            description: props.errorModulation,
            button: StringTranslate.close,
        };
    }

    onSelectImage() {
        const { clientId, parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, providerSrcImageSelectedOfParcel, rememberModulationSettings,
            clearModulationValue, buildModulationSampleAndDoses, lastModulationSettings } = this.props;

        let modulationParameter;

        //Si le client a demandé à se rappeler des settings de la dernière modulation enregistrée,
        //on renseigne la modulationParameter.
        //DoseChange doit rester à true car il faut générer les doses pour affichage.
        //Si lastModulationSettings.doseChange = false, alors doseChange sera modifié dans dosesModulation.jsx
        let newZones = [
            { actualDose: null, ratio: 0 },
            { actualDose: null, ratio: 0 }];
        if (rememberModulationSettings === true) {
            modulationParameter = {
                clientId: clientId,
                parcelId: parcelIdSelected,
                imagSourceId: satimageIdSelectedOfParcel,
                dateImagSource: satimageDateSelectedOfParcel,
                providerImagSource: providerSrcImageSelectedOfParcel,
                zonesCounter: 2,
                content: lastModulationSettings.content !== undefined ? lastModulationSettings.content : '',
                doseChange: true,
                supplyType: lastModulationSettings.supplyType !== undefined ? lastModulationSettings.supplyType : "Solid",
                distributionType: lastModulationSettings.distributionType !== undefined ? lastModulationSettings.distributionType : true,
                doseNominal: lastModulationSettings.doseNominal !== undefined ? lastModulationSettings.doseNominal : 0,
                doseMedium: lastModulationSettings.doseMedium !== undefined ? lastModulationSettings.doseMedium : 0,
                zones: newZones,
                ratios: newZones,
                dispersion: lastModulationSettings.dispersion !== undefined ? lastModulationSettings.dispersion : 0.3,
            };
        }
        else {
            modulationParameter = {
                clientId: clientId,
                parcelId: parcelIdSelected,
                imagSourceId: satimageIdSelectedOfParcel,
                dateImagSource: satimageDateSelectedOfParcel,
                providerImagSource: providerSrcImageSelectedOfParcel,
                zonesCounter: 2,
                doseChange: true,
                distributionType: true,
                supplyType: "Solid",
                zones: newZones,
                ratios: newZones,
                dispersion: 0.3,
            };
        }
        clearModulationValue(); //si une modulation a déjà été sélectionnée auparavant, vider les valeurs de la modulation :

        // Lance la première génération de modulation pour cette parcelle :
        buildModulationSampleAndDoses(clientId, modulationParameter);
        //Rq: si le retour est positif, on ira automatiquement sur l'étape des doses !!!
    }

    /* Fonction qui permet d'ouvrir ou de fermer la dialog de confirmation d'annulation pour passer
    à la parcelle suivante dans le cas où on a sélectionné plusieurs parcelles */
    handleOpenCancelParcel = (open) => {
        this.setState({
            openCancelParcel: open
        });
    }

    /* Retour à l'étape de la carte pour la prochaine parcelle sélectionnée */
    onGoToNextPlot = () => {

        const { parcelIdsSelected, goToNextStepOfModulation } = this.props;

        let newParcelIdsSelected = [];
        for (var i = 1; i < parcelIdsSelected.length; i++) { //On exclut volontairement l'item à l'index zéro (car on vient de le terminer).
            newParcelIdsSelected.push(parcelIdsSelected[i]);
        }

        this.setState({
            openCancelParcel: false
        });

        goToNextStepOfModulation(ModulationStep.MAP, newParcelIdsSelected, false);
    }

    componentDidMount() {
        this.setState({
            isSlectedVisibleLayer: false
        });

        // On prévient qu'il faut changer de fond de carte: 
        if (this.props.selectLayerForModulation) {
            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            this.props.selectLayerForModulation(ConstantsLayers.VisibleBaseLayerName, ConstantsLayers.NdviParcelLayerName);
        }
    }

    componentWillUnmount() {
        // On prévient qu'il faut changer de fond de carte: 
        if (this.props.selectLayerForModulation) {
            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            this.props.selectLayerForModulation(ConstantsLayers.VisibleBaseLayerName, ConstantsLayers.NdviParcelLayerName);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { errorModulation } = this.props;

        if (((!prevProps) || (!prevProps.errorModulation) || (prevProps.errorModulation === '')) &&
            (errorModulation && (errorModulation !== ''))) {
            this.popupErrorDialog.description = errorModulation;
            this.setState({
                openDialogOfErrors: true
            });
        }
    }

    /* Ferme le dialog des messages d'erreur */
    closeDialogOfErrors = () => {
        this.setState({
            openDialogOfErrors: false,
        });

        //Supprime l'erreur du state Redux !
        if (this.props.cleanErrorModulation) {
            this.props.cleanErrorModulation();
        }
    }

    /* Retour vers "Mes modulations" */
    onGoBackToModulations() {
        const { goBack } = this.props;

        if (goBack) {
            goBack();
            //unselectParcel();   // fonction permettant de ne pas avoir de parcelle sélectionné lors du retour sur l'écran de biomasse
        }
    }

    /**
     * fonction callback lors du click du bouton d'un choix de couche générale (par dessus celle de fond) dont son nom est passé en paramètre
     */
    onSelectGeneralLayer() {
        // ↓↓ suivant le nom du layer passé en paramètre ↓↓
        const { isSlectedVisibleLayer } = this.state;

        let newLastLayer = undefined;

        if (isSlectedVisibleLayer === false) {
            newLastLayer = ConstantsLayers.VisibleGlobalLayerName;
            /* newSettingsToSave = {
                ...this.props.settings,
                lastLayer: 1 /* type *//* ,
}; */
        }
        else {
            newLastLayer = ConstantsLayers.NdviParcelLayerName;
            /*newSettingsToSave = {
                ...this.props.settings,
                lastLayer: 3 /* type *//* ,
}; */
        }

        //On ne modifie pas le paramétrage client dans ce cas !
        // pour ne pas qu'il se retroune sur le layer vue globale visible sur la page "Carto".

        this.setState({
            isSlectedVisibleLayer: isSlectedVisibleLayer === true ? false : true
        });

        // On prévient qu'il faut changer de fond de carte: 
        if (this.props.selectLayerForModulation) {
            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            this.props.selectLayerForModulation(ConstantsLayers.VisibleBaseLayerName, newLastLayer);
        }
    }

    render() {
        const { isSlectedVisibleLayer, openDialogOfErrors, openCancelParcel } = this.state;
        const { satimageIdSelectedOfParcel, buildWaiting } = this.props;

        return (
            <>
                {/* Dialog des erreurs */}
                {openDialogOfErrors === true &&
                    <AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
                }

                { /* ↓↓ partie - Dialog pour confirmer l'annulation de la parcelle en cours et passer à la parcelle suivante - ↓↓ */}
                {openCancelParcel ?
                    <Dialog open={openCancelParcel} onClose={() => this.handleOpenCancelParcel(false)} aria-labelledby="form-dialog-title-modul">
                        <DialogTitle id="form-dialog-title-modul">{StringTranslate.goToNextFieldSkipTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{StringTranslate.goToNextFieldSkipContent}</DialogContentText>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleOpenCancelParcel(false)} variant="text" color="error">
                                {StringTranslate.cancelDeleteAction}
                            </Button>
                            <Button onClick={() => this.onGoToNextPlot(true)} variant="contained" color="primary">
                                {StringTranslate.confirmDeleteAction}
                            </Button>
                        </DialogActions>
                    </Dialog> : null}

                {/* ↓ contrôle (bouton) en lien avec la sélection de layer - mobile OU tablette/PC ↓ */}
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>

                    {/* Bouton de retour vers la pages des modulations */}
                    <Box className='leaflet-top leaflet-right leaflet-PosNone'>

                        <Tooltip title={<Typography color="inherit">{isSlectedVisibleLayer ? StringTranslate.ComeBackToIndicevege : StringTranslate.DetectClouds}</Typography>} placement="left">
                            <Fab
                                className='leaflet-control'
                                size="small"
                                onClick={() => this.onSelectGeneralLayer()}
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    color: theme.palette.primary.dark
                                }}>
                                {isSlectedVisibleLayer ? <CloudOffIcon /> : <CloudIcon />}
                            </Fab>
                        </Tooltip>

                    </Box>

                    <div className='leaflet-bottom leaflet-PosNone leaflet-center-buttons-navigation'>

                        <Card sx={{ marginBottom: "118px", marginRight: "5px", marginLeft: "5px" }}>
                            <CardActions>
                                <Grid container className={(buildWaiting === true) ? "disabledAll" : ""}>

                                    <Grid item xs={4}>

                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => this.onGoBackToModulations()}
                                            tabIndex={12}
                                            sx={{
                                                zIndex: 800,
                                                pointerEvents: "fill",
                                            }}>
                                            <ArrowBackIcon />
                                        </Button>
                                    </Grid>

                                    <Grid item xs={8} align="right">

                                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                                            <Button
                                                variant="text"
                                                color="error"
                                                sx={{
                                                    zIndex: 800,
                                                    pointerEvents: "fill",

                                                    display:"block",
                                                    ...( (this.props.parcelIdsSelected.length > 1) && {
                                                        display:"none"
                                                    })
                                                }}
                                                onClick={() => { this.handleOpenCancelParcel(true) }}
                                                disabled={(satimageIdSelectedOfParcel === undefined) || (satimageIdSelectedOfParcel <= 0)}
                                            >
                                                { StringTranslate.parcelleSuivanteMobile }
                                            </Button>

                                            <Button
                                                variant="text"
                                                color="primary"
                                                startIcon={
                                                    (buildWaiting === false) ?
                                                        <IconeModulation />
                                                        : <CircularProgress
                                                            color="inherit"
                                                            size={24}
                                                        />}
                                                sx={{
                                                    bgcolor: theme.palette.background.paper,
                                                    color: theme.palette.primary.main,
                                                    zIndex: 800,
                                                    pointerEvents: "fill",
                                                }}
                                                onClick={() => this.onSelectImage()}
                                                disabled={(satimageIdSelectedOfParcel === undefined) || (satimageIdSelectedOfParcel <= 0)}
                                            >
                                                {StringTranslate.valider}
                                            </Button>
                                        </Stack>

                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </div>

                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>

                    <Card 
                        className="leaflet-bottom leaflet-left leaflet-PosNone"
                        variant_pz="card-md-bio">

                        <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                            <CardHeader sx={headerSX} title={<Typography variant="h5">{StringTranslate.startModulationImageChoice}</Typography>} />
                            <Divider />
                        </Box>

                        <CardContent className={(buildWaiting === true) ? "disabledAll" : ""}>

                            <Box sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none" } }}>
                                <Typography variant="subtitle1">{StringTranslate.startModulationImageChoice}</Typography>
                            </Box>

                            { /* Layer */}
                            <Grid container alignItems="center" spacing={0} justifyContent="space-between">
                                <Grid item xs={8}>
                                    {isSlectedVisibleLayer ?
                                        <>
                                            <Typography variant="subtitle2" sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none" } }}>
                                                {StringTranslate.goBackToVegetationIndex}
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                                                {StringTranslate.ObserveVisibleLayer}
                                            </Typography>
                                        </>
                                        :
                                        <>
                                            <Typography variant="subtitle2" sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none" } }}>
                                                {StringTranslate.verifClouds}
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                                                {StringTranslate.ObserveCloudLayer}
                                            </Typography>
                                        </>}
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        sx={{
                                            bgcolor: theme.palette.primary.light,
                                            color: theme.palette.primary.main,
                                            '& .MuiSvgIcon-root': { fontSize: '1.5rem' }
                                        }}
                                        size="large"
                                        onClick={() => this.onSelectGeneralLayer()}>
                                        {(isSlectedVisibleLayer === true) ?
                                            <CloudOffIcon /> : <CloudIcon />}
                                    </IconButton>
                                </Grid>
                            </Grid>

                        </CardContent>

                        <CardActions>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Button variant="text" color="primary"
                                        onClick={() => this.onGoBackToModulations()}
                                        tabIndex={12}
                                        disabled={(buildWaiting === true)}
                                    >
                                        {`${StringTranslate.goBackStepBiomass}`}
                                    </Button>
                                </Grid>

                                <Grid item xs={8} align="right">
                                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                                        <Grid container>
                                            <Grid item xs={8}>
                                                <Button
                                                    variant="text"
                                                    color="error"
                                                    sx={{
                                                        display:"block",
                                                        ...( (!(this.props.parcelIdsSelected.length > 1)) && {
                                                            display:"none"
                                                        })
                                                    }}
                                                    onClick={() => { this.handleOpenCancelParcel(true) }}
                                                    disabled={(satimageIdSelectedOfParcel === undefined) || (satimageIdSelectedOfParcel <= 0) || (buildWaiting === true)}
                                                >
                                                    { StringTranslate.parcelleSuivanteDesktop }
                                                </Button>

                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={(buildWaiting === true) && (
                                                        <CircularProgress color="inherit"
                                                            size={24} />)}
                                                    onClick={() => this.onSelectImage()}
                                                    disabled={(satimageIdSelectedOfParcel === undefined) || (satimageIdSelectedOfParcel <= 0) || (buildWaiting === true)}
                                                >
                                                    {`${StringTranslate.next}`}
                                                </Button>

                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardActions>

                    </Card>
                </Box >

            </>
        );
    }
}

const mapStateToProps = state => ({
    //Infos provenant du reducer 'modulation':
    parcelIdsSelected: state.modulationsData.parcelIdsSelected,
    readableMode: state.modulationsData.readableMode,
    buildWaiting: (state && state.modulationsData) ? state.modulationsData.building : false,
    errorModulation: (state && state.modulationsData) ? state.modulationsData.errorMessage : undefined,
    lastModulationSettings: (state && state.modulationsData && state.modulationsData.settings && state.modulationsData.settings.modulationSettings) ? state.modulationsData.settings.modulationSettings : undefined,

    //Infos provenant du reducer 'modulation':
    settings: state.settingsData.settings,
    rememberModulationSettings: (state && state.settingsData && state.settingsData.settings) ? state.settingsData.settings.rememberModulationSettings : true,
    
    //Infos provenant du reducer 'contextApp':
    providerSrcImageSelectedOfParcel: state.contextAppData.providerSrcImageSelectedOfParcel,
    satimageDateSelectedOfParcel: state.contextAppData.satimageDateSelectedOfParcel,
    parcelIdSelected: state.contextAppData.parcelIdSelected,
    satimageIdSelectedOfParcel: state.contextAppData.satimageIdSelectedOfParcel,
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    goBack: () => dispatch(ActionGoToStepOfModulation(ModulationStep.CHOIX_PARCELS, false)),
    clearModulationValue: () => dispatch(ActionClearModulationValue()),
    buildModulationSampleAndDoses: (clientId, modulationParameter) => dispatch(ActionBuildModulationSampleAndDoses(clientId, modulationParameter)),
    goToNextStepOfModulation: (nextStepOfModulation, parcelIdsSelected, readableMode, modulationSelectedItemValue = undefined) =>
        dispatch(ActionGoToNextStepOfModulation(nextStepOfModulation, parcelIdsSelected, readableMode, modulationSelectedItemValue)),
    cleanErrorModulation: () => dispatch(ActionCleanErrorModulation()),
    selectLayerForModulation: (baseLayertypeValue, forewardLayertypeValue) => dispatch(ActionSelectLayerForModulation(baseLayertypeValue, forewardLayertypeValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapButtonsPaneModulation);