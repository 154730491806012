import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import lodashGet from 'lodash/get';

import { Tab, Tabs, Box } from "@mui/material";

import { TabPanel, buildPropsForTab } from '../../components/tabPanel';
import DataGridSkeleton from "../../components/dataGridSkeleton.jsx";

import BiomassesList from "../../components/biomasse/biomassesList";
import ChoixParcelsBiomass from "../../components/biomasse/choixParcelsBiomass";
import WeightingBiomass from "../../components/biomasse/weightingBiomass";
import MapBiomass from "../../components/biomasse/mapBiomass";
import MainCard from "../../components/subLayouts/mainCard.jsx";

import { Add, Balance } from '@mui/icons-material';

import { ActionSetCurrentPageName, PageName } from '../../redux/actions/contextApp.js';
import { ActionGetAllWeightingBiomasses, ActionGoToTabOfBiomass, BiomassStep, BiomassTab } from '../../redux/actions/biomass';

import { ConstantsContent } from '../../utils/constantsContent.js';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

import '../../assets/css/rm-render_maps.css';
import getTheme from "../../themes/index.js";
let theme = getTheme();

///////////////////////////////////////////////////////////////////////////
// Composant général concernant notre carte (gestion et affichage).
///////////////////////////////////////////////////////////////////////////
class AbstractBiomass extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            
        };
    }

    handleChangeTab = (event, newValue) => {
        this.props.goToBiomassTab(newValue);
    }

    componentDidMount() {
        const { setCurrentPageName, getAllWeightingBiomasses, clientId } = this.props;

        setCurrentPageName(PageName.BiomassColza, false);

        if (getAllWeightingBiomasses)
            getAllWeightingBiomasses(clientId);
    }

    getContentOfStepsForBuildBiomass(biomassStep) {

        const { isOpenMenuBurgerDesktop } = this.props;

        //Quand le popup des félicitations est affiché, l'utilisateur est redirigé vers le choix des des parcelles pour la pesée de colza 
        //La liste des pesées est affichée que quand on ferme le popup. Donc forcément, on se retrouve au niveau
        //du choix des parcelles pour la pesée. 

        if (window) {
            window.scrollTo(0, 0); //Permet de se positionner sur la liste des pesées enregistrées.
        }

        if (biomassStep === BiomassStep.WEIGHTING) {
            return (
                <WeightingBiomass />
            );
        }
        else if (biomassStep === BiomassStep.MAP) {
            return (
                <Box
                    className="contentAbstractMap"
                    sx={{
                        [theme.breakpoints.up('md')]: { 
                            left: isOpenMenuBurgerDesktop ? ConstantsContent.DrawerWidth : ConstantsContent.MarginCard,
                            width: isOpenMenuBurgerDesktop ? `calc(100% - ${ConstantsContent.DrawerWidth + ConstantsContent.MarginCard}px)` : `calc(100% - ${ConstantsContent.MarginCard * 2}px)`
                        }
                    }}>
                    <MapBiomass />
                </Box>)
                ;
        } else /*if (biomassStep === BiomassStep.CHOIX_PARCELS)*/ { //par défaut !
            return <>
                <ChoixParcelsBiomass />
            </>;
        }
    }

    /* fonction cycle de vie react */
    render() {
        const { biomassStep, loadWaiting, biomassTab } = this.props;

        return (
            (biomassStep === BiomassStep.CHOIX_PARCELS) ?
          
                    <MainCard>
                    
                        <Tabs
                            value={biomassTab}
                            variant="scrollable"
                            onChange={this.handleChangeTab}
                            variant_pz="row"
                        >
                            {/* menu des onglets: ('buildPropsForTab' génère les ids entre les items de menu et leur contenu respectif) */}
                            <Tab
                                component={Link}
                                to="#"
                                icon={<Add />}
                                label={StringTranslate.listparcelsBiomass}
                                {...buildPropsForTab('menuBiomass', BiomassTab.NewBiomass)} />
                            <Tab
                                component={Link}
                                to="#"
                                icon={<Balance />}
                                label={StringTranslate.weightingBiomassesExistsTitle}
                                {...buildPropsForTab('menuBiomass', BiomassTab.MyBiomass)} />
                        </Tabs>

                    {/* contenu des onglets: */}
                    <TabPanel value={biomassTab} index={BiomassTab.NewBiomass} prefixId='menuBiomass'>
                            <ChoixParcelsBiomass {...this.props} /> {/** // TODO virer this.props (vérifier si le composant n'utilise pas des props de celui-ci) */}
                        </TabPanel>
                    <TabPanel value={biomassTab} index={BiomassTab.MyBiomass} prefixId='menuBiomass'>
                        {(loadWaiting === true) ? (
                            <DataGridSkeleton/>
                        ) : (
                                <BiomassesList />
                            )}
                    </TabPanel>
                    
                    </MainCard>
                       :
                /* rendu des étapes lors de la génération de pesée de biomasse (dont celle de cartographique) */
                this.getContentOfStepsForBuildBiomass(biomassStep)
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        //Infos provenant du reducer 'biomasses':
        biomassTab: lodashGet(state, 'biomassData.biomassTab', BiomassTab.NewBiomass),
        biomassStep: lodashGet(state, 'biomassData.biomassStep', BiomassStep.CHOIX_PARCELS),
        readableMode: lodashGet(state, 'biomassData.readableMode', false),
        loadWaiting: lodashGet(state, 'biomassData.loading', false), //@@RQ: Il en faudrait une lors des appel aux différents 'Build...' !
        hasAskedForEndingBiomass: lodashGet(state, 'biomassData.hasAskedForEndingBiomass', false),

        //Infos provenant du reducer 'settings':
        settings: state.settingsData.settings, // TODO est potentiellement utilisé par un composant enfant, vérifier avant d'enlever
        rowsPerPageForTableBiomasses: lodashGet(state, 'settingsData.settings.rowsPerPageForTableBiomasses', 16),

        //Infos provenant du reducer 'clientUser':
        clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
        clientDatas: state.clientUserData.clientDatas, // TODO est potentiellement utilisé par un composant enfant, vérifier avant d'enlever

        //Infos provenant du reducer 'contextApp':
        isOpenMenuBurgerDesktop: lodashGet(state, 'contextAppData.isOpenMenuBurgerDesktop', false),
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    goToBiomassTab: (biomassTab) => dispatch(ActionGoToTabOfBiomass(biomassTab)),

    //nécessaire pour 'DisplayIncitationPremium':
    getAllWeightingBiomasses: (clientId) => dispatch(ActionGetAllWeightingBiomasses(clientId)),

    //nécessaire pour renseigner le nom de la page en cours
    setCurrentPageName: (pageName, showTutorialFirstParcel) => dispatch(ActionSetCurrentPageName(pageName, showTutorialFirstParcel)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AbstractBiomass);