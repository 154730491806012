import { AreaComparatorLowerCode, AreaComparatorUpperCode } from '../models/parcelFilterParams.js';
import converter from './converter.js';
import turfArea from '@turf/area';
import turfIntersect from '@turf/intersect';
import { polygon, point } from '@turf/helpers';
import turfBooleanPointInPolygon from '@turf/boolean-point-in-polygon';
import lodashGet from 'lodash/get';
import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import numberHelper from './numberHelper.js';
import dicoCultures from '../datas/dicoCultures'; /* liste fixes des cultures */


export const ComparResult = {
    Equal: 0,
    FirstIsSmaller: -1,
    FirstIsGreater: 1
};

/**
 * Helper pour la manipulation des objets 'Parcel' provenant de la WebApi
 */
export const ParcelsHelper = {

    /**
     *  fonction permettant de ...
     */
    filterParcelsFromParams(parcelDico, filter) { //reprends le contenu de la méthode 'applyselection de 'SearchBar.js'
        if (!parcelDico) return {};
        const isFilterApplied = lodashGet(filter, 'isFilterApplied', false);
        if (!filter || (isFilterApplied === false)) return parcelDico;

        let dicoPhase1 = {};
        // Filtre par surface de parcelle :
        if (filter.filterAreaComparator && (filter.filterAreaValue > 0)) {
            // On va d'abord sortir une liste de parcelle remplissant la condition du nombre d'hectare
            // Certain case sont devenu obsolète mais laissé au cas ou (ils seront supprimés quand sera mis sur Spotifarm)
            if (filter.filterAreaComparator === AreaComparatorLowerCode) {
                for (const key in parcelDico) {
                    const parcel = parcelDico[key];
                    if (parcel && parcel.details) {
                        if (parseFloat(parcel.details.surface) < parseFloat(filter.filterAreaValue)) {
                            dicoPhase1[key] = parcel;
                        }
                    }
                }
            } else if (filter.filterAreaComparator === AreaComparatorUpperCode) {
                for (const key in parcelDico) {
                    const parcel = parcelDico[key];
                    if (parcel && parcel.details) {
                        if (parseFloat(parcel.details.surface) > parseFloat(filter.filterAreaValue)) {
                            dicoPhase1[key] = parcel;
                        }
                    }
                }
            } else { //cas de la prise en compte de 'toutes les surfaces'
                dicoPhase1 = parcelDico;
            }
        } else { // sinon, c'est que le filtre n'est pas défini...
            dicoPhase1 = parcelDico;
        }

        let dicoPhase2 = {};
        // Filtre (en exploitant la première liste filtrée) de chaque parcelle dont sa culture a été sélectionné par l'utilisateur:
        //(Sélectionne tout si aucune d'activée)
        if (filter.filterCropList && Array.isArray(filter.filterCropList) && (filter.filterCropList.length > 0)) {
            //minimise les valeurs de filtre:
            let cropsLowerCase = [];
            filter.filterCropList.forEach(itemCropName => {
                const nameLowerCase = itemCropName.toLowerCase();
                cropsLowerCase.push(nameLowerCase);
            });
            
            //Constante définissant qu'il n'a pas été choisi des cultures sur laquelle les parcelles sont filtrées
            const NoFilterForCropName = StringTranslate.allCropFilter;
            const filterCropWithoutName = StringTranslate.noCropNameFilter;
            // Vérifie si on a choisi la case 'toutes':
            const itemAllCropsSelected = (cropsLowerCase.indexOf(NoFilterForCropName) > -1) || 
                (cropsLowerCase.indexOf(NoFilterForCropName.toLowerCase()) > -1);
            if (itemAllCropsSelected === true) {
                dicoPhase2 = dicoPhase1;
            } else {
                // RQ: le cas de la sélection des parcelles sans nom de culture, rentre dans le cas de la comparaison...
                const itemCropWithoutNameSelected = (cropsLowerCase.indexOf(filterCropWithoutName) > -1) || 
                    (cropsLowerCase.indexOf(filterCropWithoutName.toLowerCase()) > -1);

                for (const key in dicoPhase1) {
                    const parcel = dicoPhase1[key];
                    if (parcel && parcel.details && parcel.details.culture) { //cas d'une parcelle correspondant à l'un des choix
                        if (cropsLowerCase.indexOf(parcel.details.culture.toLowerCase()) > -1) {
                            dicoPhase2[key] = parcel;
                        }
                    } else if (parcel && (itemCropWithoutNameSelected === true)) { //cas d'une parcelle sans données/propriétés (donc sans nom d'exploitation !)
                        dicoPhase2[key] = parcel;
                    }
                }
            }
        }
        else { //si aucune culture obtenue des parcelles, on prends tout !
            dicoPhase2 = dicoPhase1;
        }

        let dicoPhase3 = {};
        // Filtre vis à vis du nom des parcelles:
        if (filter.filterParcelName && (filter.filterParcelName !== '')) {
            try {
                let regex = new RegExp('(' + filter.filterParcelName.replace(/\*/gi, '.') + '(.){0,})', 'gi');
                
                for (const key in dicoPhase2) {
                    const parcel = dicoPhase2[key];
                    if (parcel && parcel.name) {
                        if (parcel.name.match(regex)) {
                            dicoPhase3[key] = parcel;
                        }
                    }
                }
            }
            catch(errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
        } else {
            dicoPhase3 = dicoPhase2;
        }

        let dicoPhase4 = {};
        // Filtre vis à vis du nom des exploitations des parcelles:
        if (filter.filterFarmList && (Array.isArray(filter.filterFarmList)) && (filter.filterFarmList.length > 0)) {
            //minimise les valeurs de filtre:
            let farmsLowerCsae = [];
            filter.filterFarmList.forEach(itemFarmName => {
                const nameLowerCase = itemFarmName.toLowerCase();
                farmsLowerCsae.push(nameLowerCase);
            });
            
            //Constante définissant qu'il n'a pas été choisi de nom d'exploitation sur laquelle les parcelles sont filtrées
            const NoFilterForFarmName = StringTranslate.allFarmFilter;
            const filterFarmWithoutName = StringTranslate.noFarmNameFilter;
            // Vérifie si on a choisi la case 'toutes':
            const itemAllFarmsSelected = (/*filter.filterFarmList*/farmsLowerCsae.indexOf(NoFilterForFarmName) > -1) || 
                (farmsLowerCsae.indexOf(NoFilterForFarmName.toLowerCase()) > -1);
            if (itemAllFarmsSelected === true) {
                dicoPhase4 = dicoPhase3;
            } else {
                // RQ: le cas de la sélection des parcelles sans nom d'exploitation, rentre dans le cas de la comparaison...
                const itemFarmWithoutNameSelected = (/*filter.filterFarmList*/farmsLowerCsae.indexOf(filterFarmWithoutName) > -1) || 
                    (farmsLowerCsae.indexOf(filterFarmWithoutName.toLowerCase()) > -1);

                for (const key in dicoPhase3) {
                    const parcel = dicoPhase3[key];
                    if (parcel && parcel.details && parcel.details.exploitation) { //cas d'une parcelle correspondant à l'un des choix
                        if (/*filter.filterFarmList*/farmsLowerCsae.indexOf(parcel.details.exploitation.toLowerCase()) > -1) {
                            dicoPhase4[key] = parcel;
                        }
                    } else if (parcel && (itemFarmWithoutNameSelected === true)) { //cas d'une parcelle sans données/propriétés (donc sans nom d'exploitation !)
                        dicoPhase4[key] = parcel;
                    }
                }
            }
        } else {
            dicoPhase4 = dicoPhase3;
        }

        return dicoPhase4;
    },

    /**
     *  fonction permettant de récupérer une entité parcel
     */
    selectParcelFromDicoById(parcelDico, parcelId) {
        if ((!parcelDico) || (!parcelId) || (!Number.isInteger(parcelId)) || (parcelId <= 0)) {
            return undefined;
        }

        let parcelFund = undefined;
        try {
            const parcelIdStr = `${parcelId}`;
            parcelFund = parcelDico[parcelIdStr];
        } catch (e) {
            //RAS, juste pour pouvoir s'arrêter, sauf si réelle erreur.
        }

        return parcelFund;
    },

    /**
     *  fonction permettant de récupérer la première entité parcel
     */
    selectFirstParcelFromDico(parcelDico) {
        if (!parcelDico) {
            return undefined;
        }

        let parcelFund = undefined;
        try {
            for (const key in parcelDico) {
                const parcel = parcelDico[key];
                if ((!parcelFund) && parcel && parcel.id && parcel.name) {
                    parcelFund = parcel;
                }
            }
        } catch (e) {
            //RAS, juste pour pouvoir s'arrêter, sauf si réelle erreur.
        }

        return parcelFund;
    },

    /**
     *  fonction permettant de ...
     */
    selectAllParcelIds: function(parcelDico) {
        if (!parcelDico) return [];

        // Liste les ids de parcelles:
        let ids = [];
        for (var itemPropName in parcelDico) {
            //ids.push(parcelDico[itemPropName].id); //plus long je pense que :
            try {
                let idValue = parseInt(itemPropName, 10);
                if (isNaN(idValue)) {
                    ids.push(-1);
                } else {
                    ids.push(idValue);
                }
            }
            catch(e) {
                ids.push(-1);
            }
        }
        return ids;
    },

    /**
     *  fonction permettant de ...
     */
    selectFirstParcelId: function(parcelDico) {
        if (!parcelDico) return [];

        // Recherche la première propriété représentant une parcelle:
        let firstId = '';
        for (var itemPropName in parcelDico) {
            try {
                let idValue = parseInt(itemPropName, 10);
                if (!isNaN(idValue)) {
                    firstId = itemPropName;
                    break;
                }
            }
            catch(e) {
            }
        }
        return firstId;
    },

    /**
     * fonction permettant d'obtenir un dico de parcelle suivant la liste d'id de parcelle
     */
    getParcelsDicoFromParcelIdList( parcelDico, parcelIdList) {
        const result = {};
        parcelIdList.forEach(parcelId => {
            if (parcelDico && parcelDico[parcelId]) {
                result[parcelId] = parcelDico[parcelId];
            }
        });
        return result;
    },

    /**
     * fonction permettant d'obtenir un dico de parcelle suivant la liste d'id de parcelle
     */
    getModelisationInfoOfPArcel( ModelisationInfoList, parcelIdToSearch) {
        if ((!ModelisationInfoList) || (parcelIdToSearch === undefined) || (parcelIdToSearch <= 0)) return undefined;

        return ModelisationInfoList.find( (element) => element.parcelId.toString() === parcelIdToSearch.toString() );
    },

    /**
     * fonction de triage de la liste des parcelles pour le tableau en fonction de la culture sélectionnée
     * @param {*} tableGridDatas liste des parcelles pour le tableau
     * @param {*} cultureOfChoice culture par laquelle on veux trier la liste
     * @param {*} parcelIdFilteredList liste des ids de parcelles
     * @param {*} parcelsSelected liste des parcelles déjà sélectionnées
     */
    sortDataByCrop (tableGridDatas, cultureOfChoice, parcelIdFilteredList, parcelsSelected) {
        const hasKeyWords = lodashGet(cultureOfChoice, 'keyWords', null) !== null;
        
        if (parcelIdFilteredList !== undefined && parcelIdFilteredList !== null) {
            let parcelsList = [...tableGridDatas];

            let parcelsListCultureFound = [];
            let parcelsListCultureNotFound = [];
            let parcelsListDisabled = [];

            let parcelIdsSelected = [];

            if (hasKeyWords) {
                let listOfKeyWords = cultureOfChoice.keyWords.split('_');
                parcelsList.forEach(parcel => {
                    if ((parcel.culture !== undefined || parcel.culture !== null) && !parcel.isDisabled) {
                        let isOfCulture = false;
                        listOfKeyWords.forEach(keyword => {
                            if (parcel.culture.toLowerCase().includes(keyword)) {
                                isOfCulture = true;
                            } // else => on conserve la valeur de isOfCulture
                        })

                        if (isOfCulture) {
                            parcelsListCultureFound.push(parcel);
                        } else {
                            parcelsListCultureNotFound.push(parcel);
                        }
                    } else if (!parcel.isDisabled) {
                        parcelsListCultureNotFound.push(parcel);
                    } else {
                        parcelsListDisabled.push(parcel);
                    }
                });
            } else {
                parcelsList.forEach(parcel => {
                    if (parcel.culture !== undefined || parcel.culture !== null) {
                        if (this.hasFertilizerCropSelected(parcel.culture, cultureOfChoice.cropType) === true) {
                            parcelsListCultureFound.push(parcel);
                        }
                        else {
                            parcelsListCultureNotFound.push(parcel);
                        }
                    }
                    else {
                        parcelsListCultureNotFound.push(parcel);
                    }
                });
            }

            parcelsList = [...parcelsListCultureFound, ...parcelsListCultureNotFound, ...parcelsListDisabled];
            
            parcelsListCultureFound.forEach(parcel => {
                if (parcel.isDisabled === false)
                    parcelIdsSelected.push(parcel.id);
            });

            const output_parcelsSelected = (parcelsSelected.length <= 0) ? parcelIdsSelected : parcelsSelected;
            
            return {parcelsList, output_parcelsSelected};
        }
    },

    /* Fonction permettant de savoir si la culture de la parcelle correspond à la culture sélectionnée pour la fumure */
    /**
     * TODO => à revoir pour rendre plus versatile
     * @param {*} parcelCrop 
     * @param {*} fertilizerCrop 
     * @returns 
     */
    hasFertilizerCropSelected(parcelCrop, fertilizerCrop) {
        let found = false;
        switch(fertilizerCrop) {
            case 1: //blé
                if ((parcelCrop.toLowerCase().includes("ble") || parcelCrop.toLowerCase().includes("wheat") || parcelCrop.toLowerCase().includes("bth") || parcelCrop.toLowerCase().includes("blé") || parcelCrop.toLowerCase().includes("trigo"))) {
                    found = true;
                }
                break;
            case 2: //orge
                if ((parcelCrop.toLowerCase().includes("orge") || parcelCrop.toLowerCase().includes("barley") || parcelCrop.toLowerCase().includes("orh") || parcelCrop.toLowerCase().includes("orge 6 rangs d'hiver") || parcelCrop.toLowerCase().includes("escourgeon") || parcelCrop.toLowerCase().includes("cebada")) &&
                    (parcelCrop.toLowerCase().includes("hiver") || parcelCrop.toLowerCase().includes("winter") || parcelCrop.toLowerCase().includes("invierno"))) {
                        found = true;
                }
                break;
            case 3: //colza
                if ((parcelCrop.toLowerCase().includes("colza") || parcelCrop.toLowerCase().includes("czh") || parcelCrop.toLowerCase().includes("czp") || parcelCrop.toLowerCase().includes("rapeseed"))) {
                    found = true;
                }
                break;
            default:
                found = false;
                break;
        }
        return found;
    },

    /**
     *  fonction permettant de calculer le nombre total de parcelles se trouvant dans le dico
     */
    count(parcelDico) {
        if (!parcelDico) return 0;
        return Object.entries(parcelDico).length;
    },
    
    /* fonction permettant de passer d'une liste à un dico - le concept de normalisation des données est ici utilisé */
    convertToDico(parcelList) {
        if ((!parcelList) || (!Array.isArray(parcelList))) {
            return {};
        }

        let dico = {};
        parcelList.forEach(parcel => {
            parcel.dateImport = (parcel.dateImport && !isNaN(Date.parse(parcel.dateImport))) ? new Date(parcel.dateImport) : new Date();
            dico[parcel.id] = parcel;
        });

        return dico;
    },

    /* fonction permettant de récupérer la liste des noms des exploitations des parcelles présentes (génère un tableau {nom, status} de toutes les exploitations de la liste de parcelles) */
    searchFarms(parcelDico) { 
        if (!parcelDico) return [];

        let tableau = [];

        for (const key in parcelDico) {
            const parcel = parcelDico[key];
            if (parcel) {
                let farmFund = false;
                tableau.forEach((farmName) => {
                    if (parcel.details && parcel.details.exploitation && parcel.details.exploitation.toLowerCase() === farmName.toLowerCase()) {
                        farmFund = true;
                    }
                });

                if ((!farmFund) && parcel.details && 
                    parcel.details.exploitation && (parcel.details.exploitation !== '')) {
                    tableau.push(parcel.details.exploitation);
                }
            }
        }

        // trie dans l'ordre alpha du nom des exmploitations trouvées:
		tableau.sort();

        return tableau;
    },

    /* fonction permettant de récupérer la liste des cultures des parcelles présentes (génère un tableau {nom, status} de tous les types de culture de la liste de parcelles) */
    searchCultures(parcelDico) { //corresponds à la méthode anciennement présente sous 'searchBar', nommée 'generateListCulture'
        if (!parcelDico) return [];

        let tableau = [];

        for (const key in parcelDico) {
            const parcel = parcelDico[key];
            if (parcel && parcel.details) {
                let cultureFund = false;
                if (parcel.details.culture && (parcel.details.culture !== '')) { 
                    tableau.forEach((culture) => {
                        if (parcel.details.culture.toLowerCase() === culture.toLowerCase()) {
                            cultureFund = true;
                        }
                    });

                    if (!cultureFund) {
                        tableau.push(parcel.details.culture);
                    }
                }

                //Idem pour les cultures précédentes des parcelles
                cultureFund = false;
                if (parcel.details.culturePrecedente && (parcel.details.culturePrecedente !== '')) {
                    tableau.forEach((culture) => {
                        if (parcel.details.culturePrecedente.toLowerCase() === culture.toLowerCase()) {
                            cultureFund = true;
                        }
                    });

                    if (!cultureFund) {
                        tableau.push(parcel.details.culturePrecedente);
                    }
                }
            }
        }

        // trie dans l'ordre alpha du nom des cultures trouvées:
		tableau.sort();

        return tableau;
    },

    /* fonction permettant d'ajouter les cultures définies sur chacune des parcelles client, si elle n'existe pas déjà dans la liste des cultures communes (liste de Spotifarm) */
    mergeCultures(parcelDico, commonCulturesDico = undefined) { 
        if (!parcelDico) {
            return commonCulturesDico;
        }
        
        let cultures = commonCulturesDico;
        if (!cultures) { 
            //Ajout des cultures pré-définies:
            cultures = dicoCultures();
        }

        //Ajout des cultures trouvées dans la définition des parcelles:
        // RQ : comprends aussi les 'cultures précédentes' définies sur ces parcelles
        ParcelsHelper.searchCultures(parcelDico)
            .forEach(culture => {
                if (!cultures.find(c => c.title === culture))
                    cultures.push({ title: culture });
            });

        cultures = cultures.sort((a, b) => a.title > b.title ? 1 : -1);

        return cultures;
    }, 

    /* fonction permettant de récupérer la liste des variétés des parcelles présentes (génèr un tableau {nom, status} de tous les types de culture de la liste de parcelles) */
    searchVarieties(parcelDico) {
        if (!parcelDico) return [];

        let tableau = [];

        for (const key in parcelDico) {
            const parcel = parcelDico[key];

            if (parcel.details.variete && 
                parcel.details.variete !== '' && 
                !tableau.find(v => v === parcel.details.variete)) {
                tableau.push(parcel.details.variete);
            }
        }
       
        return tableau.sort();
    },
    
    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de récupérer la culture de la parcelle 
    ///////////////////////////////////////////////////////////////////////////
    getCultureByParcel(parcel) { 
        if (!parcel) return null;

        if (parcel.details.culture !== "") {
                return parcel.details.culture;
        }
        return null;
    },

    ///////////////////////////////////////////////////////////////////////////
    // Fonction permettant de trié dans un tableau et placer en premier les parcels en fonction des cultures choisi
    ///////////////////////////////////////////////////////////////////////////
    sortByCulture(parcelsList, isCulture = null) {
        if (parcelsList !== undefined && parcelsList !== null) {
            if (isCulture === "Colza") {
                parcelsList = parcelsList.sort((a, b) => (a.culture.toLowerCase().includes('colza') || a.culture.toLowerCase().includes('rapeseed') || a.culture.toLowerCase().includes('czh') || a.culture.toLowerCase().includes('czp')) ? -1 : 1);
            } else if (isCulture === "Mais") {
                parcelsList = parcelsList.sort((a, b) => (a.culture.toLowerCase().includes('mais') || a.culture.toLowerCase().includes('maïs')) ? -1 : 1);
            } else {
                parcelsList = null;
            }
        }
        return parcelsList;
    },

    ///////////////////////////////////////////////////////////////////////////
    // Fonction permettant de savoir si la culture correspond à celle qu'on souhaite
    ///////////////////////////////////////////////////////////////////////////
    checkCultureIsValid(culture, isCulture = null) {
        if ((isCulture.toLowerCase() === "colza") && ((culture.toLowerCase().includes('colza')) || (culture.toLowerCase().includes('rapeseed')) || (culture.toLowerCase().includes('czh')) || (culture.toLowerCase().includes('czp')))) {
            return true;
        } else if ((isCulture.toLowerCase() === "mais") && ((culture.toLowerCase().includes('mais') || culture.toLowerCase().includes('maïs')))) {
            return true;
        } else {
            return false;
        }
    },

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de calculer la surface depuis un objet parcel
    ///////////////////////////////////////////////////////////////////////////
    getAreaFromParcel(parcel) {
        if (!parcel) return null;

        if (parcel && parcel.turfArea && (parcel.turfArea > 0.0)) {
            return numberHelper.fixeDecimal(parcel.turfArea);
        } else if (parcel && parcel.details && parcel.details.surface && (parcel.details.surface > 0.0)) {
            return numberHelper.fixeDecimal(parcel.details.surface);
        } else if (parcel && parcel.coordinates && parcel.geometryType) {
            const feature = converter.databaseParcelTogeojson(parcel);
            if (feature) {
                return ParcelsHelper.getAreaFromFeature(feature);
            }
        } else if (parcel && parcel.geometry && parcel.geometry.coordinates) {// = feature !
            const feature = parcel;
            return ParcelsHelper.getAreaFromFeature(feature);
        }

        return null;
    },

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de calculer la surface depuis un objet feature
    ///////////////////////////////////////////////////////////////////////////
    getAreaFromFeature(feature) {
        if (!feature) return 0;

        let turfAreaCalculated = 0.00;
        try {
            turfAreaCalculated = (turfArea(feature)) / 10000;
            turfAreaCalculated = numberHelper.fixeDecimal(turfAreaCalculated);
        }
        catch (e) {
            //console.log(e);
        }
        
        return turfAreaCalculated;
    },

    /**
     * fonction permettant de renvoyer la surface commune entre 2 polygones 
     */
    getDatasFrom2PolygonsIntersection(layer1, layer2) {
        if (!layer1 || !layer2) return null;

        try {
            // layer => feature
            let feature1 = layer1.toGeoJSON();
            let feature2 = layer2.toGeoJSON();

            // on récupère les coordonnées des features
            let coordinates1 = (feature1.features && feature1.features[0]) ? feature1.features[0].geometry.coordinates : feature1.geometry.coordinates;
            let coordinates2 = (feature2.features && feature2.features[0]) ? feature2.features[0].geometry.coordinates : feature2.geometry.coordinates;

            // création des polygons version turf.js
            var poly1 = polygon( coordinates1 );
            var poly2 = polygon( coordinates2 );
            
            let intersectedFeature = turfIntersect(poly1, poly2);
            if (intersectedFeature) {
                return {
                    parcelId: lodashGet(feature2, 'features[0].properties.pz_id', -1),
                    parcelName: lodashGet(feature2, 'features[0].properties.pz_name', null),
                    area: parseFloat(ParcelsHelper.getAreaFromFeature(intersectedFeature))
                } 
            }
        } catch (error) {}
        
        return null;
    },

    /**
     * fonction permettant de savoir si un point se trouve dans un polygone (layer)
     */
    pointsWithinPolygon(layer1, layer2) {
        if (!layer1 || !layer2) return null;

        try {
            // layer => feature
            let feature1 = layer1.toGeoJSON();
            let feature2 = layer2.toGeoJSON();

            // on récupère les coordonnées des features
            let coordinates1 = (feature1.features && feature1.features[0]) ? feature1.features[0].geometry.coordinates : feature1.geometry.coordinates;
            let coordinates2 = (feature2.features && feature2.features[0]) ? feature2.features[0].geometry.coordinates : feature2.geometry.coordinates;

            // création des polygons version turf.js
            var pt = point( coordinates1 );
            var poly = polygon( coordinates2 );
            
            let isInPolygon = turfBooleanPointInPolygon(pt, poly);
            if (isInPolygon) {
                return {
                    parcelId: lodashGet(feature2, 'features[0].properties.pz_id', -1),
                    parcelName: lodashGet(feature2, 'features[0].properties.pz_name', null),
                    area: 0,
                } 
            }
        } catch (error) {}
        
        return null;
    }, 

    getParcelNamefromIdParcel(parcelDico, parcelId) {
        const parcelfund = this.selectParcelFromDicoById(parcelDico, parcelId);
        if (parcelfund) {
            return parcelfund.name;
        } else {
            return `ID(${parcelId})`;
        }
    },

    /* comparateur de données d'analyse d'une image: */
    /*compareImageAnalyse(analysOne, analysTwo) {
        if ((analysOne === undefined) || (analysOne.percentages === undefined)) {
            if  ((analysTwo === undefined) || (analysTwo.percentages === undefined)) {
                return ComparResult.Equal;
            } else {
                return ComparResult.FirstIsSmaller;
            }
        } else if ((analysTwo === undefined) || (analysTwo.percentages === undefined)) {
            return ComparResult.FirstIsGreater;
        } else { //les deux sont non-null !
            const lengthOne = analysOne.percentages.length;
            const lengthTwo = analysTwo.percentages.length;
            if (lengthOne < lengthTwo) {
                return ComparResult.FirstIsSmaller;
            } else if (lengthOne > lengthTwo) {
                return ComparResult.FirstIsGreater;
            } else {
                let difFund = ComparResult.Equal;
                let index = 0;
                
                for (let index = 0; index < lengthOne; index++) {
                    const valOne = analysOne.percentages[index];
                    const valTwo = analysOne.percentages[index];
                    if (valOne === undefined) {

                    }
                });
            }
        }
    }*/
}